import React from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Contact = props => {
  const { authenticationStore } = props

  const { currentUser } = authenticationStore
  

  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  if (!currentUser) return null

  return (
    <Row gutter={[20, 10]}>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList>
          <dt>Quê quán</dt>
          <dd>{currentUser?.other?.hometown || nullText}</dd>
          <dt>Nơi ở hiện nay</dt>
          <dd>{currentUser?.address || nullText}</dd>
          <dt>Nơi đăng ký HKTT</dt>
          <dd>{currentUser.other?.registration_location || nullText}</dd>
          <dt>Điện thoại di động</dt>
          <dd>{currentUser?.phone || nullText}</dd>
        </InfoList>
      </Col>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList>
          <dt>Số máy nội bộ</dt>
          <dd>{currentUser?.home_phone || nullText}</dd>
          <dt>Số CMTND</dt>
          <dd>{currentUser?.other?.c_id || nullText}</dd>
          <dt>Ngày cấp</dt>
          <dd>{currentUser?.other?.license_date || nullText}</dd>
          <dt>Số sổ BHXH</dt>
          <dd>{currentUser?.other?.si_no || nullText}</dd>
        </InfoList>
      </Col>
    </Row>
  )
}

Contact.propTypes = {}

export default inject('authenticationStore')(observer(Contact))
