import React, { memo, useEffect, useState } from 'react'
// Components
import { TitleContentBox } from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import DashboardAuthLayout from '../../layouts/DashboardAuthLayout'
import ChangePassword from './ChangePassword'
import Contact from './Contact'
import Education from './Education'
import Health from './Health'
import Job from './Job'
import Overview from './Overview'
// Styled component
import { MenuWrapper } from './MyProfilePageStyled'
// Antd design
import {
  ContactsFilled,
  HeartFilled,
  ReadFilled,
  RedditCircleFilled,
  ScheduleFilled,
  UnlockFilled,
} from '@ant-design/icons'
import { Menu, message } from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import { createBrowserHistory } from 'history'

const menuList = [
  {
    key: 'overview',
    title: 'Tổng quan',
    icon: <RedditCircleFilled style={{ color: blue, fontSize: 16 }} />,
  },
  {
    key: 'contact',
    title: 'Thông tin liên hệ',
    icon: <ContactsFilled style={{ color: blue, fontSize: 16 }} />,
  },
  {
    key: 'job',
    title: 'Công việc',
    icon: <ScheduleFilled style={{ color: blue, fontSize: 16 }} />,
  },
  {
    key: 'education',
    title: 'Trình độ học vấn',
    icon: <ReadFilled style={{ color: blue, fontSize: 16 }} />,
  },
  {
    key: 'health',
    title: 'Sức khỏe',
    icon: <HeartFilled style={{ color: blue, fontSize: 16 }} />,
  },
  {
    key: 'change-password',
    title: 'Thay đổi mật khẩu',
    icon: <UnlockFilled style={{ color: blue, fontSize: 16 }} />,
  },
]

const MyProfilePage = props => {
  const { authenticationStore } = props
  const { setMustChangePassword } = authenticationStore
  const history = createBrowserHistory()

  const [keyInfoActive, setKeyInfoActive] = useState({
    key: 'overview',
    title: 'Tổng quan',
  })

  useEffect(() => {
    const key = props.match.params[0].replace('/', '')
    if (key) {
      const menuItem = menuList.find(item => item.key === key)
      if (menuItem) {
        setKeyInfoActive({
          key: menuItem.key,
          title: menuItem.title,
        })
      }
    }
  }, [])

  const renderInfo = () => {
    if (keyInfoActive.key === 'overview') return <Overview />
    if (keyInfoActive.key === 'contact') return <Contact />
    if (keyInfoActive.key === 'job') return <Job />
    if (keyInfoActive.key === 'education') return <Education />
    if (keyInfoActive.key === 'health') return <Health />
    if (keyInfoActive.key === 'change-password')
      return (
        <ChangePassword
          onAfterPasswordChanged={() => {
            setKeyInfoActive({
              key: 'overview',
              title: 'Tổng quan',
            })
            setMustChangePassword()
          }}
          onCancel={undefined}
        />
      )
  }

  const onChangeTab = item => {
    setKeyInfoActive({
      key: item.key,
      title: item.item.props.title,
    })

    history.push(`/my-profile/${item.key}`)
  }

  return (
    <DashboardAuthLayout>
      <div style={{ marginLeft: 330, marginTop: 50 }}>
        <PageTitle location={{ pathname: '/my-profile*' }} />
      </div>
      <div style={{ display: 'flex', gap: 24 }}>
        <div style={{ width: 300, flex: '0 0 300px' }}>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin của bạn</TitleContentBox>
            <MenuWrapper
              selectedKeys={[keyInfoActive.key]}
              onSelect={onChangeTab}>
              {menuList.map(menuItem => (
                <Menu.Item
                  key={menuItem.key}
                  icon={menuItem.icon}
                  title={menuItem.title}>
                  {menuItem.title}
                </Menu.Item>
              ))}
            </MenuWrapper>
          </ContentBlockWrapper>
        </div>
        <div style={{ flexGrow: 1 }}>
          <ContentBlockWrapper>
            <TitleContentBox>{keyInfoActive.title}</TitleContentBox>
            <div style={{ minHeight: 288 }}>{renderInfo()}</div>
          </ContentBlockWrapper>
        </div>
      </div>
    </DashboardAuthLayout>
  )
}

export default memo(
  inject(
    'authenticationStore',
    'commonStore',
    'loadingAnimationStore'
  )(observer(MyProfilePage))
)
