import axios from 'axios'
import qs from 'querystring'
import { apiUrl, apiUrlTruc, oauth, oauthTruc } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const AuthenticationRequest = {
  userLogin: (identifier, password) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/oauth/token`,
      headers: {
        Authorization: `Basic ${Buffer.from(
          `${oauth.clientId}:${oauth.clientSecret}`,
          'utf8'
        ).toString('base64')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        username: identifier,
        password: password,
        grant_type: 'password',
        scope: 'openid',
      }),
    }),

  userLoginTruc: (identifier, password) =>
    axios({
      method: 'post',
      url: `${apiUrlTruc}/api/oauth/token`,
      headers: {
        Authorization: `Basic ${Buffer.from(
          `${oauthTruc.clientId}:${oauthTruc.clientSecret}`,
          'utf8'
        ).toString('base64')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        username: identifier,
        password: password,
        grant_type: 'password',
        scope: 'openid',
      }),
    }),

  checkCurrentUser: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users/current-user`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  checkUserPasswordExpried: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users/check-user-password-expired`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  checkCurrentUserTruc: () =>
    axios({
      method: 'get',
      url: `${apiUrlTruc}/api/v1/users/current`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateCurrentUserAvatar: formData =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/users/current-user/image`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),

  getCurrentUserAvatar: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users/current-user/image`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'image/jpeg',
      },
      responseType: 'blob',
    }),
}
