import { Button, Checkbox, Form, Modal } from 'antd'
import React from 'react'

const TinTucConfirmSendNotificaitonModal = ({
  visible,
  onConfirm,
  onCancel,
}) => {
  const [form] = Form.useForm()

  return (
    <Modal
      title="Cập nhật tin tức chung"
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      destroyOnClose>
      <p>Bạn có muốn cập nhật tin tức chung này không?</p>
      <Form form={form} onFinish={onConfirm}>
        <Form.Item
          name="is_notification_required"
          initialValue={true}
          valuePropName="checked">
          <Checkbox>Gửi thông báo</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TinTucConfirmSendNotificaitonModal
