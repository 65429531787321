import { BankOutlined, TeamOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import { ASSIGNEE_TYPE } from '../../../constants'
import selectPeopleStore from '../../../stores/selectPeopleStore'
import { AvatarHasName } from '../Avatar'
import { SelectList } from './SelectListComponentRiskStyled'

const SelectListComponentRisk = ({
  userList,
  originDepartmentWithUserList,
  style = {},
  status = '',
}) => {
  return (
    <SelectList style={{ marginBottom: 8, ...style }}>
      {userList?.map((el, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Tag
            className={'tag-selected'}
            color={'blue'}
            key={el.id}
            closable
            onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
            <AvatarHasName
              imgId={el.image_id}
              size={22}
              name={el.full_name}
              icon={
                (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                  <BankOutlined />
                )) ||
                (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
              }
            />
            <span style={{ margin: '0 3px' }}>-</span>
            <span style={{ fontWeight: 600 }}>{el?.position_name}</span>
          </Tag>
        </div>
      ))}
    </SelectList>
  )
}

export default SelectListComponentRisk
