import { observable, action } from 'mobx'
import { RiskRequest } from '../../requests/RiskRequest'

class RiskUnitStore {
  @observable units = {
    NDDPV: [],
    DVPT: [],
    CQVP: [],
    ALL: [],
  }

  @observable selectedUnits = {
    tier3: [],
  }

  @action async getDVPTUnits() {
    const { data: riskUnits } = await RiskRequest.getRiskUnitsV2('DVPT')
    this.units['DVPT'] = riskUnits
  }
}

export default new RiskUnitStore()