import { useCallback } from 'react'

export const useCreatingRiskExecutor = ({
  type,
  currentUser,
  selectUserList,
  riskUnitUserStore,
}) => {
  const getTier1Executors = useCallback(() => {
    let tier1RiskExecutors
    if (type !== 'CQVP') {
      tier1RiskExecutors = [
        ...riskUnitUserStore.selectedRiskExecutors.tier1.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
      if (riskUnitUserStore.customRiskExecutorFullname.tier1?.length > 0) {
        tier1RiskExecutors.push({
          full_name: riskUnitUserStore.customRiskExecutorFullname.tier1,
          statsus: 'CREATED',
        })
      }
    } else {
      tier1RiskExecutors = selectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1.map(
        user => ({
          username: user.id,
          departmentCode: user.department_code,
          status: undefined,
        })
      )
    }
    return tier1RiskExecutors
  }, [
    riskUnitUserStore.customRiskExecutorFullname.tier1,
    riskUnitUserStore.selectedRiskExecutors.tier1,
    selectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1,
    type,
  ])

  const getTier2Executors = useCallback(() => {
    if (type === 'NDDPV') {
      return [
        ...riskUnitUserStore.selectedRiskExecutors.tier2.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
    }

    return [{ username: currentUser.code, status: 'CREATED' }]
  }, [currentUser, riskUnitUserStore.selectedRiskExecutors.tier2, type])

  const getTier1NDDPVgeneral = useCallback(() => {
    let tier1NDDPVgeneral
    if (type === 'NDDPV') {
      tier1NDDPVgeneral = [
        ...riskUnitUserStore.selectedTier1NDDPVgeneral.tier1.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
    } else {
      tier1NDDPVgeneral = selectUserList.unitLeader?.map(user => ({
        username: user.id,
        departmentCode: user.department_code,
        status: 'PENDING',
      }))
    }

    return tier1NDDPVgeneral
  }, [
    riskUnitUserStore.selectedTier1NDDPVgeneral.tier1,
    type,
    selectUserList.unitLeader,
  ])

  const getTier3Executors = useCallback(() => {
    if (type === 'CQVP') {
      return selectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3?.map(user => ({
        username: user.id,
        full_name: user.full_name,
        department_code: user.department_code,
        status: 'CREATED',
      }))
    }

    return [
      ...riskUnitUserStore.selectedRiskExecutors.tier3.map(executor => ({
        ...executor,
        status: 'CREATED',
      })),
    ]
  }, [
    riskUnitUserStore.selectedRiskExecutors.tier3,
    selectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3,
    type,
  ])

  return {
    getTier1Executors,
    getTier2Executors,
    getTier3Executors,
    getTier1NDDPVgeneral,
  }
}
