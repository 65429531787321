import { Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'

const CompanySelect = ({ onChangeCompany, companyCode, ...props }) => {
  const { companyStore } = props

  return (
    <Select
      style={{ minWidth: '250px' }}
      placeholder={'-- Chọn công ty --'}
      allowClear
      value={companyCode}
      onChange={onChangeCompany}>
      {companyStore.companyList?.length > 0 &&
        companyStore.companyList.map(type => (
          <Select.Option key={type.code} value={type.code}>
            {type.name}
          </Select.Option>
        ))}
    </Select>
  )
}

export default inject(
  'companyStore',
  'departmentStore'
)(observer(CompanySelect))
