import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import { Button, Empty, Input, message, Pagination } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import TableComponent from '../../components/Common/TableComponent'
import { StatusTag } from '../../components/Common/StatusTag'
import { AvatarHasName } from '../../components/Common/Avatar'
import { RISK_TYPE, TYPE_STATUS } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import {
  EmptyText,
  Text,
  trimOverLengthString,
} from '../../components/Common/CellText'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import queryString from 'query-string'
import AdvanceFilter from './AdvanceFilter'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import moment from 'moment'
import { toJS } from 'mobx'

const RiskListPage = props => {
  const { history, loadingAnimationStore, riskStore } = props
  const queryStringParse = queryString.parse(location.search)
  const {
    riskList,
    total_count,
    filterPage,
    filterSize,
    type,
    year,
    unitCode,
    createdAt,
  } = riskStore
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  /** Columns table */
  const tableColumns = useMemo(
    () => [
      {
        title: 'Tiêu đề',
        render: record =>
          record.title ? (
            <Text>{trimOverLengthString(record.title, 80)}</Text>
          ) : (
            <EmptyText>Không có tiêu đề.</EmptyText>
          ),
      },
      {
        align: 'center',
        width: 180,
        title: 'Ngày tạo',
        render: record => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {moment(record.created_at).format('DD-MM-YYYY')}
          </div>
        ),
      },
      {
        title: 'Người tạo',
        width: 250,
        render: record => (
          <AvatarHasName
            imgId={record?.image}
            name={record.create_user?.name_lowercase}
          />
        ),
      },
    ],
    []
  )

  const loadRiskList = async () => {
    const queryParams = {
      page: riskStore.filterPage,
      size: riskStore.filterSize,
      type: riskStore.type,
      year: riskStore.year,
      createdAt: riskStore.createdAt,
      unit_code: riskStore.unitCode,
    }
    try {
      await riskStore.getRisks()
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/risk?${queryStringStringify}`)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    }
  }

  useEffect(() => {
    riskStore.setQueryParams(
      'filterPage',
      // @ts-ignore
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    riskStore.setQueryParams(
      'filterSize',
      // @ts-ignore
      queryStringParse.size ? parseInt(queryStringParse.size) : 10
    )
    riskStore.setQueryParams(
      'type',
      queryStringParse.type ?? RISK_TYPE.DELEGATE
    )
    riskStore.setQueryParams(
      'year',
      // @ts-ignore
      queryStringParse.year ? parseInt(queryStringParse.year) : null
    )
    riskStore.setQueryParams('unitCode', queryStringParse.unit_code)
    riskStore.setQueryParams('createAt', queryStringParse.createdAt)
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      setCheckPagination(false)

      try {
        await loadRiskList()
        setIsRefreshPage(false)
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
        setCheckPagination(true)
      }
    })()
    return () => {
      riskStore.clearRiskStore()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      try {
        await loadRiskList()
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
  }, [filterPage, filterSize, type, year, unitCode, createdAt])

  const handleChangePagination = (page, size) => {
    riskStore.setQueryParams('filterPage', page - 1)
    riskStore.setQueryParams('filterSize', size)
  }
  return (
    <DashboardLayout>
      <Helmet>
        <title>Phiếu rủi ro | Danh sách phiếu rủi ro</title>
      </Helmet>
      <PageTitle
        location={location}
        title="Danh sách phiếu rủi ro"
        hiddenGoBack>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__PKS_RUI_RO}>
          <Button
            onClick={() => history.push(`/risk/create?type=${type}`)}
            type={'primary'}
            icon={<PlusOutlined />}>
            Tạo mới
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper>
        <AdvanceFilter store={riskStore} storeName="riskStore" />
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(`/risk/view/${record.code}?type=${type}`),
            }
          }}
          rowKey={record => record.id}
          dataSource={riskList}
          columns={tableColumns}
          pagination={false}
          locale={{
            emptyText:
              checkPagination === true ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ) : (
                <div style={{ opacity: 0.1, height: '150px' }} />
              ),
          }}
          loading={loadingAnimationStore.tableLoading}
        />
        <TableBottomPaginationBlock>
          {checkPagination === true ? (
            <Pagination
              current={filterPage + 1}
              pageSize={filterSize}
              total={total_count}
              onChange={handleChangePagination}
            />
          ) : (
            ''
          )}
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

RiskListPage.propTypes = {
  proposalStore: PropTypes.object,
}

export default inject(
  'riskStore',
  'loadingAnimationStore',
  'selectPeopleStore'
)(observer(RiskListPage))
