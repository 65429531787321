import { Button, Form, Input, Select, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import uuid from 'uuid'
import AccessControlAction from '../../../components/AccessControlAction/AccessControlAction'
import EmptyContent from '../../../components/EmptyContent'
import { ACL_ACTION_TYPE, SIGN_PROVIDER, SIGN_TYPE } from '../../../constants'
import {
  ModalWrapper,
  TitleModal,
} from '../../UserGroupPage/AddUserGroupModal/AddUserGroupModalStyled'

const EditSignAccountModal = props => {
  const {
    isVisibleModal,
    handleCloseModal,
    signAccountStore,
    userStore,
    loadingAnimationStore,
  } = props

  const { Option } = Select
  const [form] = Form.useForm()

  const [allUsers, setAllUsers] = useState([])
  const { signAccountDetails } = signAccountStore

  const [isEdit, setIsEdit] = useState()

  const onFinish = async values => {
    try {
      loadingAnimationStore.showSpinner(true)

      const signAccount = {
        account: values.account,
        application_id: uuid(),
        id: isEdit ? signAccountDetails.id : 0,
        key: '12345a@',
        password: values.password,
        provider: values.provider,
        status: true,
        type: values.type,
        user_name: values.user_name,
      }

      console.log('signAccount', signAccount)

      if (isEdit) {
        await signAccountStore.update(signAccount)
      } else {
        await signAccountStore.create(signAccount)
      }

      message.success(
        isEdit ? 'Chỉnh sửa' : 'Tạo' + ' tài khoản ký số thành công'
      )

      signAccountStore.getList()

      handleCloseModal()
    } catch (error) {
      message.error(error.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)

    userStore
      .getAllUsers()
      .then(response => {
        console.log('response', response)
        setAllUsers(
          response.data.userDetails.map(user => {
            return {
              id: user.userCode,
              display: user.fullName,
              username: user.user_name,
            }
          })
        )
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })

    return () => userStore.clearStore()
  }, [userStore])

  useEffect(() => {
    if (signAccountDetails.id) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
  }, [isVisibleModal])

  return (
    <ModalWrapper
      style={{ top: 20 }}
      title={
        <TitleModal>
          <span>
            {isEdit ? 'Chỉnh sửa tài khoản ký số' : 'Tạo tài khoản ký số mới'}
          </span>
          <div>
            <Button
              onClick={() => {
                form.resetFields()
                handleCloseModal()
              }}>
              Huỷ bỏ
            </Button>
            <AccessControlAction
              aclActionType={
                isEdit
                  ? ACL_ACTION_TYPE.edit__TAI_KHOAN_KY_SO
                  : ACL_ACTION_TYPE.create__TAI_KHOAN_KY_SO
              }>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'}
                onClick={() => {
                  form.submit()
                }}>
                {isEdit ? 'Sửa' : 'Tạo mới'}
              </Button>
            </AccessControlAction>
          </div>
        </TitleModal>
      }
      visible={isVisibleModal}
      footer={null}
      width={600}>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        fields={[
          {
            name: ['provider'],
            value: SIGN_PROVIDER.SAVIS,
            // value: signAccountDetails.provider,
          },
          {
            name: ['user_name'],
            value: signAccountDetails.user_name,
          },
          {
            name: ['account'],
            value: signAccountDetails.account,
          },
          {
            name: ['password'],
            value: signAccountDetails.password,
          },
          {
            name: ['type'],
            value: signAccountDetails.type,
          },
        ]}>
        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Nhà cung cấp'}
          name={'provider'}
          rules={[{ required: true, message: 'Vui chọn nhà cung cấp' }]}>
          {/* <Select
            notFoundContent={<EmptyContent />}
            placeholder={'-- Chọn --'}
            showArrow disabled={isEdit}>
            <Option value={SIGN_PROVIDER.SAVIS} key={SIGN_PROVIDER.SAVIS}>
              {SIGN_PROVIDER.SAVIS}
            </Option>
            <Option value={SIGN_PROVIDER.FPT} key={SIGN_PROVIDER.FPT}>
              {SIGN_PROVIDER.FPT}
            </Option>
          </Select> */}
          <Input disabled />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Người dùng'}
          name={'user_name'}
          rules={[{ required: true, message: 'Vui chọn người dùng' }]}>
          <Select
            notFoundContent={<EmptyContent />}
            placeholder={'-- Chọn --'}
            showArrow
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={isEdit}>
            {allUsers.map(user => (
              <Option value={user.username} key={user.username}>
                {user.display.toUpperCase()}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Tài khoản'}
          name={'account'}
          rules={[{ required: true, message: 'Vui nhập tài khoản' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Mật khẩu'}
          name={'password'}
          rules={[{ required: true, message: 'Vui nhập mật khẩu' }]}>
          <Input.Password autoComplete={'password'} placeholder={'Mật khẩu'} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 12 }}
          label={'Loại tài khoản'}
          name={'type'}
          rules={[{ required: true, message: 'Vui chọn loại tài khoản' }]}>
          <Select
            notFoundContent={<EmptyContent />}
            placeholder={'-- Chọn --'}
            showArrow
            disabled={isEdit}>
            <Option value={SIGN_TYPE.SYSTEM} key={SIGN_TYPE.SYSTEM}>
              {SIGN_TYPE.SYSTEM}
            </Option>
            <Option value={SIGN_TYPE.USER} key={SIGN_TYPE.USER}>
              {SIGN_TYPE.USER}
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </ModalWrapper>
  )
}

export default inject(
  'loadingAnimationStore',
  'authenticationStore',
  'signAccountStore',
  'userStore'
)(observer(EditSignAccountModal))
