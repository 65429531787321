import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Button, Form, Select, message } from 'antd'
import './index.css'
import { systemName } from '../../config'

const CompanyWorkSheduleManagementPage = props => {
  const { documentAccessibilityStore, workScheduleStore, companyStore } = props
  const { companyList } = companyStore

  const [form] = Form.useForm()

  useEffect(() => {
    const fetchData = async () => {
      await workScheduleStore.getWorkSchedulePermissionView()
      const permissionView = workScheduleStore.workSchedulePermissionView

      if (permissionView.length > 0) {
        const accessTypes = permissionView.map(item => item.access_type)
        form.setFieldsValue({
          type: accessTypes,
        })
      }
    }

    fetchData()
  }, [])

  const onFinish = async values => {
    try {
      await documentAccessibilityStore.updateDocumentAccessibilitiesV2({
        type: 'WORK_SCHEDULE',
        access_types: values?.type,
      })
      message.success('Cập nhật quyền hiển thị lịch cơ quan thành công!')
    } catch (error) {
      message.error(error.vi)
      console.log(error)
    }
  }
  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <Helmet>
        <title>Lịch cơ quan | {systemName} Portal</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Lịch cơ quan'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper style={{ minHeight: 600 }}>
        <Form
          className="form-workchedule"
          style={{ paddingLeft: '16px' }}
          onFinish={onFinish}
          // scrollToFirstError={true}
          name={'work-schedule-accessbilities'}
          layout={'vertical'}
          form={form}>
          <Form.Item
            label="Công ty"
            style={{ width: '100%' }}
            name="type"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn lịch cơ quan của công ty',
              },
            ]}>
            <Select
              placeholder="Chọn lịch cơ quan sẽ được hiển thị"
              allowClear
              mode="multiple"
              style={{ width: '50%' }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {companyList.map(item => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button type={'primary'} htmlType={'submit'}>
            Lưu
          </Button>
        </Form>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'workScheduleStore',
    'authenticationStore',
    'documentAccessibilityStore',
    'companyStore'
  )(observer(CompanyWorkSheduleManagementPage))
)
