import { Tag } from 'antd'
import React from 'react'
import utils from '../../utils'
import './RiskSelecUsertListStyle'
import { RiskSelectList, UserTag, UserTagV2 } from './RiskSelecUsertListStyle'

const RiskSelectUserList = ({ userList }) => {
  return (
    <div>
      {userList[0] && userList[0]?.permission === 'LEAD' ? (
        <div style={{ marginTop: '-5px' }}>
          {userList?.map(user => (
            <div key={user?.id} style={{ marginTop: 4, height: '30px' }}>
              <UserTagV2>
                {utils.getNameInCapitalize(user?.full_name)}
                {user?.position_name ? (
                  <>
                    - <span>{user?.position_name}</span>
                  </>
                ) : null}
              </UserTagV2>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {userList?.map(user => (
            <div key={user?.id} style={{ marginTop: 4, height: '30px' }}>
              <UserTagV2>
                {utils.getNameInCapitalize(user?.full_name)}
                {user?.position_name ? (
                  <>
                    - <span>{user?.position_name}</span>
                  </>
                ) : null}
              </UserTagV2>
              {/* tạm thời ẩn */}
              {/* {user?.permission === 'don_vi_thuc_hien_xu_ly_rui_ro_3' ||
              user?.permission === 'chuyen_vien_thuc_hien_cqvp_tuyen1' ? (
                ''
              ) : (
                <>
                  {user?.status === 'PENDING' ? (
                    <Tag
                      color={'orange'}
                      style={{
                        marginTop: 4,
                        borderRadius: 10,
                      }}>
                      Chờ duyệt
                    </Tag>
                  ) : user?.status === 'APPROVED' ? (
                    <Tag
                      color={'green'}
                      style={{ marginTop: 4, borderRadius: 10 }}>
                      Đã duyệt
                    </Tag>
                  ) : user?.status === 'REJECTED' ? (
                    <Tag
                      color={'red'}
                      style={{ marginTop: 4, borderRadius: 10 }}>
                      Đã từ chối
                    </Tag>
                  ) : (
                    ''
                  )}
                </>
              )} */}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default RiskSelectUserList
