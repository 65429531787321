import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import EmptyContent from '../../components/EmptyContent'
import DashboardLayout from '../../layouts/DashboardLayout'
import validator from '../../validator'
import { systemName } from '../../config'

const { Option } = Select

const DocumentBooksPage = props => {
  const { loadingAnimationStore, bookStore, commonStore, history } = props

  const [formUpdateBookGroup] = Form.useForm()
  const [formCreateBookGroup] = Form.useForm()
  const [formUpdateBook] = Form.useForm()
  const [formCreateBook] = Form.useForm()

  const { TabPane } = Tabs

  const [pageTitle, setPageTitle] = useState('Cấu hình nhóm sổ văn bản')

  const changeTab = key => {
    setPageTitle(key)
  }

  // Group book
  const [selectedBookGroup, setSelectedBookGroup] = useState(null)

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .getBookGroup()
      .catch(error => message.error(error.vi))
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
    return () => {
      bookStore.clearBookGroupList()
    }
  }, [])

  useEffect(() => {
    setFormUpdateBookGroupDetail()
  }, [selectedBookGroup])

  const [showModalUpdateBookGroup, setShowModalUpdateBookGroup] = useState(
    false
  )
  const [showModalCreateBookGroup, setShowModalCreateBookGroup] = useState(
    false
  )

  const tableColumnsBookGroup = [
    {
      title: 'Tên nhóm sổ văn bản',
      width: 90,
      render: record => record.name,
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record => (
        <Fragment>
          <Tooltip title={'Sửa thông tin nhóm sổ văn bản'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickUpdateBookGroup(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá nhóm sổ văn bản'}>
            <Popconfirm
              onConfirm={() => handleDeleteBookGroup(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá nhóm sổ văn bản này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>
      ),
    },
  ]

  const handleClickUpdateBookGroup = selectedBookGroup => {
    setSelectedBookGroup(selectedBookGroup)
    setShowModalUpdateBookGroup(true)
    setFormUpdateBookGroupDetail()
  }
  const setFormUpdateBookGroupDetail = () => {
    formUpdateBookGroup.setFieldsValue({
      id: selectedBookGroup && selectedBookGroup.id,
      name: selectedBookGroup && selectedBookGroup.name,
      type: selectedBookGroup && selectedBookGroup.type,
    })
  }
  const handleUpdateBookGroup = values => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .updateBookGroup(values.id, values.name, values.type)
      .then(() => {
        bookStore
          .getBookGroup()
          .then(response => {
            setShowModalUpdateBookGroup(false)
            message.success('Cập nhật nhóm sổ văn bản thành công!')
            return Promise.resolve(response)
          })
          .catch(error => Promise.reject(error))
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const handleCreateBookGroup = values => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .createBookGroup(values.name, values.type)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Tạo nhóm sổ văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
      .finally(() => {
        setShowModalCreateBookGroup(false)
        bookStore.getBookGroup()
      })
  }

  const handleDeleteBookGroup = id => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .deleteBookGroup(id)
      .then(() => {
        message.success('Xóa nhóm sổ văn bản thành công!')
      })
      .catch(error => message.error(error.vi))
      .finally(() => {
        bookStore.getBookGroup()
        loadingAnimationStore.showSpinner(false)
      })
  }

  // Book
  const [selectedBook, setSelectedBook] = useState(null)

  useEffect(() => {
    formUpdateBook.setFieldsValue({
      book_group_id: selectedBook && selectedBook.book_group_id,
      id: selectedBook && selectedBook.id,
      name: selectedBook && selectedBook.name,
      type: selectedBook && selectedBook.type,
    })
  }, [selectedBook])

  const [showModalUpdateBook, setShowModalUpdateBook] = useState(false)
  const [showModalCreateBook, setShowModalCreateBook] = useState(false)

  const tableColumnsBook = [
    {
      title: 'Tên sổ văn bản',
      width: 90,
      render: record => record.name,
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record => (
        <Fragment>
          <Tooltip title={'Sửa thông tin sổ văn bản'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickUpdateBook(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá sổ văn bản'}>
            <Popconfirm
              onConfirm={() => handleDeleteBook(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá sổ văn bản này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>
      ),
    },
  ]

  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)

  const handleChangeBookGroup = id => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .getBookByBookGroupId(id)
      .then(() => setSelectedBookGroupId(id))
      .catch(error => message.error(error.vi))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }
  const handleClickUpdateBook = selectedBook => {
    setSelectedBook(selectedBook)
    setShowModalUpdateBook(true)
  }
  const handleUpdateBook = values => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .updateBook(values.book_group_id, values.id, values.name, values.type)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        setSelectedBookGroupId(values.book_group_id)
        message.success('Cập nhật sổ văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
      .finally(() => {
        setShowModalUpdateBook(false)
        bookStore.getBookByBookGroupId(
          selectedBookGroupId && selectedBookGroupId
        )
      })
  }
  const handleCreateBook = values => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .createBook(values.id, values.name)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        setSelectedBookGroupId(values.id)
        message.success('Tạo sổ văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
      .finally(() => {
        formCreateBook.resetFields()
        setShowModalCreateBook(false)
        bookStore.getBookByBookGroupId(values.id)
      })
  }
  const handleDeleteBook = id => {
    loadingAnimationStore.showSpinner(true)
    bookStore
      .deleteBook(id)
      .then(() => {
        message.success('Xóa sổ văn bản thành công!')
      })
      .catch(error => message.error(error.vi))
      .finally(() => {
        bookStore.getBookByBookGroupId(
          selectedBookGroupId && selectedBookGroupId
        )
        loadingAnimationStore.showSpinner(false)
      })
  }
  const handleCancelCreateBookGroup = () => {
    setShowModalCreateBookGroup(false)
  }
  const handleCancelCreateBook = () => {
    setShowModalCreateBook(false)
  }
  const handleClickCreateBookGroup = () => {
    formCreateBookGroup.resetFields()
    setShowModalCreateBookGroup(true)
  }
  const handleClickCreateBook = () => {
    formCreateBook.resetFields()
    setShowModalCreateBook(true)
  }
  const handleCancelUpdateBookGroup = useCallback(() => {
    setShowModalUpdateBookGroup(false)
    formUpdateBookGroup.resetFields()
  }, [])

  return (
    <>
      {!props.isTab ? (
        <DashboardLayout>
          <Helmet>
            <title>Cấu hình nhóm sổ văn bản | {systemName} Portal</title>
          </Helmet>
          <PageTitle location={props.location} title={pageTitle}>
            {pageTitle === 'Cấu hình nhóm sổ văn bản' ? (
              <Button type={'primary'} onClick={handleClickCreateBookGroup}>
                <PlusCircleOutlined />
                Tạo mới nhóm sổ văn bản
              </Button>
            ) : (
              <Button type={'primary'} onClick={handleClickCreateBook}>
                <PlusCircleOutlined />
                Tạo mới sổ văn bản
              </Button>
            )}
          </PageTitle>
          <ContentBlockWrapper>
            <Tabs defaultActiveKey="1" onChange={changeTab}>
              <TabPane
                tab="Cấu hình nhóm sổ văn bản"
                key="Cấu hình nhóm sổ văn bản">
                <Table
                  columns={tableColumnsBookGroup}
                  dataSource={bookStore.bookGroupList}
                  pagination={false}
                  rowKey={record => record.id}
                />
              </TabPane>
              <TabPane tab="Cấu hình sổ văn bản" key="Cấu hình sổ văn bản">
                <Select
                  onChange={handleChangeBookGroup}
                  notFoundContent={<EmptyContent />}
                  value={selectedBookGroupId}
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                  }}
                  placeholder={'-- Chọn nhóm sổ văn bản --'}>
                  {bookStore.bookGroupList &&
                    bookStore.bookGroupList.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
                {bookStore.bookListOfGroup &&
                  bookStore.bookListOfGroup.length > 0 && (
                    <Table
                      columns={tableColumnsBook}
                      dataSource={bookStore.bookListOfGroup}
                      pagination={false}
                      rowKey={record => record.id}
                    />
                  )}
              </TabPane>
            </Tabs>
          </ContentBlockWrapper>
        </DashboardLayout>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {pageTitle === 'Cấu hình nhóm sổ văn bản' ? (
              <Button type={'primary'} onClick={handleClickCreateBookGroup}>
                <PlusCircleOutlined />
                Tạo mới nhóm sổ văn bản
              </Button>
            ) : (
              <Button type={'primary'} onClick={handleClickCreateBook}>
                <PlusCircleOutlined />
                Tạo mới sổ văn bản
              </Button>
            )}
          </div>
          <ContentBlockWrapper>
            <Tabs defaultActiveKey="1" onChange={changeTab}>
              <TabPane
                tab="Cấu hình nhóm sổ văn bản"
                key="Cấu hình nhóm sổ văn bản">
                <Table
                  columns={tableColumnsBookGroup}
                  dataSource={bookStore.bookGroupList}
                  pagination={false}
                  rowKey={record => record.id}
                />
              </TabPane>
              <TabPane tab="Cấu hình sổ văn bản" key="Cấu hình sổ văn bản">
                <Select
                  onChange={handleChangeBookGroup}
                  notFoundContent={<EmptyContent />}
                  value={selectedBookGroupId}
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                  }}
                  placeholder={'-- Chọn nhóm sổ văn bản --'}>
                  {bookStore.bookGroupList &&
                    bookStore.bookGroupList.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
                {bookStore.bookListOfGroup &&
                  bookStore.bookListOfGroup.length > 0 && (
                    <Table
                      columns={tableColumnsBook}
                      dataSource={bookStore.bookListOfGroup}
                      pagination={false}
                      rowKey={record => record.id}
                    />
                  )}
              </TabPane>
            </Tabs>
          </ContentBlockWrapper>
        </>
      )}
      <Modal
        visible={showModalUpdateBook}
        footer={null}
        getContainer={false}
        onCancel={() => setShowModalUpdateBook(false)}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Sửa thông tin sổ văn bản
          </Fragment>
        }>
        <Form
          form={formUpdateBook}
          layout={'vertical'}
          onFinish={handleUpdateBook}>
          <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
            <Input disabled={true} placeholder={'Nhập tên sổ văn bản'} />
          </Form.Item>

          <Form.Item label={'Nhóm sổ văn bản'} name={'book_group_id'}>
            <Select
              disabled={true}
              notFoundContent={<EmptyContent />}
              style={{
                width: '100%',
                marginBottom: '15px',
              }}
              placeholder={'-- Chọn nhóm sổ văn bản --'}>
              {bookStore.bookGroupList &&
                bookStore.bookGroupList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={'Tên sổ văn bản'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên sổ văn bản!',
              },
            ]}>
            <Input placeholder={'Nhập tên sổ văn bản'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setShowModalUpdateBook(false)}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>
                Cập nhật
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={showModalCreateBook}
        footer={null}
        getContainer={false}
        onCancel={handleCancelCreateBook}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Tạo mới sổ văn bản
          </Fragment>
        }>
        <Form
          form={formCreateBook}
          layout={'vertical'}
          onFinish={handleCreateBook}>
          <Form.Item
            label={'Chọn nhóm sổ văn bản'}
            name={'id'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn nhóm sổ văn bản!',
              },
            ]}>
            <Select
              notFoundContent={<EmptyContent />}
              placeholder="-- Chọn nhóm sổ văn bản --">
              {bookStore.bookGroupList &&
                bookStore.bookGroupList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Tên sổ văn bản'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên sổ văn bản!',
              },
            ]}>
            <Input placeholder={'Nhập tên sổ văn bản'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={handleCancelCreateBook}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>
                Tạo mới
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={showModalUpdateBookGroup}
        footer={null}
        getContainer={false}
        onCancel={handleCancelUpdateBookGroup}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Sửa thông tin nhóm sổ văn bản
          </Fragment>
        }>
        <Form
          form={formUpdateBookGroup}
          layout={'vertical'}
          onFinish={handleUpdateBookGroup}>
          <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
            <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
          </Form.Item>
          <Form.Item
            label={'Tên nhóm sổ văn bản'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên nhóm sổ văn bản!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
          </Form.Item>
          <Form.Item
            label={'Loại nhóm sổ văn bản'}
            name={'type'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn loại nhóm sổ văn bản!',
              },
            ]}>
            <Select placeholder={'Chọn loại nhóm sổ'} showArrow>
              <Option value={'DI'}>Nhóm sổ đi</Option>
              <Option value={'DEN'}>Nhóm sổ đến</Option>
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setShowModalUpdateBookGroup(false)}>
                Hủy
              </Button>
              <Button type={'primary'} htmlType={'submit'}>
                Cập nhật
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={showModalCreateBookGroup}
        footer={null}
        getContainer={false}
        onCancel={handleCancelCreateBookGroup}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Tạo mới nhóm sổ văn bản
          </Fragment>
        }>
        <Form
          form={formCreateBookGroup}
          layout={'vertical'}
          onFinish={handleCreateBookGroup}>
          <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
            <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
          </Form.Item>
          <Form.Item
            label={'Tên nhóm sổ văn bản'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên nhóm sổ văn bản!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
          </Form.Item>
          <Form.Item
            label={'Loại nhóm sổ văn bản'}
            name={'type'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn loại nhóm sổ văn bản!',
              },
            ]}>
            <Select placeholder={'Chọn loại nhóm sổ'} showArrow>
              <Option value={'DI'}>Nhóm sổ đi</Option>
              <Option value={'DEN'}>Nhóm sổ đến</Option>
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={handleCancelCreateBookGroup}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'}>
                Tạo mới
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default memo(
  withRouter(
    inject(
      'loadingAnimationStore',
      'bookStore',
      'commonStore'
    )(observer(DocumentBooksPage))
  )
)
