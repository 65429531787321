import { Checkbox, Form, Modal } from 'antd'
import React from 'react'

const CompanyWorkScheduleConfirmSendNotificaitonModal = ({
  visible,
  onConfirm,
  onCancel,
  isLoading,
}) => {
  const [form] = Form.useForm()

  return (
    <Modal
      title="Cập nhật lịch công tác"
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      destroyOnClose
      confirmLoading={isLoading}>
      <p>Bạn có muốn cập nhật lịch công tác này không?</p>
      <Form form={form} onFinish={onConfirm}>
        <Form.Item
          name="is_notification_required"
          initialValue={true}
          valuePropName="checked">
          <Checkbox>Gửi thông báo</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CompanyWorkScheduleConfirmSendNotificaitonModal
