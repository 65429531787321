import React, { useEffect, useState } from 'react'
// Component
// Style Component
import {
  ModalWrapper,
  TitleModal,
} from './PopupHandleConnectedDocumentOutgoingStyled'
// Ant design
import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Space, Tag, Tooltip, message } from 'antd'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import { toJS } from 'mobx'
import { blue, yellowPrimary } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'
import SelectGroupPopupTruc from '../../components/SelectGroupPopupTruc'
import SelectPeoplePopupTruc from '../../components/SelectPeoplePopupTruc'
import { ASSIGNEE_TYPE, OUTGOING_DOCUMENT_STATE } from '../../constants'
import { SelectTitle } from '../ConnectedDocumentOutgoingDocUpdatePage/ConnectedDocumentOutgoingDocUpdatePageStyled'
import { SelectList } from '../MissionCreatePage/MissionCreatePageStyled'

const PopupHandleConnectedDocumentOutgoing = props => {
  const {
    loadingAnimationStore,
    selectPeopleStore,
    userTrucStore,
    connectedDocumentStore,
  } = props

  const { clearSelected } = selectPeopleStore

  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    handleCancelSelectGroupTruc,
    selectUserDataTruc,
    selectGroupDataTruc,
    handleOpenSelectGroupPopupTruc,
    isVisibleSelectGroupPopupTruc,
  } = userTrucStore

  const { selectedOutgoingDocument } = connectedDocumentStore

  const { listUserTo } = selectedOutgoingDocument

  const {
    isVisiblePopupHandle,
    handleCloseHandleDocument,
    handleCancelHandleDocument,
    documentId,
  } = props

  const [selectedListUserTo, setSelectedListUserTo] = useState([])
  useEffect(() => {
    const userList = toJS(listUserTo)
      ?.filter(el => el.type === ASSIGNEE_TYPE.USER)
      .map(user => {
        return {
          id: user.userName,
          name: user.name,
        }
      })
    const groupList = toJS(listUserTo)
      ?.filter(el => el.type === ASSIGNEE_TYPE.GROUP)
      .map(group => {
        return {
          id: parseInt(group.userName),
          name: group.name,
        }
      })
    userList && userTrucStore.setSelectUserDataTruc(userList)
    groupList && userTrucStore.setSelectGroupDataTruc(groupList)
  }, [listUserTo])
  useEffect(() => {
    setSelectedListUserTo([
      ...toJS(selectUserDataTruc),
      ...toJS(selectGroupDataTruc),
    ])
  }, [selectUserDataTruc, selectGroupDataTruc])

  const handleSubmit = async () => {
    if (selectedListUserTo.length === 0) {
      return message.warning('Vui lòng chọn công ty nhận!')
    }

    const selectUserDataValue = selectUserDataTruc.map(user => {
      return {
        id: user.id,
        type: ASSIGNEE_TYPE.USER,
      }
    })

    const selectGroupDataValue = selectGroupDataTruc.map(group => {
      return {
        id: group.id,
        type: ASSIGNEE_TYPE.GROUP,
      }
    })

    const listUserToValue = [...selectUserDataValue, ...selectGroupDataValue]

    loadingAnimationStore.showSpinner(true)

    try {
      const submitValues = {
        listUserTo: listUserToValue,
        action: OUTGOING_DOCUMENT_STATE.CHANGE.key,
      }
      await handleUpdateOutgoingDocument(submitValues)
      handleCloseHandleDocument()
      message.success(`Thay đổi nơi nhận thành công!`)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleUpdateOutgoingDocument = async submitData => {
    try {
      await connectedDocumentStore.updateDocumentReceive(documentId, submitData)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  return (
    <ModalWrapper
      closable={false}
      footer={null}
      width={900}
      title={
        <TitleModal>
          <span className={'title-modal'}>Xử lý văn bản</span>
          <Space>
            <Button
              danger
              onClick={() => {
                handleCancelHandleDocument()
                clearSelected()
              }}>
              Hủy bỏ
            </Button>
            <Button type={'primary'} onClick={handleSubmit}>
              Đồng ý
            </Button>
          </Space>
        </TitleModal>
      }
      visible={isVisiblePopupHandle}>
      <SelectTitle>
        <span>
          <span
            style={{
              color: '#ff4d4f',
              fontFamily: 'SimSun, sans-serif',
              marginRight: '-4px',
            }}>
            *{' '}
          </span>
          <span style={{ marginRight: '10px' }}>Công ty nhận:</span>
        </span>
        <Space>
          <Tooltip title="Chọn người dùng" color={blue}>
            <Button
              icon={<UserOutlined style={{ color: blue }} />}
              onClick={() => handleOpenSelectUserPopupTruc(false)}
            />
          </Tooltip>
          <Tooltip title="Chọn nhóm" color={'#ffc069'}>
            <Button
              icon={<TeamOutlined />}
              style={{ color: yellowPrimary }}
              onClick={() => handleOpenSelectGroupPopupTruc(false)}
            />
          </Tooltip>
        </Space>
      </SelectTitle>
      <SelectList>
        {selectedListUserTo.map(el => (
          <Tag
            className={'tag-selected'}
            color={'blue'}
            key={el.id}
            closable
            onClose={() => userTrucStore.handleRemoveSelectTruc(el)}>
            <AvatarHasName
              imgId={el.image_id}
              size={22}
              name={el.name}
              icon={typeof el.id === 'number' && <TeamOutlined />}
            />
          </Tag>
        ))}
      </SelectList>
      <SelectPeoplePopupTruc
        isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
        handleCancelSelectUserTruc={handleCancelSelectUserTruc}
        handleSubmitSelectUserTruc={() =>
          userTrucStore.setIsVisibleSelectUserPopupTruc(false)
        }
      />
      <SelectGroupPopupTruc
        isVisibleSelectGroupPopupTruc={isVisibleSelectGroupPopupTruc}
        handleCancelSelectGroupTruc={handleCancelSelectGroupTruc}
        handleSubmitSelectGroupTruc={() =>
          userTrucStore.setIsVisibleSelectGroupPopupTruc(false)
        }
      />
    </ModalWrapper>
  )
}

PopupHandleConnectedDocumentOutgoing.propTypes = {}

export default inject(
  'internalDocumentStore',
  'loadingAnimationStore',
  'selectPeopleStore',
  'userTrucStore',
  'connectedDocumentStore'
)(observer(PopupHandleConnectedDocumentOutgoing))
