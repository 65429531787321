import React, { useCallback, useEffect, useRef, useState } from 'react'
// Component
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
// Styled Component
import { FormButtonRow } from './SubmissionFormStyled'
import { SelectList } from '../../pages/MissionCreatePage/MissionCreatePageStyled'
// Ant design
import {
  BankOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
  DatePicker,
  Checkbox,
  Radio,
  Select,
  Divider,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import {
  ASSIGNEE_TYPE,
  PROPOSAL_STATUS,
  DATE_FORMAT_LIST,
  ISO_DATE_FORMAT,
} from '../../constants'
import RichEditor from '../RichEditor/RichEditor'
import SelectPeoplePopup from '../SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../Common/Avatar'
import ProposalStepApprove from './ProposalStepApprove'
import moment from 'moment'
import NumericInput from '../Common/NumericInput'
import validator from './../../validator'

const { TextArea } = Input
const { RangePicker } = DatePicker

const currentStep = 0
const OtherSubmissionForm = props => {
  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    authenticationStore,
    onCloseModal,
    proposalTypeById,
    handleModalClose,
    handleDownloadAttachment,
    contentTemplateStore,
    selectPeopleStore,
    approvalTemplateStore,
    approvalTemplateList,
    form,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const { items, content } = contentTemplateStore
  const { formProposalType, formInputTemplate } = proposalStore

  const EDITOR_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [reasonForLeaving, setReasonForLeaving] = useState('')
  const [overtime, setOvertime] = useState('')
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [approvalLevels, setApprovalLevels] = useState([])
  const [selectedContentKey, setSelectedContentKey] = useState(
    proposalTypeById?.contentKey || null
  )

  //Lấy approvalLevels
  const getApprovalLevelTemplate = async code => {
    try {
      const response = await approvalTemplateStore.getApprovalLevelTemplateByATCode(
        code
      )
      const approvalLevelsChange = response.data.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.isDeleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )

      setApprovalLevels(approvalLevelsChange)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }
  const [
    selectedApproveTemplateCode,
    setSelectedApproveTemplateCode,
  ] = useState(proposalTypeById?.approvalTemplateDTO?.code || null)
  useEffect(() => {
    ; (async () => {
      if (proposalTypeById?.approvalTemplateDTO == null) {
        if (approvalTemplateList?.length > 0) {
          const template = approvalTemplateList.find(
            template => template.approval_levels?.length === 3
          )
          if (template) {
            setSelectedApproveTemplateCode(template.code)
            await getApprovalLevelTemplate(template.code)
            return
          }
        }
        setApprovalLevels([])
        return
      }
      setSelectedApproveTemplateCode(
        proposalTypeById?.approvalTemplateDTO?.code
      )
      await getApprovalLevelTemplate(
        proposalTypeById?.approvalTemplateDTO?.code
      )
    })()
  }, [authenticationStore.currentUser, proposalTypeById])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  // useEffect(() => {
  //   // console.log('formInputTemplate', formInputTemplate)
  //
  //   if (formInputTemplate) {
  //     setEditorContent(formInputTemplate)
  //   }
  //
  //   // console.log('editorContent', editorContent)
  // }, [formInputTemplate])

  /** Xử lý thay đổi file upload */
  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  /** Xử lý ghép API tạo phiếu trình nghỉ phép */
  const handleCreateOther = async data => {
    try {
      await proposalStore.createOther(data)
      proposalStore.setActiveTabProposal('')
      proposalStore.clearFilterProposal()
      proposalStore.resetFormProposalType()
      onCloseModal()
      await proposalStore.getProposals()
      message.success('Tạo mới thành công!')
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  const onFinish = async values => {
    let hasWarning = false
    proposalStore.defaultApprovalConfigUpdate.approvalLevels.forEach(item => {
      if (!item?.username) {
        message.warning(`Hãy chọn ${item.name}!`)
        hasWarning = true
      }
    })
    if (values.title.trim() == '') {
      message.warning(`Hãy nhập tiêu đề!`)
      hasWarning = true
    }
    if (hasWarning) return
    let fromDate =
      values?.date_picker && values?.date_picker.length == 2
        ? moment(values?.date_picker[0]).format(ISO_DATE_FORMAT)
        : ''
    let toDate =
      values?.date_picker && values?.date_picker.length == 2
        ? moment(values?.date_picker[1]).format(ISO_DATE_FORMAT)
        : ''
    const approvalLevels = proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
      (approvalLevel, index) => ({
        status: index === 0 ? 'CREATED' : 'WAITING',
        allow_edit: approvalLevel.allow_edit,
        code: approvalLevel.code,
        isDeleted: approvalLevel.is_deleted,
        level: approvalLevel.level,
        name: approvalLevel.name,
        username: approvalLevel.username,
      })
    )
    const submitValues = {
      ...values,
      title: values.title.replace(/\s+/g, ' ').trim(),
      proposal_type: proposalTypeById.id,
      followers: followerList.map(el => el.id),
      // assignees: [...handlerAssignees, ...followerAssignees],
      reason: EDITOR_REF.current.editor.getData(),
      reason_for_leaving: reasonForLeaving,
      type_of_leave: values?.type_of_leave,
      from_date: fromDate,
      to_date: toDate,
      overtime: overtime,
      approval_levels: approvalLevels.map(item => ({
        level: item.level,
        name: item.name,
        username: item.username,
        allow_edit: item.allow_edit,
        status:
          item.level === currentStep + 1
            ? PROPOSAL_STATUS.PENDING
            : item.status,
      })),
    }
    const uploadFileList =
      submitValues.file_ids && submitValues.file_ids.fileList
        ? submitValues.file_ids.fileList.map(file => file.originFileObj)
        : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      submitValues.file_ids = uploadedFiles
      await handleCreateOther(submitValues)
      handleCloseAndReset()
      setFileList([])
      form.resetFields()
      loadingAnimationStore.showSpinner(false)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  useEffect(() => {
    contentTemplateStore.load()
    contentTemplateStore.getDetails(proposalTypeById.contentKey)
    setSelectedContentKey(proposalTypeById.contentKey)
    setReasonForLeaving('')
    setOvertime('')
    form.setFieldsValue({
      type_of_leave: null,
    })
    return () => {
      contentTemplateStore.clear()
    }
  }, [proposalTypeById])
  const onChangeApprovalTemplate = async value => {
    setSelectedApproveTemplateCode(value)
    await getApprovalLevelTemplate(value)
  }

  const handleChangeReasonForLeaving = value => {
    setReasonForLeaving(value.target.checked ? value.target.value : '')
  }

  const handleChangeOvertime = value => {
    if (value <= 12) {
      setOvertime(value)
    }
  }

  const handleCheckboxChange = event => {
    setIsCheckboxChecked(event.target.checked)
  }
  const handleCloseAndReset = () => {
    onCloseModal()
    form.setFieldsValue({
      title: null,
      type_of_leave: null,
    })
    setReasonForLeaving('')
    setOvertime('')
    handleModalClose()
  }

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-khac-form'}
      layout={'vertical'}
      onFinish={onFinish}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề' },
              { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
              { validator: validator.validateInputString },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={
              <div>
                <p>Nội dung:</p>
              </div>
            }>
            <RichEditor
              EDITOR_REF={EDITOR_REF}
              placeholder={'Nhập nội dung'}
              editorContent={content && content}
            />
          </Form.Item>
        </Col>
        {proposalTypeById.id === 'LEAVE_ABNORMAL' && (
          <Col xs={24} lg={24}>
            <label>Lý do:</label>
            <br />
            <Checkbox.Group
              style={{ marginTop: '8px', marginBottom: '8px' }}
              value={reasonForLeaving}>
              <Checkbox
                value={'Theo chế độ'}
                onChange={handleChangeReasonForLeaving}>
                Theo chế độ
              </Checkbox>
              <Checkbox
                value={'Lý do cá nhân'}
                onChange={handleChangeReasonForLeaving}>
                Lý do cá nhân
              </Checkbox>
              <Checkbox
                value={'Lý do công việc'}
                onChange={handleChangeReasonForLeaving}>
                Lý do công việc
              </Checkbox>
            </Checkbox.Group>
          </Col>
        )}
        {proposalTypeById.id === 'LEAVE' && (
          <Col xs={24} lg={24}>
            <Form.Item
              label={'Loại nghỉ phép:'}
              name={'type_of_leave'}
              rules={[
                { required: true, message: 'Vui lòng chọn loại nghỉ phép' },
              ]}>
              <Radio.Group style={{ marginTop: '8px', marginBottom: '8px' }}>
                <Radio value={'Nghỉ phép 1/2 ngày công'}>
                  Nghỉ phép 1/2 ngày công
                </Radio>
                <Radio value={'Nghỉ phép theo chế độ'}>
                  Nghỉ phép theo chế độ
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={12} md={10}>
          <Form.Item
            name={'date_picker'}
            label={'Thời gian:'}
            rules={[{ required: true, message: 'Vui lòng chọn thời gian' }]}>
            <RangePicker format={DATE_FORMAT_LIST} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        {(proposalTypeById.id === 'OVERTIME_NORMAL' ||
          proposalTypeById.id === 'OVERTIME_DAYOFF' ||
          proposalTypeById.id === 'OVERTIME_HOLIDAY') && (
            <>
              <Col xs={24} lg={24}>
                <label>Thêm số giờ làm việc:</label>
                <Checkbox
                  style={{ marginLeft: '5px', paddingTop: '5px' }}
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}></Checkbox>
                <br />
                <NumericInput
                  value={overtime}
                  onChange={handleChangeOvertime}
                  disabled={!isCheckboxChecked}
                />
              </Col>
            </>
          )}
        <Col xs={24} lg={24}>
          <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
            <Upload
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={() => false}
              multiple={true}
              iconRender={() => <FileTextOutlined />}>
              <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
            Người theo dõi:
          </label>

          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                }
              />
            </Tooltip>
          </Space>

          <SelectList>
            {followerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
      </Row>
      <Divider
        style={{ width: '80%', marginBottom: '30px', fontWeight: 'bold' }}
      />
      <div>
        <p>Cấp duyệt:</p>
        <Select
          showSearch
          placeholder="Chọn cấp duyệt"
          optionFilterProp="children"
          onChange={onChangeApprovalTemplate}
          filterOption={(input, option) =>
            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: '300px' }}
          value={selectedApproveTemplateCode}>
          {approvalTemplateList &&
            approvalTemplateList.map(item => (
              <Select.Option key={item.code} value={item.code}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </div>
      <ProposalStepApprove
        approvalLevelList={approvalLevels}
        styleCre={'create'}
      />

      <FormButtonRow>
        <Button
          onClick={() => {
            handleCloseAndReset()
          }}>
          Hủy bỏ
        </Button>
        <Button style={{ marginLeft: 10 }} htmlType={'submit'} type={'primary'}>
          Tạo mới
        </Button>
      </FormButtonRow>

      <ApprovalList />

      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
        isUserDisableList={false}
      />
    </Form>
  )
}

OtherSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'authenticationStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
  'selectPeopleStore',
  'contentTemplateStore',
  'approvalTemplateStore'
)(observer(OtherSubmissionForm))
