import axios from 'axios'
import { apiUrl, apiUrlTruc } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const FileRequest = {
  uploadFile: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/upload`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      data: formData,
    }),

  downloadAttachment: file_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/upload/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

  downloadFileTruc: (file_id, file_name) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/truc-upload/attachments/${file_id}?fileName=${file_name}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

  uploadFileTruc: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/truc-upload`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      data: formData,
    }),

  renameFile: (file_id, file_name) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/upload/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      data: {
        file_name: file_name,
      },
    }),

  renameFileTruc: (file_id, file_name) =>
    axios({
      method: 'patch',
      url: `${apiUrlTruc}/api/v1/docs/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      data: {
        file_name: file_name,
      },
    }),

  downloadAttachmentTruc: file_id =>
    axios({
      method: 'get',
      url: `${apiUrlTruc}/api/v1/docs/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

  uploadFileLTVB: formData =>
    axios({
      method: 'post',
      url: `${apiUrlTruc}/api/v1/docs/attachments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      data: formData,
    }),

  uploadExcel: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/time-sheet/upload-excel`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      data: formData,
    }),

  getFileByEdocOutgoingId: (documentId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrlTruc}/api/v1/docs/outgoing/${documentId}/attachments/${attachmentName}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  getFileByEdocIncomingId: (documentId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrlTruc}/api/v1/docs/incoming/${documentId}/attachments/${attachmentName}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  getReceivedFileByEdocId: (documentId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/received/${documentId}/attachments/${attachmentName}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  getFileExcelById: (fileId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/time-sheet/download-excel/${fileId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  getFileInternalEdocIncomingById: (documentId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${documentId}/attachments/${attachmentName}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  getFileInternalEdocOutgoingById: (documentId, attachmentName) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents-outgoing/${documentId}/attachments/${attachmentName}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  uploadCloudFile: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/cloud/file/upload`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      data: formData,
    }),
  deleteCloudObject: objectId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/cloud/${objectId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  sendDataExcel: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/import-excel`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: formData,
    }),
  createUsersFromExcel: userInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/save-data`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: userInfo,
    }),
  downloadTemplateAccount: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/template-account`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),
}
