import React from 'react'

import { inject, observer } from 'mobx-react'
import { OpinionItem, UpdateVotePopover } from './DrawerWorkStyled'
import { Popover } from 'antd'
import { DislikeFilled, LikeFilled } from '@ant-design/icons'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { FiCircle } from 'react-icons/fi'

const VoteWorkDetail = props => {
  const { authenticationStore, voteDetail, voteStore, reloadVoteDetail } = props

  const { currentUser } = authenticationStore

  const handleAgree = async userCode => {
    await voteStore.updateVote(userCode, { result: true })
    reloadVoteDetail()
  }
  const handleDisagree = async userCode => {
    await voteStore.updateVote(userCode, { result: false })
    reloadVoteDetail()
  }

  return (
    <>
      {voteDetail?.map((user, index) =>
        currentUser.username === user.assignee_code ? (
          <Popover
            key={index}
            content={
              <UpdateVotePopover>
                <div className="content-title">Gửi ý kiến</div>
                <div className="content-body">
                  <div onClick={() => handleAgree(user.code)}>
                    <div>
                      <span className="thumb-up" style={{}}>
                        <AiOutlineCheckCircle size={20} />
                      </span>
                    </div>
                    <div>Đồng ý</div>
                  </div>
                  <div onClick={() => handleDisagree(user.code)}>
                    <div>
                      <span className="thumb-down">
                        <AiOutlineCloseCircle size={20} />
                      </span>
                    </div>
                    <div>Không đồng ý</div>
                  </div>
                </div>
              </UpdateVotePopover>
            }
            title={false}
            trigger="hover">
            <OpinionItem>
              {user.result === true ? (
                <span className="vote-icon thumb-up">
                  <AiOutlineCheckCircle size={15} />
                  <div>Đồng ý</div>
                </span>
              ) : user.result === false ? (
                <span className="vote-icon thumb-down">
                  <AiOutlineCloseCircle size={15} />
                  <div>Ko đồng ý</div>
                </span>
              ) : (
                <span className="vote-icon">
                  <FiCircle size={15} />
                </span>
              )}
              <span
                style={{
                  fontSize: 12,
                  textTransform: 'capitalize',
                }}>
                {user.name_uppercase?.toLowerCase()}
              </span>
            </OpinionItem>
          </Popover>
        ) : (
          <OpinionItem key={index}>
            {user.result === true ? (
              <span className="vote-icon thumb-up">
                <AiOutlineCheckCircle size={17} />
              </span>
            ) : user.result === false ? (
              <span className="vote-icon thumb-down">
                <AiOutlineCloseCircle size={17} />
              </span>
            ) : (
              <span className="vote-icon">
                <FiCircle size={15} />
              </span>
            )}

            <span
              style={{
                fontSize: 12,
                textTransform: 'capitalize',
              }}>
              {user.name_uppercase?.toLowerCase()}
            </span>
          </OpinionItem>
        )
      )}
    </>
  )
}

VoteWorkDetail.propTypes = {}

export default inject(
  'voteStore',
  'authenticationStore'
)(observer(VoteWorkDetail))
