import { Form, Input, message, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

const UpdateCompanyDepartmentModal = ({
  visible,
  department,
  onCancel,
  ...otherProps
}) => {
  console.log('[LOG] : department:', department)
  const { departmentStore } = otherProps
  const [form] = Form.useForm()

  const onSubmit = async formValue => {
    const { name } = formValue
    try {
      await departmentStore.update(department.code, name)
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra');
      return
    }
    
    message.success('Cập nhật phòng ban thành công')
    departmentStore.getAllDepartmentList(department.companyCode)
    form.resetFields();
    onCancel()
  }

  return (
    <Modal
      title="Cập nhật phòng ban"
      visible={visible}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          label="Tên phòng ban"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên phòng ban!' }]}
          initialValue={department?.name ?? ''}>
          <Input placeholder={'Tên phòng ban'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default inject('departmentStore')(observer(UpdateCompanyDepartmentModal))
