import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import TargetList from './TargetList'
import { Button, message, Tooltip } from 'antd'
import { blue } from '../../color'
import { PlusOutlined, SaveOutlined, SignalFilled } from '@ant-design/icons'
import './KpiEditTargetPage.css'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import TargetCreateModal from './TargetCreateModal'
import { useParams } from 'react-router-dom'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { systemName } from '../../config'

//kpi/view/KPI27257840435000
//Edit KPI

const KpiEditTargetPage = props => {
  const { kpiStore, authenticationStore } = props

  const { kpiDetail, targetListByKpiId, actualWeightKpi } = kpiStore

  const { id: kpiId } = useParams()

  const [isVisibleTargetCreateModal, setIsVisibleTargetCreateModal] = useState(
    false
  )
  const [isLoadingKpi, setIsLoadingKpi] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingKpi(true)
        await kpiStore.getKpiDetail(kpiId)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingKpi(false)
      }
    })()

    return () => {
      kpiStore.clearKpiDetail()
    }
  }, [kpiId])

  const save = async () => {
    // console.log('targetListByKpiId', toJS(targetListByKpiId))
    setIsLoadingKpi(true)

    await kpiStore.updateTargetValues()
    await kpiStore.getKpiDetail(kpiId)

    setIsLoadingKpi(false)

    message.success('Cập nhật thành công')
  }

  const onDelete = async target => {
    setIsLoadingKpi(true)

    await kpiStore.delete(target.code)
    await kpiStore.getKpiDetail(kpiId)

    setIsLoadingKpi(false)

    message.success('Xóa thành công')
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết KPI | {systemName} KPI</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={'Chi tiết KPI'}
      />
      <ContentBlockWrapper
        style={{
          position: 'relative',
          minHeight: 'calc(100vh - 190px)',
          overflow: 'hidden',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 20,
            marginBottom: 16,
          }}>
          <div>
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
              }}>
              <SignalFilled
                style={{
                  fontSize: 16,
                  color: '#52c41a',
                  fontWeight: 700,
                  marginRight: 10,
                }}
              />
              <span
                style={{
                  marginRight: 6,
                  color: blue,
                  textDecoration: 'underline',
                }}>
                KPI:
              </span>{' '}
              {kpiDetail.name}
            </div>
          </div>
          <div className={'weight-number-target'}>
            <div className={'summary-weight-value'}>
              Tỉ Trọng cần đạt:
              <span
                style={{
                  color: '#f5222d',
                  fontWeight: 700,
                  marginLeft: 4,
                  fontSize: 14,
                }}>
                {kpiDetail.expect_weight}%
              </span>
            </div>
            <div className={'summary-weight-value'}>
              Tỉ Trọng đạt được:
              <span
                style={{
                  color: '#389e0d',
                  fontWeight: 700,
                  marginLeft: 4,
                  fontSize: 14,
                }}>
                {!isNaN(actualWeightKpi) && actualWeightKpi}%
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 12,
          }}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__KPI}>
            <Button
              block
              type={'dashed'}
              icon={<PlusOutlined style={{ color: blue }} />}
              onClick={() => setIsVisibleTargetCreateModal(true)}
              style={{
                fontWeight: 500,
                textTransform: 'uppercase',
                borderColor: blue,
              }}>
              Thêm mục tiêu
            </Button>
          </AccessControlAction>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.edit__KPI}>
            <Tooltip
              title={
                !kpiStore.allowSaveTarget ? 'Số liệu tỉ trọng chưa hợp lệ!' : ''
              }>
              <Button
                block
                icon={<SaveOutlined style={{ color: '#237804' }} />}
                type={'dashed'}
                disabled={!kpiStore.allowSaveTarget}
                onClick={save}
                style={{
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  borderColor: blue,
                }}>
                Lưu lại
              </Button>
            </Tooltip>
          </AccessControlAction>
        </div>
        <TargetList onDelete={onDelete} />
        <SpinnerInlineComponent
          sizeSpin={'small'}
          isLoading={isLoadingKpi}
          alignItems={'start'}
        />
      </ContentBlockWrapper>
      <TargetCreateModal
        isVisibleTargetCreateModal={isVisibleTargetCreateModal}
        handleCloseTargetCreateModal={() =>
          setIsVisibleTargetCreateModal(false)
        }
      />
    </DashboardLayout>
  )
}

KpiEditTargetPage.propTypes = {}

export default inject(
  'kpiStore',
  'authenticationStore'
)(observer(KpiEditTargetPage))
