import { Button, Tag } from 'antd'
import styled from 'styled-components'
import { blue } from '../../color'

export const CreateTitle = styled.div`
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
`
export const RiskSelects = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TemplateTable = styled.div`
  .ant-table-tbody {
    vertical-align: unset;
  }
`
