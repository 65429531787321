import React from 'react'
import { Helmet } from 'react-helmet'
import ApprovalTemplatePage from '../ApprovalTemplate'
import AdministrativeManagementLayout from './AdministrativeManagementLayout'

const AdministrativeManagementApprovalTemplate = () => {
  return (
    <AdministrativeManagementLayout selectedKey="approval-templates">
      <Helmet>
        <title>Cấp duyệt | Quản trị | Hành chính</title>
      </Helmet>
      <ApprovalTemplatePage isAdministrativeManagementTab />
    </AdministrativeManagementLayout>
  )
}

export default AdministrativeManagementApprovalTemplate
