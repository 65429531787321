import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import EmptyContent from '../../components/EmptyContent'
import { useLocation, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { PROPOSAL_TYPE } from '../../constants'
import { EmptyText } from '../../components/Common/CellText'
import TableComponent from '../../components/Common/TableComponent'
import validator from '../../validator'

const { Option } = Select

const ProposalTypeListItem = props => {
  const {
    contentTemplateStore,
    commonStore,
    history,
    proposalTypeStore,
    approvalTemplateStore,
  } = props
  const { items } = contentTemplateStore
  const { showCreateModal } = proposalTypeStore
  const location = useLocation()

  const [formUpdateProposalType] = Form.useForm()
  const [formCreateProposalType] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false)

  const [approvalTemplateList, setApprovalTemplateList] = useState(null)

  useEffect(() => {
    contentTemplateStore.load()

    return () => {
      contentTemplateStore.clear()
    }
  }, [])

  // ProposalType
  const [selectedProposalType, setSelectedProposalType] = useState(null)

  useEffect(() => {
    formUpdateProposalType.setFieldsValue({
      id: selectedProposalType && selectedProposalType.id,
      name: selectedProposalType && selectedProposalType.name,
      contentKey: selectedProposalType && selectedProposalType.contentKey,
      approvalTemplate:
        selectedProposalType &&
        selectedProposalType.approvalTemplateDTO &&
        selectedProposalType.approvalTemplateDTO.code,
    })
  }, [selectedProposalType])

  const [
    showModalUpdateProposalType,
    setShowModalUpdateProposalType,
  ] = useState(false)
  useEffect(() => {
    ;(async () => {
      await loadApprovalTemplateList()
      setApprovalTemplateList(approvalTemplateStore.approvalTemplateList)
    })()
  }, [])
  const loadApprovalTemplateList = async () => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.getApprovalTemplateList()
    } finally {
      setIsLoading(false)
    }
  }
  const renderContentTemplateName = contentKey => {
    for (const [value] of Object.entries(PROPOSAL_TYPE)) {
      if (contentKey === value) {
        return PROPOSAL_TYPE[value].label
      }
    }
  }
  const tableColumnsProposalType = [
    {
      title: 'Tên loại hành chính',
      width: 90,
      render: record => record.name,
    },
    {
      title: 'Cấp duyệt',
      width: 90,
      render: record => (
        <div>
          {record.approvalTemplateDTO ? (
            record.approvalTemplateDTO.name
          ) : (
            <EmptyText>Chưa áp dụng cấp duyệt</EmptyText>
          )}
        </div>
      ),
    },
    {
      title: 'Văn bản mẫu',
      width: 90,
      render: record => renderContentTemplateName(record.contentKey),
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record => (
        <Fragment>
          {record.type !== null ? (
            <>
              <Tooltip title={'Sửa thông tin loại hành chính'}>
                <EditOutlined
                  className={'anticon-color'}
                  onClick={() => handleClickUpdateProposalType(record)}
                />
              </Tooltip>
              <Divider type={'vertical'} />
              <DeleteOutlined style={{ color: '#ccc' }} disabled />
            </>
          ) : (
            <>
              <Tooltip title={'Sửa thông tin loại hành chính'}>
                <EditOutlined
                  className={'anticon-color'}
                  onClick={() => handleClickUpdateProposalType(record)}
                />
              </Tooltip>
              <Divider type={'vertical'} />
              <Tooltip title={'Xoá loại hành chính'}>
                <Popconfirm
                  onConfirm={() => handleDeleteProposalType(record.id)}
                  okText={'Đồng ý'}
                  cancelText={'Không'}
                  okType={'danger'}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title={'Bạn có muốn xoá loại hành chính này?'}>
                  <DeleteOutlined style={{ color: '#fc0000' }} />
                </Popconfirm>
              </Tooltip>
            </>
          )}
        </Fragment>
      ),
    },
  ]

  const [
    selectedProposalTypeGroupId,
    setSelectedProposalTypeGroupId,
  ] = useState(null)
  const [proposalTypes, setProposalTypes] = useState([])
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const response = await proposalTypeStore.getProposalTypes()
      setProposalTypes(response.data)
      setIsLoading(false)
    })()
  }, [])
  const handleClickUpdateProposalType = selectedProposalType => {
    setSelectedProposalType(selectedProposalType)
    setShowModalUpdateProposalType(true)
  }
  const handleUpdateProposalType = values => {
    setIsUpdating(true)
    proposalTypeStore
      .updateProposalType(
        values.id,
        values.name,
        values.contentKey,
        values.approvalTemplate
      )
      .then(async () => {
        const response = await proposalTypeStore.getProposalTypes()
        setProposalTypes(response.data)
        setIsUpdating(false)
        message.success('Cập nhật loại hành chính thành công!')
      })
      .catch(error => {
        setIsUpdating(false)
        message.error(error.vi)
      })
      .finally(() => {
        setShowModalUpdateProposalType(false)
        formUpdateProposalType.setFieldsValue({
          id: selectedProposalType && selectedProposalType.id,
          name: selectedProposalType && selectedProposalType.name,
          contentKey: selectedProposalType && selectedProposalType.contentKey,
          approvalTemplate:
            selectedProposalType &&
            selectedProposalType.approvalTemplateDTO &&
            selectedProposalType.approvalTemplateDTO.code,
        })
      })
  }
  const handleCreateProposalType = values => {
    setIsUpdating(true)
    proposalTypeStore
      .createProposalType(
        values.name,
        values.contentKey,
        values.approvalTemplate
      )
      .then(async () => {
        const response = await proposalTypeStore.getProposalTypes()
        setProposalTypes(response.data)
        setIsUpdating(false)
        message.success('Tạo loại hành chính thành công!')
      })
      .catch(error => {
        setIsUpdating(false)
        message.error(error.vi)
      })
      .finally(() => {
        formCreateProposalType.resetFields()
        proposalTypeStore.showCreateModal = false
      })
  }
  const handleDeleteProposalType = id => {
    setIsLoading(true)
    proposalTypeStore
      .deleteProposalType(id)
      .then(async () => {
        const response = await proposalTypeStore.getProposalTypes()
        setProposalTypes(response.data)
        message.success('Xóa loại hành chính thành công!')
      })
      .catch(error => message.error(error.vi))
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleCancelCreateProposalType = () => {
    proposalTypeStore.showCreateModal = false
    formCreateProposalType.resetFields()
  }

  return (
    <>
      {proposalTypes && proposalTypes.length > 0 && (
        <TableComponent
          loading={{
            tip: 'Đang tải...',
            spinning: isLoading,
          }}
          columns={tableColumnsProposalType}
          dataSource={proposalTypes}
          pagination={false}
          rowKey={record => record.id}
        />
      )}
      <Modal
        visible={showModalUpdateProposalType}
        footer={null}
        getContainer={false}
        onCancel={() => {
          setShowModalUpdateProposalType(false)
          formUpdateProposalType.setFieldsValue({
            id: selectedProposalType && selectedProposalType.id,
            name: selectedProposalType && selectedProposalType.name,
            contentKey: selectedProposalType && selectedProposalType.contentKey,
            approvalTemplate:
              selectedProposalType &&
              selectedProposalType.approvalTemplateDTO &&
              selectedProposalType.approvalTemplateDTO.code,
          })
        }}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Sửa thông tin loại hành chính
          </Fragment>
        }>
        <Form
          form={formUpdateProposalType}
          layout={'vertical'}
          onFinish={handleUpdateProposalType}>
          <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
            <Input disabled={true} placeholder={'Nhập tên loại hành chính'} />
          </Form.Item>

          <Form.Item
            label={'Tên loại hành chính'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên loại hành chính!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên loại hành chính'} />
          </Form.Item>
          <Form.Item
            label={'Chọn văn bản mẫu'}
            name={'contentKey'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn văn bản mẫu!',
              },
            ]}>
            <Select
              notFoundContent={<EmptyContent />}
              placeholder="-- Chọn văn bản mẫu --">
              {items &&
                items.map(item => (
                  <Option key={item.key} value={item.key}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Áp dụng cấp duyệt'} name={'approvalTemplate'}>
            <Select
              notFoundContent={<EmptyContent />}
              placeholder="-- Chọn cấp duyệt --">
              {approvalTemplateList &&
                approvalTemplateList.map(item => (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setShowModalUpdateProposalType(false)
                  // formUpdateProposalType.resetFields()
                  formUpdateProposalType.setFieldsValue({
                    id: selectedProposalType && selectedProposalType.id,
                    name: selectedProposalType && selectedProposalType.name,
                    contentKey:
                      selectedProposalType && selectedProposalType.contentKey,
                    approvalTemplate:
                      selectedProposalType &&
                      selectedProposalType.approvalTemplateDTO &&
                      selectedProposalType.approvalTemplateDTO.code,
                  })
                }}>
                Hủy
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={isUpdating}>
                Cập nhật
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={showCreateModal}
        footer={null}
        getContainer={false}
        onCancel={handleCancelCreateProposalType}
        title={
          <Fragment>
            <ExclamationCircleOutlined
              style={{
                color: commonStore.appTheme.solidColor,
                marginRight: 10,
              }}
            />
            Tạo mới loại hành chính
          </Fragment>
        }>
        <Form
          form={formCreateProposalType}
          layout={'vertical'}
          onFinish={handleCreateProposalType}>
          <Form.Item
            label={'Tên loại hành chính'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên loại hành chính!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên loại hành chính'} />
          </Form.Item>
          <Form.Item
            label={'Chọn văn bản mẫu'}
            name={'contentKey'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn văn bản mẫu!',
              },
            ]}>
            <Select
              notFoundContent={<EmptyContent />}
              placeholder="-- Chọn văn bản mẫu --">
              {items &&
                items.map(item => (
                  <Option key={item.key} value={item.key}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Áp dụng cấp duyệt'} name={'approvalTemplate'}>
            <Select
              notFoundContent={<EmptyContent />}
              placeholder="-- Chọn cấp duyệt --">
              {approvalTemplateList &&
                approvalTemplateList.map(item => (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={handleCancelCreateProposalType}>Hủy</Button>
              <Button type={'primary'} htmlType={'submit'} loading={isUpdating}>
                Tạo mới
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

ProposalTypeListItem.propTypes = {}

export default memo(
  withRouter(
    inject(
      'commonStore',
      'proposalTypeStore',
      'contentTemplateStore',
      'approvalTemplateStore'
    )(observer(ProposalTypeListItem))
  )
)
