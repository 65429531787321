import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Divider,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Tooltip,
} from 'antd'
import PageTitle from '../../components/PageTitle'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { ACL_ACTION_TYPE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import TableComponent from '../../components/Common/TableComponent'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { systemName } from '../../config'

// class TabPane extends React.Component {
//   render() {
//     return null
//   }
// }
//
// TabPane.propTypes = {
//   tab: PropTypes.string,
//   children: PropTypes.node,
// }

const SampleDocumentPage = props => {
  const { history, loadingAnimationStore, sampleDocumentStore } = props

  const { sampleDocumentList, size, page, totalCount } = sampleDocumentStore

  const tableColumns = [
    {
      title: 'Mã văn bản',
      // width: 150,
      render: record => record.code,
    },
    {
      title: 'Tiêu đề',
      // width: 250,
      render: record => record.title,
    },
    // {
    //   title: 'Nội dung',
    //   render: record => record.content ? UrlifiedText(record.content) : <EmptyText>Không có</EmptyText>,
    // },
    {
      title: 'Tác vụ',
      // width: 80,
      // fixed: 'right',
      render: record => (
        <Fragment>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.edit__VAN_BAN_MAU}>
            <Tooltip title={'Sửa văn bản'}>
              <EditOutlined
                className={'anticon-color'}
                onClick={() =>
                  history.push(`/management/sample-document/edit/${record.id}`)
                }
              />
            </Tooltip>
            <Divider type={'vertical'} />
          </AccessControlAction>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.delete__VAN_BAN_MAU}>
            <Tooltip title={'Xoá văn bản'}>
              <Popconfirm
                onConfirm={() => handleDeleteDocument(record.id)}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xoá văn bản này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </AccessControlAction>
        </Fragment>
      ),
    },
  ]
  // ong tạo lại đi
  const handleDeleteDocument = documentId => {
    loadingAnimationStore.showSpinner(true)
    sampleDocumentStore
      .deleteSampleDocument(documentId)
      .then(() => {
        sampleDocumentStore
          .getSampleDocumentList()
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success('Đã xoá văn bản')
          })
          .catch(() => loadingAnimationStore.showSpinner(false))
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }

  const handleChangePage = pageIndex => {
    const page = pageIndex - 1
    sampleDocumentStore.setFilter('page', page)
  }

  useEffect(() => {
    loadingAnimationStore.setTableLoading(true)
    sampleDocumentStore
      .getSampleDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    return () => sampleDocumentStore.clearSampleDocumentList()
  }, [page, size])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Danh sách văn bản mẫu | {systemName} Portal</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Danh sách văn bản mẫu'}
        hiddenGoBack>
        <AccessControlAction
          aclActionType={ACL_ACTION_TYPE.create__VAN_BAN_MAU}>
          <Button
            type={'primary'}
            onClick={() => history.push('/management/sample-document/create')}>
            <PlusCircleOutlined />
            Tạo mới văn bản mẫu
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper>
        <TableComponent
          rowKey={record => record.id}
          dataSource={sampleDocumentList}
          columns={tableColumns}
          scroll={{ x: 1000 }}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={loadingAnimationStore.tableLoading}
        />
        <TableBottomPaginationBlock>
          <Pagination
            current={page + 1}
            pageSize={size}
            total={totalCount}
            hideOnSinglePage={true}
            onChange={handleChangePage}
          />
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

SampleDocumentPage.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'sampleDocumentStore'
)(observer(SampleDocumentPage))
