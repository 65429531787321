import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ProposalsTypeRequest = {
  getProposalTypes: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/proposal-type`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getAllProposalTypes: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/proposal-type/all`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  createProposalType: (name, content_key, approvalTemplateCode) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/proposal-type`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        contentKey: content_key,
        name: name,
        approvalTemplateCode: approvalTemplateCode,
      },
    }),
  updateProposalType: (id, name, contentKey, approvalTemplateCode) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/proposal-type/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        contentKey: contentKey,
        name: name,
        approvalTemplateCode: approvalTemplateCode,
      },
    }),
  deleteProposalType: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/proposal-type/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}
