import styled from 'styled-components'

export const ListItemWrapper = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;
`

export const ListItemRight = styled.div``
export const IssueInfo = styled.div``

export const SearchBar = styled.div`
  display: flex;
  // max-width: 720px;
  margin-right: 15px;
  margin-bottom: 15px;
`
