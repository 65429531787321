import React from 'react'
import { Input, Tooltip } from 'antd'

const formatNumber = value => new Intl.NumberFormat().format(value)

const NumericInput = props => {
  const { value, onChange } = props

  const handleChange = e => {
    const { value: inputValue } = e.target
    const reg = /^\d*(\.\d*)?$/
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue)
    }
  }

  const handleBlur = () => {
    let valueTemp = value
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1)
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'))
  }

  return (
    <Tooltip
      trigger={['focus']}
      placement="topLeft"
      overlayClassName="numeric-input">
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ width: '171.04px', marginTop: '8px', marginBottom: '8px' }}
        placeholder="Nhập số giờ dương <= 12"
      />
    </Tooltip>
  )
}

export default NumericInput
