import { PrinterOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Select, Tabs, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DATE_FORMAT_DEFAULT, RISK_TYPE } from '../../constants'
import { dowloaderUtils } from '../../dowloaderUtils'
import { SearchBar } from '../UserAccountManagementPage/UserAcountManagementPageStyled'

const { TabPane } = Tabs
const { Search } = Input

const tabs = [
  {
    key: RISK_TYPE.DELEGATE,
    name: 'Người đại diện phần vốn',
  },
  {
    key: RISK_TYPE.UNIT,
    name: 'Đơn vị phụ thuộc',
  },
  {
    key: RISK_TYPE.AGENCY,
    name: 'Cơ quan văn phòng',
  },
]

const defaultType = RISK_TYPE.DELEGATE
const AdvanceFilter = props => {
  const {
    store,
    riskStore,
    riskTemplateStore,
    authenticationStore,
    departmentStore,
    loadingAnimationStore,
  } = props
  const { setKeyword } = riskStore
  const location = useLocation()
  const [searchValue, setSearchValue] = useState(null)
  const [createDateValue, setCreateDateValue] = useState(null)
  const [isExportingDocx, setIsExportingDocx] = useState(false)
  const [selectPrintYear, setSelectPrintYear] = useState(undefined)
  const [activeExport, setActiveExport] = useState(false)

  const queryStringParse = queryString.parse(location.search)
  const riskType = queryStringParse.type

  const riskUnits =
    riskType === RISK_TYPE.AGENCY
      ? departmentStore?.departmentList
      : riskStore?.riskUnitsV2

  useEffect(() => {
    setSearchValue(null)

    if (riskType === RISK_TYPE.AGENCY) {
      departmentStore.getAllDepartmentList(
        authenticationStore.currentCompanyCode()
      )
    } else {
      riskStore.getRiskUnitsV2(riskType)
    }
  }, [queryStringParse.type, authenticationStore.currentUser])
  useEffect(() => {
    riskStore.clearSearch()
  }, [activeExport])

  const onChangeTab = async key => {
    store.setQueryParams('filterPage', 0)
    store.setQueryParams('type', key || RISK_TYPE.DELEGATE)
    store.setQueryParams('unitCode', null)
    setKeyword(null)
    riskStore.clearSearch()
    // await riskStore.getRisks()
  }

  const handleSearch = async value => {
    if (value === riskStore.keyword) return

    setKeyword(value)
    await riskStore.getRisks()
  }

  const changeYear = date => {
    const selectedYear = date ? moment(date).year() : null
    setSelectPrintYear(selectedYear)
    riskStore.setQueryParams('year', selectedYear)
  }
  const changeDate = date => {
    const selectedDate = date ? moment(date).format('YYYY-MM-DD') : null
    setCreateDateValue(selectedDate)
    riskStore.setCreatedAt(selectedDate)
  }

  const changeUnit = unit => {
    riskStore.setQueryParams('unitCode', unit)
  }

  /**
   * Export risk doc
   */
  const exportRiskDoc = async () => {
    setIsExportingDocx(true)
    try {
      const { data } = await riskTemplateStore.exportRisk({
        year: riskStore.year,
        unit_code: riskStore.unitCode,
        type: riskStore.type,
        keyword: riskStore.keyword,
      })

      // let fileNamePostfix = ''
      // if (filter.keyword) {
      //   fileNamePostfix += ` ${filter.keyword}`
      // }
      // if (filter.type) {
      //   fileNamePostfix += ` - ${
      //     riskTemplateStore.riskTemplateTypes.find(
      //       type => type.code === filter.type
      //     ).name
      //   }`
      // }

      dowloaderUtils.downloadDocxFromResponse(data, {
        filename: `CV yêu cầu  BC Qly RR 9 tháng và NDRR quý 4.${riskStore.year}.docx`,
      })
    } catch (error) {
      console.error(error)
      message.error('Đã có lỗi xảy ra!')
    } finally {
      setIsExportingDocx(false)
    }
  }

  return (
    <>
      <Tabs
        defaultActiveKey={
          Array.isArray(queryStringParse.type)
            ? queryStringParse.type[0]
            : queryStringParse.type || RISK_TYPE.DELEGATE
        }
        onChange={onChangeTab}>
        {tabs.map(tab => (
          <TabPane tab={tab.name} key={tab.key} />
        ))}
      </Tabs>
      <div
        style={{
          display: 'flex',
          gap: 20,
          marginBottom: 14,
        }}>
        {activeExport ? (
          <div>
            <Select
              allowClear
              showSearch
              value={riskStore.unitCode}
              filterOption={(input, option) =>
                (option?.label.toString() ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ minWidth: 300, marginRight: '19px' }}
              placeholder={'Lọc theo đơn vị'}
              options={
                Array.isArray(riskUnits)
                  ? riskUnits.map(({ code, name }) => ({
                      value: code,
                      label: name,
                    }))
                  : []
              }
              onChange={changeUnit}></Select>
            <DatePicker
              style={{ marginRight: '19px' }}
              value={riskStore.year ? moment().year(riskStore.year) : null}
              placeholder="Lọc theo năm"
              picker="year"
              onChange={changeYear}
            />
            <Button
              disabled={
                isExportingDocx || !riskStore.unitCode || !riskStore.year
              }
              loading={isExportingDocx}
              onClick={exportRiskDoc}
              style={{ marginLeft: 'auto' }}>
              <PrinterOutlined />
              In
            </Button>
          </div>
        ) : (
          <>
            <SearchBar>
              <Search
                allowClear
                onSearch={handleSearch}
                placeholder={'Tìm kiếm phiếu rủi ro...'}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </SearchBar>

            <DatePicker
              style={{ marginRight: '16px', minWidth: 200 }}
              allowClear
              placeholder={'Ngày tạo...'}
              format={DATE_FORMAT_DEFAULT}
              onChange={changeDate}
            />
          </>
        )}
        <Button
          onClick={() => setActiveExport(!activeExport)}
          style={{ marginLeft: 'auto' }}>
          {activeExport ? 'Huỷ' : 'In báo cáo'}
        </Button>
      </div>
    </>
  )
}

AdvanceFilter.propTypes = {
  store: PropTypes.object,
  storeName: PropTypes.string,
}

export default inject(
  'riskStore',
  'riskTemplateStore',
  'departmentStore',
  'authenticationStore',
  'loadingAnimationStore'
)(observer(AdvanceFilter))
