import React, { useEffect, useState } from 'react'
import { Card, Checkbox } from 'antd'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { blue } from '../../color'

const CardWrapper = styled(Card)`
  .ant-card-head {
    background-color: ${blue};
    color: #fff;
    margin: -1px;
  }
`

const ACLActionCard = props => {
  const { actionList, title, aclStore } = props

  const [actionUpdateList, setActionUpdateList] = useState([])

  useEffect(() => {
    setActionUpdateList(
      actionList.map(item => ({
        ...item,
        status: item.status || false,
      }))
    )
  }, [actionList])

  useEffect(() => {
    if (actionUpdateList.length === 0) return
    aclStore.setAclActionUpdateList([
      ...aclStore.aclActionUpdateList.filter(
        item => item.tag !== actionUpdateList[0].tag
      ),
      ...actionUpdateList,
    ])
  }, [actionUpdateList])

  const onChange = (status, code) => {
    const newActionList = [...actionUpdateList]
    const i = actionUpdateList.findIndex(item => item.code === code)
    if (i !== -1) {
      newActionList[i].status = status
    }
    setActionUpdateList(newActionList)
  }
  return (
    <CardWrapper
      title={<b>{title}</b>}
      size={'small'}
      style={{
        width: 300,
        backgroundColor: '#F0F5FF',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
      }}>
      <div
        style={{
          display: 'flex',
          gap: 6,
          flexDirection: 'column',
        }}>
        {actionUpdateList.map(item => (
          <div key={item.code}>
            <Checkbox
              checked={item.status}
              onChange={e => onChange(e.target.checked, item.code)}>
              {item.name}
            </Checkbox>
          </div>
        ))}
      </div>
    </CardWrapper>
  )
}

ACLActionCard.propTypes = {}
export default inject('aclStore')(observer(ACLActionCard))
