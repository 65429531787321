import { action, observable } from 'mobx'
import { ProposalsRequest } from '../requests/ProposalsRequest.js'
import { ProposalsTypeRequest } from '../requests/ProposalsTypeRequest.js'
import { PROPOSAL_TYPE } from '../constants.js'

class ProposalTypeStore {
  @observable showCreateModal = false
  @action getProposalTypes = () => {
    return new Promise((resolve, reject) => {
      ProposalsTypeRequest.getProposalTypes()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getAllProposalTypes = () => {
    return new Promise((resolve, reject) => {
      ProposalsTypeRequest.getAllProposalTypes()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createProposalType = (name, contentKey, approvalTemplateCode) => {
    return new Promise((resolve, reject) => {
      ProposalsTypeRequest.createProposalType(
        name,
        contentKey,
        approvalTemplateCode
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateProposalType = (id, name, contentKey, approvalTemplateCode) => {
    return new Promise((resolve, reject) => {
      ProposalsTypeRequest.updateProposalType(
        id,
        name,
        contentKey,
        approvalTemplateCode
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteProposalType = id => {
    return new Promise((resolve, reject) => {
      ProposalsTypeRequest.deleteProposalType(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new ProposalTypeStore()
