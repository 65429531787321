import { Button, Checkbox, Divider, message } from 'antd'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import selectPeopleStore from '../../stores/selectPeopleStore'
import utils from '../../utils'
import RichText from '../RichEditor/RichText'
import './RiskActionDetails.css'
import { CheckboxWrapper, RiskActionStyle } from './RiskActionDetailsStyled'
import { systemName } from '../../config'

const labelBold = { fontWeight: 600 }
const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }
const labelValue = { marginLeft: ' 10px !important' }

// const ActionLabel = ({ label = '', text = '', labelStyle = {} }) => {
//   return (
//     <p style={{ marginLeft: ' 6px !important' }}>
//       <span>{label}&nbsp;</span>
//       <span style={{ ...textValue, ...labelBold }}>{text}</span>
//     </p>
//   )
// }
const ActionLabel = ({
  label = '',
  text = '',
  title = '',
  labelStyle = {},
}) => {
  return (
    <p style={{ marginLeft: labelStyle.marginLeft || '6px !important' }}>
      <span>{label}&nbsp;</span>
      <span style={{ ...textValue, ...labelBold }}>{text}</span>
      <span
        style={{
          ...textValue,
          ...labelBold,
          display: 'flex',
          marginRight: '5px',
        }}>
        {title}
      </span>
    </p>
  )
}
const ActionLabels = ({ label = '', title = '', labelStyle = {} }) => {
  return (
    <p
      style={{
        marginLeft: labelStyle.marginLeft || '6px !important',
        maxHeight: '25px',
      }}>
      <span>{label}&nbsp;</span>
      <span
        style={{
          ...textValue,
          ...labelBold,
          display: 'flex',
          marginLeft: '2px',
        }}>
        {title}
      </span>
    </p>
  )
}
const actionBoldLabel = (label, content) => {
  return (
    <p>
      <span style={labelBold}>{label}&nbsp;</span>
      <span style={textValue}>{content}</span>
    </p>
  )
}

const TableHasCheckbox = ({ title, checked }) => {
  return (
    <table style={{ margin: '0 ' }}>
      <tbody>
        <tr style={{ backgroundColor: 'white', margin: '0 ' }}>
          <td
            style={{
              border: 'none',
              margin: '0 ',
              backgroundColor: 'white',
              textAlign: 'center',
            }}>
            <div>{title}</div>
          </td>
          <td
            style={{
              border: 'none',
              margin: '0 ',
              backgroundColor: 'white',
              textAlign: 'center',
            }}>
            {checked ? (
              <image
                id="logo"
                style={{ width: '20px', height: '16px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/icons/checkedbox.PNG`}
              />
            ) : (
              <image
                id="logo"
                style={{ width: '20px', height: '16px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/icons/uncheckedbox.PNG`}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const showAgree = data => {
  return data === true ? 'Đồng ý' : data === false ? 'Không đồng ý' : ''
}

const displayRiskAssessment = value => {
  return value === '1'
    ? '1 -> 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)'
    : value === '2'
    ? ' 6 -> 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được phép tiến hành với sự quản lý, kiểm soát thích hợp)'
    : value === '3'
    ? ' 16 -> 25 - Nguy cơ rủi ro cao (Hoạt động không được phép tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ hơn để giảm thiểu rủi ro)'
    : ''
}

const displayResult = value => {
  return value === '1'
    ? 'a/ Rủi ro ở mức không cần xử lý (theo dõi)'
    : value === '2'
    ? 'b/ Rủi ro đã được xử lý'
    : value === '3'
    ? 'c/ Rủi ro đã xảy ra chưa được xử lý'
    : ''
}

const RiskActionDetails = ({
  data,
  tier,
  type,
  isPartOfPdf = false,
  openEditPopUp = () => {},
  actionStore,
  departmentStore,
  riskStore,
  updateTierApproval,
  riskUnitsV2,
}) => {
  // console.log('[INFO]  data:', toJS(data))
  // const [data, setDataList] = useState([])
  // useEffect(() => {
  //   if (data.length > 0) {
  //     setDataList(data)
  //   }
  // }, [data])
  const checkArr = Array.isArray(
    data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat
  )
  const { setIndexDetailAction } = actionStore
  const { departmentList } = departmentStore
  const queryStringParse = queryString.parse(location.search)
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }

  const [codeDepartmentList, setCodeDepartmentList] = useState([])
  useEffect(() => {
    if (departmentList) {
      const codes = departmentList.map(dp => dp.code)
      setCodeDepartmentList(codes)
    }
  }, [departmentList])
  const styleTable = isPartOfPdf
    ? {
        boder: 'none',
        backgroundColor: 'white',
        margin: '16px 0 50px',
      }
    : {
        margin: 0,
        width: '100%',
      }
  const styleTable2 = isPartOfPdf
    ? {
        paddingLeft: 50,
      }
    : {
        margin: 0,
        width: '100%',
      }
  const treeData =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.map(item => ({
      title: item.name,
      value: item.code,
      key: item.code,
      children: item.unit_users.map(user => ({
        title: user.full_name,
        value: user.user_code,
        key: user.user_code,
      })),
    }))
  const treeDataDp = selectPeopleStore.departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))
  const BAN_CMNV_VIMC = selectPeopleStore.departmentWithUserList.find(
    depart => depart.name === data[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv
  )

  const [treeData_BPDX, setTreeData] = useState([])

  useEffect(() => {
    const updatedTreeData = treeDataDp.concat(treeData)
    setTreeData(updatedTreeData)
  }, [riskUnitsV2, selectPeopleStore.departmentWithUserList])

  const webTableCellStyle = totalColumn => {
    return {
      width: `${100 / totalColumn}%`,
      textAlign: 'center',
    }
  }
  const webTableCellStylePDF = totalColumn => {
    return {
      width: `${100 / totalColumn}%`,
      textAlign: 'center',
      border: 'none',
      margin: '0 50px ',
      backgroundColor: 'white',
      color: 'black',
    }
  }
  const getTitleForCongViec = congViec => {
    if (congViec == 'cong_viec_thuoc_chuc_nang_nhiem_vu')
      return 'a/ Công việc thuộc chức năng, nhiệm vụ:'
    if (congViec == 'cong_viec_theo_kpi_kri')
      return 'b/ Công việc theo KPI/KRI:'
    if (congViec == 'cong_viec_theo_muc_tieu_chien_luoc')
      return 'c/ Công việc theo mục tiêu, chiến lược:'
  }
  const [dvptList, setDvptList] = useState([])

  const department_name_nddpv_cua_vimc_tai_dntv =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(
      e =>
        e.code ===
        data[0]?.doi_tuong_thuc_hien?.department_code_nddpv_cua_vimc_tai_dntv
    )
  const department_name_DVPY_cua_VIMC =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(
      e => e.code === data[0]?.doi_tuong_thuc_hien?.dvpt_cua_vimc
    )
  const name = departmentList
    .filter(
      el =>
        el.code ===
        data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat
    )
    .map(el => {
      return el.name
    })
  const department_name_CQVP_cua_VIMC = departmentList?.find(
    e =>
      e.code === data[0]?.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
  )

  const findDepartmentByCode = unit_code => {
    return departmentList?.find(dept => dept.code === unit_code)
  }
  const findDVPTByCode = unit_code => {
    return dvptList?.find(dept => dept.code === unit_code)
  }

  const getUnitDVPT = async type => {
    try {
      const response = await riskStore.getRiskUnitsByType(type)
      setDvptList(response.data)
    } catch (error) {
      console.log(error)
      message.error('Đã có lỗi xảy ra')
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        getUnitDVPT('DVPT')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const renderNameLanhDaoBanT2 = names => {
    if (names == null || names.length == 0) return
    if (names.length == 1) return names[0].label
    return names.map(name => name.label).join(', ')
  }
  const riskActionDetailsTier1 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:&nbsp;
        </h6>
        {utils.checkRichType(
          type,
          <ActionLabel
            label={`- NĐDPV của ${systemName} tại DNTV (trong trường hợp giao NĐDPV thực hiện công việc):`}
            text={utils.getNameInCapitalize(
              department_name_nddpv_cua_vimc_tai_dntv?.name
            )}
            labelStyle={labelBold}
          />,
          <ActionLabel
            label={`- ĐVPT của ${systemName} (trong trường hợp ĐVPT thực hiện công việc):`}
            text={utils.getNameInCapitalize(
              department_name_DVPY_cua_VIMC?.name
            )}
            labelStyle={labelBold}
          />,
          <ActionLabel
            label={`- Các Đơn vị thuộc cơ quan Văn phòng của ${systemName} (trong trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện công việc):`}
            text={
              findDepartmentByCode(
                data[0]?.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
              )?.name
            }
            labelStyle={labelBold}
          />
          // actionBoldLabel(
          //   '- Các Đơn vị thuộc cơ quan Văn phòng của VIMC (trong trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện công việc):',
          //   data[0]?.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
          // )
        )}
        <Divider />
        <h6>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h6>
        <h6>1. Công việc/lĩnh vực hoạt động</h6>
        {isPartOfPdf ? (
          <>
            {[
              'cong_viec_thuoc_chuc_nang_nhiem_vu',
              'cong_viec_theo_kpi_kri',
              'cong_viec_theo_muc_tieu_chien_luoc',
            ].map(congViec => (
              <TableHasCheckbox
                key={congViec}
                title={getTitleForCongViec(congViec)} // Hàm getTitleForCongViec() trả về tiêu đề tương ứng cho công việc
                checked={data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo?.includes(
                  congViec
                )}
              />
            ))}
          </>
        ) : (
          <>
            {[
              'cong_viec_thuoc_chuc_nang_nhiem_vu',
              'cong_viec_theo_kpi_kri',
              'cong_viec_theo_muc_tieu_chien_luoc',
            ].map((congViec, index) => (
              <ActionLabels
                key={index}
                label={
                  <p>
                    <span
                      className={
                        data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo?.includes(
                          congViec
                        )
                          ? 'customStyleText customStyle'
                          : 'customStyle'
                      }>
                      {getTitleForCongViec(congViec)}
                    </span>
                    <CheckboxWrapper>
                      <Checkbox
                        checked={data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo?.includes(
                          congViec
                        )}></Checkbox>
                    </CheckboxWrapper>
                  </p>
                }
                labelStyle={{ marginLeft: '6px' }}
              />
            ))}
          </>
        )}
        {/* <p style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
          <span style={{ marginRight: 8 }}>
            d/ Nội dung công việc khác không thuộc các nhóm trên:&nbsp;
          </span>
          <span
            className="customStyleText customStyle"
            style={{ fontStyle: isPartOfPdf ? 'italic' : '' }}>
            {
              data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                ?.cong_viec_linh_vuc_hoat_dong
                ?.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren
            }
          </span>
        </p> */}
        <ActionLabel
          label="d/ Nội dung công việc khác không thuộc các nhóm trên:&nbsp;"
          text={
            <span
              className="customStyleText customStyle"
              style={{ fontStyle: isPartOfPdf ? 'italic' : '' }}>
              {
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_linh_vuc_hoat_dong
                  ?.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren
              }
            </span>
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <h6>2. Công việc thực hiện</h6>
        {/* <p style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
          <span>a/ Nội dung công việc:</span>
          <span className="customStyleText customStyle">
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;' : ''
              }">${
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_thuc_hien?.noi_dung_cong_viec || ''
              }</div>`}></RichText>
          </span>
        </p> */}
        <ActionLabel
          label="a/ Nội dung công việc:&nbsp;"
          text={
            <span style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
              <RichText
                htmlText={`<div style="${
                  isPartOfPdf ? 'font-style: italic;' : ''
                }">${
                  data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.cong_viec_thuc_hien?.noi_dung_cong_viec || ''
                }</div>`}></RichText>
            </span>
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <ActionLabel
          label="b/ Người thực hiện:&nbsp;"
          text={
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
              ?.nguoi_thuc_hien
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <ActionLabel
          label="c/ Thời gian hoàn thành:&nbsp;"
          text={
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
              ?.thoi_gian_hoan_thanh
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <h6>3. Nhận diện rủi ro và đánh giá rủi ro</h6>
        {/* <p style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
          <span style={{ marginRight: 8 }}>a/ Nhận diện rủi ro:</span>
          <span className="customStyleText customStyle">
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;padding-left:6px;' : ''
              }">${
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro || ''
              }</div>`}></RichText>
          </span>
        </p> */}
        {/* <p style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
          <span style={{ marginRight: 8 }}>
            b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:
          </span>
          <span className="customStyleText customStyle"></span>
        </p> */}
        <ActionLabel
          label="a/ Nhận diện rủi ro:&nbsp;"
          text={
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;padding-left:6px;' : ''
              }">${
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro || ''
              }</div>`}></RichText>
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <ActionLabel
          label="b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:&nbsp;"
          text={
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;' : ''
              }">${
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.xay_dung_giai_phap_giam_thieu_phong_ngua || ''
              }</div>`}></RichText>
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <ActionLabel
          label="c/ Đánh giá rủi ro:&nbsp;"
          text={displayRiskAssessment(
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
              ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.r || ''
          )}
          labelStyle={{ marginLeft: '6px' }}
        />
        {isPartOfPdf ? (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>Người thực hiện </b>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                {type === 'NDDPV' ? (
                  <div>
                    <b>NĐDPV phụ trách chung </b>
                  </div>
                ) : type === 'DVPT' ? (
                  <div>
                    <b>Lãnh đạo đơn vị phụ thuộc</b>
                  </div>
                ) : (
                  <div>
                    <b>Lãnh đạo Đơn vị/NĐDPV phụ trách chung </b>
                  </div>
                )}
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  color: 'black',
                }}>
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign
                  ?.filter(el => el.value.startsWith('RU_CPN'))
                  .map(item => (
                    <div style={{ marginTop: '5px' }}>{item.label}</div>
                  ))}
                {/* Đã ký */}
                <div style={{ marginTop: '5px' }}>
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nguoi_thuc_hien_signed
                  }
                </div>
                {/* // Nếu có đã ký thì không cần cách nữa - người thực hiện - NDDPV */}
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign?.map(
                  item => (
                    <>
                      {departmentList
                        .filter(el => el.code === item.value)
                        ?.map(unit => (
                          <div
                            style={{
                              marginTop: data[0]
                                ?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                                .nguoi_thuc_hien_signed
                                ? '5px'
                                : '60px',
                            }}>
                            {unit.name}
                          </div>
                        ))}
                    </>
                  )
                )}
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign
                  ?.filter(
                    el =>
                      !el.value.startsWith('RU_CPN') &&
                      !codeDepartmentList.includes(el.value)
                  )
                  .map(item => (
                    <div
                      style={{
                        marginTop: data[0]
                          ?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                          .nguoi_thuc_hien_signed
                          ? '5px'
                          : '60px',
                      }}>
                      {item.label}
                    </div>
                  ))}
                <div
                  style={{
                    marginTop: data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nguoi_thuc_hien_signed
                      ? '5px'
                      : '60px',
                  }}>
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      ?.nguoi_thuc_hien_sign_khac
                  }
                </div>
              </td>
              {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro //NDDPV
                ?.nddpv_phu_trach_chung ? (
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nddpv_phu_trach_chung__signed
                  }
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nddpv_phu_trach_chung?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {/* // Nếu không có đã ký thì không cần cách - NDDPV phụ trách chung - NDDPV */}

                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div
                        style={{
                          marginTop: data[0]
                            ?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                            .nddpv_phu_trach_chung__signed
                            ? '5px'
                            : '60px',
                        }}>
                        {item.label}
                      </div>
                    ))}
                </td>
              ) : data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro //DVPT
                  ?.lanh_dao_don_vi_phu_thuoc ? (
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nddpv_phu_trach_chung__signed
                  }
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.lanh_dao_don_vi_phu_thuoc?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {/* Đã ký - Lãnh đạo đơn vị phụ thuộc - DVPT - tuyến 1 */}
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div
                        style={{
                          marginTop: data[0]
                            ?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                            .nddpv_phu_trach_chung__signed
                            ? '5px'
                            : '60px',
                        }}>
                        {item.label}
                      </div>
                    ))}
                </td>
              ) : (
                //CQVP
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc_cqvp
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.lanh_dao_don_vi_phu_thuoc_cqvp?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {/* Đã ký-CQVP */}
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nddpv_phu_trach_chung__signed
                  }
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc_cqvp
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div
                        style={{
                          marginTop: data[0]
                            ?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                            .nddpv_phu_trach_chung__signed
                            ? '5px'
                            : '60px',
                        }}>
                        {item.label}
                      </div>
                    ))}
                </td>
              )}
            </tr>
          </table>
        ) : (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                <div>
                  <b>Người thực hiện </b>
                </div>
              </td>
              <td style={webTableCellStyle(2)}>
                {type === 'NDDPV' ? (
                  <div>
                    <b>NĐDPV phụ trách chung </b>
                  </div>
                ) : type === 'DVPT' ? (
                  <div>
                    <b>Lãnh đạo đơn vị phụ thuộc</b>
                  </div>
                ) : (
                  <div>
                    <b>Lãnh đạo Đơn vị/NĐDPV phụ trách chung </b>
                  </div>
                )}
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign
                  ?.filter(el => el.value.startsWith('RU_CPN'))
                  .map(item => (
                    <div style={{ marginTop: '5px' }}>{item.label}</div>
                  ))}
                <span className="w-24">
                  {/* Đã ký */}
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nguoi_thuc_hien_signed
                  }
                </span>
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign?.map(
                  item => (
                    <>
                      {departmentList
                        .filter(el => el.code === item.value)
                        ?.map(unit => (
                          <div style={{ marginTop: '5px' }}>{unit.name}</div>
                        ))}
                    </>
                  )
                )}
                {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign
                  ?.filter(
                    el =>
                      !el.value.startsWith('RU_CPN') &&
                      !codeDepartmentList.includes(el.value)
                  )
                  .map(item => (
                    <div>{item.label}</div>
                  ))}

                <div style={{ marginTop: '0px' }}>
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      ?.nguoi_thuc_hien_sign_khac
                  }
                </div>
              </td>
              {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro //NDDPV
                ?.nddpv_phu_trach_chung ? (
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                  <span>
                    {
                      // phần (đã ký)
                      data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                        .nddpv_phu_trach_chung__signed
                    }
                  </span>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nddpv_phu_trach_chung?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div>{item.label}</div>
                    ))}
                </td>
              ) : data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro //DVPT
                  ?.lanh_dao_don_vi_phu_thuoc ? (
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                  <span>
                    {
                      data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                        .nddpv_phu_trach_chung__signed
                    }
                  </span>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.lanh_dao_don_vi_phu_thuoc?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                </td>
              ) : (
                //CQVP
                <td style={webTableCellStylePDF(2)}>
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc_cqvp
                    ?.filter(el => el.value.startsWith('RU_CPN'))
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}

                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.lanh_dao_don_vi_phu_thuoc_cqvp?.map(
                    item => (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  )}
                  {
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      .nddpv_phu_trach_chung__signed
                  }
                  {data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.lanh_dao_don_vi_phu_thuoc_cqvp
                    ?.filter(
                      el =>
                        !el.value.startsWith('RU_CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div style={{ marginTop: '5px' }}>{item.label}</div>
                    ))}
                </td>
              )}
            </tr>
          </table>
        )}

        <Divider />

        <h6 style={{ marginTop: 20 }}>II. XỬ LÝ RỦI RO</h6>
        <h6>1. Đề xuất loại rủi ro cần xử lý </h6>
        {checkArr === false ? (
          <>
            <ActionLabel
              label="a/ Loại rủi ro đề xuất:&nbsp;"
              text={
                <>
                  <RichText
                    htmlText={`<div style="${
                      isPartOfPdf ? 'font-style: italic;' : ''
                    }">${
                      data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                        ?.loai_rui_ro_de_xuat || ''
                    }</div>`}></RichText>
                </>
              }
              labelStyle={{ marginLeft: '6px' }}
            />
            {/* <ActionLabel
              // label="a/ Loại rủi ro đề xuất:&nbsp;"
              text={
                <>
                  {type === 'NDDPV' ? (
                    <RichText
                      htmlText={`<div style="${
                        isPartOfPdf ? 'font-style: italic;' : ''
                      }">${
                        data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                          ?.loai_rui_ro_de_xuat_khac || ''
                      }</div>`}></RichText>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>
                      {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                        ?.loai_rui_ro_de_xuat_khac || ''}
                    </span>
                  )}
                </>
              }
              labelStyle={{ marginLeft: '6px' }}
            /> */}
          </>
        ) : (
          <ActionLabel
            label="a/ Loại rủi ro đề xuất:&nbsp;"
            text={
              <>
                {displayRiskAssessment(
                  data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                    ?.loai_rui_ro_de_xuat
                )}
                {type === 'NDDPV' ? (
                  <RichText
                    htmlText={`<div style="${
                      isPartOfPdf ? 'font-style: italic;' : ''
                    }">${
                      data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                        ?.loai_rui_ro_de_xuat_khac || ''
                    }</div>`}></RichText>
                ) : (
                  <span style={{ fontStyle: 'italic' }}>
                    {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                      ?.loai_rui_ro_de_xuat_khac || ''}
                  </span>
                )}
              </>
            }
            // labelStyle={labelBold}
            labelStyle={{ marginLeft: '6px' }}
          />
        )}
        {type === 'NDDPV' ? (
          <>
            <ActionLabel
              label="b/ Bộ phận đề xuất:&nbsp;"
              text={
                <>
                  {/* {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat
                ?.filter(item => item.value.startsWith('RU_'))
                .map(el => (
                  <span>{el}</span>
                ))} */}
                  {
                    data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                      ?.bo_phan_de_xuat_khac
                  }
                </>
              }
              labelStyle={{ marginLeft: '6px' }}
            />
          </>
        ) : (
          <>
            {type === 'DVPT' ? (
              <>
                <ActionLabel
                  label="b/ Bộ phận đề xuất:&nbsp;"
                  text={
                    <>
                      {/* {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat
                    ?.filter(item => item.value.startsWith('RU_'))
                    .map(el => (
                      <span>{el}</span>
                    ))} */}
                      {
                        data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                          ?.bo_phan_de_xuat_khac
                      }
                    </>
                  }
                  labelStyle={{ marginLeft: '6px' }}
                />
              </>
            ) : (
              <>
                <ActionLabel
                  label="b/ Bộ phận đề xuất:&nbsp;"
                  text={
                    <>
                      {/* {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat
                ?.filter(item => item.value.startsWith('RU_'))
                .map(el => (
                  <span>{el}</span>
                ))} */}
                      {
                        data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                          ?.bo_phan_de_xuat
                      }
                    </>
                  }
                  labelStyle={{ marginLeft: '6px' }}
                />
              </>
            )}
          </>
        )}

        <ActionLabel
          label="c/ Biện pháp xử lý:&nbsp;"
          text={
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;' : ''
              }">${
                data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                  ?.bien_phap_xu_ly || ''
              }</div>`}
            />
          }
          labelStyle={{ marginLeft: '6px' }}
        />
        <div style={{ paddingBottom: '20px' }}>
          {type === 'NDDPV' ? (
            <ActionLabels
              label="d/ Người thực hiện:&nbsp;"
              title={
                <div>
                  <div>
                    {data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly?.nguoi_thuc_hien__de_xuat_khac?.map(
                      (item, index) => (
                        <div key={index}>{item?.label}</div>
                      )
                    )}
                  </div>
                  <div>
                    {
                      data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                        ?.nguoi_thuc_hien
                    }
                  </div>
                </div>
              }
              labelStyle={{ marginLeft: '6px' }}
            />
          ) : (
            <ActionLabels
              label="d/ Người thực hiện:&nbsp;"
              title={
                data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                  ?.nguoi_thuc_hien
              }
              labelStyle={{ marginLeft: '6px' }}
            />
          )}
        </div>

        <Divider />
        {isPartOfPdf ? (
          <h6 style={{ marginTop: 20 }}>
            2. Lãnh đạo quyết định biện pháp xử lý
          </h6>
        ) : (
          <h6>2. Lãnh đạo quyết định biện pháp xử lý</h6>
        )}

        {isPartOfPdf ? (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  {type === 'NDDPV' ? (
                    <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN THỰC HIỆN XỬ LÝ RỦI RO</b>
                  ) : (
                    <b>LÃNH ĐẠO ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                  )}

                  <div style={{ marginTop: 10 }}>
                    {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
                      ?.filter(el => el.value.startsWith('RU_CPN'))
                      .map(el => (
                        <div style={{ marginTop: '5px' }}>{el.label}</div>
                      ))}
                    {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro?.map(
                      item => (
                        <>
                          {departmentList
                            .filter(el => el.code === item.value)
                            ?.map(unit => (
                              <div style={{ marginTop: '5px' }}>
                                {unit.name}
                              </div>
                            ))}
                        </>
                      )
                    )}
                    {
                      data[0]?.II_xu_ly_rui_ro
                        ?.don_vi_thuc_hien_xu_ly_rui_ro__signed
                    }
                    {
                      // Nếu có đã ký thì không cách để ký nữa - người đại diện phần vốn thực hiện xử lý rủi ro - NDDPV
                      data[0]?.II_xu_ly_rui_ro
                        ?.don_vi_thuc_hien_xu_ly_rui_ro__signed ? (
                        <>
                          {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
                            ?.filter(
                              el =>
                                !el.value.startsWith('RU_CPN') &&
                                !codeDepartmentList.includes(el.value)
                            )
                            .map(el => (
                              <div style={{ marginTop: '5px' }}>{el.label}</div>
                            ))}
                          <div>
                            {
                              data[0]?.II_xu_ly_rui_ro
                                ?.don_vi_thuc_hien_xu_ly_rui_ro_khac?.full_name
                            }
                          </div>
                        </>
                      ) : (
                        <>
                          {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
                            ?.filter(
                              el =>
                                !el.value.startsWith('RU_CPN') &&
                                !codeDepartmentList.includes(el.value)
                            )
                            .map(el => (
                              <div style={{ marginTop: '65px' }}>
                                {el.label}
                              </div>
                            ))}
                          <div style={{ marginTop: '65px' }}>
                            {
                              data[0]?.II_xu_ly_rui_ro
                                ?.don_vi_thuc_hien_xu_ly_rui_ro_khac?.full_name
                            }
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  {type === 'NDDPV' ? (
                    <>
                      <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG</b>
                      <div style={{ marginTop: 10 }}>
                        {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung
                          ?.filter(el => el.value.startsWith('RU_'))
                          .map(el => (
                            <div style={{ marginTop: '5px' }}>{el.label}</div>
                          ))}
                        {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung?.map(
                          item => (
                            <>
                              {departmentList
                                .filter(el => el.code === item.value)
                                ?.map(unit => (
                                  <div style={{ marginTop: '5px' }}>
                                    {unit.name}
                                  </div>
                                ))}
                            </>
                          )
                        )}

                        {
                          data[0]?.II_xu_ly_rui_ro
                            ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed
                        }

                        {
                          //Nếu có chữ đã ký thì không cần cách để ký nữa - người đại diện phần vốn phụ trách chung - NDDPV
                          data[0]?.II_xu_ly_rui_ro
                            ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed ? (
                            <>
                              {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung
                                ?.filter(
                                  el =>
                                    !el.value.startsWith('RU_CPN') &&
                                    !codeDepartmentList.includes(el.value)
                                )
                                .map(el => (
                                  <div style={{ marginTop: '5px' }}>
                                    {el.label}
                                  </div>
                                ))}
                            </>
                          ) : (
                            <>
                              {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung
                                ?.filter(
                                  el =>
                                    !el.value.startsWith('RU_CPN') &&
                                    !codeDepartmentList.includes(el.value)
                                )
                                .map(el => (
                                  <div style={{ marginTop: '65px' }}>
                                    {el.label}
                                  </div>
                                ))}
                            </>
                          )
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <b>
                        LÃNH ĐẠO {systemName}
                        <br />
                        (theo phân công trách nhiệm quản lý rủi ro)
                      </b>
                      <span style={{ margin: '5px 0' }}>
                        {
                          // Đã ký - Lãnh đạo VIMC - CQVP
                          data[0]?.II_xu_ly_rui_ro
                            ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed
                        }
                      </span>
                      <div>
                        <div
                          style={{
                            fontSize: '13px',
                            marginTop: data[0]?.II_xu_ly_rui_ro
                              ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed
                              ? '0px'
                              : '65px',
                          }}>
                          {
                            data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]
                              ?.position_name
                          }
                        </div>
                        {utils.getNameInCapitalize(
                          data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]?.full_name
                        )}
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
          </table>
        ) : (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                {type === 'NDDPV' ? (
                  <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN THỰC HIỆN XỬ LÝ RỦI RO</b>
                ) : (
                  <b>LÃNH ĐẠO ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                )}

                <td style={webTableCellStyle(2)} className="textValue">
                  <div style={{ marginTop: 10 }}>
                    {utils.getNameInCapitalize(
                      data[0]?.II_xu_ly_rui_ro
                        ?.don_vi_thuc_hien_xu_ly_rui_ro_khac?.full_name
                    )}
                  </div>
                </td>
              </td>
              <td style={webTableCellStyle(2)}>
                {type === 'NDDPV' ? (
                  <div>
                    <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG</b>
                    <td style={webTableCellStyle(2)} className="textValue">
                      <div style={{ marginTop: 10 }}></div>
                    </td>
                  </div>
                ) : (
                  <b>
                    LÃNH ĐẠO {systemName}
                    <br />
                    (theo phân công trách nhiệm quản lý rủi ro)
                  </b>
                )}
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)} className="textValue">
                {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
                  ?.filter(el => el.value.startsWith('RU_CPN'))
                  .map(el => {
                    return <div style={{ marginTop: '5px' }}>{el.label}</div>
                  })}
                {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro?.map(
                  item => {
                    return (
                      <>
                        {departmentList
                          .filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '5px' }}>{unit.name}</div>
                          ))}
                      </>
                    )
                  }
                )}

                {
                  data[0]?.II_xu_ly_rui_ro
                    ?.don_vi_thuc_hien_xu_ly_rui_ro__signed
                }

                {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
                  ?.filter(
                    el =>
                      !el.value.startsWith('RU_CPN') &&
                      !codeDepartmentList.includes(el.value)
                  )
                  .map(el => (
                    <div>{el.label}</div>
                  ))}
                <div style={{ marginTop: '5px' }}>
                  {
                    data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro_khac
                      ?.full_name
                  }
                </div>
              </td>
              {type === 'NDDPV' ? (
                <td style={webTableCellStyle(2)} className="textValue">
                  <div>
                    {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung
                      ?.filter(el => el.value.startsWith('RU_'))
                      .map(el => (
                        <div style={{ marginTop: '5px' }}>{el.label}</div>
                      ))}
                    {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung?.map(
                      item => (
                        <>
                          {departmentList
                            .filter(el => el.code === item.value)
                            ?.map(unit => (
                              <div style={{ marginTop: '5px' }}>
                                {unit.name}
                              </div>
                            ))}
                        </>
                      )
                    )}
                    {
                      data[0]?.II_xu_ly_rui_ro
                        ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed
                    }
                    {data[0]?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung
                      ?.filter(
                        el =>
                          !el.value.startsWith('RU_CPN') &&
                          !codeDepartmentList.includes(el.value)
                      )
                      .map(el => (
                        <div style={{ marginTop: '0px' }}>{el.label}</div>
                      ))}
                  </div>
                </td>
              ) : (
                <td style={webTableCellStyle(2)} className="textValue">
                  {
                    data[0]?.II_xu_ly_rui_ro
                      ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed
                  }
                  <div>
                    <div style={{ fontSize: '13px' }}>
                      {
                        data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]
                          ?.position_name
                      }
                    </div>
                    <div>
                      {utils.getNameInCapitalize(
                        data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]?.full_name
                      )}
                    </div>
                  </div>
                </td>
              )}
            </tr>
          </table>
        )}
      </RiskActionStyle>
    )
  }

  // const resultCVTH = treeData_BPDX.reduce((acc, item) => {
  //   const matchingChildren = item?.children?.filter(child =>
  //     data[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv?.includes(
  //       child.value
  //     )
  //   )
  //   if (matchingChildren?.length > 0) {
  //     acc.push(...matchingChildren)
  //   }
  //   return acc
  // }, [])
  // const resultLDB = treeData_BPDX.reduce((acc, item) => {
  //   const matchingChildren = item?.children?.filter(child =>
  //     data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.includes(child.value)
  //   )
  //   if (matchingChildren?.length > 0) {
  //     acc.push(...matchingChildren)
  //   }
  //   return acc
  // }, [])

  const riskActionDetailsTier2 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:&nbsp;
        </h6>
        {isPartOfPdf ? (
          <>
            {type === 'NDDPV' ? (
              ''
            ) : (
              <>
                {data[0]?.doi_tuong_thuc_hien?.ban_pc_qtrr_vimc.length > 0 ? (
                  <span>
                    - Ban PC&QTRR {systemName} (trong trường hợp các Ban CMNV
                    thực hiện công việc):&nbsp;
                  </span>
                ) : (
                  ''
                )}

                {data[0]?.doi_tuong_thuc_hien?.ban_pc_qtrr_vimc ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    {data[0].doi_tuong_thuc_hien?.ban_pc_qtrr_vimc?.map(
                      (item, index) => {
                        const fullName = utils.getNameInCapitalize(
                          item.full_name
                        )
                        return index ===
                          data[0].doi_tuong_thuc_hien?.ban_pc_qtrr_vimc
                            ?.length -
                            1
                          ? fullName
                          : fullName + ', '
                      }
                    )}
                  </span>
                ) : (
                  <span></span>
                )}
              </>
            )}
            <p>
              {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                <span>
                  - Ban CMNV {systemName} (trong trường hợp giao NĐDPV, ĐVPT
                  thực hiện công việc; các Ban cmnv chủ trì):&nbsp;
                </span>
              ) : (
                ''
              )}

              {type === 'NDDPV' ? (
                data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    {
                      findDepartmentByCode(
                        data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                      )?.name
                    }
                  </span>
                ) : (
                  <span></span>
                )
              ) : (
                <>
                  {type === 'DVPT' ? (
                    <>
                      {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                        <span style={{ ...textValue, ...labelBold }}>
                          {
                            findDVPTByCode(
                              data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                            )?.name
                          }
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </>
                  ) : (
                    <>
                      {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                        <span style={{ ...textValue, ...labelBold }}>
                          {
                            findDepartmentByCode(
                              data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                            )?.name
                          }
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </>
                  )}
                </>
              )}
            </p>
          </>
        ) : (
          <>
            {type === 'NDDPV' ? (
              ''
            ) : (
              <p style={{ display: 'flex' }}>
                <span>
                  - Ban PC&QTRR {systemName} (trong trường hợp các Ban CMNV thực
                  hiện công việc):&nbsp;
                </span>

                {data[0]?.doi_tuong_thuc_hien?.ban_pc_qtrr_vimc ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    {data[0].doi_tuong_thuc_hien?.ban_pc_qtrr_vimc?.map(
                      (item, index, array) => {
                        const fullName = utils.getNameInCapitalize(
                          item.full_name
                        )
                        return (
                          <span key={index} style={{ display: 'block' }}>
                            {fullName}
                            <span style={{ margin: '0 3px' }}>-</span>
                            <span>{item.position_name}</span>
                            {index !== array.length - 1 && <span>, </span>}
                          </span>
                        )
                      }
                    )}
                  </span>
                ) : (
                  <span></span>
                )}
              </p>
            )}
            <p>
              <span>
                - Ban CMNV {systemName} (trong trường hợp giao NĐDPV, ĐVPT thực
                hiện công việc; các Ban cmnv chủ trì):&nbsp;
              </span>

              {type === 'NDDPV' ? (
                data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    {
                      findDepartmentByCode(
                        data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                      )?.name
                    }
                  </span>
                ) : (
                  <span></span>
                )
              ) : (
                <>
                  {type === 'DVPT' ? (
                    <>
                      {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                        <span style={{ ...textValue, ...labelBold }}>
                          {/* {Array.isArray(data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc) &&
                    data[0]?.doi_tuong_thuc_hien.ban_cmnv_vimc?.map(
                      (item, index) => {
                        return index ===
                          data[0]?.doi_tuong_thuc_hien.ban_cmnv_vimc?.length - 1
                          ? findDVPTByCode(item)?.name
                          : findDVPTByCode(item)?.name + ', '
                      }
                    )} */}
                          {
                            findDVPTByCode(
                              data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                            )?.name
                          }
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </>
                  ) : (
                    <>
                      {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc ? (
                        <span style={{ ...textValue, ...labelBold }}>
                          {/* {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc?.map(
                        (item, index) => {
                          return index ===
                            data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                              ?.length -
                              1
                            ? findDepartmentByCode(item)?.name
                            : findDepartmentByCode(item)?.name + ', '
                        }
                      )} */}
                          {
                            findDepartmentByCode(
                              data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc
                            )?.name
                          }
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </>
                  )}
                </>
              )}
            </p>
          </>
        )}

        <Divider />

        <h6>III. KIỂM SOÁT RỦI RO</h6>
        <p>
          <span>1. Đối với các loại rủi ro đã nhận diện:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {data[0]?.III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
              ?.dong_y === 'Đồng ý'
              ? 'Đồng ý'
              : data[0]?.III_kiem_soat_rui_ro
                  ?.doi_voi_cac_loai_rui_ro_da_nhan_dien?.dong_y ===
                'Không đồng ý'
              ? 'Không đồng ý'
              : ''}
          </span>{' '}
          <span style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.III_kiem_soat_rui_ro
                ?.doi_voi_cac_loai_rui_ro_da_nhan_dien?.dieu_chinh_bo_sung
            }
          </span>
        </p>
        <p>
          <span>2. Đối với đánh giá mức rủi ro:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {data[0]?.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro
              ?.dong_y === 'Đồng ý'
              ? 'Đồng ý'
              : data[0]?.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro
                  ?.dong_y === 'Không đồng ý'
              ? 'Không đồng ý'
              : ''}
          </span>{' '}
          <span style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
                ?.dieu_chinh_bo_sung
            }
          </span>
        </p>
        <p>
          <span>3. Loại rủi ro đã đề xuất xử lý:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {data[0]?.III_kiem_soat_rui_ro.loai_rui_ro_da_de_xuat_xu_ly}
          </span>
        </p>
        <p>
          <span>4. Đánh giá biện pháp xử lý đã đề xuất:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {data[0]?.III_kiem_soat_rui_ro.danh_gia_bien_phap_xu_ly_da_de_xuat}
          </span>
        </p>
        <p>
          <span>5. Thời gian hoàn thành công việc được giao:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.III_kiem_soat_rui_ro
                .thoi_gian_hoan_thanh_cong_viec_duoc_giao
            }
          </span>
        </p>
        <p>
          <span>6. Ý kiến khác của Ban CMNV {systemName}:&nbsp;</span>
          <span style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.III_kiem_soat_rui_ro
                .bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
            }
            {data[0]?.III_kiem_soat_rui_ro
              .bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
              ? ': '
              : ''}
            {
              data[0]?.III_kiem_soat_rui_ro
                .y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
            }
          </span>
        </p>
        {isPartOfPdf ? (
          <>
            {type === 'NDDPV' ? (
              <>
                <h6 style={{ textAlign: 'center' }}>
                  BAN CMNV {systemName}{' '}
                  {data[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv && (
                    <span>
                      : {data[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv}
                    </span>
                  )}
                </h6>
                <table style={styleTable}>
                  <tr style={{ backgroundColor: 'white' }}>
                    <td
                      style={{
                        ...styleTable2,
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        width: '50%',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Chuyên viên thực hiện</b>
                      </div>
                    </td>
                    <td
                      style={{
                        ...styleTable2,
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Lãnh đạo ban</b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        ...styleTable2,
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div>
                        {
                          data[0]?.III_kiem_soat_rui_ro
                            ?.chuyen_vien_thuc_hien_nddpv_signed
                        }
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          marginTop: '5px',
                        }}>
                        {data[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv?.map(
                          item =>
                            BAN_CMNV_VIMC?.users.map((user, index) => {
                              if (user.user_code === item) {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      marginTop: data[0]?.III_kiem_soat_rui_ro
                                        ?.chuyen_vien_thuc_hien_nddpv_signed
                                        ? '5px'
                                        : '60px',
                                    }}>
                                    {utils.getNameInCapitalize(
                                      user.name_uppercase
                                    )}
                                  </div>
                                )
                              }
                            })
                        )}
                      </div>
                    </td>
                    <td
                      style={{
                        ...styleTable2,
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed}

                      <div style={{ fontSize: '16px' }}>
                        {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.map(
                          item =>
                            BAN_CMNV_VIMC?.users.map((user, index) => {
                              if (user.user_code === item) {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      marginTop: data[0]?.III_kiem_soat_rui_ro
                                        ?.lanh_dao_ban_signed
                                        ? '5px'
                                        : '60px',
                                    }}>
                                    {utils.getNameInCapitalize(
                                      user.name_uppercase
                                    )}
                                  </div>
                                )
                              }
                            })
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </>
            ) : (
              <>
                <h6 style={{ textAlign: 'center' }}>
                  BAN PC&QTRR {systemName}/ BAN CMNV {systemName}
                </h6>
                <table style={styleTable}>
                  <tr style={{ backgroundColor: 'white' }}>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Chuyên viên thực hiện</b>
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>
                          {data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban
                            ? 'Lãnh đạo ban: '
                            : 'Lãnh đạo ban'}
                          {Array.from(
                            new Set(
                              data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban?.map(
                                item => item?.department_name
                              )
                            )
                          ).map((departmentName, index, array) => (
                            <span key={index}>
                              {departmentName}{' '}
                              {index < array?.length - 1 ? ',' : ''}
                            </span>
                          ))}
                          {
                            renderNameLanhDaoBanT2(
                              data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
                                el => el.value.startsWith('RU_')
                              )
                            )
                            //   data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp
                            // ?.filter(el => el.value.startsWith('RU_'))
                            // ?.map(item => (
                            //   <span>
                            //     {''}
                            //     {item.label}
                            //   </span>
                            // ))
                          }
                        </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div>
                        {
                          // Đã ký của chuyên viên thực hiện tuyến 2 - CQVP
                          data[0]?.III_kiem_soat_rui_ro
                            ?.chuyen_vien_thuc_hien_nddpv_signed
                        }
                      </div>
                      <div
                        style={{
                          marginTop: data[0]?.III_kiem_soat_rui_ro
                            ?.chuyen_vien_thuc_hien_nddpv_signed
                            ? '5px'
                            : '60px',
                          fontSize: '16px',
                        }}>
                        {data[0]?.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien}
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      {/* Đã ký - ban lãnh đạo - tuyến 2 -CQVP - in pdf */}
                      {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed}
                      <div
                        style={{
                          marginTop: '5px',
                          fontSize: '16px',
                        }}>
                        <div>
                          {/* Đã ký - lãnh đạo ban - tuyến 2 -DVPT */}
                          {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp
                            ?.filter(el => !el.value.startsWith('RU_CPN'))
                            ?.map(item => (
                              <div
                                style={{
                                  marginTop: data[0]?.III_kiem_soat_rui_ro
                                    ?.lanh_dao_ban_signed
                                    ? '5px'
                                    : '60px',
                                }}>
                                {item.label}
                              </div>
                            ))}
                        </div>
                        <span>
                          {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv}
                        </span>
                        {data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban ? (
                          <div>
                            {data[0].III_kiem_soat_rui_ro.lanh_dao_ban.map(
                              (item, index) => {
                                const fullName = utils.getNameInCapitalize(
                                  item.full_name
                                )
                                return index ===
                                  data[0].III_kiem_soat_rui_ro.lanh_dao_ban
                                    .length -
                                    1 ? (
                                  <>
                                    <div
                                      style={{
                                        fontSize: '13px',
                                        marginTop: data[0]?.III_kiem_soat_rui_ro
                                          ?.lanh_dao_ban_signed
                                          ? '5px'
                                          : '60px',
                                      }}>
                                      {item.position_name}
                                    </div>
                                    <div>{`${fullName}`}</div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        fontSize: '13px',
                                        marginTop: data[0]?.III_kiem_soat_rui_ro
                                          ?.lanh_dao_ban_signed
                                          ? '5px'
                                          : '60px',
                                      }}>
                                      {item.position_name}
                                    </div>
                                    <div>{fullName + ', '}</div>
                                  </>
                                )
                              }
                            )}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </>
            )}
          </>
        ) : (
          <>
            {' '}
            {type === 'NDDPV' ? (
              <>
                <h6 style={{ textAlign: 'center' }}>
                  BAN CMNV {systemName}{' '}
                  {data[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv && (
                    <span>
                      : {data[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv}
                    </span>
                  )}
                </h6>
                <table style={styleTable}>
                  <tr style={{ backgroundColor: 'white' }}>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        width: '50%',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Chuyên viên thực hiện</b>
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Lãnh đạo ban</b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div>
                        {
                          data[0]?.III_kiem_soat_rui_ro
                            ?.chuyen_vien_thuc_hien_nddpv_signed
                        }
                      </div>
                      <div style={{ margin: '0 20px', fontSize: '16px' }}>
                        {data[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv?.map(
                          item =>
                            BAN_CMNV_VIMC?.users?.map(user => {
                              if (user?.user_code === item) {
                                return (
                                  <div style={{ marginTop: '5px' }}>
                                    {utils.getNameInCapitalize(
                                      user.name_uppercase
                                    )}
                                  </div>
                                )
                              }
                            })
                        )}
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed}
                      <div style={{ margin: '0 20px', fontSize: '16px' }}>
                        {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.map(
                          item =>
                            BAN_CMNV_VIMC?.users?.map(user => {
                              if (user?.user_code === item) {
                                return (
                                  <div style={{ marginTop: '5px' }}>
                                    {utils.getNameInCapitalize(
                                      user.name_uppercase
                                    )}
                                  </div>
                                )
                              }
                            })
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </>
            ) : (
              <>
                <h6 style={{ textAlign: 'center' }}>
                  BAN PC&QTRR {systemName}/ BAN CMNV {systemName}
                </h6>
                <table style={styleTable}>
                  <tr style={{ backgroundColor: 'white' }}>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>Chuyên viên thực hiện</b>
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px' }}>
                        <b>
                          {data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban
                            ? 'Lãnh đạo ban: '
                            : 'Lãnh đạo ban'}
                          {Array.from(
                            new Set(
                              data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban?.map(
                                item => item?.department_name
                              )
                            )
                          ).map((departmentName, index, array) => (
                            <span key={index}>
                              {departmentName}{' '}
                              {index < array?.length - 1 ? ',' : ''}
                            </span>
                          ))}
                          {
                            renderNameLanhDaoBanT2(
                              data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
                                el => el.value.startsWith('RU_')
                              )
                            )
                            //   data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp
                            // ?.filter(el => el.value.startsWith('RU_'))
                            // ?.map(item => (
                            //   <span>
                            //     {''}
                            //     {item.label}
                            //   </span>
                            // ))
                          }
                        </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px', fontSize: '16px' }}>
                        {
                          data[0]?.III_kiem_soat_rui_ro
                            ?.chuyen_vien_thuc_hien_nddpv_signed
                        }
                      </div>
                      <div style={{ margin: '0 20px', fontSize: '16px' }}>
                        {data[0]?.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien}
                      </div>
                    </td>
                    <td
                      style={{
                        border: 'none',
                        margin: '0 50px ',
                        backgroundColor: 'white',
                        textAlign: 'center',
                      }}>
                      <div style={{ margin: '0 20px', fontSize: '16px' }}>
                        {/* Đã ký của ban lãnh đạo - tuyến 2 - CQVP */}
                        {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed}
                        <div>
                          {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp
                            ?.filter(el => !el.value.startsWith('RU_CPN'))
                            ?.map(item => (
                              <div
                                style={{
                                  marginTop: '60px !important ',
                                }}>
                                {item.label}
                              </div>
                            ))}
                        </div>
                        <span>
                          {data[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv}
                        </span>
                        {data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban ? (
                          <span>
                            {data[0].III_kiem_soat_rui_ro.lanh_dao_ban.map(
                              (item, index, array) => {
                                const fullName = utils.getNameInCapitalize(
                                  item.full_name
                                )
                                return (
                                  <span
                                    key={index}
                                    style={{ display: 'block' }}>
                                    <div style={{ fontSize: '13px' }}>
                                      {item.position_name}
                                    </div>
                                    <div>{fullName}</div>
                                  </span>
                                )
                              }
                            )}
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </>
            )}
          </>
        )}
      </RiskActionStyle>
    )
  }

  const riskActionDetailsTier3 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:
        </h6>
        <p style={{ display: 'flex' }}>
          <span>- Tổng giám đốc, các Phó Tổng giám đốc:&nbsp;</span>
          {data[0]?.doi_tuong_thuc_hien.tgd_ptgd ? (
            <span style={{ ...textValue, ...labelBold }}>
              {data[0]?.doi_tuong_thuc_hien.tgd_ptgd.map(
                (item, index, array) => {
                  const fullName = utils.getNameInCapitalize(item.full_name)
                  return (
                    <span key={index} style={{ display: 'block' }}>
                      {fullName}
                      <span style={{ margin: '0 3px' }}>-</span>
                      <span>{item.position_name}</span>
                      {index !== array.length - 1 && <span>, </span>}
                    </span>
                  )
                }
              )}
            </span>
          ) : (
            <span></span>
          )}
        </p>
        <p style={{ display: 'flex' }}>
          <span>
            {/* (2) ĐVPT của VIMC (trong trường hợp ĐVPT thực hiện công việc): */}
            - Ban KTNB (tham mưu đánh giá độc lập cho HĐQT):&nbsp;
          </span>
          {data[0]?.doi_tuong_thuc_hien.ban_kt_ktnb ? (
            // <span style={{ ...textValue, ...labelBold }}>
            //   {data[0]?.doi_tuong_thuc_hien?.ban_kt_ktnb.map((item, index) => (
            //     <span key={index} style={{ display: 'block' }}>
            //       {utils.getNameInCapitalize(item.full_name)}
            //       <span style={{ margin: '0 3px' }}>-</span>
            //       <span>{item.position_name}</span>
            //     </span>
            //   ))}
            // </span>
            <span style={{ ...textValue, ...labelBold }}>
              {data[0]?.doi_tuong_thuc_hien?.ban_kt_ktnb.map(
                (item, index, array) => {
                  const fullName = utils.getNameInCapitalize(item.full_name)
                  return (
                    <span key={index} style={{ display: 'block' }}>
                      {fullName}
                      <span style={{ margin: '0 3px' }}>-</span>
                      <span>{item.position_name}</span>
                      {index !== array.length - 1 && <span>, </span>}
                    </span>
                  )
                }
              )}
            </span>
          ) : (
            <span></span>
          )}
        </p>
        <Divider />
        <h6>IV. KẾT QUẢ RÀ SOÁT RỦI RO</h6>
        <p>
          {isPartOfPdf ? (
            <h6 style={{ fontSize: '14px' }}>
              {' '}
              1. Đồng ý/không đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng
              phòng vệ thứ nhất và thứ hai:&nbsp;
            </h6>
          ) : (
            <span>
              1. Đồng ý/không đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng
              phòng vệ thứ nhất và thứ hai:&nbsp;
            </span>
          )}

          <p style={{ ...textValue, ...labelBold }}>
            {/* {showAgree(
              data[0]?.IV_ket_qua_ra_soat_rui_ro
                ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai
            )} */}

            {
              data[0]?.IV_ket_qua_ra_soat_rui_ro
                ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai
            }
          </p>

          <p style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.IV_ket_qua_ra_soat_rui_ro
                ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh
            }
          </p>

          <p style={{ ...textValue, ...labelBold }}>
            {
              data[0]?.IV_ket_qua_ra_soat_rui_ro
                ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt
            }
          </p>
        </p>

        <p>
          <p>
            {isPartOfPdf ? (
              <h6 style={{ fontSize: '14px' }}>
                2. Kiến nghị giải pháp khắc phục:&nbsp;
              </h6>
            ) : (
              <span>2. Kiến nghị giải pháp khắc phục:&nbsp;</span>
            )}
          </p>
          <p>
            <span>
              2.1 Kiến nghị giải pháp khắc phục BAN KTNB {systemName}:&nbsp;
            </span>
            {data[0]?.IV_ket_qua_ra_soat_rui_ro
              ?.kien_nghi_giai_phap_khac_phuc ? (
              <>
                {data[0].IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc[0] &&
                data[0]?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc.trim() !=
                  '' ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    Kiến nghị của ông/bà{' '}
                    {utils.getNameInCapitalize(
                      data[0].IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc[0]
                        ?.full_name
                    )}
                    :{' '}
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc
                    }
                  </span>
                ) : (
                  <span style={{ ...textValue, ...labelBold }}>
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc
                    }
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
          </p>

          <p>
            <span>2.2 Kiến nghị giải pháp khắc phục BAN ĐIỀU HÀNH:&nbsp;</span>
            {data[0]?.IV_ket_qua_ra_soat_rui_ro
              ?.kien_nghi_giai_phap_khac_phuc_ban_dh ? (
              <>
                {data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0] &&
                data[0]?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc_ban_dh.trim() !=
                  '' ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    Kiến nghị của ông/bà{' '}
                    {utils.getNameInCapitalize(
                      data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0]
                        ?.full_name
                    )}
                    :{' '}
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc_ban_dh
                    }
                  </span>
                ) : (
                  <span style={{ ...textValue, ...labelBold }}>
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc_ban_dh
                    }
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
          </p>

          <p>
            <span>
              2.3 Kiến nghị giải pháp khắc phục HĐQT {systemName} (CT/TV
              HĐQT):&nbsp;
            </span>
            {data[0]?.IV_ket_qua_ra_soat_rui_ro
              ?.kien_nghi_giai_phap_khac_phuc_hdqt ? (
              <>
                {' '}
                {data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0] &&
                data[0]?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc_hdqt.trim() !=
                  '' ? (
                  <span style={{ ...textValue, ...labelBold }}>
                    Kiến nghị của ông/bà{' '}
                    {utils.getNameInCapitalize(
                      data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0]?.full_name
                    )}
                    :{' '}
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc_hdqt
                    }
                  </span>
                ) : (
                  <span style={{ ...textValue, ...labelBold }}>
                    {
                      data[0]?.IV_ket_qua_ra_soat_rui_ro
                        ?.kien_nghi_giai_phap_khac_phuc_hdqt
                    }
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
          </p>
        </p>

        {isPartOfPdf ? (
          <table style={styleTable}>
            <tbody>
              <tr style={{ backgroundColor: 'white' }}>
                <td
                  style={{
                    border: 'none',
                    margin: '0 36px ',
                    backgroundColor: 'white',
                    textAlign: 'center',
                  }}>
                  <div>
                    <b> BAN KTNB {systemName}</b>
                    {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_ktnb_vimc_signed}
                    <div>
                      {data[0]?.IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc ? (
                        <div>
                          <div
                            style={{
                              fontSize: '13px',
                              marginTop: data[0]?.IV_ket_qua_ra_soat_rui_ro
                                ?.ban_ktnb_vimc_signed
                                ? '5px'
                                : '65px',
                            }}>
                            {
                              data[0].IV_ket_qua_ra_soat_rui_ro
                                .ban_kt_ktnb_vimc[0]?.position_name
                            }
                          </div>
                          <div>
                            {utils.getNameInCapitalize(
                              data[0].IV_ket_qua_ra_soat_rui_ro
                                .ban_kt_ktnb_vimc[0]?.full_name
                            )}
                          </div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    border: 'none',
                    margin: '0 36px ',
                    backgroundColor: 'white',
                    textAlign: 'center',
                  }}>
                  <div>
                    <b> BAN ĐIỀU HÀNH</b>
                    {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh_signed}
                    <div>
                      {data[0]?.IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh ? (
                        <div>
                          <div
                            style={{
                              fontSize: '13px',
                              marginTop: data[0]?.IV_ket_qua_ra_soat_rui_ro
                                ?.ban_dieu_hanh_signed
                                ? '5px'
                                : '65px',
                            }}>
                            {
                              data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0]
                                ?.position_name
                            }
                          </div>
                          <div>
                            {utils.getNameInCapitalize(
                              data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0]
                                ?.full_name
                            )}
                          </div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    border: 'none',
                    margin: '0 36px ',
                    backgroundColor: 'white',
                    textAlign: 'center',
                  }}>
                  <div>
                    <b>
                      HĐQT {systemName}
                      <br />
                      (CT/TV HĐQT)
                    </b>
                    {data[0]?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc_signed}
                    <div>
                      {data[0]?.IV_ket_qua_ra_soat_rui_ro.hdqt_vimc ? (
                        <div>
                          <div
                            style={{
                              fontSize: '13px',
                              marginTop: data[0]?.IV_ket_qua_ra_soat_rui_ro
                                ?.hdqt_vimc_signed
                                ? '5px'
                                : '65px',
                            }}>
                            {
                              data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0]
                                ?.position_name
                            }
                          </div>
                          <div>
                            {utils.getNameInCapitalize(
                              data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0]
                                ?.full_name
                            )}
                          </div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table style={styleTable}>
            <tbody>
              <tr style={{ backgroundColor: 'white' }}>
                <td style={webTableCellStyle(3)}>
                  <b> BAN KTNB {systemName}</b>
                </td>
                <td style={webTableCellStyle(3)}>
                  <b> BAN ĐIỀU HÀNH</b>
                </td>
                <td style={webTableCellStyle(3)}>
                  <b>
                    HĐQT {systemName}
                    <br />
                    (CT/TV HĐQT)
                  </b>
                </td>
              </tr>
              <tr style={{ backgroundColor: 'white' }}>
                <td style={webTableCellStyle(3)} className="textValue">
                  {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_ktnb_vimc_signed}
                  {data[0]?.IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc ? (
                    <div>
                      <div style={{ fontSize: '13px' }}>
                        {
                          data[0].IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc[0]
                            ?.position_name
                        }
                      </div>
                      <div>
                        {utils.getNameInCapitalize(
                          data[0].IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc[0]
                            ?.full_name
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </td>
                <td style={webTableCellStyle(3)} className="textValue">
                  {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh_signed}

                  {data[0]?.IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh ? (
                    <div>
                      <div style={{ fontSize: '13px' }}>
                        {
                          data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0]
                            ?.position_name
                        }
                      </div>
                      <div>
                        {utils.getNameInCapitalize(
                          data[0].IV_ket_qua_ra_soat_rui_ro.ban_dieu_hanh[0]
                            ?.full_name
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </td>
                <td style={webTableCellStyle(3)} className="textValue">
                  {data[0]?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc_signed}

                  {data[0]?.IV_ket_qua_ra_soat_rui_ro.hdqt_vimc ? (
                    <div>
                      <div style={{ fontSize: '13px' }}>
                        {
                          data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0]
                            ?.position_name
                        }
                      </div>
                      <div>
                        {utils.getNameInCapitalize(
                          data[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc[0]
                            ?.full_name
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </RiskActionStyle>
    )
  }
  const riskActionDetailsTier4 = () => {
    return (
      <RiskActionStyle>
        <h6 style={{ marginTop: isPartOfPdf ? 16 : 0 }}>
          V. KẾT QUẢ KIỂM SOÁT RỦI RO
        </h6>
        <h6 style={{ display: 'inline-block' }}>1. Kết quả:&nbsp;</h6>
        <span style={{ ...textValue, ...labelBold }}>
          {displayResult(data[0]?.ket_qua)}
        </span>
        {isPartOfPdf ? (
          <h6 style={{ marginTop: 15 }}>2. Các ý kiến liên quan</h6>
        ) : (
          <h6>2. Các ý kiến liên quan khác</h6>
        )}
        {/* <ActionLabel label="Ý KIẾN:&nbsp;" /> */}
        <div>
          <span>
            {data[0]?.nguoi_dang_y_kien?.length > 0
              ? 'Ý kiến của: '
              : 'Ý kiến: '}
          </span>
          {data[0]?.nguoi_dang_y_kien?.map((item, index, array) => (
            <span key={index}>
              {item?.label}
              {index !== array.length - 1 && ', '}
            </span>
          ))}
        </div>

        <p style={{ ...textValue, ...labelBold }}>
          {/* <RichText htmlText={data[0]?.cac_y_kien_lien_quan || ''}></RichText> */}
          <div
            style={{ ...textValue, ...labelBold }}
            dangerouslySetInnerHTML={{
              __html: `${data[0]?.cac_y_kien_lien_quan || ''}`,
            }}></div>
        </p>
        {isPartOfPdf ? (
          <>
            <table style={styleTable}>
              <tbody>
                <tr style={{ backgroundColor: 'white' }}>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      {Array.isArray(data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc) &&
                      data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                        item => item.department_code === 'BPC&QTRR'
                      ) ? (
                        <b>BAN PC&QTRR</b>
                      ) : Array.isArray(
                          data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc
                        ) &&
                        data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                          item => item.department_code === 'BKTKTNB'
                        ) ? (
                        <b>BAN KTNB {systemName}</b>
                      ) : (
                        <b>BAN PC&QTRR/BAN KTNB {systemName}</b>
                      )}
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      {Array.isArray(data[0]?.ban_dieu_hanh_hdqt_vimc) &&
                      data[0]?.ban_dieu_hanh_hdqt_vimc?.some(
                        item => item.department_code === 'BDH'
                      ) ? (
                        <b>BAN ĐIỀU HÀNH</b>
                      ) : Array.isArray(data[0]?.ban_dieu_hanh_hdqt_vimc) &&
                        data[0]?.ban_dieu_hanh_hdqt_vimc?.some(
                          item => item.department_code === 'HDQT'
                        ) ? (
                        <b>BAN HĐQT {systemName}</b>
                      ) : (
                        <b>BAN ĐIỀU HÀNH/HĐQT {systemName}</b>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div className="textValue">
                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(el => el.value.startsWith('RU_CPN'))
                        .map(el => (
                          <div>{el.label}</div>
                        ))}
                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(el => !el.value.startsWith('RU_CPN'))
                        .map(el => {
                          return (
                            <>
                              {el.value.slice(0, 3) === 'USR' ? (
                                <>
                                  {
                                    actionStore?.dataActions?.tier1[0]
                                      ?.II_xu_ly_rui_ro
                                      .don_vi_thuc_hien_xu_ly_rui_ro__signed
                                  }
                                </>
                              ) : null}
                              <div
                                style={{
                                  marginTop: actionStore?.dataActions?.tier1[0]
                                    ?.II_xu_ly_rui_ro
                                    .don_vi_thuc_hien_xu_ly_rui_ro__signed
                                    ? '5px'
                                    : el.value.slice(0, 3) === 'USR'
                                    ? '65px'
                                    : '0',
                                }}>
                                {el.label}
                              </div>
                            </>
                          )
                        })}
                      {/*
                      tạm thời comment
                      {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro?.map(
                        item => (
                          <>
                            {departmentList
                              .filter(el => el.code === item.value)
                              ?.map(unit => (
                                <div style={{ marginTop: '5px' }}>
                                  {unit.name}
                                </div>
                              ))}
                          </>
                        )
                      )}
                      {
                        actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
                          .don_vi_thuc_hien_xu_ly_rui_ro__signed
                      }

                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(
                          el =>
                            !el.value.startsWith('RU_CPN') &&
                            !codeDepartmentList.includes(el.value)
                        )
                        .map(el => (
                          <div
                            style={{
                              marginTop: actionStore?.dataActions?.tier1[0]
                                ?.II_xu_ly_rui_ro
                                .don_vi_thuc_hien_xu_ly_rui_ro__signed
                                ? '5px'
                                : '65px',
                            }}>
                            {el.label}
                          </div>
                        ))} */}
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ marginTop: '65px' }} className="textValue">
                      <div>
                        {data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc_signed}
                        <div
                          style={{
                            fontSize: '13px',
                            marginTop: data[0]
                              ?.ban_pc_qtrr_ban_kt_ktnb_vimc_signed
                              ? '5px'
                              : '60px',
                          }}>
                          {
                            data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc[0]
                              ?.position_name
                          }
                        </div>

                        <div>
                          {utils.getNameInCapitalize(
                            data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc[0]?.full_name
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ marginTop: '65px' }} className="textValue">
                      <div>
                        {data[0]?.ban_dieu_hanh_hdqt_vimc_signed}
                        <div
                          style={{
                            fontSize: '13px',
                            marginTop: data[0]?.ban_dieu_hanh_hdqt_vimc_signed
                              ? '5px'
                              : '60px',
                          }}>
                          {data[0]?.ban_dieu_hanh_hdqt_vimc[0]?.position_name}
                        </div>

                        <div>
                          {utils.getNameInCapitalize(
                            data[0]?.ban_dieu_hanh_hdqt_vimc[0]?.full_name
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <table style={styleTable}>
              <tbody>
                <tr style={{ backgroundColor: 'white' }}>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      {Array.isArray(data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc) &&
                      data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                        item => item.department_code === 'BPC&QTRR'
                      ) ? (
                        <b>BAN PC&QTRR</b>
                      ) : Array.isArray(
                          data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc
                        ) &&
                        data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                          item => item.department_code === 'BKTKTNB'
                        ) ? (
                        <b>BAN KTNB {systemName}</b>
                      ) : (
                        <b>BAN PC&QTRR/BAN KTNB {systemName}</b>
                      )}
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ margin: '0 20px' }}>
                      {Array.isArray(data[0]?.ban_dieu_hanh_hdqt_vimc) &&
                      data[0]?.ban_dieu_hanh_hdqt_vimc?.some(
                        item => item.department_code === 'BDH'
                      ) ? (
                        <b>BAN ĐIỀU HÀNH</b>
                      ) : Array.isArray(data[0]?.ban_dieu_hanh_hdqt_vimc) &&
                        data[0]?.ban_dieu_hanh_hdqt_vimc?.some(
                          item => item.department_code === 'HDQT'
                        ) ? (
                        <b>BAN HĐQT {systemName}</b>
                      ) : (
                        <b>BAN ĐIỀU HÀNH/HĐQT {systemName}</b>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div className="textValue">
                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(el => el.value.startsWith('RU_CPN'))
                        .map(el => (
                          <div>{el.label}</div>
                        ))}
                      {/* Đã ký - đơn vị thực hiện xử lý rủi ro - CQVP */}
                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(el => !el.value.startsWith('RU_CPN'))
                        .map(el => {
                          return (
                            <>
                              {el.value.slice(0, 3) === 'USR' ? (
                                <>
                                  {
                                    actionStore?.dataActions?.tier1[0]
                                      ?.II_xu_ly_rui_ro
                                      .don_vi_thuc_hien_xu_ly_rui_ro__signed
                                  }
                                </>
                              ) : null}
                              <div>{el.label}</div>
                            </>
                          )
                        })}
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ marginTop: '5px' }} className="textValue">
                      <div>
                        {data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc_signed}
                        <div style={{ fontSize: '13px' }}>
                          {
                            data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc[0]
                              ?.position_name
                          }
                        </div>
                        <div>
                          {utils.getNameInCapitalize(
                            data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc[0]?.full_name
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      border: 'none',
                      margin: '0 20px ',
                      backgroundColor: 'white',
                      textAlign: 'center',
                    }}>
                    <div style={{ marginTop: '5px' }} className="textValue">
                      <div>
                        {data[0]?.ban_dieu_hanh_hdqt_vimc_signed}
                        <div style={{ fontSize: '13px' }}>
                          {data[0]?.ban_dieu_hanh_hdqt_vimc[0]?.position_name}
                        </div>

                        <div>
                          {utils.getNameInCapitalize(
                            data[0]?.ban_dieu_hanh_hdqt_vimc[0]?.full_name
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </RiskActionStyle>
    )
  }

  return (
    <div>
      {updateTierApproval !== undefined ? (
        <>
          {!isPartOfPdf && (
            <>
              <Divider />
              <Button
                style={{
                  // marginTop: 16,
                  // top: '-30px',
                  left: '86%',
                  // transform: 'translate(-50%, 0)',
                  background: '#2c65ac',
                  color: 'white',
                }}
                onClick={() => {
                  setIndexDetailAction({ tier, index: null })

                  openEditPopUp()
                }}>
                Sửa thông tin
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          {!isPartOfPdf && (
            <>
              <Divider />
              <Button
                style={{
                  // marginTop: 16,
                  // top: '-440px',
                  left: '86%',
                  // transform: 'translate(-50%, 0)',
                  background: '#2c65ac',
                  color: 'white',
                }}
                onClick={() => {
                  setIndexDetailAction({ tier, index: null })

                  openEditPopUp()
                }}>
                Điền thông tin
              </Button>
            </>
          )}
        </>
      )}

      {data && tier === '1' ? riskActionDetailsTier1() : <></>}
      {data && tier === '2' ? riskActionDetailsTier2() : <></>}
      {data && tier === '3' ? riskActionDetailsTier3() : <></>}
      {data && tier === '4' ? riskActionDetailsTier4() : <></>}
    </div>
  )
}

export default inject(
  'actionStore',
  'departmentStore',
  'riskStore',
  'selectPeopleStore'
)(observer(RiskActionDetails))
