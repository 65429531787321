import React, { useState } from 'react'
import {
  CommentBlock,
  FormButtonGroup,
  MessageWrapper,
  UploadWrapper,
  UploadFileListWrapper,
} from './ReplyMessageInternalStyled'
import { Input, Upload, Button, Form, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import { DATE_FORMAT_SLASH, HH_mm } from '../../constants'
import FileItem from '../Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../ContentBlockWrapper'
import { systemName } from '../../config'
import UploadFileItem from '../Common/FileItemComponent/UploadFileItem'
import validator from '../../validator'

const { TextArea } = Input
const ReplyMessageInternal = props => {
  const {
    fileStore,
    loadingAnimationStore,
    internalMessageStore,
    authenticationStore,
    internalMessageDetail,
    code,
    isReceive,
  } = props
  const { replyInternalMessages } = internalMessageStore
  const { currentUser } = authenticationStore

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const sendComment = async value => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    if ((isReceive && !code) || (isReceive && !internalMessageDetail.code)) {
      return message.warning('Không tìm thấy tin nhắn cần trả lời')
    }
    loadingAnimationStore.showSpinner(true)
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      const messageData = {
        content: value.content,
        file_ids: uploadedFiles,
        owner: currentUser?.userName,
        owner_name: currentUser?.fullName,
        parent_code: internalMessageDetail.code,
      }
      await internalMessageStore.create(messageData)
      if (isReceive) {
        await internalMessageStore.getReceiveMessageById(code)
      } else {
        await internalMessageStore.getSendMessageById(
          internalMessageDetail.code
        )
      }

      setFileList([])
      form.resetFields()
      message.success('Gửi phản hồi thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <ContentBlockWrapper style={{ marginTop: 20 }}>
      {replyInternalMessages &&
        replyInternalMessages.length > 0 &&
        replyInternalMessages.map(message => {
          return (
            <MessageWrapper key={message.code}>
              <p className="title">{`${utils.getNameInCapitalize(
                message.owner_name
              )} - ${moment(message.created_time).format(
                DATE_FORMAT_SLASH
              )} - ${moment(message.created_time).format(HH_mm)}`}</p>
              <p className="content">{message.content}</p>
              <div>
                {message.file_ids?.length > 0 &&
                  message.file_ids.map(file => (
                    <FileItem
                      key={file.file_id}
                      file_id={file.file_id}
                      file_name={file.name}
                      file_type={utils.getExtensionFile(file.name)}
                    />
                  ))}
              </div>
            </MessageWrapper>
          )
        })}
      <CommentBlock>
        <Form form={form} onFinish={sendComment}>
          <Form.Item
            name={'content'}
            rules={[
              { required: true, message: 'Vui lòng nhập nội dung phản hồi!' },
              { validator: validator.validateInputString },
            ]}>
            <TextArea rows={4} placeholder="Nội dung phản hồi" />
          </Form.Item>
          {fileList.length !== 0 && (
            <UploadFileListWrapper>
              {fileList.map(file => (
                <UploadFileItem
                  key={file.uid}
                  file_id={file.uid}
                  file_name={file.name}
                  file_type={utils.getExtensionFile(file.name)}
                  handleRemoveFileFromUploadList={() =>
                    handleRemoveFileFromUploadList(file.uid)
                  }
                />
              ))}
            </UploadFileListWrapper>
          )}
          <FormButtonGroup>
            <UploadWrapper>
              <Upload
                style={{ marginLeft: 4, marginRight: 4 }}
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                showUploadList={false}>
                <Button htmlType={'button'}>
                  <UploadOutlined /> Tải file
                </Button>
              </Upload>
            </UploadWrapper>
            <Button
              htmlType={'submit'}
              type={'primary'}
              style={{ padding: '4px 20px' }}>
              Gửi phản hồi
            </Button>
          </FormButtonGroup>
        </Form>
      </CommentBlock>
    </ContentBlockWrapper>
  )
}

ReplyMessageInternal.propTypes = {}

export default inject(
  'fileStore',
  'loadingAnimationStore',
  'internalMessageStore',
  'authenticationStore'
)(observer(ReplyMessageInternal))
