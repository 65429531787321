import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import { Button, Col, Input, Pagination, Row } from 'antd'
import {
  AlignLeftOutlined,
  CloseCircleOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import NormalTag from '../../components/NormalTag'
import UserPermissionPanel from '../../components/UserPermissionPanel'
import { FilterButton, SearchBar } from './UserAppManagementPageStyled'
import { withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import UserListFilterBlock from '../../components/UserListFilterBlock'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import TableComponent from '../../components/Common/TableComponent'
import DocumentsLayout from '../UserManagementPage/DocumentsLayout'
import ConnectedTrucAccountUserConfigPanel from '../DocumentManagement/ConnectedDocument/ConnectedTrucAccount/ConnectedTrucAccountUserConfigPanel'
import { TRUC_COMMAND } from '../../command_constant'
import { systemName } from '../../config'

const { Search } = Input

const UserAppManagementPage = props => {
  const {
    userStore,
    loadingAnimationStore,
    authenticationStore,
    history,
    accountEditStore,
  } = props

  const {
    userListPageIndex,
    userListPageSize,
    userListKeyword,
    userListStatus,
    userListDepartmentCode,
    userListSortDirection,
    userListSortBy,
  } = userStore

  const [showUserInfo, setShowUserInfo] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [
    isVisibleConnectedTrucConfigPanel,
    setIsVisibleConnectedTrucConfigPanel,
  ] = useState(false)

  const handleChangePageIndex = page => {
    userStore.changeUserListPageIndex(page - 1)
  }

  useEffect(() => {
    if (!showUserInfo) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [showUserInfo])

  const handleViewUserInfo = userInfo => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      userStore.setSelectedUser(userInfo),
      accountEditStore.getAccountsByUserCode(userInfo.code),
    ])
      .then(() => setShowUserInfo(true))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const handleCloseUserInfoModal = () => {
    userStore.clearSelectedUser()
    setShowUserInfo(false)
  }

  const handleShowAdvancedFilter = status => {
    setShowFilter(status)
    if (!status) {
      userStore.clearUserListFilter()
    }
  }

  const handleShowConnectedTrucConfigPanel = userInfo => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      userStore.setSelectedUser(userInfo),
      accountEditStore.getAccountsByUserCode(userInfo.code),
      accountEditStore.getCommandsByUser(userInfo.code),
    ])
      .then(() => setIsVisibleConnectedTrucConfigPanel(true))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const handleCloseConnectedTrucConfigPanel = () => {
    setIsVisibleConnectedTrucConfigPanel(false)
  }

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.showSpinner(true)
      // userStore.setFilter('userListHasAdmin', true)
      userStore.setFilter(
        'companyCode',
        authenticationStore.currentCompanyCode()
      )
      userStore
        .getUserList()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }, [
    userListPageIndex,
    userListKeyword,
    userListStatus,
    userListDepartmentCode,
    userListSortDirection,
    userListSortBy,
    loadingAnimationStore,
    userStore,
    authenticationStore.currentUser,
  ])

  useEffect(() => {
    return () => {
      userStore.clearStore()
    }
  }, [])

  const actionColumn = !props.isDocumentManagementTab
    ? {
        title: 'Tác vụ',
        key: 'action',
        render: record => (
          <Button onClick={() => handleViewUserInfo(record)} type="primary">
            <AlignLeftOutlined />
            Cấu hình
          </Button>
        ),
      }
    : {
        title: 'Tác vụ',
        key: 'action',
        render: record => (
          <Button
            onClick={() => handleShowConnectedTrucConfigPanel(record)}
            type="primary">
            <AlignLeftOutlined />
            Cấu hình
          </Button>
        ),
      }

  const tableColumns = [
    {
      title: 'Họ tên',
      key: 'fullname',
      render: record => (
        <strong style={{ textTransform: 'capitalize' }}>
          {record.name_lowercase}
        </strong>
      ),
    },
    {
      title: 'Username',
      key: 'username',
      render: record => record.username,
    },
    {
      title: 'Phần mềm',
      key: 'portal',
      render: record =>
        record.commands.length !== 0 ? (
          <NormalTag tags={record.commands.map(command => command.name)} />
        ) : (
          'Không có'
        ),
    },
    actionColumn,
  ]

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    // if (
    //   authenticationStore.currentUser
    //   && authenticationStore.currentUser.roles.includes('SUPER_ADMIN')
    // ) return
    // history.push('/')
    // message.error('Bạn không có quyền truy cập trang này, vui lòng liên hệ admin')
  }, [authenticationStore.currentUser, history])

  return (
    <>
      {!props.isDocumentManagementTab ? (
        <DocumentsLayout
          title={
            <PageTitle location={props.location} title={'Quản lý phần mềm'} />
          }
          selectedKey={'user-app-management'}>
          <Helmet>
            <title>Quản lý phần mềm | {systemName} Portal</title>
          </Helmet>
          <Row>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'normal'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 640 }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </DocumentsLayout>
      ) : (
        <>
          <Row>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'normal'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 640 }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </>
      )}
      <UserPermissionPanel
        drawerVisible={showUserInfo}
        onClose={handleCloseUserInfoModal}
      />

      <ConnectedTrucAccountUserConfigPanel
        selectedCommandCode={TRUC_COMMAND}
        drawerVisible={isVisibleConnectedTrucConfigPanel}
        onClose={handleCloseConnectedTrucConfigPanel}
      />
    </>
  )
}

export default withRouter(
  inject(
    'userStore',
    'loadingAnimationStore',
    'authenticationStore',
    'accountEditStore'
  )(observer(UserAppManagementPage))
)
