import { Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import DashboardLayout from '../../../layouts/DashboardLayout'
import CompanyACLTable from './CompanyACLTable'
import { systemName } from '../../../config'

const CompanyACL = ({ ...otherProps }) => {
  const { location } = otherProps

  return (
    <DashboardLayout isSuperAdminPage>
      <Helmet>
        <title>Quản lý phân quyền | {systemName} Quản lý công ty</title>
      </Helmet>
      <PageTitle location={location} />
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          style={{ marginBottom: 16 }}
          selectedKeys={['/company-acl']}>
          <Menu.Item key="/company">
            <Link to={'/company'}>Công ty</Link>
          </Menu.Item>
          <Menu.Item key="/company-department">
            <Link to={'/company-department'}>Phòng ban</Link>
          </Menu.Item>
          <Menu.Item key="/company-acl">
            <Link to={'/company-acl'}>Phân quyền</Link>
          </Menu.Item>
        </Menu>
        <CompanyACLTable />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject('aclStore')(observer(CompanyACL))
