import { message } from 'antd'
import { useCallback } from 'react'

export const useUpdatingUnitLeader = ({
  type,
  riskId = undefined,
  loadingAnimationStore,
  riskStore,
  riskUnitUserStore,
}) => {
  const validateUnitLeaderInput = useCallback(() => {
    switch (type) {
      case 'DVPT':
        if (riskUnitUserStore.selectedUnitLeaders.tier1.length === 0) {
          throw new Error('Vui lòng chọn lãnh đạo đơn vị ở tuyến 1')
        }

        break

      case 'NDDPV':
      case 'CQVP':
      default:
        break
    }
  }, [type, riskUnitUserStore.selectedUnitLeaders.tier1])

  const getAddingAndRemovingTier1UnitLeader = useCallback(() => {
    const newSelectedItems = riskUnitUserStore.selectedUnitLeaders.tier1
    const allOldItems = riskStore.riskDetail?.tiers[0]?.approvals[1]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems.filter(
      item =>
        !oldSelectedItems.some(oldItem => oldItem.username === item.username)
    )
    const removingItems = oldSelectedItems.filter(
      item =>
        !newSelectedItems.some(newItem => newItem.username === item.username)
    )

    return {
      addingItems: addingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
      removingItems: removingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
    }
  }, [riskUnitUserStore.selectedUnitLeaders.tier1, riskStore.riskDetail.tiers])

  const updateTier1UnitLeader = useCallback(async () => {
    if (!riskId) {
      throw new Error('Không tìm thấy mã rủi ro')
    }

    try {
          // validateUnitLeaderInput()

          const {
            addingItems,
            removingItems,
          } = getAddingAndRemovingTier1UnitLeader()
          const riskTierCode =
            riskStore.riskDetail?.tiers[0]?.approvals[1]?.peoples[0]
              ?.risk_tier_approval_code ||
            riskStore.riskDetail?.tiers[0]?.approvals[1]?.code

          loadingAnimationStore.showSpinner(true)
          await riskStore.updateTierApproval(riskTierCode, {
            approval_people: {
              remove_items: removingItems,
              new_items: addingItems,
            },
          })

          await riskStore.getRiskDetail(riskId)
          message.success(`Cập nhật thành công`)
        } catch (error) {
      console.error(error)
      message.error(error.message ?? `Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [
    loadingAnimationStore,
    riskStore,
    riskId,
    getAddingAndRemovingTier1UnitLeader,
    validateUnitLeaderInput,
  ])

  return { validateUnitLeaderInput, updateTier1UnitLeader }
}
