import React, { useState } from 'react'
// Component
import { EmptyText } from '../../../components/Common/CellText'
// Ant design
import { Avatar, Button, Col, Form, Input, message, Radio, Row } from 'antd'
import {
  BankFilled,
  CalendarFilled,
  CrownFilled,
  HeartFilled,
  HomeFilled,
  IdcardFilled,
  MailFilled,
  MobileFilled,
  PhoneFilled,
  SlackCircleFilled,
} from '@ant-design/icons'

// Other
import { blue } from '../../../color'
import { inject, observer } from 'mobx-react'
import utils from '../../../utils'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FaUser, FaHome } from 'react-icons/fa'

const styleIcon = {
  color: blue,
  fontSize: 14,
  marginTop: 5,
}

const nullText = <EmptyText>Chưa cập nhật</EmptyText>

const UserDetail = props => {
  const [form] = Form.useForm()
  const { aclStore, selectedUser, userStore } = props

  const { userAvatarByCode } = userStore

  const { aclAclGroupName } = aclStore

  const [fields, setFields] = useState([
    {
      name: ['email'],
      value: undefined,
    },
    {
      name: ['phone'],
      value: undefined,
    },
    {
      name: ['home_phone'],
      value: undefined,
    },
    {
      name: ['gender'],
      value: undefined,
    },
  ])

  if (!selectedUser?.username) {
    return null
  }

  /**
   * @type {{label: string, value: string, icon: import('react').ReactNode}[]}
   */
  const decor = {
    color: '#65676b',
    fontSize: '10px',
    marginTop: '5px',
  }
  return (
    <div>
      <div
        className={'avatar'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}>
        <Avatar
          size={150}
          style={{ backgroundColor: blue }}
          src={userAvatarByCode && URL.createObjectURL(userAvatarByCode)}>
          <p style={{ marginBottom: '0px', fontSize: '40px' }}>
            {selectedUser?.name_uppercase.split('')[0].toString().toUpperCase()}
          </p>
        </Avatar>
      </div>
      <div>
        <Form fields={fields} form={form}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}>
            <h1 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {selectedUser?.name_lowercase || nullText}
            </h1>
          </div>
          <Row
            gutter={[20, 0]}
            style={{
              paddingLeft: '20%',
            }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <FaUser style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.username || nullText}</div>
                  <span style={decor} className={'desc'}>
                    Id
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <HeartFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>
                    {utils.getGenderName(selectedUser?.gender) || nullText}
                  </div>
                  <span style={decor} className={'desc'}>
                    Giới tính
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 0]} style={{ paddingLeft: '20%' }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <MailFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.email || nullText}</div>
                  <span style={decor} className={'desc'}>
                    Email
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <BankFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.department?.name || nullText}</div>
                  <span style={decor} className={'desc'}>
                    Phòng ban
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[20, 0]}
            style={{
              paddingLeft: '20%',
            }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <IdcardFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.ma_nv || nullText}</div>
                  <span style={decor} className={'desc'}>
                    Mã nhân viên
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <CrownFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.position?.name || nullText}</div>
                  <span style={decor} lassName={'desc'}>
                    Chức danh
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[20, 0]}
            style={{
              paddingLeft: '20%',
            }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <PhoneFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>
                    {!selectedUser?.home_phone?.trim()
                      ? nullText
                      : selectedUser?.home_phone}
                  </div>
                  <span style={decor} className={'desc'}>
                    Số máy nội bộ
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <CalendarFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>
                    {selectedUser?.birthDate
                      ? moment(selectedUser?.birthDate).format('DD/MM/YYYY')
                      : nullText}
                  </div>
                  <span style={decor} className={'desc'}>
                    Ngày sinh
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[20, 0]}
            style={{
              paddingLeft: '20%',
            }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <MobileFilled style={styleIcon} />
                <div style={{ wordBreak: 'break-all' }}>
                  <div>{selectedUser?.phone || nullText}</div>
                  <span style={decor} className={'desc'}>
                    Điện thoại di động
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <HomeFilled style={styleIcon} />
                <div>
                  <div>
                    {!selectedUser?.address?.trim()
                      ? nullText
                      : selectedUser?.address}
                  </div>
                  <span style={decor} className={'desc'}>
                    Địa chỉ
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[20, 0]}
            style={{
              paddingLeft: '20%',
            }}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <SlackCircleFilled style={styleIcon} />
                <div>
                  <div>{aclAclGroupName}</div>
                  <span style={decor} className={'desc'}>
                    Phân quyền
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

UserDetail.propTypes = {
  selectedUser: PropTypes.object,
}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(UserDetail))
