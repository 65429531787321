import React from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Menu, message } from 'antd'
import CompanyList from './CompanyList'
import { systemName } from '../../config'

const CompanyPage = props => {
  const { authenticationStore } = props
  const history = useHistory()

  useEffect(() => {
    if (authenticationStore.isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])
  return (
    <DashboardLayout isSuperAdminPage>
      <Helmet>
        <title>Quản lý công ty | {systemName} Quản lý công ty</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={['/company']}
          style={{ marginBottom: 16 }}>
          <Menu.Item key="/company">
            <Link to={'/company'}>Công ty</Link>
          </Menu.Item>
          <Menu.Item key="/company-department">
            <Link to={'/company-department'}>Phòng ban</Link>
          </Menu.Item>
          <Menu.Item key="/company-acl">
            <Link to={'/company-acl'}>Phân quyền</Link>
          </Menu.Item>
        </Menu>

        <CompanyList />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'moduleStore',
  'authenticationStore'
)(observer(CompanyPage))
