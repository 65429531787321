import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, message, Modal, Row, Select, Space } from 'antd'
import { DOCUMENT_TYPE } from '../../constants'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import validator from './../../validator'

const AuthoritiesActionModalOutgoing = props => {
  const {
    payloadModal,
    handleCloseModal,
    authorityStore,
    handleSetValueFilter,
    tabActive,
  } = props

  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!payloadModal.updatePayLoad) return
    form.setFieldsValue({
      type: payloadModal.updatePayLoad.type,
      name: payloadModal.updatePayLoad.name.replace(/\s+/g, ' ').trim(),
    })
  }, [payloadModal])

  useEffect(() => {
    if (payloadModal.isCreate) {
      form.setFieldsValue({
        type: tabActive,
      })
    }
  }, [tabActive])

  const onFinish = async values => {
    try {
      let messageSuccess
      setIsLoading(true)
      if (payloadModal.isCreate) {
        await authorityStore.createAuthority(values)
        messageSuccess = 'Tạo bộ phận dự thảo thành công!'
      } else {
        await authorityStore.updateAuthority(
          payloadModal.updatePayLoad?.id,
          values
        )
        messageSuccess = 'Chỉnh sửa bộ phận dự thảo thành công!'
      }
      await authorityStore.getAuthorityIssueds()
      handleSetValueFilter && handleSetValueFilter(payloadModal.isCreate)
      handleCancel()
      message.success(messageSuccess)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    handleCloseModal()
    setTimeout(() => {
      form.resetFields()
    }, 100)
  }

  return (
    <Modal
      visible={payloadModal.status}
      footer={null}
      onCancel={handleCancel}
      title={
        payloadModal.isCreate
          ? 'Tạo mới bộ phận dự thảo'
          : 'Chỉnh sửa bộ phận dự thảo'
      }>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{
          type: tabActive,
        }}>
        <Form.Item
          name="type"
          label="Loại bộ phận dự thảo"
          rules={[{ required: true, message: 'Vui lòng chọn loại văn bản!' }]}>
          <Select
            disabled={
              !payloadModal.isCreate || props.disabledAuthoritiesTypePicker
            }>
            <Select.Option value={DOCUMENT_TYPE.INCOMING}>
              Văn bản đến
            </Select.Option>
            <Select.Option value={DOCUMENT_TYPE.OUTGOING}>
              Văn bản đi
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={'Tên bộ phận dự thảo'}
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên bộ phận dự thảo!',
            },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập tên bộ phận dự thảo'} />
        </Form.Item>
        <Row justify={'end'} style={{ marginTop: 20 }}>
          <Space>
            <Button onClick={handleCancel}>Hủy</Button>
            <Button type={'primary'} htmlType={'submit'} loading={isLoading}>
              {payloadModal.isCreate ? 'Tạo mới' : 'Cập nhật'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

AuthoritiesActionModalOutgoing.propTypes = {
  payloadModal: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSetValueFilter: PropTypes.func,
  disabledAuthoritiesTypePicker: PropTypes.bool,
}

export default memo(
  withRouter(
    inject(
      'loadingAnimationStore',
      'authenticationStore',
      'authorityStore',
      'commonStore'
    )(observer(AuthoritiesActionModalOutgoing))
  )
)
