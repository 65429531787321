import React, { useEffect, useState } from 'react'
import { PAGE_NAME } from '../../../constants'
import { inject, observer } from 'mobx-react'
import utils from '../../../utils'
import { CommentTitle } from './DrawerWorkStyled'
import { Space, Spin, message } from 'antd'
import CommentsPopoverMenu from '../../../components/Shared/CommentsPopoverMenu'
import WorkCommentList from './WorkComment/WorkCommentList'
import WorkCommentEditor from './WorkComment/WorkCommentEditor'
import { LoadingOutlined } from '@ant-design/icons'
import { ContentLoading } from '../../PopupNotificationPage/PopupNotificationPageStyled'
import WorkPdf from './WorkPdf'

const WorkAllCommentList = props => {
  const { notificationStore, code, workStore } = props
  const { notificationType } = notificationStore
  const { workDetail } = workStore
  const { isImportantWork, attachments, voteDetail } = workDetail

  const [commentList, setCommentList] = useState([])

  const [isShowDelComments, setIsShowDelComments] = useState(false)
  const [loading, setLoading] = useState(false)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const breakpoint = 900

  useEffect(() => {
    const handleWindowResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useEffect(() => {
    if (!code) return
    if (!utils.isIOSDevice()) {
      const channel4Broadcast = new BroadcastChannel('channel4')
      channel4Broadcast.onmessage = async even => {
        if (even.data.type === PAGE_NAME.WORK && even.data.code === code) {
          const scrollCommentWrapper = document.querySelector(
            '.ant-drawer-body'
          )
          await getCommentWorkList()
          await scrollCommentWrapper.scrollTo(
            0,
            scrollCommentWrapper.scrollHeight
          )
        }
      }
    }
  }, [code])

  useEffect(() => {
    if (!code) return
    /** Check thiết bị IOS */
    if (
      utils.isIOSDevice() &&
      notificationType &&
      notificationType.type === PAGE_NAME.WORK
    ) {
      ;(async () => {
        const scrollCommentWrapper = document.querySelector('.ant-drawer-body')
        await getCommentWorkList()
        await scrollCommentWrapper.scrollTo(
          0,
          scrollCommentWrapper.scrollHeight
        )
      })()
    }
  }, [code, notificationType])

  useEffect(() => {
    if (!code) return
    ;(async () => {
      await getCommentWorkList()
    })()
  }, [code])

  const handleToggleShowDelCommentsMenu = () => {
    setIsShowDelComments(!isShowDelComments)
  }

  const getCommentWorkList = async () => {
    try {
      setLoading(true)
      let response = await workStore.getCommentWorkListByCode(code)
      setCommentList(response.data.reverse())
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoading(false)
    }
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  return (
    <>
      <div
        style={{
          position: 'relative',
          minHeight: loading ? '130px' : '0px',
        }}>
        {loading ? (
          <div
            style={{
              position: 'absolute',
              background: 'rgba(0, 0, 0, 0.05)',
              width: '100%',
              height: '100%',
            }}>
            <div
              style={{
                height: '100%',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}></div>
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                width: '100%',
              }}>
              <ContentLoading>
                <Space>
                  <Spin indicator={antIcon} />
                  Đang tải
                </Space>
              </ContentLoading>
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            justifyContent: 'space-between',
          }}>
          {commentList.length > 0 && (
            <>
              <CommentTitle>Danh sách ý kiến:</CommentTitle>
              <CommentsPopoverMenu
                hideSortMenu={true}
                handleToggleMenu={handleToggleShowDelCommentsMenu}
                isShow={isShowDelComments}
                sortComment={''}
              />
            </>
          )}
        </div>
        <WorkCommentList
          commentList={commentList}
          setCommentList={setCommentList}
          code={code}
          isShowDelComments={isShowDelComments}
          loading={true}
        />
      </div>
      <WorkCommentEditor
        isShowDelComments={isShowDelComments}
        screenWidth={screenWidth}
        breakpoint={breakpoint}
        commentList={commentList}
        getCommentWorkList={getCommentWorkList}
        code={code}
        setLoading={setLoading}
      />
      <div style={{ display: 'none' }}>
        <WorkPdf
          workDetail={workDetail}
          commentList={commentList.filter(item => !item.deleted) || []}
          isImportantWork={isImportantWork}
          attachments={attachments || []}
          voteList={voteDetail || []}
        />
      </div>
    </>
  )
}

WorkAllCommentList.propTypes = {}

export default inject(
  'voteStore',
  'workStore',
  'notificationStore',
  'loadingAnimationStore'
)(observer(WorkAllCommentList))
