import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Divider, Empty, message, Popconfirm, Row, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import CompanySelect from './CompanySelect'
import CreateCompanyDepartmentModal from './CreateCompanyDepartmentModal'
import UpdateCompanyDepartmentModal from './UpdateCompanyDepartmentModal'

const CompanyDepartmentTable = ({ ...otherProps }) => {
  const { departmentStore, companyStore } = otherProps
  const [isCreateModalVisible, setCreateModalVisibility] = useState(false)
  const [isUpdateModalVisible, setUpdateModalVisibility] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState()
  const [isLoading, setLoadingState] = useState(false)
  const [selectedCompanyCode, setSelectedCompanyCode] = useState()

  useEffect(() => {
    companyStore
      .getCompanyList()
      .then(() => {
        const companies = companyStore.companyList
        if (companies.length > 0) {
          const firstCompanyCode = companies[0].code
          departmentStore.getAllDepartmentList(firstCompanyCode)
          setSelectedCompanyCode(firstCompanyCode)
        }
      })
      .catch(error => message.error(error.vi || 'Có lỗi xảy ra'))
  }, [])

  const onChangeCompany = companyCode => {
    departmentStore.getAllDepartmentList(companyCode)
    setSelectedCompanyCode(companyCode)
  }

  const createModal = {
    open: () => setCreateModalVisibility(true),
    close: () => setCreateModalVisibility(false),
  }
  const updateModal = {
    open: department => {
      setUpdateModalVisibility(true)
      setSelectedDepartment(department)
    },
    close: () => setUpdateModalVisibility(false),
  }

  const deleteDepartment = code => {
    setLoadingState(true)
    departmentStore
      .delete(code)
      .then(() => {
        message.success('Xoá phòng ban thành công')
        departmentStore.getAllDepartmentList(selectedCompanyCode)
      })
      .catch(error => message.error(error.vi || 'Đã có lỗi xảy ra!'))
      .finally(() => setLoadingState(false))
  }

  const tableColumns = [
    {
      title: 'Tên phòng ban',
      key: 'name',
      render: record => <span>{record.name}</span>,
      width: '70%',
    },
    {
      title: 'Tác vụ',
      key: 'action',
      align: 'right',
      width: '30%',
      render: record => (
        <>
          <Tooltip title={'Sửa'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => updateModal.open(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => deleteDepartment(record.code)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá phòng ban này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <>
      <CreateCompanyDepartmentModal
        visible={isCreateModalVisible}
        onCancel={createModal.close}
        companyCode={selectedCompanyCode}
      />
      <UpdateCompanyDepartmentModal
        visible={isUpdateModalVisible}
        onCancel={updateModal.close}
        department={selectedDepartment}
      />
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 14,
        }}>
        <CompanySelect onChangeCompany={onChangeCompany} companyCode={selectedCompanyCode} />
        <Button type={'primary'} onClick={createModal.open}>
          <PlusCircleOutlined />
          Tạo mới
        </Button>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={departmentStore.departmentList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
    </>
  )
}

export default inject(
  'departmentStore',
  'companyStore'
)(observer(CompanyDepartmentTable))
