import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { Button, message, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import EmptyContent from '../EmptyContent'
import { find } from 'lodash-es'
import { TRUC_COMMAND } from '../../command_constant'

const RefreshTrucButton = props => {
  const { Option } = Select

  const { authenticationStore, loadingAnimationStore, accountStore } = props
  const { accountTruc, accountList } = accountStore

  const refreshTrucAccount = async () => {
    loadingAnimationStore.showSpinner(true)

    // console.log('refreshTrucAccount')
    authenticationStore.logoutTruc()
    await accountStore.getCurrentUserAccount()

    loadingAnimationStore.showSpinner(false)
    message.success('Đã làm mới lại liên kết tới trục')
  }

  const onAccountChange = async accountId => {
    try {
      loadingAnimationStore.showSpinner(true)
      await accountStore.setActiveAccount(accountId)

      await refreshTrucAccount()
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <>
      <Button
        style={{ marginLeft: 14, marginRight: 14 }}
        onClick={refreshTrucAccount}>
        <ReloadOutlined />
        Cấu hình lại liên kết trục
      </Button>

      <Select
        style={{ width: 280, height: 32 }}
        notFoundContent={<EmptyContent />}
        onChange={onAccountChange}
        value={
          find(
            accountList.filter(item => item.command.code === TRUC_COMMAND),
            { is_active: true }
          )?.id
        }
        placeholder={'-- Chọn tài khoản trục --'}>
        {accountList
          .filter(item => item.command.code === TRUC_COMMAND)
          .map(item => (
            <Option key={item.id} value={item.id}>
              {item.alias}
            </Option>
          ))}
      </Select>
    </>
  )
}

RefreshTrucButton.propTypes = {}

export default withRouter(
  inject(
    'loadingAnimationStore',
    'authenticationStore',
    'accountStore'
  )(observer(RefreshTrucButton))
)
