import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TreeSelect,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { blue } from '../../color'
import SelectListComponent from '../../components/Common/SelectListComponent'
import SelectUserButton from '../../components/Common/SelectUserButton'
import RichEditor from '../../components/RichEditor/RichEditor'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { ASSIGNEE_TYPE } from '../../constants'
import useOpenItem from '../../hooks/useOpenItem'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import { RiskSelects } from './RiskCreatePageStyled'
import RuiRoNhanDienTemplate from './RuiRoNhanDienTemplate'
import RuiRoNhanDienTemplatePropose from './RuiRoNhanDienTemplatePropose'
import utils from '../../utils'
import { toJS } from 'mobx'
import SelectListComponentRisk from '../../components/Common/SelectListComponentRisk'
import { find } from 'lodash-es'
import { systemName } from '../../config'

const RiskIdentificationCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  type,
  setType,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
  riskUnitsV2,
  departmentStore,
  dataRiskUnitsByTypeNDDPV,
  dataRiskUnitsByType,
}) => {
  const { riskDetail } = riskStore
  const { departmentList } = departmentStore
  const {
    indexDetailAction,
    dataActions,
    setIndexDetailAction,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    isVisibleSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    departmentWithUserList,
  } = selectPeopleStore

  const { SHOW_ALL } = TreeSelect
  const treeData =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2.map(item => ({
      title: item.name,
      value: item.code,
      key: item.code,
      children: item.unit_users.map(user => ({
        title: user.full_name,
        value: user.user_code,
        key: user.user_code,
        position_name: user.position_name,
      })),
    }))
  const treeData1 = dataRiskUnitsByType.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData2 = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData3 = dataRiskUnitsByTypeNDDPV?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item?.unit_users?.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const [treeData_BPDX, setTreeData] = useState([])
  const [treeData_BP, setTreeDataBP] = useState([])
  const [treeData_LD, setTreeDataLD] = useState([])
  useEffect(() => {
    const updatedTreeData = treeData2.concat(treeData1)
    setTreeData(updatedTreeData)
    const updatedTreeDataBP = treeData2.concat(treeData)
    setTreeDataBP(updatedTreeDataBP)
    const updatedTreeDataLD = treeData2.concat(treeData3)
    setTreeDataLD(updatedTreeDataLD)
  }, [riskUnitsV2, departmentWithUserList])

  const queryStringParse = queryString.parse(location.search)
  const [riskPoint, setRiskPoint] = useState({ p: 0, s: 0 })
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplates,
    setIsVisibleRuiRoNhanDienTemplates,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState([])
  const [ruiRoNhanDienPropose, setRuiRoNhanDienPropose] = useState([])

  const [form] = Form.useForm()
  const CONTENT_REF = useRef(null)
  const NDRR_REF = useRef(null)
  const LRRDX1_REF = useRef(null)

  const XDGPGTPN_REF = useRef(null)
  const BPXL_REF = useRef(null)
  const LRRDX_REF = useRef(null)

  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedCqvp, setSelectedCqvp] = useState(null)

  useEffect(() => {
    setSelectedOption(renderTypeToOption(queryStringParse.type))
  }, [])

  useEffect(() => {
    if (!dataActions?.tier1[0] || indexDetailAction?.tier !== '1') {
      return
    }
    let fillData = dataActions.tier1[0]

    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.USER]:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .lanh_dao_don_vi_nddpv_phu_trach_chung,
      [ASSIGNEE_TYPE.lanh_dao_vimc]: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
    })
    let selected_doi_tuong = null
    if (type == 'NDDPV') selected_doi_tuong = 'nddpv_cua_vimc_tai_dntv'
    if (type == 'DVPT') selected_doi_tuong = 'dvpt_cua_vimc'
    if (type == 'CQVP') selected_doi_tuong = 'cac_don_vi_thuoc_cq_vp_cua_vimc'
    setSelectedOption(selected_doi_tuong)
    form.setFieldsValue({
      //doi_tuong_thuc_hien
      group_doi_tuong: selected_doi_tuong,
      nddpv_cua_vimc_tai_dntv:
        fillData.doi_tuong_thuc_hien.nddpv_cua_vimc_tai_dntv,
      department_code_nddpv_cua_vimc_tai_dntv:
        fillData.doi_tuong_thuc_hien.department_code_nddpv_cua_vimc_tai_dntv,
      dvpt_cua_vimc: fillData.doi_tuong_thuc_hien?.dvpt_cua_vimc,
      cac_don_vi_thuoc_cq_vp_cua_vimc:
        fillData.doi_tuong_thuc_hien.cac_don_vi_thuoc_cq_vp_cua_vimc,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_linh_vuc_hoat_dong
      cong_viec_theo:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .cong_viec_linh_vuc_hoat_dong.cong_viec_theo,
      noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .cong_viec_linh_vuc_hoat_dong
          .noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_thuc_hien
      noi_dung_cong_viec:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .noi_dung_cong_viec,
      nguoi_thuc_hien__cong_viec:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .nguoi_thuc_hien,
      thoi_gian_hoan_thanh:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .thoi_gian_hoan_thanh,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> nhan_dien_rui_ro_va_danh_gia_rui_ro
      // nhan_dien_rui_ro:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.nhan_dien_rui_ro,
      // xay_dung_giai_phap_giam_thieu_phong_ngua:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .xay_dung_giai_phap_giam_thieu_phong_ngua,
      p:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.p,
      s:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.s,
      r:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.r,
      //

      nguoi_thuc_hien__sign:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_sign,
      nguoi_thuc_hien__signed:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien_signed,
      nguoi_thuc_hien__sign_khac:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nguoi_thuc_hien_sign_khac,
      // lanh_dao_don_vi_nddpv_phu_trach_chung:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .lanh_dao_don_vi_nddpv_phu_trach_chung,
      //II_xu_ly_rui_ro -> de_xuat_loai_rui_ro_can_xu_ly
      loai_rui_ro_de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          .loai_rui_ro_de_xuat,
      loai_rui_ro_de_xuat_khac:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          .loai_rui_ro_de_xuat_khac,
      bo_phan_de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.bo_phan_de_xuat,
      bo_phan_de_xuat_khac:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          .bo_phan_de_xuat_khac,
      bien_phap_xu_ly:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.bien_phap_xu_ly,
      nguoi_thuc_hien__de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.nguoi_thuc_hien,
      nguoi_thuc_hien__de_xuat_khac:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          .nguoi_thuc_hien__de_xuat_khac,
      //
      lanh_dao_quyet_dinh_bien_phap_xu_ly:
        fillData.II_xu_ly_rui_ro.lanh_dao_quyet_dinh_bien_phap_xu_ly,
      don_vi_thuc_hien_xu_ly_rui_ro:
        fillData.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro,
      don_vi_thuc_hien_xu_ly_rui_ro__signed:
        fillData.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro__signed,
      don_vi_thuc_hien_xu_ly_rui_ro_khac:
        fillData.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro_khac,
      // lanh_dao_vimc: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
      nddpv_phu_trach_chung__signed:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.nddpv_phu_trach_chung__signed,
      nddpv_phu_trach_chung:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.nddpv_phu_trach_chung,
      nguoi_dai_dien_phan_von_phu_trach_chung:
        fillData?.II_xu_ly_rui_ro?.nguoi_dai_dien_phan_von_phu_trach_chung,
      nguoi_dai_dien_phan_von_phu_trach_chung__signed:
        fillData?.II_xu_ly_rui_ro
          ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed,
      lanh_dao_don_vi_phu_thuoc:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_don_vi_phu_thuoc,
      lanh_dao_don_vi_phu_thuoc_cqvp:
        fillData?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          ?.lanh_dao_don_vi_phu_thuoc_cqvp,
    })
  }, [indexDetailAction])
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  const p = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.p
  )

  const s = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.s
  )

  useEffect(() => {
    const P = riskPoint.p ? riskPoint.p : p
    const S = riskPoint.s ? riskPoint.s : s
    if (!P || !S) return
    const r = Math.ceil(P * S)
    const displayedR = r >= 1 && r <= 5 ? '1' : r >= 6 && r <= 15 ? '2' : '3'
    form.setFieldsValue({
      r: displayedR,
      loai_rui_ro_de_xuat: displayedR,
    })
  }, [riskPoint])
  useEffect(() => {
    return () => {
      setRiskPoint({ p: 0, s: 0 })
    }
  }, [])

  // Rủi ro nhận diện
  const dataNDRR = [] // Nhận diện rủi ro
  const dataLRRDX = [] // Loại rủi ro đề xuất
  const dataXDGPGTPN = [] // Xây dựng giải pháp giảm thiểu phòng ngừa
  const causesOfIdentifiedRisks = [] // Nguyên nhân
  const dataXDGPGTPNII = [] // Xây dựng giải pháp giảm thiểu phòng ngừa II
  // Xử lý rủi ro
  const causesOfHandledRisks = [] // Nguyên nhân

  useEffect(() => {
    if (ruiRoNhanDien.length > 0) {
      ruiRoNhanDien?.map(item => {
        dataNDRR.push(
          `${item.rui_ro_nhan_dien ?? item.identification_risk} <br/>`
        )
        causesOfIdentifiedRisks.push(
          `${item.nguyen_nhan ?? item.reason ?? ''} <br>`
        )
        dataXDGPGTPN.push(`${item.bien_phap_phong_ngua_giam_thieu ?? ''} <br>`)
      })
      NDRR_REF.current?.editor.setData(`${dataNDRR.join('')}`)
      XDGPGTPN_REF.current?.editor.setData(
        `${dataXDGPGTPN.join('').replace(/<\/?p>/g, '')}`
      )
    } else {
      NDRR_REF.current?.editor.setData(`<p>${''}</p>`)
      XDGPGTPN_REF.current?.editor.setData(`<p>${''}</p>`)
    }
  }, [ruiRoNhanDien])

  useEffect(() => {
    if (ruiRoNhanDienPropose.length > 0) {
      ruiRoNhanDienPropose?.map(item => {
        dataLRRDX.push(
          `${
            item.rui_ro_nhan_dien
              ? item.rui_ro_nhan_dien
              : item.identification_risk
          } <br/>`
        )
        causesOfHandledRisks.push(
          `${item.nguyen_nhan ?? item.reason ?? ''} <br>`
        )
        dataXDGPGTPNII.push(
          `${item.bien_phap_phong_ngua_giam_thieu ?? ''} <br>`
        )
      })
      LRRDX1_REF.current?.editor.setData(`${dataLRRDX.join('')}`)
      BPXL_REF.current?.editor.setData(
        `${dataXDGPGTPNII.join('').replace(/<\/?p>/g, '')}`
      )
    } else {
      LRRDX1_REF.current?.editor.setData(`<p>${''}</p>`)
      BPXL_REF.current?.editor.setData(`<p>${''}</p>`)
    }
  }, [ruiRoNhanDienPropose])
  const handleCancelModal = () => {
    handleCancel()
    setRiskPoint({ p: 0, s: 0 })
    // setTimeout(() => {
    //   setIndexDetailAction({ tiers: null, index: null })
    // }, 500)
  }
  const renderUserFullNames = userList => {
    if (!userList || userList.length === 0) {
      return ''
    }

    const fullNames = userList.map(user => user.full_name)
    return fullNames.join(', ')
  }
  const handleCreateRiskId = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ nhất',
      doi_tuong_thuc_hien: {
        nddpv_cua_vimc_tai_dntv:
          type == 'NDDPV'
            ? selectUserData.nddpv_vimc.length > 0
              ? renderUserFullNames(selectUserData.nddpv_vimc)
              : value?.nddpv_cua_vimc_tai_dntv
            : '',
        department_code_nddpv_cua_vimc_tai_dntv:
          type == 'NDDPV' ? value?.department_code_nddpv_cua_vimc_tai_dntv : '',
        dvpt_cua_vimc:
          type == 'DVPT'
            ? selectUserData.dvpt_vimc.length > 0
              ? renderUserFullNames(selectUserData.dvpt_vimc)
              : value?.dvpt_cua_vimc
            : '',
        cac_don_vi_thuoc_cq_vp_cua_vimc:
          type == 'CQVP'
            ? selectUserData.dv_cqvp_vimc.length > 0
              ? renderUserFullNames(selectUserData.dv_cqvp_vimc)
              : value?.cac_don_vi_thuoc_cq_vp_cua_vimc
            : '',
      },
      I_nhan_dien_rui_ro_va_danh_gia_rui_ro: {
        cong_viec_linh_vuc_hoat_dong: {
          cong_viec_theo: value.cong_viec_theo,
          noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
            value.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
        },
        cong_viec_thuc_hien: {
          noi_dung_cong_viec: CONTENT_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__cong_viec,
          thoi_gian_hoan_thanh: value.thoi_gian_hoan_thanh,
        },
        nhan_dien_rui_ro_va_danh_gia_rui_ro: {
          nhan_dien_rui_ro: NDRR_REF.current.editor.getData(),
          xay_dung_giai_phap_giam_thieu_phong_ngua: XDGPGTPN_REF.current.editor.getData(),
          nguyen_nhan: causesOfIdentifiedRisks.join(''),
          // danh_gia_rui_ro: value.danh_gia_rui_ro,
          p: value.p,
          s: value.s,
          r: value.r,
        },
        nguoi_thuc_hien_sign: value.nguoi_thuc_hien__sign,
        nguoi_thuc_hien_signed:
          checked_nth === true ? value.nguoi_thuc_hien__signed : null,
        nguoi_thuc_hien_sign_khac: value.nguoi_thuc_hien__sign_khac,
        lanh_dao_don_vi_nddpv_phu_trach_chung: selectUserList.USER,
        nddpv_phu_trach_chung: value.nddpv_phu_trach_chung,
        nddpv_phu_trach_chung__signed:
          checked_ptc === true ? value.nddpv_phu_trach_chung__signed : null,
        lanh_dao_don_vi_phu_thuoc: value.lanh_dao_don_vi_phu_thuoc,
        lanh_dao_don_vi_phu_thuoc_cqvp: value.lanh_dao_don_vi_phu_thuoc_cqvp,
      },
      II_xu_ly_rui_ro: {
        de_xuat_loai_rui_ro_can_xu_ly: {
          loai_rui_ro_de_xuat: LRRDX1_REF.current.editor.getData(),
          loai_rui_ro_de_xuat_khac: value.loai_rui_ro_de_xuat_khac,
          bo_phan_de_xuat: value.bo_phan_de_xuat,
          bo_phan_de_xuat_khac: value.bo_phan_de_xuat_khac,
          bien_phap_xu_ly: BPXL_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__de_xuat,
          nguoi_thuc_hien__de_xuat_khac: value.nguoi_thuc_hien__de_xuat_khac,
          nguyen_nhan: causesOfHandledRisks.join(''),
        },
        lanh_dao_quyet_dinh_bien_phap_xu_ly:
          value.lanh_dao_quyet_dinh_bien_phap_xu_ly,
        don_vi_thuc_hien_xu_ly_rui_ro: value.don_vi_thuc_hien_xu_ly_rui_ro,
        don_vi_thuc_hien_xu_ly_rui_ro__signed:
          checked_lddv === true
            ? value.don_vi_thuc_hien_xu_ly_rui_ro__signed
            : null,
        don_vi_thuc_hien_xu_ly_rui_ro_khac: selectUserList?.cqvp_I,
        lanh_dao_vimc: selectUserList.lanh_dao_vimc,
        nguoi_dai_dien_phan_von_phu_trach_chung:
          value.nguoi_dai_dien_phan_von_phu_trach_chung,
        nguoi_dai_dien_phan_von_phu_trach_chung__signed: checked_nddpv
          ? value.nguoi_dai_dien_phan_von_phu_trach_chung__signed
          : null,
      },
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[0]?.actions[0]) {
        await updateAction(riskDetail?.tiers[0]?.actions[0]?.code, {
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
          risk_type: type,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      if (dataActions.tier1[0]) {
        dataActions.tier1[0] = submitData
        setDataActions({ ...dataActions, tier1: [...dataActions?.tier1] })
      } else {
        setDataActions({
          ...dataActions,
          tier1: [submitData],
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }
  const {
    isOpen: isVisibleSelectUserPopup,
    handleOpen: handleVisibleSelectUserPopup,
    handleClose: handleCloseSelectUserPopup,
  } = useOpenItem()
  const handleRadioChange = e => {
    setSelectedOption(e.target.value)
    setType(() => renderOptionToType(e.target.value))
  }
  const handleRadioChangeV2 = e => {
    setSelectedCqvp(e.target.value)
  }
  const renderTypeToOption = type => {
    if (type === 'NDDPV') return 'nddpv_cua_vimc_tai_dntv'
    if (type === 'DVPT') return 'dvpt_cua_vimc'
    if (type === 'CQVP') return 'cac_don_vi_thuoc_cq_vp_cua_vimc'
  }
  const renderOptionToType = type => {
    if (type === 'nddpv_cua_vimc_tai_dntv') return 'NDDPV'
    if (type === 'dvpt_cua_vimc') return 'DVPT'
    if (type === 'cac_don_vi_thuoc_cq_vp_cua_vimc') return 'CQVP'
  }
  useEffect(() => {
    ;(async () => {
      try {
        if (selectedOption === 'cac_don_vi_thuoc_cq_vp_cua_vimc') {
          await departmentStore.getDepartmentList()
        }
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    // return () => {
    // riskStore.clearRiskStore()
    // actionStore.clearActionStore()
    // }
  }, [selectedOption])
  const [checked_ptc, setChecked_ptc] = useState(false)
  const [checked_nth, setChecked_nth] = useState(false)
  const [checked_lddv, setChecked_lddv] = useState(false)
  const [checked_nddpv, setChecked_nddpv] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [disabled_ptc, setDisabled_ptc] = useState(true)
  const [disabled_nth, setDisabled_nth] = useState(true)
  const [disabled_nddpv, setDisabled_nddpv] = useState(true)
  const toggleDisable = e => {
    if (e?.length > 0) {
      setDisabled(false)
    }
    if (e?.length < 1) {
      setChecked_lddv(false)
      setDisabled(true)
    }
  }
  const onChangeCheckedLddv = e => {
    setChecked_lddv(e.target.checked)
  }

  const toggleDisablePtc = e => {
    if (e?.length > 0) {
      setDisabled_ptc(false)
    }
    if (e?.length < 1) {
      setChecked_ptc(false)
      setDisabled_ptc(true)
    }
  }
  const onChangeCheckedPtc = e => {
    setChecked_ptc(e.target.checked)
  }

  const toggleDisableNth = e => {
    if (e?.length > 0) {
      setDisabled_nth(false)
    }
    if (e?.length < 1) {
      setChecked_nth(false)
      setDisabled_nth(true)
    }
  }
  const toggleDisableNthk = value => {
    if (value.target.value.trim() !== '') {
      setDisabled_nth(false)
    } else {
      setChecked_nth(false)
      setDisabled_nth(true)
    }
  }
  const onChangeCheckedNth = e => {
    setChecked_nth(e.target.checked)
  }

  const toggleDisableNddpv = e => {
    if (e?.length > 0) {
      setDisabled_nddpv(false)
    }
    if (e?.length < 1) {
      setChecked_nddpv(false)
      setDisabled_nddpv(true)
    }
  }
  const onChangeCheckedNddpv = e => {
    setChecked_nddpv(e.target.checked)
  }
  useEffect(() => {
    if (
      (selectUserList.lanh_dao_vimc.length > 0 && type === 'CQVP') ||
      (selectUserList.lanh_dao_vimc.length > 0 && type === 'DVPT')
    ) {
      setDisabled_nddpv(false)
    } else if (
      (selectUserList.lanh_dao_vimc.length == 0 && type === 'CQVP') ||
      (selectUserList.lanh_dao_vimc.length == 0 && type === 'DVPT')
    ) {
      setChecked_nddpv(false)
      setDisabled_nddpv(true)
    }
  }, [selectUserList.lanh_dao_vimc.length])
  console.log(
    'check 1',
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nguoi_thuc_hien_signed
  )
  useEffect(() => {
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_signed === '(Đã ký)'
    ) {
      setChecked_nth(true)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nddpv_phu_trach_chung__signed === '(Đã ký)'
    ) {
      setChecked_ptc(true)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.don_vi_thuc_hien_xu_ly_rui_ro__signed === '(Đã ký)'
    ) {
      setChecked_lddv(true)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.nguoi_dai_dien_phan_von_phu_trach_chung__signed === '(Đã ký)'
    ) {
      setChecked_nddpv(true)
    }
  }, [])
  useEffect(() => {
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_sign?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nguoi_thuc_hien_sign_khac !== undefined
    ) {
      setDisabled_nth(false)
    }
    if (
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.nddpv_phu_trach_chung?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_don_vi_phu_thuoc_cqvp?.length > 0 ||
      dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
        ?.lanh_dao_don_vi_phu_thuoc?.length > 0
    ) {
      setDisabled_ptc(false)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro
        ?.length > 0
    ) {
      setDisabled(false)
    }
    if (
      dataActions?.tier1[0]?.II_xu_ly_rui_ro
        ?.nguoi_dai_dien_phan_von_phu_trach_chung?.length > 0
    ) {
      setDisabled_nddpv(false)
    }
  }, [])

  const handleSelectUser = assigneeType => {
    handleVisibleSelectUserPopup()
    handleOpenSelectUserDepartmentPopup(assigneeType)
  }

  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40 }}
        title={'A. Tuyến phòng vệ thứ nhất'}
        visible={isVisible}
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={() => {
          handleCancelModal()
        }}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleCreateRiskId}
          preserve={false}>
          <h3>Đối tượng thực hiện</h3>
          <Form.Item name="group_doi_tuong">
            <Radio.Group onChange={handleRadioChange}>
              <Radio value="nddpv_cua_vimc_tai_dntv">
                <label style={{ marginRight: '20px' }}>
                  - NĐDPV của {systemName} tại DNTV (trong trường hợp giao NĐDPV
                  thực hiện công việc):
                </label>
              </Radio>
              <Form.Item
                name="department_code_nddpv_cua_vimc_tai_dntv"
                style={{ width: '57%', margin: '10px 0 10px 25px' }}>
                {selectedOption === 'nddpv_cua_vimc_tai_dntv' && (
                  <Select
                    showSearch
                    allowClear // Cho phép xóa lựa chọn đã chọn
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => {
                      setDepartmentCodeNDDPV(value)
                    }}
                    placeholder={`Chọn NĐDPV của ${systemName} tại DNTV`}>
                    {Array.isArray(riskUnitsV2) &&
                      riskUnitsV2.map((item, index) => (
                        <Select.Option key={item.code} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Radio value="dvpt_cua_vimc">
                <label style={{ marginRight: '20px' }}>
                  - ĐVPT của {systemName} (trong trường hợp ĐVPT thực hiện công
                  việc):
                </label>
              </Radio>
              <Form.Item
                name="dvpt_cua_vimc"
                style={{ width: '57%', margin: '10px 0 10px 25px' }}>
                {selectedOption === 'dvpt_cua_vimc' && (
                  <Select
                    showSearch
                    allowClear // Cho phép xóa lựa chọn đã chọn
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={`Chọn ĐVPT của ${systemName}`}>
                    {Array.isArray(riskUnitsV2) &&
                      riskUnitsV2.map((item, index) => (
                        <Select.Option key={item.code} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Radio value="cac_don_vi_thuoc_cq_vp_cua_vimc">
                <label style={{ marginRight: '20px' }}>
                  - Các Đơn vị thuộc cơ quan Văn phòng của {systemName} (trong
                  trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện
                  công việc):
                </label>
              </Radio>
              <Form.Item
                name="cac_don_vi_thuoc_cq_vp_cua_vimc"
                style={{ width: '57%', margin: '10px 0 10px 25px' }}>
                {selectedOption === 'cac_don_vi_thuoc_cq_vp_cua_vimc' && (
                  <Select
                    showSearch
                    allowClear // Cho phép xóa lựa chọn đã chọn
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={`Chọn CQVP của ${systemName}`}>
                    {departmentList.map((item, index) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Radio.Group>
          </Form.Item>
          <h3>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h3>
          <h3>1. Công việc/lĩnh vực hoạt động</h3>
          <Form.Item name={'cong_viec_theo'}>
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox value={'cong_viec_thuoc_chuc_nang_nhiem_vu'}>
                  a/ Công việc thuộc chức năng, nhiệm vụ
                </Checkbox>
                <Checkbox value={'cong_viec_theo_kpi_kri'}>
                  b/ Công việc theo KPI/KRI
                </Checkbox>
                <Checkbox value={'cong_viec_theo_muc_tieu_chien_luoc'}>
                  c/ Công việc theo mục tiêu, chiến lược
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name={'noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren'}
            label={'d/ Nội dung công việc khác không thuộc các nhóm trên:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <h3>2. Công việc thực hiện</h3>
          <Form.Item
            label={'a/ Nội dung công việc:'}
            name={'noi_dung_cong_viec'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.cong_viec_thuc_hien.noi_dung_cong_viec || ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'nguoi_thuc_hien__cong_viec'}
            label={'b/ Người thực hiện:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <Form.Item
            name={'thoi_gian_hoan_thanh'}
            label={'c/ Thời gian hoàn thành:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <h3>
            3. Nhận diện rủi ro và đánh giá rủi ro{' '}
            {type === 'NDDPV' ? (
              ''
            ) : (
              <Button
                style={{ marginTop: 8, marginLeft: 8, color: blue }}
                onClick={() => {
                  setIsVisibleRuiRoNhanDienTemplate(true)
                }}>
                Chọn
              </Button>
            )}
          </h3>
          <Form.Item name={'nhan_dien_rui_ro'} label={'a/ Nhận diện rủi ro:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={NDRR_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro ||
                  ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'xay_dung_giai_phap_giam_thieu_phong_ngua'}
            label={'b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:'}>
            {!isRenderRichText && (
              <div style={{ height: '312px', position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={XDGPGTPN_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .xay_dung_giai_phap_giam_thieu_phong_ngua || ''
                }
              />
            )}
          </Form.Item>
          <p>c/ Đánh giá rủi ro:</p>
          <RiskSelects>
            <Form.Item name={'p'} label={'P:'} style={{ width: '30%' }}>
              <Select
                onChange={value => {
                  setRiskPoint({ ...riskPoint, p: +value })
                }}>
                <Select.Option value="1">
                  1 - Rất thấp (Hiếm khi xảy ra)
                </Select.Option>
                <Select.Option value="2">
                  2 - Thấp (Đôi khi xảy ra)
                </Select.Option>
                <Select.Option value="3">
                  3 - Trung bình (Hay xảy ra)
                </Select.Option>
                <Select.Option value="4">
                  4 - Cao (Thường xuyên xảy ra)
                </Select.Option>
                <Select.Option value="5">
                  5 - Rất cao (Gần như chắc chắn xảy ra)
                </Select.Option>
              </Select>
            </Form.Item>
            <span style={{ marginTop: 16 }}>X</span>
            <Form.Item name={'s'} label={'S:'} style={{ width: '30%' }}>
              <Select
                onChange={value => {
                  setRiskPoint({ ...riskPoint, s: +value })
                }}>
                <Select.Option value="1">
                  1 - Không đáng kể (Ảnh hưởng thấp)
                </Select.Option>
                <Select.Option value="2">
                  2 - Vừa phải (Ảnh hưởng trung bình)
                </Select.Option>
                <Select.Option value="3">
                  3 - Quan trọng (Ảnh hưởng cao)
                </Select.Option>
                <Select.Option value="4">
                  4 - Trầm trọng (Ảnh hưởng nghiêm trọng)
                </Select.Option>
                <Select.Option value="5">
                  5 - Rất trầm trọng (Ảnh hưởng đặc biệt nghiêm trọng)
                </Select.Option>
              </Select>
            </Form.Item>
            <span style={{ marginTop: 16 }}>=</span>
            <Form.Item name={'r'} label={'R:'} style={{ width: '30%' }}>
              <Select>
                <Select.Option value="1">
                  1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
                </Select.Option>
                <Select.Option value="2">
                  6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được
                  phép tiến hành với sự quản lý, kiểm soát thích hợp)
                </Select.Option>
                <Select.Option value="3">
                  16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được phép
                  tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ
                  hơn để giảm thiểu rủi ro)
                </Select.Option>
              </Select>
            </Form.Item>
          </RiskSelects>
          <Divider />
          <p>Người thực hiện:</p>{' '}
          <Form.Item name={'nguoi_thuc_hien__signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_nth}
                disabled={disabled_nth}
                onChange={e => onChangeCheckedNth(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          {type === 'NDDPV' ? (
            <>
              <Form.Item name={'nguoi_thuc_hien__sign'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisableNth(e)}
                  placeholder={'Chọn người thực hiện'}
                />
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__sign_khac'}>
                <Input
                  onInput={value => toggleDisableNthk(value)}
                  placeholder={'Nhập người thực hiện khác danh sách'}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item name={'nguoi_thuc_hien__sign_khac'}>
                <Input
                  onInput={value => toggleDisableNthk(value)}
                  placeholder={'Nhập người thực hiện khác danh sách'}
                />
              </Form.Item>
            </>
          )}
          {type === 'NDDPV' ? (
            <p>NĐDPV phụ trách chung:</p>
          ) : type === 'DVPT' ? (
            <p>Lãnh đạo đơn vị phụ thuộc:</p>
          ) : (
            <p>Lãnh đạo Đơn vị/NĐDPV phụ trách chung:</p>
          )}
          <Form.Item name={'nddpv_phu_trach_chung__signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_ptc}
                disabled={disabled_ptc}
                onChange={e => onChangeCheckedPtc(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          {type === 'NDDPV' ? (
            <Form.Item name={'nddpv_phu_trach_chung'}>
              <TreeSelect
                treeData={treeData}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisablePtc(e)}
                placeholder={'Chọn NĐDPV phụ trách chung'}
              />
            </Form.Item>
          ) : type === 'DVPT' ? (
            <Form.Item name={'lanh_dao_don_vi_phu_thuoc'}>
              <TreeSelect
                treeData={treeData}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisablePtc(e)}
                placeholder={'Chọn lãnh đạo đơn vị phụ thuộc'}
              />
            </Form.Item>
          ) : (
            <Form.Item name={'lanh_dao_don_vi_phu_thuoc_cqvp'}>
              <TreeSelect
                treeData={treeData_LD}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisablePtc(e)}
                placeholder={'Chọn lãnh đạo Đơn vị/NĐDPV phụ trách chung'}
              />
            </Form.Item>
          )}
          <Divider />
          <h3>II. XỬ LÝ RỦI RO</h3>
          <h3>
            1. Đề xuất loại rủi ro cần xử lý{' '}
            {type === 'NDDPV' ? (
              ''
            ) : (
              <Button
                style={{ marginTop: 8, marginLeft: 8, color: blue }}
                onClick={() => {
                  setIsVisibleRuiRoNhanDienTemplates(true)
                }}>
                Chọn
              </Button>
            )}
          </h3>
          <>
            <Form.Item
              name={'loai_rui_ro_de_xuat'}
              label={'a/ Loại rủi ro đề xuất:'}>
              {!isRenderRichText && (
                <div style={{ height: 312, position: 'relative' }}>
                  <SpinnerInlineComponent
                    sizeSpin={'small'}
                    tip="Đang tải dữ liệu..."
                    isLoading={!isRenderRichText}
                    backGround={'#ffffffc7'}
                  />
                </div>
              )}
              {isRenderRichText && (
                <RichEditor
                  EDITOR_REF={LRRDX1_REF}
                  placeholder={'Nhập nội dung'}
                  editorContent={
                    dataActions?.tier1[0]?.II_xu_ly_rui_ro
                      ?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat || ''
                  }
                />
              )}
            </Form.Item>
            {/* {type === 'NDDPV' && isRenderRichText ? (
              <Form.Item name={'loai_rui_ro_de_xuat_khac'}>
                <RichEditor
                  EDITOR_REF={LRRDX_REF}
                  placeholder={'Nhập loại rủi ro đề xuất khác'}
                  editorContent={
                    dataActions?.tier1[0]?.II_xu_ly_rui_ro
                      .de_xuat_loai_rui_ro_can_xu_ly.loai_rui_ro_de_xuat_khac ||
                    ''
                  }
                />
              </Form.Item>
            ) : (
              ''
            )} */}
          </>
          {type === 'NDDPV' ? (
            <>
              <Form.Item
                name={'bo_phan_de_xuat_khac'}
                label={'b/ Bộ phận đề xuất:'}>
                <Input placeholder="Nhập bộ phận đề xuất"></Input>
              </Form.Item>
            </>
          ) : (
            <>
              {type === 'DVPT' ? (
                <>
                  <Form.Item
                    name={'bo_phan_de_xuat_khac'}
                    label={'b/ Bộ phận đề xuất:'}>
                    <Input placeholder="Nhập bộ phận đề xuất"></Input>
                  </Form.Item>
                </>
              ) : (
                <>
                  {' '}
                  <Form.Item
                    name={'bo_phan_de_xuat'}
                    label={'b/ Bộ phận đề xuất:'}>
                    <Select
                      showSearch
                      allowClear // Cho phép xóa lựa chọn đã chọn
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={'Chọn bộ phận đề xuất'}>
                      {Array.isArray(treeData_BPDX) &&
                        treeData_BPDX.map((item, index) => (
                          <Select.Option key={item.code} value={item.title}>
                            {item.title}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              )}
            </>
          )}
          <Form.Item name={'bien_phap_xu_ly'} label={'c/ Biện pháp xử lý:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={BPXL_REF}
                placeholder={'Nhập nội dung'}
                editorContent={
                  dataActions?.tier1[0]?.II_xu_ly_rui_ro
                    .de_xuat_loai_rui_ro_can_xu_ly.bien_phap_xu_ly || ''
                }
              />
            )}
          </Form.Item>
          {type === 'NDDPV' ? (
            <>
              <Form.Item
                name={'nguoi_thuc_hien__de_xuat_khac'}
                label={'d/Người thực hiện:'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  placeholder={'Chọn người thực hiện'}
                />
              </Form.Item>
              <Form.Item name={'nguoi_thuc_hien__de_xuat'}>
                <Input placeholder={'Nhập người thực hiện khác danh sách'} />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name={'nguoi_thuc_hien__de_xuat'}
              label={'d/ Người thực hiện:'}>
              <Input placeholder={'Nhập người thực hiện'} />
            </Form.Item>
          )}
          <h3>2. Lãnh đạo quyết định biện pháp xử lý</h3>
          <Form.Item
            name={'lanh_dao_quyet_dinh_bien_phap_xu_ly'}
            label={'Lãnh đạo:'}
            hidden={true}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          {/* <Divider /> */}
          {type === 'NDDPV' ? (
            <>NGƯỜI ĐẠI DIỆN PHẦN VỐN THỰC HIỆN XỬ LÝ RỦI RO:</>
          ) : (
            <>
              {type === 'DVPT' ? (
                <>Chọn người đại diện phần vốn thực hiện xử lý rủi ro:</>
              ) : (
                <>LÃNH ĐẠO ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO:</>
              )}
            </>
          )}
          <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro__signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_lddv ? true : false}
                disabled={disabled}
                onChange={e => onChangeCheckedLddv(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <div>
            {type === 'NDDPV' ? (
              <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro'}>
                <TreeSelect
                  treeData={treeData}
                  style={{ width: '100%' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  onChange={e => toggleDisable(e)}
                  placeholder={
                    'Chọn người đại diện phần vốn thực hiện xử lý rủi ro'
                  }
                />
              </Form.Item>
            ) : (
              <>
                {type === 'DVPT' ? (
                  <Form.Item name={'don_vi_thuc_hien_xu_ly_rui_ro'}>
                    <TreeSelect
                      treeData={treeData}
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      showSearch
                      filterTreeNode
                      treeNodeFilterProp={'title'}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_ALL}
                      treeCheckStrictly={true}
                      onChange={e => toggleDisable(e)}
                      placeholder={
                        'Chọn lãnh đạo đơn vị thực hiện xử lý rủi ro'
                      }
                    />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item name="don_vi_thuc_hien_xu_ly_rui_ro">
                      <TreeSelect
                        // treeDataSimpleMode
                        treeData={treeData_BPDX}
                        style={{ width: '100%' }}
                        showArrow
                        allowClear
                        showSearch
                        filterTreeNode
                        treeNodeFilterProp={'title'}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_ALL}
                        treeCheckStrictly={true}
                        onChange={e => toggleDisable(e)}
                        placeholder={
                          'Chọn lãnh đạo đơn vị thực hiện xử lý rủi ro'
                        }
                      />
                    </Form.Item>
                    {/* <Form.Item>
                      <Radio.Group onChange={handleRadioChangeV2}>
                        <Radio value="cqvp_ban_chuyen_mon_nv">
                          <label style={{ marginRight: '20px' }}>
                            - Cơ quan văn phòng - ban chuyên môn nghiệp vụ :
                          </label>
                        </Radio>
                        <Form.Item
                          name="don_vi_thuc_hien_xu_ly_rui_ro"
                          style={{ width: '100%', margin: '10px 0 10px 25px' }}>
                          {selectedCqvp === 'cqvp_ban_chuyen_mon_nv' && (
                            <TreeSelect
                              // treeDataSimpleMode
                              treeData={treeData}
                              style={{ width: '100%' }}
                              showArrow
                              allowClear
                              showSearch
                              filterTreeNode
                              treeNodeFilterProp={'title'}
                              treeCheckable={true}
                              showCheckedStrategy={SHOW_ALL}
                              treeCheckStrictly={true}
                              placeholder={
                                '-- Chọn cơ quan văn phòng, ban chuyên môn nghiệp vụ --'
                              }
                            />
                          )}
                        </Form.Item>
                        <Radio value="cqvp_dvpt">
                          <label style={{ marginRight: '20px' }}>
                            -Cơ quan văn phòng ĐVPT :
                          </label>
                        </Radio>
                        <Form.Item
                          name="don_vi_thuc_hien_xu_ly_rui_ro_khac"
                          style={{ width: '100%', margin: '10px 0 10px 25px' }}>
                          {selectedCqvp === 'cqvp_dvpt' && (
                            <SelectPeopleWrapper>
                              <SelectUserButton
                                tooltipTitle="Chọn người"
                                style={{ marginTop: 16 }}
                                title="Cơ quan văn phòng ĐVPT:"
                                handleOnClick={() => {
                                  handleVisibleSelectOneUserPopup()
                                  handleOpenSelectUserDepartmentPopup(
                                    ASSIGNEE_TYPE.cqvp_I
                                  )
                                }}
                              />
                              <SelectListComponent
                                userList={selectUserList.cqvp_I}
                              />
                            </SelectPeopleWrapper>
                          )}
                        </Form.Item>
                      </Radio.Group>
                    </Form.Item> */}
                  </div>
                )}
              </>
            )}
          </div>
          {type !== 'CQVP' && type !== 'DVPT' ? (
            <>NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG:</>
          ) : (
            <>
              LÃNH ĐẠO {systemName} (theo phân công trách nhiệm quản lý rủi ro):
            </>
          )}
          <Form.Item name={'nguoi_dai_dien_phan_von_phu_trach_chung__signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_nddpv}
                disabled={disabled_nddpv}
                onChange={e => onChangeCheckedNddpv(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          {type !== 'CQVP' && type !== 'DVPT' ? (
            <Form.Item name={'nguoi_dai_dien_phan_von_phu_trach_chung'}>
              <TreeSelect
                treeData={treeData_BP}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'title'}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                treeCheckStrictly={true}
                onChange={e => toggleDisableNddpv(e)}
                placeholder={'Chọn người đại diện phần vốn phụ trách chung'}
              />
            </Form.Item>
          ) : (
            <SelectPeopleWrapper>
              <SelectUserButton
                tooltipTitle="Chọn người"
                style={{ marginTop: 16 }}
                // title="LÃNH ĐẠO VIMC (theo phân công trách nhiệm quản lý rủi ro):"
                handleOnClick={() => {
                  handleSelectUser(ASSIGNEE_TYPE.lanh_dao_vimc)
                  // handleVisibleSelectOneUserPopup()
                  // handleOpenSelectUserDepartmentPopup(
                  //   ASSIGNEE_TYPE.lanh_dao_vimc
                  // )
                }}
              />
              <SelectListComponentRisk
                userList={selectUserList.lanh_dao_vimc}
              />
            </SelectPeopleWrapper>
          )}
          <FormActionFooter style={{ marginBottom: 0 }}>
            <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
              Đóng
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Lưu thông tin
            </Button>
          </FormActionFooter>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => setIsVisibleRuiRoNhanDienTemplate(false)}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
        multiple={true}
        dataActions={dataActions?.tier1[0]}
      />
      <RuiRoNhanDienTemplatePropose
        isVisible={isVisibleRuiRoNhanDienTemplates}
        handleCancelModal={() => setIsVisibleRuiRoNhanDienTemplates(false)}
        riskStore={riskStore}
        setRuiRoNhanDienPropose={setRuiRoNhanDienPropose}
        multiple={true}
        dataActions={dataActions?.tier1[0]}
      />
      <SelectPeoplePopup
        disableSelectDepartment
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={handleCancelSelectUserDepartment}
        isVisibleSelectPeoplePopup={
          isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
        }
        isUserDisableList={false}
      />
    </>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore',
  'departmentStore'
)(observer(RiskIdentificationCreatePopup))
