import {
  EditOutlined,
  HistoryOutlined,
  MoreOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Menu, message } from 'antd'

import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'
import { blue } from '../../color'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import RiskSteps from '../../components/RiskSteps'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'

import queryString from 'query-string'
import { ASSIGNEE_TYPE } from '../../constants'
import useOpenItem from '../../hooks/useOpenItem'
import { Container } from '../../layouts/Container/Container'
import DashboardLayout from '../../layouts/DashboardLayout'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import utils from '../../utils'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import ListHistoryLogModal from '../ListHistoryLog/ListHistoryLogModal'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import { RiskDetail } from './RiskDetailPageStyled'
import RiskPdf2 from './RiskPdf2'
import RiskUpdatePopup from './RiskUpdatePopup'
import { useUpdatingRiskExecutor } from './hooks/useUpdatingRiskExecutor'
import { useUpdatingUnitLeader } from './hooks/useUpdatingUnitLeader'
import { systemName } from '../../config'

const RiskDetailPage = props => {
  const {
    riskStore,
    riskUnitUserStore,
    selectPeopleStore,
    authenticationStore,
    actionStore,
  } = props
  const { riskDetail, updateApproval } = riskStore
  const { dataActions } = actionStore
  const { currentUser } = authenticationStore
  const {
    selectUserData,
    setSelectUserData,
    originDepartmentWithUserList,
    clearSelectPeopleStore,
    assigneePopupType,
  } = selectPeopleStore
  const { riskId } = useParams()
  const [selectUserList, setSelectUserList] = useState({
    unitLeader: [],
    VIMCLeader: [],
    KT_KTNB: [],
    HDQT: [],
    USER: [],
    lanh_dao_vimc: [],
    ban_kt_ktnb: [],
    ban_pc_qtrr_ban_kt_ktnb_vimc: [],
    ban_dieu_hanh_hdqt_vimc: [],
    tgd_ptgd: [],
    ban_kt_ktnb_vimc: [],
    hdqt_vimc: [],
    ban_dieu_hanh: [],
    ban_pc_qtrr_vimc: [],
    ban_cmnv_vimc: [],
    lanh_dao_ban: [],
    don_vi_thuc_hien_xu_ly_rui_ro_3: [],
    cqvp_I: [],
    chuyen_vien_thuc_hien_cqvp_tuyen1: [],
  })
  const [prevSelectUserList, setPrevSelectUserList] = useState({
    unitLeader: [],
    VIMCLeader: [],
    KT_KTNB: [],
    HDQT: [],
    don_vi_thuc_hien_xu_ly_rui_ro_3: [],
    cqvp_I: [],
    chuyen_vien_thuc_hien_cqvp_tuyen1: [],
  })
  const [current, setCurrent] = useState(0)
  const [approvalUser, setApprovalUser] = useState({})
  const [documentHistory, setDocumentHistory] = useState({})
  const history = useHistory()
  const {
    isOpen: isLoading,
    handleOpen: visibleLoading,
    handleClose: cancelLoading,
  } = useOpenItem()
  const {
    isOpen: isVisibleRiskUpdatePopup,
    handleOpen: handleVisibleRiskUpdatePopup,
    handleClose: handleCloseRiskUpdatePopup,
  } = useOpenItem()
  const {
    isOpen: isVisibleHistory,
    handleOpen: handleVisibleHistory,
    handleClose: handleCloseHistory,
  } = useOpenItem()

  const onChangeSteps = value => {
    setCurrent(value)
  }
  const queryStringParse = queryString.parse(location.search)
  const [type, setType] = useState(queryStringParse.type)

  const convertSelectUser = (userList, type) => {
    return userList?.map(user => ({
      full_name: user.full_name,
      permission: type,
      assignee_type: type,
      id: user.username,
      image_id: user.image_id,
      status: user.status,
      code: user.code,
      derpartment_name: user.department_name,
    }))
  }

  useEffect(() => {
    if (!riskDetail?.tiers) return
    actionStore.setDataActions({
      tier1: riskDetail?.tiers[0]?.actions?.map(action =>
        JSON.parse(action?.data)
      ),
      tier2: riskDetail?.tiers[1]?.actions?.map(action =>
        JSON.parse(action?.data)
      ),
      tier3: riskDetail?.tiers[2]?.actions?.map(action =>
        JSON.parse(action?.data)
      ),
      tier4: riskDetail?.tiers[3]?.actions?.map(action =>
        JSON.parse(action?.data)
      ),
    })
  }, [riskDetail])

  useEffect(() => {
    if (!riskDetail?.tiers) return
    const unitLeader = riskDetail?.tiers[0].approvals[1].peoples.map(item => {
      return {
        ...item,
        ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
      }
    })
    const VIMCLeader = riskDetail?.tiers[1].approvals[1].peoples.map(item => {
      return {
        ...item,
        ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
      }
    })
    const KT_KTNB = riskDetail?.tiers[2].approvals[1].peoples.map(item => {
      return {
        ...item,
        ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
      }
    })
    const HDQT = riskDetail?.tiers[2].approvals[2].peoples.map(item => {
      return {
        ...item,
        ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
      }
    })
    const don_vi_thuc_hien_xu_ly_rui_ro_3 = riskDetail?.tiers[2].approvals[0].peoples.map(
      item => {
        return {
          ...item,
          ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
        }
      }
    )
    const chuyen_vien_thuc_hien_cqvp_tuyen1 = riskDetail?.tiers[0].approvals[0].peoples.map(
      item => {
        return {
          ...item,
          ...utils.getInfoUser(item?.username, originDepartmentWithUserList),
        }
      }
    )

    setPrevSelectUserList({
      unitLeader: unitLeader,
      VIMCLeader: VIMCLeader,
      KT_KTNB: KT_KTNB,
      HDQT: HDQT,
      don_vi_thuc_hien_xu_ly_rui_ro_3: don_vi_thuc_hien_xu_ly_rui_ro_3,
      chuyen_vien_thuc_hien_cqvp_tuyen1: chuyen_vien_thuc_hien_cqvp_tuyen1,
    })

    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.UNIT_LEADER]: unitLeader
        ? convertSelectUser(unitLeader, ASSIGNEE_TYPE.UNIT_LEADER)
        : [],
      [ASSIGNEE_TYPE.LEADER]: VIMCLeader
        ? convertSelectUser(VIMCLeader, ASSIGNEE_TYPE.LEADER)
        : [],
      [ASSIGNEE_TYPE.KT_KTNB]: KT_KTNB
        ? convertSelectUser(KT_KTNB, ASSIGNEE_TYPE.KT_KTNB)
        : [],
      [ASSIGNEE_TYPE.HDQT]: HDQT
        ? convertSelectUser(HDQT, ASSIGNEE_TYPE.HDQT)
        : [],
      [ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3]: don_vi_thuc_hien_xu_ly_rui_ro_3
        ? convertSelectUser(
            don_vi_thuc_hien_xu_ly_rui_ro_3,
            ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3
          )
        : [],
      [ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1]: chuyen_vien_thuc_hien_cqvp_tuyen1
        ? convertSelectUser(
            chuyen_vien_thuc_hien_cqvp_tuyen1,
            ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1
          )
        : [],
    })
  }, [riskDetail, originDepartmentWithUserList])

  useEffect(() => {
    setSelectUserList({
      ...selectUserList,
      ban_pc_qtrr_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_pc_qtrr_vimc]],

      unitLeader: [...selectUserData[ASSIGNEE_TYPE.UNIT_LEADER]],
      VIMCLeader: [...selectUserData[ASSIGNEE_TYPE.LEADER]],
      KT_KTNB: [...selectUserData[ASSIGNEE_TYPE.KT_KTNB]],
      HDQT: [...selectUserData[ASSIGNEE_TYPE.HDQT]],
      USER: [...selectUserData[ASSIGNEE_TYPE.USER]],
      lanh_dao_vimc: [...selectUserData[ASSIGNEE_TYPE.lanh_dao_vimc]],
      ban_kt_ktnb: [...selectUserData[ASSIGNEE_TYPE.ban_kt_ktnb]],
      ban_pc_qtrr_ban_kt_ktnb_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc],
      ],
      ban_dieu_hanh_hdqt_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc],
      ],
      lanh_dao_ban: [...selectUserData[ASSIGNEE_TYPE.lanh_dao_ban]],
      tgd_ptgd: [...selectUserData[ASSIGNEE_TYPE.tgd_ptgd]],
      ban_kt_ktnb_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_kt_ktnb_vimc]],
      hdqt_vimc: [...selectUserData[ASSIGNEE_TYPE.hdqt_vimc]],
      ban_dieu_hanh: [...selectUserData[ASSIGNEE_TYPE.ban_dieu_hanh]],
      don_vi_thuc_hien_xu_ly_rui_ro_3: [
        ...selectUserData[ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3],
      ],
      chuyen_vien_thuc_hien_cqvp_tuyen1: [
        ...selectUserData[ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1],
      ],
      // ban_cmnv_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_cmnv_vimc]],
    })
  }, [selectUserData])

  const getRiskDetail = () => {
    visibleLoading()
    ;(async () => {
      try {
        await riskStore.getRiskDetail(riskId)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        cancelLoading()
      }
    })()
  }

  useEffect(() => {
    getRiskDetail()
    return () => {
      riskStore.clearRiskStore()
      actionStore.clearActionStore()
      clearSelectPeopleStore()
    }
  }, [])

  useEffect(() => {
    if (!selectUserList) return
    setApprovalUser(
      current === 0
        ? selectUserList.unitLeader.find(
            user => user.id === currentUser?.username
          )
        : current === 1
        ? selectUserList.VIMCLeader.find(
            user => user.id === currentUser?.username
          )
        : selectUserList.KT_KTNB.find(
            user => user.id === currentUser?.username
          ) ||
          selectUserList.HDQT.find(user => user.id === currentUser?.username)
    )
  }, [current, selectUserList])

  const handleDelete = () => {
    ;(async () => {
      const queryParams = {
        page: riskStore.filterPage,
        size: riskStore.filterSize,
        type: riskStore.type,
      }
      const queryStringStringify = queryString.stringify(queryParams)
      try {
        await riskStore.deleteRisk(riskId)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        message.success('Xóa thành công!')
        history.goBack()
        // history.push(`/risk?${queryStringStringify}`)
      }
    })()
  }

  const menuHandleDocument = (
    <Menu>
      <Menu.Item
        style={{ color: blue }}
        onClick={handleVisibleRiskUpdatePopup}
        icon={<EditOutlined />}>
        Chỉnh sửa
      </Menu.Item>
      <Menu.Item
        style={{ color: blue }}
        onClick={() => {
          setDocumentHistory({
            code: riskId,
          })
          handleVisibleHistory()
        }}
        icon={<HistoryOutlined />}>
        Lịch sử
      </Menu.Item>
      <Menu.Item
        key="print"
        icon={<PrinterOutlined style={{ color: blue }} />}
        onClick={() => {
          utils.exportPDF('riskDivToPrint', riskDetail.title, 'TimesNewRoman')
        }}>
        <span style={{ color: blue }}>In</span>
      </Menu.Item>
      {/* 
      tạm thời comment
      <Menu.Item
        danger
        onClick={() => {
          utils.confirmDelete(handleDelete)
        }}
        icon={<DeleteOutlined />}>
        Xóa
      </Menu.Item> */}
    </Menu>
  )

  const handleTierApproval = async status => {
    if (approvalUser?.status === status)
      return status === 'APPROVED'
        ? message.info('Đã duyệt')
        : message.info('Đã từ chối')
    visibleLoading()
    const data = {
      approve_test_user: approvalUser?.id,
      status,
    }
    await updateApproval(approvalUser?.code, data)
    await riskStore.getRiskDetail(riskId)
    cancelLoading()
    message.success('Cập nhật thành công')
  }

  const filterRemoveItems = (prevList, newList) => {
    return prevList
      ?.filter(
        prevUser =>
          !newList?.some(newUser => prevUser?.username === newUser?.id)
      )
      .map(user => ({
        assignee_code: user?.username,
        department_code: user?.department_code,
      }))
  }

  const filterNewItems = (prevList, newList) => {
    return newList
      ?.filter(
        newUser =>
          !prevList?.some(prevUser => prevUser?.username === newUser?.id)
      )
      .map(user => ({
        assignee_code: user.id,
        department_code: user?.department_code,
      }))
  }

  const updateTierApproval = async (type = '') => {
    visibleLoading()
    let remove_items = []
    let new_items = []
    let risk_tier_code = ''
    let remove_all = []

    if (assigneePopupType === ASSIGNEE_TYPE.UNIT_LEADER) {
      remove_items = filterRemoveItems(
        prevSelectUserList.unitLeader,
        selectUserList.unitLeader
      )
      new_items = filterNewItems(
        prevSelectUserList.unitLeader,
        selectUserList.unitLeader
      )
      remove_all = prevSelectUserList.unitLeader.map(user => ({
        assignee_code: user?.username,
      }))
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[0].approvals[1].code
    } else if (assigneePopupType === ASSIGNEE_TYPE.LEADER) {
      remove_items = filterRemoveItems(
        prevSelectUserList.VIMCLeader,
        selectUserList.VIMCLeader
      )
      new_items = filterNewItems(
        prevSelectUserList.VIMCLeader,
        selectUserList.VIMCLeader
      )
      remove_all = prevSelectUserList.VIMCLeader.map(user => ({
        assignee_code: user?.username,
      }))
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[1].approvals[1].code
    } else if (
      assigneePopupType === ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1
    ) {
      remove_items = filterRemoveItems(
        prevSelectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1,
        selectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1
      )
      new_items = filterNewItems(
        prevSelectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1,
        selectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1
      )
      remove_all = prevSelectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1.map(
        user => ({
          assignee_code: user?.username,
        })
      )
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[0].approvals[0].code
    } else if (
      assigneePopupType === ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3
    ) {
      remove_items = filterRemoveItems(
        prevSelectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3,
        selectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3
      )
      new_items = filterNewItems(
        prevSelectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3,
        selectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3
      )
      remove_all = prevSelectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3.map(
        user => ({
          assignee_code: user?.username,
        })
      )
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[2].approvals[0].code
    } else if (assigneePopupType === ASSIGNEE_TYPE.KT_KTNB) {
      remove_items = filterRemoveItems(
        prevSelectUserList.KT_KTNB,
        selectUserList.KT_KTNB
      )
      new_items = filterNewItems(
        prevSelectUserList.KT_KTNB,
        selectUserList.KT_KTNB
      )
      remove_all = prevSelectUserList.KT_KTNB.map(user => ({
        assignee_code: user?.username,
      }))
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[2].approvals[1].code
    } else if (assigneePopupType === ASSIGNEE_TYPE.HDQT) {
      remove_items = filterRemoveItems(
        prevSelectUserList.HDQT,
        selectUserList.HDQT
      )
      new_items = filterNewItems(prevSelectUserList.HDQT, selectUserList.HDQT)
      remove_all = prevSelectUserList.HDQT.map(user => ({
        assignee_code: user?.username,
      }))
      risk_tier_code =
        riskDetail?.tiers && riskDetail?.tiers[2].approvals[2].code
    }
    type === 'empty'
      ? await riskStore.updateTierApproval(risk_tier_code, {
          approval_people: {
            remove_items: remove_all,
            new_items,
          },
        })
      : await riskStore.updateTierApproval(risk_tier_code, {
          approval_people: {
            remove_items,
            new_items,
          },
        })
    await riskStore.getRiskDetail(riskId)
    cancelLoading()
    message.success('Cập nhật thành công')
  }

  const {
    updateTier1RiskExecutor,
    updateTier2RiskExecutor,
    updateTier3RiskExecutor,
  } = useUpdatingRiskExecutor({
    riskStore,
    riskUnitUserStore,
    loadingAnimationStore,
    type,
    riskId,
  })
  const { updateTier1UnitLeader } = useUpdatingUnitLeader({
    loadingAnimationStore,
    riskId,
    riskStore,
    riskUnitUserStore,
    type,
  })

  const onUpdateNDDPVgeneral = async () => {
    if (riskUnitUserStore.selectedNDDPVgeneral.tier2.length === 0) {
      return message.error('Vui lòng chọn người thực hiện')
    }

    const {
      addingItems,
      removingItems,
    } = getAddingAndRemovingTier2NDDPVgeneral()
    const riskTierCode =
      riskStore.riskDetail?.tiers[1]?.approvals[1]?.peoples[0]
        ?.risk_tier_approval_code ||
      riskStore.riskDetail?.tiers[1]?.approvals[1]?.code
    loadingAnimationStore.showSpinner(true)
    try {
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(`Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const onUpdateTier1NDDPVgeneral = async () => {
    if (riskUnitUserStore.selectedTier1NDDPVgeneral.tier1.length === 0) {
      return message.error('Vui lòng chọn người thực hiện')
    }

    const {
      addingItems,
      removingItems,
    } = getAddingAndRemovingTier1NDDPVgeneral()
    const riskTierCode =
      riskStore.riskDetail?.tiers[0]?.approvals[1]?.peoples[0]
        ?.risk_tier_approval_code ||
      riskStore.riskDetail?.tiers[0]?.approvals[1]?.code
    loadingAnimationStore.showSpinner(true)
    try {
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(`Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onUpdateTier2DVPTprofessional = async () => {
    if (riskUnitUserStore.selectedDVPTprofessional.tier2.length === 0) {
      return message.error('Vui lòng chọn người thực hiện')
    }

    const {
      addingItems,
      removingItems,
    } = getAddingAndRemovingTier2DVPTprofessional()
    const riskTierCode =
      riskStore.riskDetail?.tiers[1]?.approvals[1]?.peoples[0]
        ?.risk_tier_approval_code ||
      riskStore.riskDetail?.tiers[1]?.approvals[1]?.code
    loadingAnimationStore.showSpinner(true)
    try {
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(`Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const getAddingAndRemovingTier2NDDPVgeneral = () => {
    const newSelectedItems = riskUnitUserStore.selectedNDDPVgeneral.tier2
    const allOldItems = riskStore.riskDetail?.tiers[1]?.approvals[1]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems.filter(
      item =>
        !oldSelectedItems.some(oldItem => oldItem.username === item.username)
    )
    const removingItems = oldSelectedItems.filter(
      item =>
        !newSelectedItems.some(newItem => newItem.username === item.username)
    )

    return {
      addingItems: addingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
      removingItems: removingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
    }
  }
  const getAddingAndRemovingTier1NDDPVgeneral = () => {
    const newSelectedItems = riskUnitUserStore.selectedTier1NDDPVgeneral.tier1
    const allOldItems = riskStore.riskDetail?.tiers[0]?.approvals[1]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems.filter(
      item =>
        !oldSelectedItems.some(oldItem => oldItem.username === item.username)
    )
    const removingItems = oldSelectedItems.filter(
      item =>
        !newSelectedItems.some(newItem => newItem.username === item.username)
    )

    return {
      addingItems: addingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
      removingItems: removingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
    }
  }

  const getAddingAndRemovingTier2DVPTprofessional = () => {
    const newSelectedItems = riskUnitUserStore.selectedDVPTprofessional.tier2
    const allOldItems = riskStore.riskDetail?.tiers[1]?.approvals[1]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems.filter(
      item =>
        !oldSelectedItems.some(oldItem => oldItem.username === item.username)
    )
    const removingItems = oldSelectedItems.filter(
      item =>
        !newSelectedItems.some(newItem => newItem.username === item.username)
    )

    return {
      addingItems: addingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
      removingItems: removingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
    }
  }

  return (
    <RiskDetail>
      <DashboardLayout>
        <Helmet>
          <title>
            Chi tiết phiếu rủi ro | {systemName} chi tiết phiếu rủi ro
          </title>
        </Helmet>
        <PageTitle
          showTitle={true}
          location={props.location}
          title={riskDetail.title}>
          <HeadingButtonWrapper>
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </PageTitle>

        <div style={{ marginBottom: 15 }} />

        <ContentBlockWrapper style={{ position: 'relative' }}>
          <Container maxWidth={1000}>
            <RiskSteps
              current={current}
              type={type}
              setType={setType}
              onChangeSteps={onChangeSteps}
              createUser={
                riskDetail?.tiers
                  ? utils.getInfoUser(
                      riskDetail?.tiers[0].approvals[0].peoples[0]?.username,
                      originDepartmentWithUserList
                    )
                  : ''
              }
              selectUserList={selectUserList}
              updateTierApproval={updateTierApproval}
              getRiskDetail={getRiskDetail}
              riskDetail={riskDetail}
              onUpdateTier1RiskExecutor={updateTier1RiskExecutor}
              onUpdateTier1UnitLeader={updateTier1UnitLeader}
              onUpdateTier2RiskExecutor={updateTier2RiskExecutor}
              onUpdateTier3RiskExecutor={updateTier3RiskExecutor}
              onUpdateNDDPVgeneral={onUpdateNDDPVgeneral}
              onUpdateTier2DVPTprofessional={onUpdateTier2DVPTprofessional}
              onUpdateTier1NDDPVgeneral={onUpdateTier1NDDPVgeneral}
            />
            <div style={{ textAlign: 'left' }}>
              <FormActionFooter>
                <Button
                  disabled={!approvalUser}
                  onClick={() => handleTierApproval('REJECTED')}>
                  Từ chối
                </Button>
                <Button
                  disabled={!approvalUser}
                  style={{ marginLeft: 10 }}
                  type={'primary'}
                  htmlType={'submit'}
                  onClick={() => handleTierApproval('APPROVED')}>
                  Duyệt
                </Button>
              </FormActionFooter>
            </div>
          </Container>
          <SpinnerInlineComponent
            sizeSpin={'small'}
            tip="Đang tải dữ liệu..."
            isLoading={isLoading}
            backGround={'#ffffffc7'}
          />
        </ContentBlockWrapper>
      </DashboardLayout>
      <RiskUpdatePopup
        type={type}
        handleCancel={handleCloseRiskUpdatePopup}
        isVisible={isVisibleRiskUpdatePopup}
        riskStore={riskStore}
        getRiskDetail={getRiskDetail}
      />
      <ListHistoryLogModal
        riskDetail={riskDetail}
        type={'RISK'}
        isVisible={isVisibleHistory}
        taskInfo={documentHistory}
        handleCloseHistory={handleCloseHistory}
      />
      {/* use this to display pdf */}
      {/* <div style={{ display: 'block' }}> */}
      <div style={{ display: 'none' }}>
        <RiskPdf2
          type={type}
          riskDetail={riskDetail}
          dataActions={dataActions}
          createUser={
            riskDetail?.tiers
              ? utils.getInfoUser(
                  riskDetail?.tiers[0].approvals[0].peoples[0]?.username,
                  originDepartmentWithUserList
                )
              : ''
          }
          selectUserList={selectUserList}
        />
      </div>
    </RiskDetail>
  )
}

export default inject(
  'riskStore',
  'riskUnitUserStore',
  'selectPeopleStore',
  'authenticationStore',
  'loadingAnimationStore',
  'actionStore'
)(observer(RiskDetailPage))
