import React from 'react'
import { Helmet } from 'react-helmet'
import AdministrativeManagementLayout from './AdministrativeManagementLayout'
import ProposalTypePage from '../../ProposalTypePage/ProposalTypePage'

const AdministrativeManagementProposalType = () => {
  return (
    <AdministrativeManagementLayout selectedKey="proposal-type">
      <Helmet>
        <title>Loại hành chính | Quản trị | Hành chính</title>
      </Helmet>
      <ProposalTypePage isAdministrativeManagementTab />
    </AdministrativeManagementLayout>
  )
}

export default AdministrativeManagementProposalType
