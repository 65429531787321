import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Modal, Row, Space } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import commonStore from '../../stores/commonStore'

const CompanyCreateModal = ({
  showCreateModal,
  handleCancelCreateModal,
  companyStore,
  loadCompanyList,
  handleCancelEditModal,
  company,
  setEditCompany,
}) => {
  const { createCompany, updateCompany } = companyStore
  const [form] = Form.useForm()
  const [isAdministrators, setIsAdministrators] = useState(false)

  useEffect(() => {
    if (!company) {
      return
    }
    form.setFieldsValue({
      name: company.name,
    })
  }, [company])

  const handleCreateCompany = async value => {
    const editData = {
      name: value.name,
    }
    const createDataHaveAdmin = {
      create_default_acl_group: value.acl,
      name: value.name,
      mAdmin: {
        username: value.username,
        password: value.password,
        name: value.fullName,
      },
    }
    const createData = {
      create_default_acl_group: value.acl,
      name: value.name,
    }
    try {
      company
        ? await updateCompany(company.code, editData)
        : await createCompany(
            isAdministrators ? createDataHaveAdmin : createData
          )
      await loadCompanyList()
      company
        ? message.success('Cập nhật thành công')
        : message.success('Tạo mới thành công')
      handleCancelCreateModal()
      setEditCompany(null)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const handleCreateAdministrators = value => {
    setIsAdministrators(value.target.checked)
  }
  console.log('isAdministrators', isAdministrators)
  return (
    <Modal
      visible={showCreateModal}
      footer={null}
      destroyOnClose={true}
      width={600}
      afterClose={() => {
        setIsAdministrators(false)
      }}
      onCancel={company ? handleCancelEditModal : handleCancelCreateModal}
      title={<>{company ? 'Cập nhật công ty' : 'Tạo mới công ty'}</>}>
      <Form
        layout={'vertical'}
        onFinish={handleCreateCompany}
        preserve={false}
        form={form}>
        <Form.Item
          label={'Tên công ty'}
          name={'name'}
          rules={[{ required: true, message: 'Vui lòng nhập !' }]}>
          <Input placeholder={'Nhập tên công ty'} />
        </Form.Item>
        {!company && (
          <>
            <Form.Item name="acl" valuePropName="checked" initialValue={false}>
              <Checkbox>Phân quyền</Checkbox>
            </Form.Item>
            <Form.Item name={'name'}>
              <Checkbox
                checked={isAdministrators}
                onChange={handleCreateAdministrators}>
                Quản trị viên
              </Checkbox>
            </Form.Item>
          </>
        )}
        {isAdministrators && (
          <>
            <Form.Item
              label={'Họ tên'}
              name={'fullName'}
              rules={[{ required: true, message: 'Vui lòng nhập !' }]}>
              <Input placeholder={'Nhập họ tên quản trị viên'} />
            </Form.Item>
            <Form.Item
              label={'Tên đăng nhập'}
              name={'username'}
              rules={[{ required: true, message: 'Vui lòng nhập !' }]}>
              <Input placeholder={'Nhập tên đăng nhập'} />
            </Form.Item>
            <Form.Item
              label={'Mật khẩu'}
              name={'password'}
              rules={[{ required: true, message: 'Vui lòng nhập !' }]}>
              <Input
                type="password"
                placeholder={'Nhập mật khẩu quản trị viên'}
              />
            </Form.Item>
          </>
        )}

        <Row justify={'end'}>
          <Space>
            <Button
              onClick={
                company ? handleCancelEditModal : handleCancelCreateModal
              }>
              Hủy
            </Button>
            <Button type={'primary'} htmlType={'submit'}>
              {company ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default CompanyCreateModal
