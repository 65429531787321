import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Components
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { TitleContentBox } from '../../components/Common/CellText'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout'
// Styled Components
import {
  BlockLeft,
  BlockRight,
  HeadingButtonWrapper,
  TaskContentWrapper,
  TaskInfoList,
} from './MissionDetailPageStyled'
// Ant design
import { Dropdown, Menu, message, Modal } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  NodeExpandOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  MISSION_STATUS,
  TYPE_STATUS,
} from '../../constants'
import { blue } from '../../color'
import { StatusTag } from '../../components/Common/StatusTag'
import utils from '../../utils'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import MissionDetailUpdate from './MissionDetailUpdate'
import MissionDetailFiles from './MissionDetailFiles'
import MissionDetailWorks from './MissionDetailWorks'
import MissionDetailAssign from './MissionDetailAssign'
import MissionDetailGeneralInfo from './MissionDetailGeneralInfo'
import moment from 'moment'
import { systemName } from '../../config'

const { confirm } = Modal

const MissionDetailPage = props => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const queryStringStringify = queryString.stringify(queryStringParse, {
    skipNull: true,
  })

  const { taskStore, history, match, loadingAnimationStore, aclStore } = props
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore

  const { missionId } = match.params

  const { taskDetail } = taskStore

  const contentTask = taskDetail.contentTask
  const resultTask = contentTask?.ket_qua || {}
  const leader = toJS(contentTask)?.assignees.filter(
    el => el.permission === ASSIGNEE_TYPE.LEADER
  )
  const handler = toJS(contentTask)?.assignees.filter(
    el => el.permission === ASSIGNEE_TYPE.HANDLER
  )
  const combiner = toJS(contentTask)?.assignees.filter(
    el => el.permission === ASSIGNEE_TYPE.COMBINER
  )

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await taskStore.getTaskDetail(missionId)
      } catch (err) {
        history.push(`/mission?${queryStringStringify}`)
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskDetail()
    }
  }, [missionId])

  const onClickDelete = async taskID => {
    loadingAnimationStore.showSpinner(true)
    try {
      await taskStore.deleteTask(taskID)
      history.push(`/mission?${queryStringStringify}`)
      return message.success('Xóa nhiệm vụ thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const deleteConfirm = id => {
    return confirm({
      title: 'Bạn có muốn xóa nhiệm vụ này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk: async () => {
        await onClickDelete(id)
      },
      onCancel() {},
    })
  }

  const nextMonthConfirm = () => {
    return confirm({
      title: 'Bạn có muốn chuyển nhiệm vụ này sang tháng sau không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      onOk: async () => {
        await handleNextMonth()
      },
      onCancel() {},
    })
  }

  const handleNextMonth = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const payload = {
        display_date:
          taskDetail.contentTask?.display_date &&
          moment(taskDetail.contentTask?.display_date).add(1, 'months'),
        task_codes: [missionId],
      }
      await taskStore.moveTask(payload)
      message.success('Đã chuyển nhiệm vụ này sang tháng sau!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const menuHandleDocument = (
    <Menu>
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__MISSION) && (
        <Menu.Item
          onClick={() => history.push(`/mission/edit/${missionId}`)}
          icon={<EditOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Chỉnh sửa nhiệm vụ</span>
        </Menu.Item>
      )}
      {taskDetail.contentTask?.ket_qua?.phan_tram_hoan_thanh + '' !== '100' &&
        aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.chuyen_sang_thang__MISSION
        ) && (
          <Menu.Item
            onClick={nextMonthConfirm}
            icon={<NodeExpandOutlined style={{ color: blue }} />}>
            <span style={{ color: blue }}>Chuyển nhiệm vụ sang tháng sau</span>
          </Menu.Item>
        )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__MISSION) && (
        <Menu.Item
          danger
          onClick={() => deleteConfirm(missionId)}
          icon={<DeleteOutlined />}>
          Xóa nhiệm vụ
        </Menu.Item>
      )}
    </Menu>
  )

  const statusTask = rate_complete => {
    if (rate_complete === '0' || !rate_complete) return MISSION_STATUS.PENDING
    if (rate_complete === '100' || !rate_complete)
      return MISSION_STATUS.COMPLETE
    return MISSION_STATUS.INPROGRESS
  }

  const titleMissionDetail =
    leader && leader.length !== 0
      ? `Nhiệm vụ của ${utils.getNameInCapitalize(leader[0].name_uppercase)}`
      : 'Chi tiết nhiệm vụ'

  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhiệm vụ | {systemName} Nhiệm vụ</title>
      </Helmet>
      <PageTitle
        hiddenGoBack
        routerGoBack={`/mission?${queryStringStringify}`}
        location={props.location}
        showTitle={true}
        title={titleMissionDetail}
        status={StatusTag(
          TYPE_STATUS.MISSION,
          statusTask(resultTask.phan_tram_hoan_thanh)
        )}>
        <HeadingButtonWrapper>
          <Dropdown overlay={menuHandleDocument} trigger={['click']}>
            <span className={'btn-style'}>
              <MoreOutlined style={{ color: blue }} />
            </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      <TaskContentWrapper>
        <BlockLeft>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin</TitleContentBox>
            <TaskInfoList>
              <MissionDetailGeneralInfo contentTask={contentTask} />
              <MissionDetailUpdate
                canUpdateProgress={!!taskDetail.canUpdateProgress}
                missionId={missionId}
                files={resultTask.files || []}
                resultTask={resultTask}
                contentTask={contentTask}
              />
            </TaskInfoList>
          </ContentBlockWrapper>
        </BlockLeft>
        <BlockRight>
          <MissionDetailAssign
            leader={leader}
            handler={handler}
            combiner={combiner}
            capGiao={contentTask?.cap_giao}
          />
          <MissionDetailFiles
            canUpdateProgress={!!taskDetail.canUpdateProgress}
            missionId={missionId}
            files={resultTask.files}
            resultTask={resultTask}
          />
          <MissionDetailWorks
            canAddWork={!!taskDetail.canAddWork}
            missionId={missionId}
            works={contentTask?.linkedWork}
          />
        </BlockRight>
      </TaskContentWrapper>
    </DashboardLayout>
  )
}

MissionDetailPage.propTypes = {}

export default inject(
  'taskStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'aclStore'
)(observer(MissionDetailPage))
