import { Dropdown, Menu, Modal, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentHeadingWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
  HeadingTitleWrapper,
} from '../../layouts/ConnectedDocumentDetailLayout/ConnectedDocumentDetailLayoutStyled'
// PDF Viewer
import {
  CalendarOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { blue } from '../../color'
import { TRUC_COMMAND } from '../../command_constant'
import {
  EmptyText,
  TitleContentBox,
  WordBreak,
} from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import ConnectedDocumentIncomingDocHistoryBlock from '../../components/ConnectedDocumentIncomingDocHistoryBlock'
import PDFViewer from '../../components/PDFViewer'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import TrucWrapper from '../../components/TrucWrapper/TrucWrapper'
import { systemName } from '../../config'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  OUTGOING_DOCUMENT_STATE,
  OUTGOING_DOCUMENT_STATUS,
} from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import utils from '../../utils'

const { confirm } = Modal

const ConnectedDocumentIncomingDocDetailPage = props => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedDocumentStore,
    loadingAnimationStore,
    authenticationStore,
    aclStore,
    accountStore,
  } = props

  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const { documentId } = match.params

  const { selectedIncomingDocument, actionLogList } = connectedDocumentStore
  const {
    attachments,
    code,
    documentType,
    userFrom,
    priority,
    promulgationDate,
    signer,
    listUserTo,
    view_logs,
    state,
    action,
    subject,
  } = selectedIncomingDocument
  const { accountList } = accountStore

  const [usernameTruc, setUsernameTruc] = useState(null)
  // const [subject] = useState(selectedIncomingDocument.subject || '')

  useEffect(() => {
    const filterAccountTruc = accountList.filter(
      arr => arr.command.code === TRUC_COMMAND && arr.is_active === true
    )
    if (filterAccountTruc) {
      setUsernameTruc(filterAccountTruc?.account_name)
    }
  }, [accountList])

  const [loading, setLoading] = useState(false)

  const [attachmentForView, setAttachmentForView] = useState()

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(
      attachments.find(file =>
        ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
      )
    )
  }, [attachments])
  useEffect(() => {
    if (documentId && authenticationStore?.trucToken) {
      refreshData()
    }
  }, [documentId, authenticationStore?.trucToken])

  const refreshData = async () => {
    if (documentId) {
      try {
        loadingAnimationStore.showSpinner(true)

        const response = await connectedDocumentStore.getIncomingDocumentById(
          documentId
        )
        await connectedDocumentStore.getLogDocument(documentId, 'DOC_OUTGOING')
        const filePDFList = response.data.attachments
          ? response.data.attachments.filter(x =>
              x.name.toLocaleLowerCase().includes('.pdf')
            )
          : []
        if (filePDFList.length === 0) {
          loadingAnimationStore.showSpinner(false)
          return
        }
        await connectedDocumentStore.getIncomingDocumentAttachmentById(
          documentId,
          filePDFList[0].fileId
        )
      } catch (err) {
        props.history.push('/connected-document/incoming-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    } else {
      connectedDocumentStore.clearSelectedIncomingDocument()
      connectedDocumentStore.clearLogDocument()
    }
  }

  useEffect(() => {
    if (documentId && authenticationStore?.trucToken) {
      authenticationStore
        .checkCurrentUserTruc()
        .then(() => Promise.resolve())
        .catch(err => console.log(err))
    }
  }, [authenticationStore?.trucToken])

  const renderPriorityName = priorityId => {
    switch (priorityId) {
      case '0':
      case 0:
        return 'Thường'
      case '1':
      case 1:
        return 'Khẩn'
      case '2':
      case 2:
        return 'Thượng khẩn'
      case '3':
      case 3:
        return 'Hỏa tốc'
      case '4':
      case 4:
        return 'Hỏa tốc hẹn giờ'
    }
  }

  const renderDocumentTypeName = typeId => {
    switch (typeId) {
      case '1':
        return 'Văn bản hành chính'
      case '2':
        return 'Văn bản quy phạm pháp luật'
      default:
        return ''
    }
  }

  const renderAttachmentFile = useMemo(
    () => (
      <PDFViewer
        fileId={attachmentForView?.fileId}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    ),
    [attachmentForView]
  )

  const handleRejectDoc = () => {
    loadingAnimationStore.showSpinner(true)
    let action = OUTGOING_DOCUMENT_STATUS.REJECTED.key
    connectedDocumentStore
      .updateDocumentReceive(documentId, {
        action: action,
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        props.history.goBack()
        message.success('Từ chối văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const handleClickReceiveDocument = () => {
    let action = OUTGOING_DOCUMENT_STATUS.RECEIVED.key
    connectedDocumentStore
      .updateDocumentReceive(documentId, {
        action: action,
      })
      .then(() => {
        refreshData()
      })
  }

  const handleClickProcessDocument = () => {
    connectedDocumentStore.setDataReceiveDocument(selectedIncomingDocument)
    let action = OUTGOING_DOCUMENT_STATUS.PROCESSED.key
    connectedDocumentStore.updateDocumentReceive(documentId, {
      action: action,
    })
    props.history.push('/internal-document/incoming-document/create')
  }

  const showConfirmReject = () => {
    confirm({
      title: 'Bạn có muốn từ chối văn bản này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk() {
        handleRejectDoc()
      },
    })
  }

  const renderButtonHandleDocumentDropDown = (
    <Menu>
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.receive__LIEN_THONG_INCOMING
      ) &&
        action != OUTGOING_DOCUMENT_STATUS.RECEIVED.key &&
        action != OUTGOING_DOCUMENT_STATUS.PROCESSED.key && (
          <Menu.Item
            icon={<ProfileOutlined />}
            onClick={handleClickReceiveDocument}
            style={{ color: blue }}>
            Nhận văn bản
          </Menu.Item>
        )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.receive__LIEN_THONG_INCOMING
      ) && (
        <Menu.Item
          icon={<ProfileOutlined />}
          onClick={handleClickProcessDocument}
          style={{ color: blue }}>
          Xử lý văn bản
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.receive__LIEN_THONG_INCOMING
      ) &&
        !listUserTo?.find(item => item?.userName === usernameTruc)?.status && (
          <Menu.Item
            danger
            icon={<CloseOutlined color="#f56642" />}
            onClick={showConfirmReject}>
            Từ chối
          </Menu.Item>
        )}
    </Menu>
  )

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>{`${subject} | ${systemName} Liên thông văn bản`}</title>
        </Helmet>
        <DocumentHeadingWrapper>
          <HeadingTitleWrapper>
            <PageTitle
              location={props.location}
              title={subject}
              showTitle={true}
              color={commonStore.appTheme.solidColor}
            />
          </HeadingTitleWrapper>
          {state !== OUTGOING_DOCUMENT_STATE.DELETE.key &&
            action !== OUTGOING_DOCUMENT_STATUS.REJECTED.key && (
              <HeadingButtonWrapper>
                <Dropdown
                  overlay={renderButtonHandleDocumentDropDown}
                  trigger={['click']}>
                  <span className={'btn-style'}>
                    <MoreOutlined />
                  </span>
                </Dropdown>
              </HeadingButtonWrapper>
            )}
        </DocumentHeadingWrapper>
        <DocumentContentWrapper>
          <BlockLeft>{renderAttachmentFile}</BlockLeft>

          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Số hiệu</dt>
                <dd>{code && code}</dd>
                <dt>Loại văn bản</dt>
                <dd>
                  {documentType ? (
                    renderDocumentTypeName(documentType)
                  ) : (
                    <EmptyText>Không có loại văn bản.</EmptyText>
                  )}
                </dd>
                <dt>CQ ban hành</dt>
                <dd>{userFrom && WordBreak(userFrom)}</dd>
                <dt>Nơi nhận</dt>
                <dd style={{ marginBottom: 8 }}>
                  {listUserTo && listUserTo.length > 0 ? (
                    <PopoverWatchTime
                      userList={listUserTo}
                      title={'Nơi nhận'}
                    />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
                <dt>Độ quan trọng</dt>
                <dd>
                  {priority ? (
                    renderPriorityName(priority)
                  ) : (
                    <EmptyText>Không có độ quan trọng.</EmptyText>
                  )}
                </dd>
                <dt>Ngày ban hành</dt>
                <dd>
                  {promulgationDate ? (
                    <>
                      <CalendarOutlined
                        style={{ color: blue, marginRight: 4 }}
                      />
                      <span>
                        {moment(promulgationDate).format(DATE_FORMAT_DEFAULT)}
                      </span>
                    </>
                  ) : (
                    <EmptyText>Không rõ ngày ban hành.</EmptyText>
                  )}
                </dd>
                <dt>Người ký</dt>
                <dd>
                  {signer ? signer : <EmptyText>Không có người ký.</EmptyText>}
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {attachments?.length ? (
                      attachments.map(file => (
                        <FileItem
                          key={file.fileId}
                          file_id={file.fileId}
                          file_name={file.name}
                          file_type={utils.getExtensionFile(file.name)}
                        />
                      ))
                    ) : (
                      <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                    )}
                  </div>
                </dd>
                <dt>Đã xem</dt>
                <dd style={{ marginBottom: 8 }}>
                  {view_logs && view_logs.length > 0 ? (
                    <PopoverWatchTime userList={view_logs} title={'Đã xem'} />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>
            {actionLogList.length > 0 && (
              <>
                <div style={{ marginBottom: 15 }} />
                <ContentBlockWrapper>
                  <ConnectedDocumentIncomingDocHistoryBlock
                    history={actionLogList}
                    title={'Lịch sử văn bản đến'}
                  />
                </ContentBlockWrapper>
              </>
            )}
          </BlockRight>
        </DocumentContentWrapper>
      </DashboardLayout>
    </TrucWrapper>
  )
}

ConnectedDocumentIncomingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'connectedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'aclStore',
  'accountStore'
)(observer(ConnectedDocumentIncomingDocDetailPage))
