import React from 'react'
import { InfoList } from './MyProfilePageStyled'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Health = props => {
  const { authenticationStore } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  return (
    <InfoList dtWidth={'160px'}>
      <dt>Tình trạng sức khỏe</dt>
      <dd>{currentUser?.other?.health_status || nullText}</dd>
      <dt>Chiều cao</dt>
      <dd>{currentUser?.other?.height || nullText}</dd>
      <dt>Cân nặng</dt>
      <dd>{currentUser?.other?.weight || nullText}</dd>
      <dt>Nhóm máu</dt>
      <dd>{currentUser?.other?.blood_types || nullText}</dd>
      <dt>Là thương binh hạng</dt>
      <dd>{currentUser?.other?.wounded_soldier_class || nullText}</dd>
      <dt>Là con gia đình chính sách</dt>
      <dd>{currentUser?.other?.child_policy_family || nullText}</dd>
    </InfoList>
  )
}

Health.propTypes = {}

export default inject('authenticationStore')(observer(Health))
