export const dowloaderUtils = {
  /**
   * Tải file khi body của respone là file
   *
   * @param {string} data
   * @param {{ filename: string }} param1
   */
  downloadDocxFromResponse(data, { filename }) {
    const url = URL.createObjectURL(
      new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  },
}
