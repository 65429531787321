import { action, observable } from 'mobx'
import { REPORT_TYPE } from '../constants'
import { ReportRequest } from '../requests/ReportRequest'

class ReportStore {
  /** Report list */
  @observable type = REPORT_TYPE.PERIODICAL_REPORT
  @observable reportList = []
  @observable reportDetail = {}
  @action setType = key => {
    this.type = key
  }
  @action getReportList = params => {
    return new Promise((resolve, reject) => {
      ReportRequest.getReportList(params)
        .then(response => {
          this.reportList = response.data.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action getReportDetail = code => {
    return new Promise((resolve, reject) => {
      ReportRequest.getReportDetail(code)
        .then(response => {
          this.reportDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createReport = payload => {
    return new Promise((resolve, reject) => {
      ReportRequest.createReport(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateReport = (code, payload) => {
    return new Promise((resolve, reject) => {
      ReportRequest.updateReport(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action deleteReport = code => {
    return ReportRequest.deleteReport(code)
  }

  /** Clear store */
  @action clearStore = () => {
    this.reportList.length = 0
  }
}

export default new ReportStore()
