import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import DrawerWork from './DrawerWork'
import PopupWorkForm from './PopupWorkForm'
// Style Component
// Ant design
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import ListWork from './ListWork/ListWork'
import queryString from 'query-string'
import selectPeopleStore from '../../stores/selectPeopleStore'
import { useLocation } from 'react-router-dom'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import { WorkPageWrapper } from './WorkStyledPage'
import useOpenItem from '../../hooks/useOpenItem'
import { systemName } from '../../config'

const WorkProcedurePage = props => {
  const {
    history,
    workStore,
    loadingAnimationStore,
    notificationStore,
    match,
  } = props

  const { queryParams } = workStore

  const [childCode, setChildCode] = useState('')

  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  let { code_work } = match.params
  const {
    isOpen: isVisibleDrawerWork,
    handleOpen: openDrawerWork,
    handleClose: handleCloseDrawerWork,
  } = useOpenItem()
  const {
    isOpen: isVisiblePopupWorkForm,
    handleOpen: handleOpenPopupWorkForm,
    handleClose: handleClosePopupWorkForm,
  } = useOpenItem()

  const handleOpenDrawerWork = childCode => {
    setChildCode(childCode)
    openDrawerWork()
  }

  const fetchDetails = async () => {
    if (code_work) {
      handleOpenDrawerWork()
      loadingAnimationStore.setShowSpinInline(true)
      try {
        await workStore.getWorkDetail(code_work, true)
      } catch (err) {
        history.push('/works-procedure')
        handleCloseDrawerWork()
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    }
  }

  useEffect(() => {
    ;(queryStringParsed.document_code || queryStringParsed.mission_code) &&
      handleOpenPopupWorkForm()
    if (workStore.workList.length === 0) {
      workStore.setQueryParams({
        ...queryParams,
        ...queryStringParsed,
        assignee: queryStringParsed.assignee
          ? queryStringParsed.assignee.join('&')
          : null,
        page: 0,
      })
      ;(async () => {
        try {
          // Ẩn lọc công việc theo tháng theo yêu cầu mới
          await fetchDetails()
        } catch (err) {
          console.log(err)
          message.error(err?.vi || 'Đã có lỗi xảy ra!')
        } finally {
        }
      })()
    }

    return () => {
      selectPeopleStore.clearSelectPeopleStore()
      workStore.clearWorkStore()
      notificationStore.clearNotificationType()
    }
  }, [code_work])

  const onClosesDrawerWork = () => {
    history.replace('/works-procedure')
    handleCloseDrawerWork()
  }

  return (
    <WorkPageWrapper isVisibleDrawerWork={isVisibleDrawerWork}>
      <DashboardLayout>
        <Helmet>
          <title>Công việc | {systemName} Công việc</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__WORK}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={() => {
                handleOpenPopupWorkForm()
              }}>
              Tạo công việc mới
            </Button>
          </AccessControlAction>
        </PageTitle>

        <ListWork
          code_work={code_work}
          handleOpenDrawerWork={handleOpenDrawerWork}
          queryStringParsed={queryStringParsed}
        />
        <DrawerWork
          onCloseDrawerWork={onClosesDrawerWork}
          isVisibleDrawerWork={isVisibleDrawerWork}
          queryStringParsed={queryStringParsed}
          childCode={childCode}
        />
        <PopupWorkForm
          queryStringParsed={queryStringParsed}
          isVisiblePopupWorkForm={isVisiblePopupWorkForm}
          handleCloseWorkForm={handleClosePopupWorkForm}
        />
      </DashboardLayout>
    </WorkPageWrapper>
  )
}

WorkProcedurePage.propTypes = {}

export default inject(
  'workStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore'
)(observer(WorkProcedurePage))
