import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, Modal, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useMediaQuery } from 'react-responsive'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentHeadingWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
  HeadingTitleWrapper,
} from '../../layouts/ConnectedDocumentDetailLayout/ConnectedDocumentDetailLayoutStyled'
// PDF Viewer
import moment from 'moment'
import { blue } from '../../color'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import ConnectedDocumentIncomingDocHistoryBlock from '../../components/ConnectedDocumentIncomingDocHistoryBlock'
import PDFViewer from '../../components/PDFViewer'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import TrucWrapper from '../../components/TrucWrapper'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  OUTGOING_DOCUMENT_STATE,
  OUTGOING_DOCUMENT_STATUS,
} from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import utils from '../../utils'
import PopupHandleConnectedDocumentOutgoing from './PopupHandleConnectedDocumentOutgoing'
import { systemName } from '../../config'

const { confirm } = Modal

const ConnectedDocumentOutgoingDocDetailPage = props => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedDocumentStore,
    loadingAnimationStore,
    history,
    aclStore,
    authenticationStore,
  } = props

  const { documentId } = match.params
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const { selectedOutgoingDocument, actionLogList } = connectedDocumentStore

  const {
    attachments,
    code,
    createdAt,
    subject,
    userFrom,
    listUserTo,
    view_logs,
    state,
    action,
  } = selectedOutgoingDocument

  const [
    isVisiblePopupHandleConnectedDocumentOutgoing,
    setIsVisiblePopupHandleConnectedDocumentOutgoing,
  ] = useState(false)

  const renderInfoText = string => {
    if (string) {
      if (string.length <= 20) return string
      return (
        <Tooltip title={string}>
          {string.substring(0, 20).concat('...')}
        </Tooltip>
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      refreshData()
    })()
    return () => {
      connectedDocumentStore.clearSelectedOutgoingDocument()
      connectedDocumentStore.clearLogDocument()
    }
  }, [documentId, authenticationStore?.trucToken])

  const refreshData = async () => {
    if (documentId && authenticationStore?.trucToken) {
      try {
        loadingAnimationStore.showSpinner(true)
        const response = await connectedDocumentStore.getOutgoingDocumentById(
          documentId
        )
        await connectedDocumentStore.getLogDocument(documentId, 'DOC_OUTGOING')
        const filePDFList = response.data.attachments
          ? response.data.attachments.filter(x =>
              x.name.toLocaleLowerCase().includes('.pdf')
            )
          : []
        if (filePDFList.length === 0) {
          loadingAnimationStore.showSpinner(false)
          return
        }
        await connectedDocumentStore.getOutgoingDocumentAttachmentById(
          documentId,
          filePDFList[0].fileId
        )
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
  }

  const [attachmentForView, setAttachmentForView] = useState()

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(
      attachments.find(file =>
        ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
      )
    )
  }, [attachments])

  const renderAttachmentFile = useMemo(
    () => (
      <PDFViewer
        fileId={attachmentForView?.fileId}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    ),
    [attachmentForView]
  )

  const handleDeleteDoc = () => {
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore
      .deleteOutgoingDocumentByID(documentId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        history.goBack()
        message.success('Thu hồi văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn thu hồi văn bản này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk() {
        handleDeleteDoc()
      },
    })
  }
  const menuHandleDoc = (
    <Menu>
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.edit__LIEN_THONG_OUTGOING
      ) && (
        <Menu.Item
          onClick={() =>
            history.push(`/connected-document/outgoing-document/${documentId}`)
          }
          style={{ color: blue }}
          icon={<EditOutlined />}>
          Chỉnh sửa văn bản
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.edit__LIEN_THONG_OUTGOING
      ) && (
        <Menu.Item
          onClick={() => setIsVisiblePopupHandleConnectedDocumentOutgoing(true)}
          style={{ color: blue }}
          icon={<FormOutlined />}>
          Thay đổi nơi nhận
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.delete__LIEN_THONG_OUTGOING
      ) && (
        <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
          Thu hồi văn bản
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>{`${subject} | ${systemName} Quản lý VB nội bộ`}</title>
        </Helmet>
        <DocumentHeadingWrapper>
          <HeadingTitleWrapper>
            <PageTitle
              location={props.location}
              title={subject}
              showTitle={true}
              color={commonStore.appTheme.solidColor}
            />
          </HeadingTitleWrapper>
          {state !== OUTGOING_DOCUMENT_STATE.DELETE.key &&
            action !== OUTGOING_DOCUMENT_STATUS.REJECTED.key && (
              <HeadingButtonWrapper>
                <Dropdown overlay={menuHandleDoc} trigger={'click'}>
                  <span className={'btn-style'}>
                    <MoreOutlined />
                  </span>
                </Dropdown>
              </HeadingButtonWrapper>
            )}
        </DocumentHeadingWrapper>
        <DocumentContentWrapper>
          <BlockLeft>{renderAttachmentFile}</BlockLeft>
          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Sổ văn bản</dt>
                <dd>{code && renderInfoText(code)}</dd>
                <dt>Nơi ban hành</dt>
                <dd>{userFrom && userFrom}</dd>
                <dt>Nơi nhận</dt>
                <dd style={{ marginBottom: 8 }}>
                  {listUserTo && listUserTo.length > 0 ? (
                    <PopoverWatchTime
                      userList={listUserTo}
                      title={'Nơi nhận'}
                    />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
                <dt>Ngày gửi</dt>
                <dd>
                  {createdAt && (
                    <>
                      <CalendarOutlined
                        style={{ color: blue, marginRight: 4 }}
                      />
                      <span>
                        {moment(createdAt).format(DATE_FORMAT_DEFAULT)}
                      </span>
                    </>
                  )}
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {attachments ? (
                      attachments.map(file => (
                        <FileItem
                          key={file.fileId}
                          file_id={file.fileId}
                          file_name={file.name}
                          file_type={utils.getExtensionFile(file.name)}
                        />
                      ))
                    ) : (
                      <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                    )}
                  </div>
                </dd>
                <dt>Đã xem</dt>
                <dd style={{ marginBottom: 8 }}>
                  {view_logs && view_logs.length > 0 ? (
                    <PopoverWatchTime userList={view_logs} title={'Đã xem'} />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>
            {actionLogList.length > 0 && (
              <>
                <div style={{ marginBottom: 15 }} />
                <ContentBlockWrapper>
                  <ConnectedDocumentIncomingDocHistoryBlock
                    history={actionLogList}
                    title={'Lịch sử văn bản đi'}
                  />
                </ContentBlockWrapper>
              </>
            )}
          </BlockRight>
        </DocumentContentWrapper>
        <PopupHandleConnectedDocumentOutgoing
          isVisiblePopupHandle={isVisiblePopupHandleConnectedDocumentOutgoing}
          documentId={documentId}
          handleCancelHandleDocument={() =>
            setIsVisiblePopupHandleConnectedDocumentOutgoing(false)
          }
          handleCloseHandleDocument={() => {
            setIsVisiblePopupHandleConnectedDocumentOutgoing(false)
            refreshData()
          }}
        />
      </DashboardLayout>
    </TrucWrapper>
  )
}
ConnectedDocumentOutgoingDocDetailPage.propTypes = {}
export default inject(
  'commonStore',
  'connectedDocumentStore',
  'loadingAnimationStore',
  'aclStore',
  'authenticationStore'
)(observer(ConnectedDocumentOutgoingDocDetailPage))
