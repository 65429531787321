import { isEqual } from 'lodash'
import { useState } from 'react'

export const useFilters = initFilters => {
  const [filters, setFilters] = useState(initFilters)

  const setFiltersIfChange = (value) => {
    if (!isEqual(filters, value)) {
      setFilters(() => value);
    }
  }

  const resetFilter = () => {
    setFilters(initFilters)
  }

  return [filters, setFiltersIfChange, resetFilter]
}
