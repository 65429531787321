import {
  ApartmentOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CopyOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  HomeOutlined,
  NotificationOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { Badge, Menu } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { memo, useCallback, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  ACL_ACTION_TYPE,
  MODULE_CODE,
  VAN_PHONG_DIEN_TU,
} from '../../constants'
import { SiderbarWrapper } from './MainSidebarStyled'
import { systemName } from '../../config'

const sortCommands = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  let comparison = 0
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }
  return comparison
}

const MainSidebar = props => {
  const {
    location,
    commandStore,
    accountStore,
    authenticationStore,
    commonStore,
    moduleStore,
    aclStore,
    notificationStore,
  } = props

  const { commandList, sideMenuCounter } = commandStore
  const { accountList } = accountStore
  const { currentUser, isAccountAdmin, isSuperAdmin } = authenticationStore
  const { openedSubMenu, setOpenedSubMenu, collapsedMenu } = commonStore
  const { moduleList } = moduleStore
  const { aclActionsByUser } = aclStore
  const { unreadNewsCount } = notificationStore

  // const [moduleFlatList, setModuleFlatList] = useState([])

  useEffect(() => {
    commandStore.getSideMenuCounter(['WORK', 'PROPOSAL', 'INTERNAL_MESSAGE'])
    authenticationStore.checkUserPasswordExpried()
  }, [location.pathname])

  useEffect(() => {
    const moduleFlatListConvert = []
    moduleList.forEach(item => {
      moduleFlatListConvert.push({
        ...toJS(item),
        sub_modules: null,
      })
      if (item.sub_modules?.length > 0) {
        item.sub_modules.forEach(el => {
          moduleFlatListConvert.push(toJS(el))
        })
      }
    })
    // setModuleFlatList(moduleFlatListConvert)
  }, [moduleList])

  const isAccessControl = (moduleCode, accessControlType) => {
    if (isSuperAdmin) return true
    if (!moduleStore.checkAccessModule(moduleCode)) return false
    if (accessControlType)
      return (
        isAccountAdmin ||
        !!aclActionsByUser.find(item => item.code === accessControlType)?.status
      )
    return true
  }

  const isCurrentUserHasCommand =
    currentUser?.commands && currentUser.commands.length !== 0

  const offsetMenuCounter = [20, 24]

  // Menu trang chủ
  const menuTrangChu = (
    <Menu.Item key={'/dashboard'} icon={<HomeOutlined />}>
      <Link to={'/dashboard'}>Trang chủ</Link>
    </Menu.Item>
  )

  // Menu thông báo chung
  const thongBaoChung = (
    <Menu.Item key={'/general-notifications'} icon={<NotificationOutlined />}>
      <Badge
        count={unreadNewsCount > 99 ? '99+' : unreadNewsCount}
        color={'blue'}
        overflowCount={9}
        offset={offsetMenuCounter}>
        <Link to={'/general-notifications'}>Thông báo chung</Link>
      </Badge>
    </Menu.Item>
  )

  const menuLichCoQuan = (
    <Menu.Item key={'/company-work-schedule'} icon={<CalendarOutlined />}>
      <Link to={'/company-work-schedule'}>Lịch cơ quan</Link>
    </Menu.Item>
  )

  // Menu nhiệm vụ
  const menuNhiemVu = () => {
    let linkToMisson = '/mission'
    if (
      !aclStore.checkAccessControlAction(ACL_ACTION_TYPE.bang_tong_hop__MISSION)
    ) {
      linkToMisson = `/mission?PIC=${currentUser.username}&assignee[0]=assignee%3D${currentUser.username}%2CUSER%2CPIC%2Cunknown`
    }
    return (
      <Menu.SubMenu
        key={'missions'}
        icon={<ProfileOutlined />}
        title="Nhiệm vụ">
        <Menu.Item key="/mission">
          <Link to={linkToMisson}>Nhiệm vụ</Link>
        </Menu.Item>
        {aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.lanh_dao__MISSION
        ) && (
          <Menu.Item key="/mission-group">
            <Link to={'/mission-group'}>Lãnh đạo</Link>
          </Menu.Item>
        )}
        {(isAccountAdmin || isSuperAdmin) && (
          <Menu.Item key={'/mission-management'}>
            <Link to={'/mission-management'}>Quản trị</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    )
  }

  // Menu công việc moi
  const menuCongViec = (
    // <Menu.SubMenu key={'works'} icon={<SnippetsOutlined />} title="Công việc">
    //   <Menu.Item key="/works">
    //     <Link to={'/works'}>Công việc</Link>
    //   </Menu.Item>
    //   <Menu.Item key="/works-procedure">
    //     <Link to={'/works-procedure'}>CV theo quy trình</Link>
    //   </Menu.Item>
    // </Menu.SubMenu>
    <Menu.Item key={'/works'} icon={<SnippetsOutlined />}>
      <Badge
        count={sideMenuCounter?.work ?? 0}
        color={'blue'}
        overflowCount={9}
        offset={offsetMenuCounter}>
        <Link to={'/works'}>Công việc</Link>
      </Badge>
    </Menu.Item>
  )

  // Menu Kpi
  const menuKpi = (
    <Menu.Item key={'/kpi'} icon={<BarChartOutlined />}>
      <Link to={'/kpi'}>KPI</Link>
    </Menu.Item>
  )

  // Menu bảng lương

  const menuSalary = (
    <Menu.SubMenu key={'salary'} icon={<BarChartOutlined />} title="Bảng lương">
      {isAccessControl(
        MODULE_CODE.duyet_luong,
        ACL_ACTION_TYPE.menu__SALARY
      ) && (
        <Menu.Item key={'/proposal-salary-request'}>
          <Link to={'/proposal-salary-request'}>Duyệt lương</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.luong_cua_toi,
        ACL_ACTION_TYPE.menu__MY_SALARY
      ) && (
        <Menu.Item key={'/my-salary'}>
          <Link to={'/my-salary'}>Lương của tôi</Link>
        </Menu.Item>
      )}
      {(isAccountAdmin || isSuperAdmin) && (
        <Menu.Item key={'/proposal-salary-management'}>
          <Link to={'/proposal-salary-management'}>Quản trị</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )
  //phieu rui ro
  const menuPhieuRuiRo = (
    <Menu.SubMenu
      key={'risk'}
      icon={<CopyOutlined />}
      title="Phiếu kiểm soát rủi ro">
      {isAccessControl(
        MODULE_CODE.phieu_rui_ro,
        ACL_ACTION_TYPE.menu__PKS_RUI_RO
      ) && (
        <Menu.Item key={'/risk'}>
          <Link to={'/risk'}>Phiếu kiểm soát rủi ro</Link>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.phieu_rui_ro,
        ACL_ACTION_TYPE.menu__PKS_RUI_RO
      ) && (
        <Menu.Item key={'/report'}>
          <Link to={'/report'}>Chế độ báo cáo</Link>
        </Menu.Item>
      )}
      {(isAccountAdmin ||
        isSuperAdmin ||
        isAccessControl(
          MODULE_CODE.phieu_rui_ro,
          ACL_ACTION_TYPE.quanTri__PKS_RUI_RO
        )) && (
        <Menu.Item key={'/risk-template'}>
          <Link to={'/risk-template'}>Quản trị loại rủi ro</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )
  // Menu Hành chính
  const menuHanhChinh = (
    <Menu.SubMenu
      key={'proposal'}
      icon={<FileProtectOutlined />}
      title="Hành chính">
      {isAccessControl(MODULE_CODE.sub_hanh_chinh) && (
        <Menu.Item key={'/proposal'}>
          <Badge
            count={sideMenuCounter?.proposal_other ?? 0}
            overflowCount={9}
            color={'blue'}
            offset={offsetMenuCounter}>
            <Link to={'/proposal'}>Hành chính</Link>
          </Badge>
        </Menu.Item>
      )}
      {isAccessControl(
        MODULE_CODE.tam_ung,
        ACL_ACTION_TYPE.menu__PROPOSAL_ADVANCE
      ) && (
        <Menu.Item key={'/proposal-advance'}>
          <Link to={'/proposal-advance'}>Tạm ứng</Link>
        </Menu.Item>
      )}

      {(isAccountAdmin ||
        isSuperAdmin ||
        isAccessControl(
          MODULE_CODE.administration,
          ACL_ACTION_TYPE.administration_PROPOSAL
        )) && (
        <Menu.Item key={'/administrative-management'}>
          <Link to={'/administrative-management'}>Quản trị</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  // Menu Tiện ích
  const menuTienIch = (
    <Menu.SubMenu
      key={'utilities'}
      icon={<SolutionOutlined />}
      title="Tiện ích">
      <Menu.Item key={'/utility/contacts'}>
        <Link to={'/utility/contacts'}>Danh bạ</Link>
      </Menu.Item>

      {isAccessControl(
        MODULE_CODE.thong_bao_chung,
        ACL_ACTION_TYPE.menu__NEWS
      ) && (
        <Menu.Item key={'/utility/general-notifications'}>
          <Link to={'/utility/general-notifications'}>Thông báo chung</Link>
        </Menu.Item>
      )}

      {isAccessControl(MODULE_CODE.tai_lieu_iso, ACL_ACTION_TYPE.menu__ISO) && (
        <Menu.Item key={'/utility/iso'}>
          <Link to={'/utility/iso'}>Tài liệu ISO</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.vb_phap_che,
        ACL_ACTION_TYPE.menu__PHAP_CHE
      ) && (
        <Menu.Item key={'/utility/vbpc'}>
          <Link to={'/utility/vbpc'}>VB Pháp chế</Link>
        </Menu.Item>
      )}

      {isAccessControl(MODULE_CODE.ky_so, ACL_ACTION_TYPE.menu__KY_SO) && (
        <Menu.Item key={'/utility/digital-signature'}>
          <Link to={'/utility/digital-signature'}>Ký số điện tử</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.tin_nhan_lien_thong,
        ACL_ACTION_TYPE.menu__TIN_NHAN_LIEN_THONG
      ) && (
        <Menu.Item key={'/utility/messages'}>
          <Link to={'/utility/messages'}>Tin nhắn liên thông</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.tin_nhan_noi_bo,
        ACL_ACTION_TYPE.menu__TIN_NHAN_NOI_BO
      ) && (
        <Menu.Item key={'/utility/internal-message/receive'}>
          <Badge
            count={sideMenuCounter?.unread_internal_message ?? 0}
            overflowCount={9}
            color={'blue'}
            offset={offsetMenuCounter}>
            <Link to={'/utility/internal-message/receive'}>
              Tin nhắn nội bộ
            </Link>
          </Badge>
        </Menu.Item>
      )}

      {isAccessControl(MODULE_CODE.kpi, ACL_ACTION_TYPE.menu__KPI) && (
        <Menu.Item key={'/kpi_quarter'}>
          <Link to={'/kpi_quarter'}>KPI</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  const menuModule = (
    <Menu.Item key={'/module'}>
      <Link to={'/module'}>Hệ thống</Link>
    </Menu.Item>
  )

  const menuACL = (
    <Menu.Item key={'/acl'}>
      <Link to={'/acl'}>Phân quyền</Link>
    </Menu.Item>
  )

  // Menu quản trị
  const menuQuanTri = (
    <Menu.SubMenu
      key={'management'}
      icon={<ApartmentOutlined />}
      title="Quản trị">
      {isSuperAdmin && menuModule}

      {isAccessControl(
        MODULE_CODE.phong_ban,
        ACL_ACTION_TYPE.menu__QUAN_LY_PHONG_BAN
      ) && (
        <Menu.Item key={'/management/department'}>
          <Link to={'/management/department'}>Phòng ban</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.chuc_danh,
        ACL_ACTION_TYPE.menu__CHUC_DANH
      ) && (
        <Menu.Item key={'/management/position'}>
          <Link to={'/management/position'}>Chức danh</Link>
        </Menu.Item>
      )}

      {(isAccountAdmin || isSuperAdmin) && (
        <Menu.Item key={'/admin/user-account-management'}>
          <Link to={'/admin/user-account-management'}>Tài khoản</Link>
        </Menu.Item>
      )}

      {(isAccountAdmin || isSuperAdmin) && (
        <Menu.Item key={'/management/timekeeping'}>
          <Link to={'/management/timekeeping'}>Chấm công</Link>
        </Menu.Item>
      )}

      {(isAccountAdmin || isSuperAdmin) && menuACL}

      {isAccessControl(
        MODULE_CODE.nhom_nguoi_dung,
        ACL_ACTION_TYPE.menu__NHOM_NGUOI_DUNG
      ) && (
        <Menu.Item key={'/management/group'}>
          <Link to={'/management/group'}>Nhóm người dùng</Link>
        </Menu.Item>
      )}

      {isAccessControl(
        MODULE_CODE.tai_khoan_ky_so,
        ACL_ACTION_TYPE.menu__TAI_KHOAN_KY_SO
      ) && (
        <Menu.Item key={'/management/sign-account'}>
          <Link to={'/management/sign-account'}>Tài khoản ký số</Link>
        </Menu.Item>
      )}

      {(isAccountAdmin || isSuperAdmin) && (
        <Menu.Item key={'/management/company-work-schedule'}>
          <Link to={'/management/company-work-schedule'}>Lịch cơ quan</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  const onSubMenuToggle = useCallback(keys => {
    setOpenedSubMenu(keys)
  }, [])

  const onClickMenuItem = ({ keyPath }) => {
    setOpenedSubMenu([keyPath[1]])
  }

  const renderCommandMenu = item => {
    let linkToInternalDocument = '/dashboard'
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_cau_hinh_vb__INTERNAL_DOCUMENT
      )
    ) {
      linkToInternalDocument =
        '/internal-document/config-document/document-books'
    }
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_phat_hanh_vb__INTERNAL_DOCUMENT
      )
    ) {
      linkToInternalDocument = '/internal-document/sign-document'
    }
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_vb_di__INTERNAL_DOCUMENT
      )
    ) {
      linkToInternalDocument = '/internal-document/outgoing-document'
    }
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_vb_den__INTERNAL_DOCUMENT
      )
    ) {
      linkToInternalDocument = '/internal-document/incoming-document'
    }
    let linkToLTVB = '/dashboard'
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_vb_di__LIEN_THONG_VB
      )
    ) {
      linkToLTVB = '/connected-document/outgoing-document'
    }
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_vb_den__LIEN_THONG_VB
      )
    ) {
      linkToLTVB = '/connected-document/incoming-document'
    }
    let linkToUBQLV = '/dashboard'
    if (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_vb_di__UBQLV)) {
      linkToUBQLV = '/connected-committee-document/outgoing-document'
    }
    if (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_vb_den__UBQLV)) {
      linkToUBQLV = '/connected-committee-document/incoming-document'
    }
    switch (item.code) {
      case VAN_PHONG_DIEN_TU:
        return (
          <Menu.SubMenu
            key={'connected-document'}
            icon={<FileTextOutlined />}
            title="Văn bản">
            {isAccessControl(
              MODULE_CODE.van_ban_noi_bo,
              ACL_ACTION_TYPE.menu__INTERNAL_DOCUMENT
            ) && (
              <Menu.Item key={'/internal-document/incoming-document'}>
                <Link to={linkToInternalDocument}>Văn bản {systemName}</Link>
              </Menu.Item>
            )}

            {isAccessControl(
              MODULE_CODE.lien_thong_van_ban,
              ACL_ACTION_TYPE.menu__LIEN_THONG_VB
            ) && (
              <Menu.Item key={'/connected-document/incoming-document'}>
                <Link to={linkToLTVB}>Liên thông văn bản {systemName}</Link>
              </Menu.Item>
            )}

            {isAccessControl(
              MODULE_CODE.lien_thong_van_ban_ubqlv,
              ACL_ACTION_TYPE.menu__UBQLV
            ) && (
              <Menu.Item
                key={'/connected-committee-document/incoming-document'}>
                <Link to={linkToUBQLV}>Liên thông văn bản UBQLV</Link>
              </Menu.Item>
            )}

            {(isAccountAdmin || isSuperAdmin) && (
              <Menu.Item key={'/document-management'}>
                <Link to={'/document-management'}>Quản trị</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )
    }
  }

  useEffect(() => {
    // Trang chủ
    if (location.pathname.includes('/dashboard')) {
      commonStore.setPage(['/dashboard'])
      setOpenedSubMenu([])
      return
    }
    // Lịch cơ quan
    // if (location.pathname.includes('/company-work-schedule')) {
    //   commonStore.setPage(['/company-work-schedule'])
    //   setOpenedSubMenu(['company-work-schedule'])
    //   return
    // }

    // Thông báo
    if (location.pathname.includes('/notification')) {
      commonStore.setPage(['/notification'])
      return
    }
    // Văn bản
    if (location.pathname.includes('/document-management/')) {
      commonStore.setPage(['/document-management'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    if (location.pathname.includes('/connected-document/')) {
      commonStore.setPage(['/connected-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    if (location.pathname.includes('/connected-committee-document/')) {
      commonStore.setPage(['/connected-committee-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }
    if (location.pathname.includes('/internal-document/')) {
      commonStore.setPage(['/internal-document/incoming-document'])
      setOpenedSubMenu(['connected-document'])
      return
    }

    // Nhiệm vụ
    if (location.pathname.includes('/mission-group/')) {
      commonStore.setPage(['/mission-group/'])
      setOpenedSubMenu(['missions'])
      return
    }
    if (location.pathname.includes('/mission-management/')) {
      commonStore.setPage(['/mission-management'])
      setOpenedSubMenu(['missions'])
      return
    }
    // Công việc
    if (
      location.pathname.includes('/my-tasks') ||
      location.pathname.includes('/my-tasks/')
    ) {
      commonStore.setPage(['/my-tasks'])
      setOpenedSubMenu([])
      return
    }
    // Bảng lương

    if (
      location.pathname.includes('/my-salary') ||
      location.pathname.includes('/my-salary/')
    ) {
      commonStore.setPage(['/my-salary'])
      setOpenedSubMenu(['salary'])
      return
    }

    if (
      location.pathname.includes('/proposal-salary-management') ||
      location.pathname.includes('/proposal-salary-management/')
    ) {
      commonStore.setPage(['/proposal-salary-management'])
      setOpenedSubMenu(['salary'])
      return
    }

    if (
      location.pathname.includes('/salary') ||
      location.pathname.includes('/salary/')
    ) {
      commonStore.setPage(['/salary'])
      setOpenedSubMenu(['salary'])
      return
    }

    if (
      location.pathname.includes('/proposal-salary-request') ||
      location.pathname.includes('/proposal-salary-request/')
    ) {
      commonStore.setPage(['/proposal-salary-request'])
      setOpenedSubMenu(['salary'])
      return
    }
    // Quản lý phân hệ
    if (
      location.pathname.includes('/module') ||
      location.pathname.includes('/module/')
    ) {
      commonStore.setPage(['/module'])
      // setOpenedSubMenu([])
      return
    }
    // Quản lý phân quyền
    if (
      location.pathname.includes('/acl') ||
      location.pathname.includes('/acl/')
    ) {
      commonStore.setPage(['/acl'])
      // setOpenedSubMenu([])
      return
    }
    if (
      location.pathname.includes('/works') ||
      location.pathname.includes('/works/')
    ) {
      commonStore.setPage(['/works'])
      // setOpenedSubMenu([])
      return
    }
    // Quy trình
    if (
      location.pathname.includes('/administrative/consult') ||
      location.pathname.includes('/administrative/consult/')
    ) {
      commonStore.setPage(['/administrative/consult'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (
      location.pathname.includes('/administrative/policy') ||
      location.pathname.includes('/administrative/policy/')
    ) {
      commonStore.setPage(['/administrative/policy'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (
      location.pathname.includes('/administrative/advance-payment') ||
      location.pathname.includes('/administrative/advance-payment/')
    ) {
      commonStore.setPage(['/administrative/advance-payment'])
      setOpenedSubMenu(['administrative'])
      return
    }
    if (location.pathname.includes('/administrative/payslip')) {
      commonStore.setPage(['/administrative/payslip'])
      setOpenedSubMenu(['administrative'])
      return
    }
    // Hành chính
    if (
      location.pathname.includes('/proposal-advance') ||
      location.pathname.includes('/proposal-advance/')
    ) {
      commonStore.setPage(['/proposal-advance'])
      setOpenedSubMenu(['proposal'])
      return
    }
    if (location.pathname.includes('/administrative-management/')) {
      commonStore.setPage(['/administrative-management'])
      setOpenedSubMenu(['proposal'])
      return
    }
    if (
      location.pathname.includes('/proposal') ||
      location.pathname.includes('/proposal/')
    ) {
      commonStore.setPage(['/proposal'])
      setOpenedSubMenu(['proposal'])
      return
    }

    // Tiện ích
    if (
      location.pathname.includes('/utility/iso') ||
      location.pathname.includes('/utility/iso/')
    ) {
      commonStore.setPage(['/utility/iso'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/vbpc') ||
      location.pathname.includes('/utility/vbpc/')
    ) {
      commonStore.setPage(['/utility/vbpc'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/messages') ||
      location.pathname.includes('/utility/messages/')
    ) {
      commonStore.setPage(['/utility/messages'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/signed-document') ||
      location.pathname.includes('/utility/signed-document/')
    ) {
      commonStore.setPage(['/utility/signed-document'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/contacts') ||
      location.pathname.includes('/utility/contacts/')
    ) {
      commonStore.setPage(['/utility/contacts'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/general-notifications') ||
      location.pathname.includes('/utility/general-notifications/')
    ) {
      commonStore.setPage(['/utility/general-notifications'])
      setOpenedSubMenu(['utilities'])
      return
    }
    if (
      location.pathname.includes('/utility/digital-signature') ||
      location.pathname.includes('/utility/digital-signature/')
    ) {
      commonStore.setPage(['/utility/digital-signature'])
      setOpenedSubMenu(['utilities'])
      return
    }
    // Quản trị

    if (location.pathname.includes('/management/group')) {
      commonStore.setPage(['/management/group'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/nhiem-vu-group')) {
      commonStore.setPage(['/management/nhiem-vu-group'])
      setOpenedSubMenu(['management'])
      return
    }

    if (location.pathname.includes('/management/document-books')) {
      commonStore.setPage(['/management/document-books'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/sample-document')) {
      commonStore.setPage(['/management/sample-document'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/timekeeping')) {
      commonStore.setPage(['/management/timekeeping'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management//management/sign-account')) {
      commonStore.setPage(['/management/sample-document'])
      setOpenedSubMenu(['management'])
      return
    }
    if (location.pathname.includes('/management/company-work-schedule')) {
      commonStore.setPage(['/management/company-work-schedule'])
      setOpenedSubMenu(['management'])
      return
    }
    commonStore.setPage([location.pathname])
  }, [location.pathname])

  useEffect(() => {
    if (isCurrentUserHasCommand) {
      currentUser.commands.forEach(userCommand => {
        commandList.length !== 0 &&
          commandList.forEach(command => {
            if (command.code === userCommand.code) {
              userCommand.url = command.url
              userCommand.description = command.description
              userCommand.image = command.image
            }
          })
        accountList.length !== 0 &&
          accountList.forEach(account => {
            if (account.command.code === userCommand.code) {
              userCommand.account_name = account.account_name
              userCommand.id = account.id
              userCommand.password = account.password
            }
          })
      })
    }
  }, [currentUser, commandList, accountList, isCurrentUserHasCommand])

  return (
    <SiderbarWrapper>
      <Menu
        mode="inline"
        selectedKeys={commonStore.pageName}
        openKeys={openedSubMenu}
        onOpenChange={onSubMenuToggle}
        inlineCollapsed={!collapsedMenu}
        onClick={onClickMenuItem}>
        {menuTrangChu}
        {/* {thongBaoChung} */}
        {isAccessControl(
          MODULE_CODE.lich_co_quan,
          ACL_ACTION_TYPE.menu__WORK_SCHEDULE
        ) && menuLichCoQuan}
        {
          //menu VPDT
          isAccessControl(MODULE_CODE.van_ban) && isCurrentUserHasCommand
            ? currentUser.commands
                .slice()
                .sort(sortCommands)
                .map(item => renderCommandMenu(item))
            : null
        }
        {isAccessControl(MODULE_CODE.nhiem_vu, ACL_ACTION_TYPE.menu__MISSION) &&
          menuNhiemVu()}
        {isAccessControl(MODULE_CODE.cong_viec, ACL_ACTION_TYPE.menu__WORK) &&
          menuCongViec}
        {isAccessControl(MODULE_CODE.bang_luong) && menuSalary}
        {isAccessControl(
          MODULE_CODE.hanh_chinh,
          ACL_ACTION_TYPE.menu__PROPOSAL
        ) && menuHanhChinh}
        {isAccessControl(MODULE_CODE.phieu_rui_ro) && menuPhieuRuiRo}
        {isAccessControl(MODULE_CODE.tien_ich) && menuTienIch}
        {isAccessControl(MODULE_CODE.quan_tri) && menuQuanTri}
      </Menu>
    </SiderbarWrapper>
  )
}

export default memo(
  withRouter(
    inject(
      'commandStore',
      'accountStore',
      'authenticationStore',
      'commonStore',
      'loadingAnimationStore',
      'notificationStore',
      'moduleStore',
      'aclStore'
    )(observer(MainSidebar))
  )
)
