import React, { useEffect, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Ant design
import {
  Button,
  Empty,
  message,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'

import ContentBlockWrapper from '../../components/ContentBlockWrapper'
//other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_SLASH,
  ISO_DATE_FORMAT,
  TASK_DOCUMENT_TYPE,
} from '../../constants'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import utils from '../../utils'
import axios from 'axios'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import { MissionFullPageWrapper, TableWrapper } from './MissionPageStyled'
import SearchFull from './SearchFull'
import {
  CheckCircleFilled,
  CopyOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  NodeExpandOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import MissionFullCreateModal from './MissionFullCreateModal'
import MissionFullDetailModal from './MissionFullDetailModal'
import { DocumentList } from '../MissionDetailPage/MissionDetailPageStyled'
import { blue, green } from '../../color'
import AccessControlAction from '../../components/AccessControlAction'
import ListHistoryLogModal from '../ListHistoryLog/ListHistoryLogModal'
import ExportTaskToExcel from './ExportTaskToExcel'
import MissionCreatePopup from '../MissionCreatePage/MissionCreatePopup'
import { systemName } from '../../config'

const { Option, OptGroup } = Select
const { Paragraph } = Typography

const reducer = (state, action) => {
  switch (action.type) {
    case ASSIGNEE_TYPE.LEADER:
      return {
        ...state,
        [ASSIGNEE_TYPE.LEADER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.LEADER},${action.fullName || 'unknown'}`,
      }
    case ASSIGNEE_TYPE.HANDLER:
      return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
      }
    case ASSIGNEE_TYPE.COMBINER:
      return {
        ...state,
        [ASSIGNEE_TYPE.COMBINER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.COMBINER},${action.fullName || 'unknown'}`,
      }
    case 'CHANGE_TAB':
      if (action.value.type === 'ALL')
        return {
          ...state,
          [ASSIGNEE_TYPE.HANDLER]: null,
          [ASSIGNEE_TYPE.COMBINER]: null,
        }
      if (action.value.type === ASSIGNEE_TYPE.COMBINER)
        return {
          ...state,
          [ASSIGNEE_TYPE.COMBINER]:
            action.value.value &&
            `assignee=${encodeURIComponent(action.value.value)},${
              ASSIGNEE_TYPE.USER
            },${ASSIGNEE_TYPE.COMBINER},${action.fullName || 'unknown'}`,
          [ASSIGNEE_TYPE.HANDLER]: null,
        }
      if (action.value.type === ASSIGNEE_TYPE.HANDLER)
        return {
          ...state,
          [ASSIGNEE_TYPE.HANDLER]:
            action.value.value &&
            `assignee=${encodeURIComponent(action.value.value)},${
              ASSIGNEE_TYPE.USER
            },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
          [ASSIGNEE_TYPE.COMBINER]: null,
        }
    default:
      return state
  }
}

const MissionFullPage = props => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const {
    taskStore,
    history,
    authenticationStore,
    departmentStore,
    loadingAnimationStore,
    selectPeopleStore,
  } = props

  const { currentUser } = authenticationStore

  const { taskList, unitList, taskLevelList, missionQueryParams } = taskStore

  const [taskListView, setTaskListView] = useState([])
  const [counterTask, setCounterTask] = useState({
    uncompleted: 0,
    completed: 0,
  })

  useEffect(() => {
    setCounterTask({
      uncompleted: taskListView.filter(
        item =>
          item.ket_qua.phan_tram_hoan_thanh + '' !== '100' ||
          item.ket_qua.phan_tram_hoan_thanh === null
      ).length,
      completed: taskListView.filter(
        item => item.ket_qua.phan_tram_hoan_thanh + '' === '100'
      ).length,
    })
  }, [taskListView])

  useEffect(() => {
    const taskListRemoveDuplicate = [
      ...new Map(taskList.map(item => [item.task_code, item])).values(),
    ]
      .filter(item => !item.isUser)
      .map((item, index) => ({ ...item, stt: index + 1 }))
    setTaskListView(taskListRemoveDuplicate)
  }, [taskList])

  const [loadingList, setLoadingList] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  const [
    isVisibleMissionFullCreatePopup,
    setIsVisibleMissionFullCreatePopup,
  ] = useState(false)
  const [
    isVisibleMissionFullDetailModal,
    setIsVisibleMissionFullDetailModal,
  ] = useState(false)

  const [isVisibleHistory, setIsVisibleHistory] = useState(false)

  const [usersFilter, dispatchUserFilter] = useReducer(reducer, {
    [ASSIGNEE_TYPE.LEADER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.LEADER)
          )
        : null,
    [ASSIGNEE_TYPE.HANDLER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.HANDLER)
          )
        : null,
    [ASSIGNEE_TYPE.COMBINER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.COMBINER)
          )
        : null,
  })
  const getTaskList = async () => {
    try {
      setLoadingList(true)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoadingList(true)
        await Promise.all([
          taskStore.getTaskLevelList(),
          departmentStore.getDepartmentWithUsers(),
        ])
        await getTaskList()
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setLoadingList(false)
      }
    })()
    return () => {
      taskStore.clearTaskStoreFilter()
      taskStore.clearTaskList()
      departmentStore.clearDepartmentList()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    const source = axios.CancelToken.source()
    ;(async () => {
      try {
        setLoadingList(true)
        const res = await taskStore.getTaskList(source.token)
        if (res) {
          setLoadingList(false)
        }
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setLoadingList(false)
      }
    })()
    return () => {
      source.cancel()
    }
  }, [missionQueryParams])

  const columns = [
    {
      align: 'center',
      width: 60,
      title: 'STT',
      render: record => <div style={{ textAlign: 'center' }}>{record.stt}</div>,
    },
    {
      title: 'Nội dung',
      render: record => (
        <div>
          {record.noi_dung ? (
            WordBreak(record.noi_dung, true)
          ) : (
            <EmptyText>Không có nội dung</EmptyText>
          )}
          <div>
            {record.ghi_chu && (
              <b
                style={{
                  marginRight: 6,
                  display: 'block',
                  margin: '6px 0 2px 0',
                  textDecoration: 'underline',
                }}>
                Ghi chú:
              </b>
            )}
            {<div>{record.ghi_chu && WordBreak(record.ghi_chu, true)}</div>}
          </div>
        </div>
      ),
    },
    {
      align: 'center',
      width: 100,
      title: (
        <div style={{ textAlign: 'center' }}>
          Ngày giao
          <br />
          Hoàn thành
        </div>
      ),
      render: record => (
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontWeight: 500 }}>
            {`${moment(record?.ngay_bat_dau).format(DATE_FORMAT_SLASH)}`}
          </div>
          <div>-</div>
          <div>
            {record?.ngay_ket_thuc ? (
              <span style={{ fontWeight: 500 }}>
                {`${moment(record?.ngay_ket_thuc).format(DATE_FORMAT_SLASH)}`}
              </span>
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      ),
    },
    {
      width: 230,
      title: 'Văn bản',
      render: record => (
        <div>
          <b
            style={{
              marginRight: 6,
              display: 'block',
              marginBottom: 2,
              textDecoration: 'underline',
            }}>
            Văn bản liên kết:
          </b>
          <DocumentList>
            {record.van_bans && record.van_bans.length === 0 && (
              <EmptyText>Không có văn bản</EmptyText>
            )}
            {record.van_bans &&
              record.van_bans
                .filter(doc => doc.loai_van_ban === TASK_DOCUMENT_TYPE.INCOMING)
                .map(el => (
                  <Tooltip key={el.code_van_ban} title={'Xem'}>
                    <li
                      onClick={() =>
                        window.open(
                          `/internal-document/incoming-document/view/${el.code_van_ban}`,
                          '_blank'
                        )
                      }>
                      <CopyOutlined style={{ color: green, marginRight: 8 }} />
                      {`${el.document_number} - ${el.ten_van_ban}`}
                    </li>
                  </Tooltip>
                ))}
            {record.van_bans &&
              record.van_bans
                .filter(doc => doc.loai_van_ban === TASK_DOCUMENT_TYPE.OUTGOING)
                .map(el => (
                  <Tooltip key={el.code_van_ban} title={'Xem'}>
                    <li
                      onClick={() =>
                        window.open(
                          `/internal-document/outgoing-document/view/${el.code_van_ban}`,
                          '_blank'
                        )
                      }>
                      <CopyOutlined style={{ color: blue, marginRight: 8 }} />
                      {`${el.document_number} - ${el.ten_van_ban}`}
                    </li>
                  </Tooltip>
                ))}
          </DocumentList>

          <b
            style={{
              marginRight: 6,
              display: 'block',
              margin: '6px 0 2px 0',
              textDecoration: 'underline',
            }}>
            Tài liệu đính kèm văn bản:
          </b>
          <div>
            {record?.file_dinh_kem_vb?.length > 0 ? (
              record?.file_dinh_kem_vb?.map(item => {
                return (
                  <FileItem
                    key={item.file_id}
                    file_id={item.file_id}
                    file_name={item.file_title}
                    file_type={utils.getExtensionFile(item.file_title)}
                  />
                )
              })
            ) : (
              <EmptyText>Không có tài liệu nào</EmptyText>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Kết quả',
      render: record => (
        <div>
          {record.ket_qua?.phan_tram_hoan_thanh === '100' ? (
            <Tag color="green">Hoàn thành</Tag>
          ) : (
            <Tag color="red">Chưa hoàn thành</Tag>
          )}
          <b
            style={{
              marginRight: 6,
              display: 'block',
              margin: '6px 0 2px 0',
              textDecoration: 'underline',
            }}>
            Nội dung:
          </b>
          <div>
            {record.ket_qua?.noi_dung_ket_qua_giao ? (
              WordBreak(record.ket_qua?.noi_dung_ket_qua_giao, true)
            ) : (
              <EmptyText>Không có nội dung kết quả</EmptyText>
            )}
          </div>
          <b
            style={{
              marginRight: 6,
              display: 'block',
              margin: '6px 0 2px 0',
              textDecoration: 'underline',
            }}>
            Kiến nghị:
          </b>
          <Paragraph style={{ marginBottom: 0 }}>
            {record.ket_qua?.kien_nghi ? (
              WordBreak(record.ket_qua?.kien_nghi, true)
            ) : (
              <EmptyText>Không có kiến nghị</EmptyText>
            )}
          </Paragraph>
          <b
            style={{
              marginRight: 6,
              display: 'block',
              margin: '6px 0 2px 0',
              textDecoration: 'underline',
            }}>
            Tài liệu đính kèm:
          </b>
          <div>
            {record.ket_qua?.files?.length > 0 ? (
              record.ket_qua.files.map(item => {
                return (
                  <FileItem
                    key={item.file_id}
                    file_id={item.file_id}
                    file_name={item.file_title}
                    file_type={utils.getExtensionFile(item.file_title)}
                  />
                )
              })
            ) : (
              <EmptyText>Không có tài liệu nào</EmptyText>
            )}
          </div>
        </div>
      ),
    },
    {
      align: 'center',
      title: 'Xem',
      fixed: 'right',
      width: 90,
      render: record => (
        <>
          <div
            className={'button-text'}
            onClick={() => {
              setTaskInfo({
                code: record.task_code,
                id: record.id,
              })
              setSelectUserDataBefore(selectPeopleStore.selectUserData)
              setSelectDepartmentDataBefore(
                selectPeopleStore.selectDepartmentData
              )
              setSelectGroupDataBefore(selectPeopleStore.selectGroupData)
              selectPeopleStore.clearSelected()
              setIsVisibleMissionFullDetailModal(true)
            }}>
            Chi tiết
          </div>
          {!record.isCompleted && (
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.chuyen_sang_thang__MISSION}>
              <div
                style={{
                  marginTop: 8,
                  paddingTop: 8,
                  borderTop: '2px solid #f0f0f0',
                }}>
                <Popconfirm
                  placement="left"
                  title="Bạn có muốn chuyển nhiệm vụ này sang tháng sau?"
                  onConfirm={() => handleNextMonth(record.task_code)}
                  okText="Đồng ý"
                  cancelText="Không">
                  <span className={'button-text'}>
                    Chuyển
                    <br />
                    tháng sau
                  </span>
                </Popconfirm>
              </div>
            </AccessControlAction>
          )}
          <div
            className={'button-text'}
            style={{
              marginTop: 8,
              paddingTop: 8,
              borderTop: '2px solid #f0f0f0',
            }}
            onClick={() => {
              setDocumentHistory({
                code: record.task_code,
              })
              setIsVisibleHistory(!isVisibleHistory)
            }}>
            Lịch sử
          </div>
        </>
      ),
    },
  ]

  const renderUnitList = () => {
    return unitList.map((unit, index) => {
      return (
        <OptGroup
          label={
            <b
              style={{
                fontSize: 15,
                fontWeight: 500,
                color: '#000',
              }}>
              {unit.name_unit}
            </b>
          }
          key={unit.id_unit}>
          {unitList[index].user.map(user => {
            return (
              <Option value={user.id_user} key={user.id_user}>
                {user.name_user}
              </Option>
            )
          })}
        </OptGroup>
      )
    })
  }

  const HandleSetSelectDataF5 = () => {
    if (queryStringParse.assignee && Array.isArray(queryStringParse.assignee)) {
      queryStringParse.assignee.forEach(item => {
        const itemInfo = item.split(',')
        const username = itemInfo[0].split('=')[1]
        if (itemInfo.includes(ASSIGNEE_TYPE.USER)) {
          selectPeopleStore.setSelectUserData({
            ...selectPeopleStore.selectUserData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.USER,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.DEPARTMENT)) {
          selectPeopleStore.setSelectDepartmentData({
            ...selectPeopleStore.selectDepartmentData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.GROUP)) {
          selectPeopleStore.setSelectGroupData({
            ...selectPeopleStore.selectGroupData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.GROUP,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
      })
    }
  }

  useEffect(() => {
    if (!currentUser) return
    HandleSetSelectDataF5()
    return () => {
      selectPeopleStore.clearSelected()
    }
  }, [currentUser])

  useEffect(() => {
    taskStore.setMissionQueryParams({
      date_by: queryStringParse.date_by || 'DISPLAY_DATE',
      tien_do: queryStringParse.tien_do,
      from_date: !queryStringParse.all_time
        ? queryStringParse.from_date ||
          moment().startOf('month').format(ISO_DATE_FORMAT)
        : null,
      to_date: !queryStringParse.all_time
        ? queryStringParse.to_date ||
          moment().endOf('month').format(ISO_DATE_FORMAT)
        : null,
      id_unit: queryStringParse.id_unit,
      id_user_unit: queryStringParse.id_user_unit,
      id_task_level: queryStringParse.id_task_level,
      loai_nhiem_vu: queryStringParse.loai_nhiem_vu,
      tag: queryStringParse.tag,
      assignee: Object.values(usersFilter)
        .filter(item => item)
        .map(item => {
          const assignee = item.split(',')
          if (assignee.length === 3) {
            return assignee.join()
          }
          assignee.pop()
          return assignee.join()
        })
        .join('&'),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        assignee: Object.values(usersFilter),
        [ASSIGNEE_TYPE.LEADER]:
          typeof usersFilter[ASSIGNEE_TYPE.LEADER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.LEADER].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.HANDLER]:
          typeof usersFilter[ASSIGNEE_TYPE.HANDLER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.HANDLER].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.COMBINER]:
          typeof usersFilter[ASSIGNEE_TYPE.COMBINER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.COMBINER].split(',')[0].substring(9)
            : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission/full?${queryStringStringify}`)
  }, [usersFilter])

  const handleNextMonth = async (taskCode, type) => {
    try {
      setLoadingList(true)
      let taskCodes = []
      if (type === 'ALL') {
        taskCodes = taskListView
          .filter(item => !item.isUser && !item.isCompleted)
          .map(item => item.task_code)
      } else {
        taskCodes = [taskCode]
      }
      const payload = {
        display_date:
          missionQueryParams.from_date &&
          moment(missionQueryParams.from_date).add(1, 'months'),
        task_codes: taskCodes,
      }
      await taskStore.moveTask(payload)
      await taskStore.getTaskList()
      message.success('Đã chuyển nhiệm vụ này sang tháng sau!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
    }
  }

  const [selectUserDataBefore, setSelectUserDataBefore] = useState()
  const [selectDepartmentDataBefore, setSelectDepartmentDataBefore] = useState()
  const [selectGroupDataBefore, setSelectGroupDataBefore] = useState()
  useEffect(() => {}, [selectDepartmentDataBefore])

  const handleCancelCreateMission = async refesh => {
    if (selectUserDataBefore) {
      selectPeopleStore.setSelectUserData(selectUserDataBefore)
    }
    if (selectDepartmentDataBefore) {
      selectPeopleStore.setSelectDepartmentData(selectDepartmentDataBefore)
    }
    if (selectGroupDataBefore) {
      selectPeopleStore.setSelectGroupData(selectGroupDataBefore)
    }
    setIsVisibleMissionFullCreatePopup(false)
    if (refesh === true) {
      await getTaskList()
    }
  }

  const [taskInfo, setTaskInfo] = useState({})
  const [documentHistory, setDocumentHistory] = useState({})

  const handleCloseDetailMission = async type => {
    taskStore.clearTaskDetail()
    if (selectUserDataBefore) {
      setTimeout(() => {
        selectPeopleStore.setSelectUserData(selectUserDataBefore)
      }, 100)
    }
    if (selectDepartmentDataBefore) {
      setTimeout(() => {
        selectPeopleStore.setSelectDepartmentData(selectDepartmentDataBefore)
      }, 100)
    }
    if (selectGroupDataBefore) {
      setTimeout(() => {
        selectPeopleStore.setSelectGroupData(selectGroupDataBefore)
      }, 100)
    }
    setIsVisibleMissionFullDetailModal(false)
    if (type === 'REFRESH') {
      await getTaskList()
    }
  }

  const handleCloseHistory = async type => {
    setIsVisibleHistory(false)
  }

  return (
    <MissionFullPageWrapper>
      <Helmet>
        <title>Nhiệm vụ | {systemName} Nhiệm vụ</title>
      </Helmet>
      <ContentBlockWrapper
        style={{ paddingTop: 20, height: '100vh', borderRadius: 0 }}>
        <SearchFull
          isVisibleMissionFullCreatePopup={isVisibleMissionFullCreatePopup}
          isVisibleMissionFullDetailModal={isVisibleMissionFullDetailModal}
          handleNextMonth={handleNextMonth}
          dispatchUserFilter={dispatchUserFilter}
          renderUnitList={renderUnitList}
          taskLevelList={taskLevelList}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
            marginTop: 5,
          }}>
          <div style={{ display: 'flex', gap: 8 }}>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__MISSION}>
              <Button
                type="primary"
                onClick={() => {
                  setSelectUserDataBefore(selectPeopleStore.selectUserData)
                  setSelectDepartmentDataBefore(
                    selectPeopleStore.selectDepartmentData
                  )
                  setSelectGroupDataBefore(selectPeopleStore.selectGroupData)
                  selectPeopleStore.clearSelected()
                  setIsVisibleMissionFullCreatePopup(true)
                }}>
                <PlusOutlined style={{ marginRight: 5 }} />
                THÊM NHIỆM VỤ
              </Button>
            </AccessControlAction>
            <div style={{ marginBottom: 10 }}>
              <AccessControlAction
                aclActionType={ACL_ACTION_TYPE.chuyen_sang_thang__MISSION}>
                <Popconfirm
                  placement="left"
                  title="Bạn có chắc chắn muốn chuyển toàn bộ nhiệm vụ sang tháng không ?"
                  onConfirm={() => handleNextMonth(null, 'ALL')}
                  okText="Đồng ý"
                  cancelText="Không">
                  <Button icon={<NodeExpandOutlined />}>
                    Chuyển tất cả sang tháng
                  </Button>
                </Popconfirm>
              </AccessControlAction>
            </div>
            <ExportTaskToExcel data={taskListView} />
          </div>
          <Tag color={'blue'} style={{ padding: '5px 12px', marginRight: 0 }}>
            <span>
              <CheckCircleFilled style={{ color: '#52c41a', marginRight: 4 }} />
              Hoàn thành:{' '}
              <span style={{ fontWeight: 500 }}>{counterTask.completed}</span>
            </span>
            <span
              style={{
                paddingLeft: '10px',
                marginLeft: '10px',
                borderLeft: `2px solid  #a0bbdc`,
              }}>
              <ExclamationCircleFilled
                style={{ color: '#ff4d4f', marginRight: 4 }}
              />
              Chưa hoàn thành:{' '}
              <span style={{ fontWeight: 500 }}>{counterTask.uncompleted}</span>
            </span>
          </Tag>
        </div>
        <TableWrapper>
          <Table
            bordered
            rowKey={record => record.id}
            dataSource={taskListView}
            columns={columns}
            pagination={false}
            sticky
            scroll={{ x: 1300, y: 'calc(100vh - 250px)' }}
            locale={{
              emptyText: (
                <Empty
                  style={loadingList ? { opacity: 0 } : {}}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ),
            }}
            loading={{
              tip: 'Đang tải nhiệm vụ...',
              spinning: loadingList,
            }}
          />
        </TableWrapper>
      </ContentBlockWrapper>
      <MissionCreatePopup
        isMissionFull={true}
        isModalOpen={isVisibleMissionFullCreatePopup}
        handleCancel={handleCancelCreateMission}
      />

      <MissionFullDetailModal
        taskInfo={taskInfo}
        isVisibleMissionFullDetailModal={isVisibleMissionFullDetailModal}
        handleCloseDetailMission={handleCloseDetailMission}
      />
      <ListHistoryLogModal
        type={'TASK'}
        isVisible={isVisibleHistory}
        taskInfo={documentHistory}
        handleCloseHistory={handleCloseHistory}
      />
    </MissionFullPageWrapper>
  )
}

MissionFullPage.propTypes = {}

export default inject(
  'taskStore',
  'commonStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
  'selectPeopleStore'
)(observer(MissionFullPage))
