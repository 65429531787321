import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import RiskTemplateList from '../RiskTemplate/RiskTemplateList'
import RiskAriseList from '../RiskTemplate/RiskAriseList'
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle/PageTitle'

const RiskTemplate = props => {
  const [activeTab, setActiveTab] = useState('rui_ro_nhan_dien')
  const handleTabChange = key => {
    setActiveTab(key)
  }

  const tabs = [
    {
      key: 'rui_ro_nhan_dien',
      name: 'Rủi ro nhận diện',
      component: <RiskTemplateList />,
    },
    {
      key: 'rui_ro_nhan_dien_phat_sinh',
      name: 'Rủi ro nhận diện phát sinh',
      component: <RiskAriseList />,
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản trị loại rủi ro | Phiếu kiểm soát rủi ro</title>
      </Helmet>
      <PageTitle location={props.location}></PageTitle>
      <ContentBlockWrapper>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {tabs.map(tab => (
            <Tabs.TabPane tab={tab.name} key={tab.key}>
              <div style={{ flexGrow: 1 }}>{tab.component}</div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default RiskTemplate
