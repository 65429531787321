import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Empty, message, Popconfirm, Row, Tag } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import { ACL_TYPE } from '../../../constants'
import CompanySelect from '../CompanyDepartment/CompanySelect'

const renderTag = type => {
  if (type === ACL_TYPE.DEFAULT) {
    return (
      <Tag color={'blue'} style={{ borderRadius: 10 }}>
        Hệ thống
      </Tag>
    )
  }
  if (type === ACL_TYPE.CUSTOM) {
    return (
      <Tag color={'orange'} style={{ borderRadius: 10 }}>
        Tùy chỉnh
      </Tag>
    )
  }
  return (
    <Tag color={'purple'} style={{ borderRadius: 10 }}>
      {type}
    </Tag>
  )
}

const CompanyACLTable = ({ ...otherProps }) => {
  const { aclStore, companyStore } = otherProps
  const [isLoading, setLoadingState] = useState(false)
  const [selectedCompanyCode, setSelectedCompanyCode] = useState()

  useEffect(() => {
    companyStore
      .getCompanyList()
      .then(() => {
        const companies = companyStore.companyList
        if (companies.length > 0) {
          const firstCompanyCode = companies[0].code
          aclStore.getCompanyACLGroups(firstCompanyCode)
          setSelectedCompanyCode(firstCompanyCode)
        }
      })
      .catch(error => {
        console.log(error)
        message.error(error.vi || 'Có lỗi xảy ra')
      })
  }, [])

  const onChangeCompany = companyCode => {
    setLoadingState(true)
    aclStore
      .getCompanyACLGroups(companyCode)
      .catch(error => {
        console.log(error)
        message.error(error.vi || 'Có lỗi xảy ra')
      })
      .finally(() => setLoadingState(false))
    setSelectedCompanyCode(companyCode)
  }

  const createCompanyDefaultAcl = () => {
    setLoadingState(true)
    aclStore
      .createCompanyDefaultACLGroup(selectedCompanyCode)
      .then(() => aclStore.getCompanyACLGroups(selectedCompanyCode))
      .catch(error => {
        console.log(error)
        message.error(error.vi || 'Có lỗi xảy ra')
      })
      .finally(() => setLoadingState(false))
  }

  const tableColumns = [
    {
      title: 'Quyền người dùng',
      render: record => <span>{record.name}</span>,
    },
    {
      align: 'center',
      title: 'Loại',
      render: record => renderTag(record.type),
    },
  ]

  return (
    <>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 14,
        }}>
        <CompanySelect
          onChangeCompany={onChangeCompany}
          companyCode={selectedCompanyCode}
        />
        <Button
          style={{
            ...(aclStore.isDefaultAclGroupExisted && { color: 'gray' }),
          }}
          type={'primary'}
          onClick={createCompanyDefaultAcl}
          disabled={aclStore.isDefaultAclGroupExisted}>
          {aclStore.isDefaultAclGroupExisted ? null : <PlusCircleOutlined />}
          {aclStore.isDefaultAclGroupExisted
            ? 'Đã có phân quyền mặc định'
            : 'Tạo phân quyền mặc định'}
        </Button>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={aclStore.aclGroupList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
    </>
  )
}

export default inject('aclStore', 'companyStore')(observer(CompanyACLTable))
