import { Form, Input, message, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

const CreateCompanyDepartmentModal = ({
  visible,
  onCancel,
  companyCode,
  ...otherProps
}) => {
  const { departmentStore } = otherProps
  const [form] = Form.useForm()

  const onSubmit = async formValue => {
    const { name } = formValue
    try {
      await departmentStore.create(name, companyCode)
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra')
      return
    }

    message.success('Tạo phòng ban thành công')
    departmentStore.getAllDepartmentList(companyCode)
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      title="Tạo phòng ban"
      visible={visible}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          label="Tên phòng ban"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên phòng ban!' }]}>
          <Input placeholder={'Tên phòng ban'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default inject('departmentStore')(observer(CreateCompanyDepartmentModal))
