import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, message, Space, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import { difference } from 'lodash-es'
import { PROPOSAL_TYPE } from '../../../constants'

const ApprovalTemplateApply = props => {
  const [isApplying, setIsApplying] = useState(false)
  const { approvalTemplateStore, templateId } = props
  const { approvalTemplateDetail } = approvalTemplateStore
  const [selectedOption, setSelectedOption] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    let arr = []
    for (const [value] of Object.entries(PROPOSAL_TYPE)) {
      arr.push({ value: value, label: PROPOSAL_TYPE[value].label })
    }
    setOptions(arr)
  }, [])

  useEffect(() => {
    ;(async () => {
      await getApprovalTemplateDetail(props.templateId)
    })()

    return () => approvalTemplateStore.clearApprovalTemplateDetail()
  }, [props.templateId])

  useEffect(() => {
    setSelectedOption(approvalTemplateDetail.applied_to)
  }, [approvalTemplateDetail])

  const getApprovalTemplateDetail = async id => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.getApprovalTemplateDetail(id)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = value => {
    setSelectedOption(value)
  }

  const onFinish = async value => {
    try {
      setIsApplying(true)

      let activeItems = selectedOption.map(item => {
        return {
          apply_to: item,
          value: true,
        }
      })

      let inActiveItems = difference(
        options.map(item => item.value),
        selectedOption
      ).map(item => {
        return {
          apply_to: item,
          value: false,
        }
      })

      await approvalTemplateStore.applyApprovalTemplate(templateId, {
        description: null,
        data: [...activeItems, ...inActiveItems],
      })
      await approvalTemplateStore.getApprovalTemplateList()
      props.onCancel()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsApplying(false)
    }
  }

  return (
    <Form onFinish={onFinish} layout="vertical">
      <Spin spinning={isLoading}>
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          options={options}
          value={selectedOption}
          onChange={onChange}
        />
      </Spin>
      <ButtonFooter>
        <Space>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Đóng
          </Button>
          <Button
            icon={<EditOutlined />}
            type={'primary'}
            htmlType={'submit'}
            loading={isApplying}>
            Áp dụng
          </Button>
        </Space>
      </ButtonFooter>
    </Form>
  )
}

export default inject('approvalTemplateStore')(observer(ApprovalTemplateApply))
