import React, { memo, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Select, Tabs } from 'antd'
import {
  TabContainer,
  TabPanelContainer,
  UserTabPanelContainer,
} from './ContactStyled'
import ListUserPage from './ListUsers'
import ListDepartmentPage from './ListDepartment'
import EmptyContent from '../../components/EmptyContent'
import contactStore from './contactStore'

const { TabPane } = Tabs
const { Option } = Select

const ContactPage = props => {
  const {
    userStore,
    loadingAnimationStore,
    authenticationStore,
    companyStore,
  } = props
  const { companyList } = companyStore
  const { companyCode } = contactStore

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.setTableLoading(true)
      userStore.setFilter('userListStatus', true)
      userStore.setFilter(
        'companyCode',
        authenticationStore.currentCompanyCode()
      )
      userStore.getUserList().finally(() => {
        loadingAnimationStore.setTableLoading(false)
      })

      contactStore.companyCode = authenticationStore.currentCompanyCode()
    }
    return () => {
      userStore.clearStore()
    }
  }, [authenticationStore.currentUser])

  const onCompanyChange = value => {
    contactStore.companyCode = value
    loadingAnimationStore.setTableLoading(true)
    userStore.setFilter('companyCode', value)
    userStore.setFilter('userListPageIndex', 0)
    userStore.getUserList().finally(() => {
      loadingAnimationStore.setTableLoading(false)
    })

    return () => {
      userStore.companyCode = null
      contactStore.companyCode = null
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Danh bạ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Danh Bạ'} hiddenGoBack>
        <Select
          allowClear
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: 200 }}
          value={companyCode}
          onChange={onCompanyChange}
          notFoundContent={<EmptyContent />}
          placeholder={'-- Chọn công ty --'}>
          {companyList.map(item => (
            <Option key={item.code} value={item.code}>
              {item.name}
            </Option>
          ))}
        </Select>
      </PageTitle>
      <ContentBlockWrapper>
        <TabContainer>
          <ListDepartmentPage />
        </TabContainer>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ContactPage.propTypes = {}

export default memo(
  inject(
    'departmentStore',
    'userStore',
    'loadingAnimationStore',
    'authenticationStore',
    'companyStore'
  )(observer(ContactPage))
)
