import React, { useEffect, useState } from 'react'
// Component
import { DivFlex } from '../../../../components/Common/CellText'
// Style Component
import {
  LableItem,
  ModalWrapper,
  TitleModal,
} from './PopupHandleDocumentIncomingStyled'
// Ant design
import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import moment from 'moment'
import { blue, yellowPrimary } from '../../../../color'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  INTERNAL_DOCUMENT_INCOMING_STATUS,
} from '../../../../constants'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../../components/SelectGroupPopup/SelectGroupPopup'
import { SelectList } from '../../../MissionCreatePage/MissionCreatePageStyled'

const { Option } = Select

const PopupHandleDocumentIncoming = props => {
  const {
    internalDocumentStore,
    loadingAnimationStore,
    selectPeopleStore,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
    clearSelected,
  } = selectPeopleStore

  const {
    selectedInternalDocumentIncoming,
    userAssignList,
    departmentAssignList,
    groupAssignList,
  } = internalDocumentStore

  const {
    end_date,
    start_date,
    document_status,
    date_issued,
  } = selectedInternalDocumentIncoming

  const {
    isVisiblePopupHandleDocument,
    handleCloseHandleDocument,
    handleCancelHandleDocument,
  } = props

  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setCombinerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleSubmit = async () => {
    const new_items_assign = []
    const remove_items_assign = []
    const listAssignOrigin = [
      ...userAssignList,
      ...departmentAssignList,
      ...groupAssignList,
    ].map(el => ({
      assignee_code: el.assignee_code,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const listAssignAfter = [
      ...followerList,
      ...handlerList,
      ...combinerList,
    ].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    listAssignAfter.forEach(assignAfter => {
      if (
        !listAssignOrigin.some(
          assignOrigin =>
            assignOrigin.assignee_code + assignOrigin.permission ===
            assignAfter.assignee_code + assignAfter.permission
        )
      ) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (
        !listAssignAfter.some(
          assignAfter =>
            assignAfter.assignee_code + assignAfter.permission ===
            assignOrigin.assignee_code + assignOrigin.permission
        )
      ) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })
    const dataSubmit = {
      new_items: new_items_assign,
      remove_items: remove_items_assign,
      start_date: valueInput.start_date,
      end_date: valueInput.end_date,
      document_status: valueInput.document_status,
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await internalDocumentStore.updateAssignees(
        selectedInternalDocumentIncoming.code,
        dataSubmit
      )
      await internalDocumentStore.getInternalDocumentIncomingById(
        selectedInternalDocumentIncoming.code
      )
      handleCloseHandleDocument()
      message.success('Xử lý văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const [valueInput, setValueInput] = useState({
    start_date: null,
    end_date: null,
    document_status: null,
  })

  useEffect(() => {
    if (!isVisiblePopupHandleDocument) return

    if (
      selectUserData[ASSIGNEE_TYPE.COMBINER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectUserData(
        selectPeopleStore.convertSelectDataObj(userAssignList)
      )
    }

    if (
      selectDepartmentData[ASSIGNEE_TYPE.COMBINER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectDepartmentData(
        selectPeopleStore.convertSelectDataObj(departmentAssignList)
      )
    }

    if (
      selectGroupData[ASSIGNEE_TYPE.COMBINER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectGroupData(
        selectPeopleStore.convertSelectDataObj(groupAssignList)
      )
    }

    setValueInput({
      start_date: start_date || (date_issued ? moment(date_issued) : null),
      end_date: end_date,
      document_status:
        document_status !== INTERNAL_DOCUMENT_INCOMING_STATUS.INPROGRESS
          ? document_status
          : null,
    })
  }, [
    selectedInternalDocumentIncoming,
    isVisiblePopupHandleDocument,
    originDepartmentWithUserList,
  ])

  const disabledEndDate = endValue => {
    const startValue = valueInput.start_date;
    if (!startValue || !endValue) {
      return false;
    }
    return endValue.isBefore(startValue, 'day');
  };
  return (
    <ModalWrapper
      closable={false}
      footer={null}
      width={900}
      title={
        <TitleModal>
          <span className={'title-modal'}>Xử lý văn bản</span>
          <Space>
            <Button
              danger
              onClick={() => {
                handleCancelHandleDocument()
                clearSelected()
              }}>
              Hủy bỏ
            </Button>
            <Button type={'primary'} onClick={handleSubmit}>
              Đồng ý
            </Button>
          </Space>
        </TitleModal>
      }
      visible={isVisiblePopupHandleDocument}>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <LableItem>Trạng thái văn bản:</LableItem>
          <Select
            placeholder={'Trạng thái'}
            style={{ width: '100%' }}
            value={valueInput.document_status}
            onChange={value =>
              setValueInput({ ...valueInput, document_status: value })
            }>
            <Option value={INTERNAL_DOCUMENT_INCOMING_STATUS.PENDING}>
              Chưa xử lý
            </Option>
            <Option value={INTERNAL_DOCUMENT_INCOMING_STATUS.COMPLETE}>
              Hoàn thành
            </Option>
          </Select>
        </Col>
        <Col span={8}>
          <LableItem>Ngày bắt đầu:</LableItem>
          <DatePicker
            style={{ width: '100%' }}
            format={DATE_FORMAT_LIST}
            value={valueInput.start_date && moment(valueInput.start_date)}
            onChange={value =>
              setValueInput({
                ...valueInput,
                start_date: value,
              })
            }
          />
        </Col>
        <Col span={8}>
          <LableItem>Ngày kết thúc:</LableItem>
          <DatePicker
            style={{ width: '100%' }}
            format={DATE_FORMAT_LIST}
            value={valueInput.end_date && moment(valueInput.end_date)}
            onChange={value =>
              setValueInput({
                ...valueInput,
                end_date: value,
              })
            }
            disabledDate={disabledEndDate}
          />
        </Col>
        <Col span={8}>
          <DivFlex margin={'0 0 15px'}>
            <LableItem>Lãnh đạo xử lý:</LableItem>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)
                  }
                />
              </Tooltip>
            </Space>
          </DivFlex>
          <SelectList>
            {handlerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
        <Col span={8}>
          <DivFlex margin={'0 0 15px'}>
            <LableItem>Người xử lý:</LableItem>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.COMBINER)
                  }
                />
              </Tooltip>
              <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                <Button
                  icon={<TeamOutlined />}
                  style={{ color: yellowPrimary }}
                  onClick={() =>
                    handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                  }
                />
              </Tooltip>
            </Space>
          </DivFlex>
          <SelectList>
            {combinerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
        <Col span={8}>
          <DivFlex margin={'0 0 15px'}>
            <LableItem>Phối hợp/ Theo dõi:</LableItem>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
              <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                <Button
                  icon={<TeamOutlined />}
                  style={{ color: yellowPrimary }}
                  onClick={() =>
                    handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>
          </DivFlex>
          <SelectList>
            {followerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
      </Row>
      <SelectPeoplePopup
        onlyOneUser={
          selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER
        }
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </ModalWrapper>
  )
}

PopupHandleDocumentIncoming.propTypes = {}

export default inject(
  'internalDocumentStore',
  'loadingAnimationStore',
  'selectPeopleStore'
)(observer(PopupHandleDocumentIncoming))
