import { Button, Empty, message, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import ApprovalTemplateApply from './ApprovalTemplateApply'
import ApprovalTemplateUpdate from './ApprovalTemplateUpdate'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { blue } from '../../../color'

const { confirm } = Modal

const ApprovalTemplateList = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [isVisibleApplyModal, setIsVisibleApplyModal] = useState(false)

  const { selectPeopleStore, authenticationStore } = props
  const { originDepartmentWithUserList, companyCode } = selectPeopleStore

  const { approvalTemplateStore } = props

  const [selectedTemplateId, setSelectedTemplateId] = useState({})

  useEffect(() => {
    ;(async () => {
      await loadApprovalTemplateList()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (companyCode) {
        try {
          await selectPeopleStore.getDepartmentWithUsers()
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        }
      }
    })()
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, [authenticationStore.currentUser])

  const loadApprovalTemplateList = async () => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.getApprovalTemplateList()
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenUpdateModal = id => event => {
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleCancelApplyModal = () => {
    setIsVisibleApplyModal(false)
  }

  const handleOpenAppyModal = id => event => {
    setIsVisibleApplyModal(true)
    setSelectedTemplateId(id)
  }

  const handleDelete = async code => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.delete(code)
      await loadApprovalTemplateList()
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const showConfirm = code => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa Cấp duyệt này ?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDelete(code)
      },
    })
  }

  const tableColumns = [
    {
      title: 'Tên mẫu',
      render: record => <p>{record.name}</p>,
    },
    {
      title: 'Mô tả',
      width: 450,
      render: record => <p>{record.description}</p>,
    },
    {
      title: 'Thao tác',
      width: 150,
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button
            type={'text'}
            style={{ color: blue }}
            onClick={handleOpenUpdateModal(record.code)}>
            Sửa
          </Button>

          {/* <Button
            type={'text'}
            style={{ color: blue }}
            onClick={handleOpenAppyModal(record.code)}>
            Áp dụng
          </Button> */}

          <Button type={'text'} danger onClick={() => showConfirm(record.code)}>
            Xóa
          </Button>
        </div>
      ),
    },
  ]
  return (
    <>
      <TableComponent
        rowKey={record => record.code}
        dataSource={approvalTemplateStore.approvalTemplateList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />

      <Modal
        width={1000}
        title={'Chỉnh sửa cấp duyệt'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ApprovalTemplateUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
          />
        )}
      </Modal>

      <Modal
        width={800}
        title={'Áp dụng mẫu'}
        visible={isVisibleApplyModal}
        maskClosable={false}
        onCancel={handleCancelApplyModal}
        footer={null}
        destroyOnClose>
        {isVisibleApplyModal && (
          <ApprovalTemplateApply
            templateId={selectedTemplateId}
            onCancel={handleCancelApplyModal}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'approvalTemplateStore',
  'authenticationStore',
  'selectPeopleStore'
)(observer(ApprovalTemplateList))
