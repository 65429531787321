import moment from 'moment'

export default Object.freeze({
  FrequenceType: {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
  },

  Trait: {
    FREQUENCY: 'FREQUENCY',
    COUNT: 'COUNT',
    UNTIL: 'UNTIL',
    DAY_OF_MONTH: 'DAY_OF_MONTH',
    DAY_OF_WEEK: 'DAY_OF_WEEK',
  },

  DayOfWeek: {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
  },

  extractFromFormValues(formValue) {
    const {
      is_repeat,
      repeat_type,
      repeat_interval,
      day_of_weeks,
      start_date,
      repeat_until,
      repeat_count,
    } = formValue

    if (!is_repeat) {
      return null
    }

    const result = {
      frequency: repeat_type,
      ...(day_of_weeks &&
        day_of_weeks.length > 0 && { dayOfWeek: day_of_weeks }),
      ...(repeat_type === this.FrequenceType.MONTHLY && {
        dayOfMonth: moment(start_date).date(),
      }),
      ...(repeat_interval && { interval: repeat_interval }),
      ...(repeat_until && { until: repeat_until }),
      ...(repeat_count && { count: repeat_count }),
    }

    return result
  },
})
