import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Layout
import DocumentsLayout from '../../../layouts/DocumentsLayout'
// Component
import PageTitle from '../../../components/PageTitle'
import UnreadText from '../../../components/UnreadText/UnreadText'
import AdvanceFilterBlock from './AdvanceFilterBlock'
// Style Component
import { FilterButtonWrapper } from '../InternalDocumentIncomingDocPage/InternalDocumentIncomingDocPageStyled'
import {
  SearchBar,
  TableFooterActionWrapper,
} from './InternalDocumentOutgoingDocPageStyled'
// Ant design
import {
  CloseCircleOutlined,
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  message,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import { useLocation, withRouter } from 'react-router-dom'
import AccessControlAction from '../../../components/AccessControlAction'
import { EmptyText } from '../../../components/Common/CellText'
import TableComponent from '../../../components/Common/TableComponent'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  DOCUMENT_TYPE,
} from '../../../constants'
import utils from '../../../utils'
import { systemName } from '../../../config'

const { Search } = Input
const { Option } = Select

const InternalDocumentOutgoingOutgoingDocPage = props => {
  const {
    history,
    internalDocumentStore,
    loadingAnimationStore,
    bookStore,
    authorityStore,
  } = props

  const {
    internalDocumentOutgoingList,
    internalDocumentOutgoingListTotalCount,
    queryParamsOutgoing,
  } = internalDocumentStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  const NGAY_DI_WIDTH = 100
  const SO_HIEU_WIDTH = 100
  const TRICH_YEU_WIDTH = 400
  const BO_PHAN_DU_THAO_WIDTH = 250
  const NGUOI_KY_WIDTH = 150
  const SCROLL_WIDTH = 1000

  const tableColumns = [
    {
      title: 'Ngày đi',
      width: NGAY_DI_WIDTH,
      render: record => (
        <UnreadText
          text={moment(record.outgoing_date).format(DATE_FORMAT_DEFAULT)}
          isUnread={checkUnread(record)}
          width={NGAY_DI_WIDTH}
        />
      ),
    },
    {
      title: 'Số hiệu',
      width: SO_HIEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.document_number}
          isUnread={checkUnread(record)}
          width={SO_HIEU_WIDTH}
        />
      ),
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.title}
          isUnread={checkUnread(record)}
          width={TRICH_YEU_WIDTH}
        />
      ),
    },
    {
      title: 'Bộ phận dự thảo',
      width: BO_PHAN_DU_THAO_WIDTH,
      render: record => (
        <UnreadText
          text={record.authority_issued}
          isUnread={checkUnread(record)}
          width={BO_PHAN_DU_THAO_WIDTH}
        />
      ),
    },
    {
      title: 'Người ký',
      width: NGUOI_KY_WIDTH,
      render: record =>
        record.signer ? (
          <UnreadText
            text={record.signer}
            isUnread={checkUnread(record)}
            width={NGUOI_KY_WIDTH}
          />
        ) : (
          <EmptyText>Không có.</EmptyText>
        ),
    },
  ]

  const checkUnread = record => record.status_doc_outgoing === 'UNREAD'

  const exportDocument = useCallback(async () => {
    const col = [
      'Ngày đi',
      'Số hiệu',
      'Trích yếu',
      'Bộ phận dự thảo',
      'Người ký',
    ]

    let rows = []
    rows.push(col)

    try {
      setExportLoading(true)
      const res = await internalDocumentStore.getForExportInternalDocumentOutgoingListAll()

      if (res && res.data && res.data.data) {
        res.data.data.map(item => {
          rows.push([
            moment(item.outgoing_date).format(DATE_FORMAT_DEFAULT),
            item.document_number,
            item.title,
            item.authority_issued,
          ])
        })
      }
      utils.exportExcel(
        rows,
        'VBNB - văn bản đi - ' + moment().format('YYYY-MM-DD hmms')
      )
      internalDocumentStore.setQueryParamsOutgoing({
        ...queryParamsOutgoing,
        ...queryStringParse,
      })
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setExportLoading(false)
    }
  }, [queryParamsOutgoing])

  const handleChangeParams = params => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      page: 0,
      ...params,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        ...params,
      },
      {
        skipNull: true,
      }
    )
    history.replace(
      `/internal-document/outgoing-document?${queryStringStringify}`
    )
  }

  const handleQuickSearch = title => {
    handleChangeParams({ title: title ? title.trim() : null })
  }

  const handleChangeUnread = event => {
    let only_unread = event?.target?.checked ? true : false
    handleChangeParams({ only_unread: only_unread })
  }

  const onChangePagination = pageIndex => {
    handleChangeParams({ page: pageIndex - 1 })
  }

  const getInternalDocumentOutgoingList = async () => {
    try {
      loadingAnimationStore.setTableLoading(true)
      await internalDocumentStore.getInternalDocumentOutgoingList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    internalDocumentStore.setQueryParamsOutgoing({
      ...queryParamsOutgoing,
      ...queryStringParse,
    })
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await setCheckPagination(false)
        authorityStore.setAuthorityIssuedsQueryParams({
          type: DOCUMENT_TYPE.OUTGOING,
          key: null,
        })
        await Promise.all([
          authorityStore.getAuthorityIssueds(),
          bookStore.getBookGroup('DI'),
          internalDocumentStore.getInternalDocumentOutgoingList(),
        ])
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
        setCheckPagination(true)
      }
    })()
    return () => {
      internalDocumentStore.clearInternalDocumentOutgoingList()
      bookStore.clearBookGroupList()
      internalDocumentStore.clearQueryParamsOutgoing()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await getInternalDocumentOutgoingList()
    })()
  }, [queryParamsOutgoing])

  useEffect(() => {
    // Đếm số filter hiện tại
    let count = 0
    if (queryStringParse.title) {
      count++
    }
    if (queryStringParse.from_date_issued && queryStringParse.to_date_issued) {
      count++
    }
    if (queryStringParse.document_type) {
      count++
    }
    if (queryStringParse.document_number) {
      count++
    }
    if (queryStringParse.urgency_level) {
      count++
    }
    if (queryStringParse.signer) {
      count++
    }
    if (queryStringParse.book_group_id) {
      count++
    }
    if (queryStringParse.book_id) {
      count++
    }
    if (queryStringParse.authority_name) {
      count++
    }
    setCountFilter(count)
  }, [queryStringParse])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Văn bản đi" hiddenGoBack>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__OUTGOING}>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                history.push('/internal-document/outgoing-document/create')
              }
              type={'primary'}>
              Tạo mới văn bản đi
            </Button>
          </AccessControlAction>
        </PageTitle>
      }
      selectedKey="outgoing-document"
      linkToIncomingDocument="/internal-document/incoming-document"
      linkToOutgoingDocument="/internal-document/outgoing-document"
      linkToSignDocument="/internal-document/sign-document"
      linkToConfigDocument="/internal-document/config-document/document-books">
      <Helmet>
        <title>
          Văn bản đi | {systemName} Quản lý VB {systemName}
        </title>
      </Helmet>
      <div>
        <Row>
          <Col span={12}>
            {!showAdvanceFilter ? (
              <SearchBar style={{ width: 500 }}>
                <Search
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    width: 300,
                  }}
                  allowClear
                  onSearch={value => handleQuickSearch(value)}
                  defaultValue={queryStringParse.title}
                  placeholder={'Tìm kiếm văn bản theo Trích yếu / Số hiệu'}
                />
                <Space
                  style={{
                    width: 180,
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    marginLeft: 10,
                  }}>
                  <Checkbox
                    checked={
                      queryStringParse.only_unread == 'true' ? true : false
                    }
                    onChange={handleChangeUnread}>
                    Hiển thị văn bản chưa đọc
                  </Checkbox>
                </Space>
              </SearchBar>
            ) : null}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButtonWrapper>
              <span
                className={'count-filter'}
                style={{ display: !countFilter && 'none' }}>
                {countFilter}
              </span>
              <Button
                type={showAdvanceFilter ? 'primary' : 'default'}
                ghost={showAdvanceFilter}
                style={{ marginBottom: showAdvanceFilter ? 10 : 0 }}
                onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                {showAdvanceFilter ? (
                  <Space>
                    <CloseCircleOutlined />
                    Ẩn tìm kiếm nâng cao
                  </Space>
                ) : (
                  <Space>
                    <FilterOutlined />
                    Tìm kiếm nâng cao
                  </Space>
                )}
              </Button>
            </FilterButtonWrapper>
            <Button
              loading={exportLoading}
              onClick={exportDocument}
              style={{ marginLeft: '10px' }}>
              <PrinterOutlined />
              In
            </Button>
          </Col>
        </Row>
        {showAdvanceFilter ? (
          <AdvanceFilterBlock handleChangeParams={handleChangeParams} />
        ) : null}
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/internal-document/outgoing-document/view/${record.code}`
                ),
            }
          }}
          rowKey={record => record.id}
          dataSource={internalDocumentOutgoingList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText:
              checkPagination === true ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ) : (
                <div style={{ opacity: 0.1, height: '150px' }} />
              ),
          }}
          loading={{
            tip: 'Đang tải văn bản...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />
        <TableFooterActionWrapper>
          {checkPagination === true ? (
            <Pagination
              onChange={e => onChangePagination(e)}
              pageSize={queryParamsOutgoing.size}
              showSizeChanger={false}
              showLessItems
              current={queryParamsOutgoing.page + 1}
              total={internalDocumentOutgoingListTotalCount}
              hideOnSinglePage={true}
            />
          ) : (
            ''
          )}
        </TableFooterActionWrapper>
      </div>
    </DocumentsLayout>
  )
}

InternalDocumentOutgoingOutgoingDocPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'internalDocumentStore',
      'loadingAnimationStore',
      'authorityStore',
      'bookStore',
      'selectUserStore'
    )(observer(InternalDocumentOutgoingOutgoingDocPage))
  )
)
