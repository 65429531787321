import React, { useEffect, useState } from 'react'
import {
  ASSIGNEE_TYPE,
  ISO_DATE_FORMAT,
  STATUS_DEFAULT,
  VIEWLOG_DATE,
  WORK_STATUS,
} from '../../../constants'
import { inject, observer } from 'mobx-react'
import utils from '../../../utils'
import { ContentWrapper, HeadingButtonWrapper } from './DrawerWorkStyled'
import { Button, List, message, Modal, Menu, Dropdown } from 'antd'
import { toJS } from 'mobx'
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { blue, greenPrimary } from '../../../color'
import { EmptyText, WordBreak } from '../../../components/Common/CellText'
import { Transition } from 'react-transition-group'
import date_format from '../../../date_format'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import useOpenItem from '../../../hooks/useOpenItem'
import PopupAddPersonWork from '../PopupAddPersonWork'
import PopupUpdateWork from '../PopupUpdateWork/PopupUpdateWork'
import moment from 'moment'
import WorkAllCommentList from './WorkAllCommentList'
import { PopupAddPersonVote, PopupEditPersonVote } from '../PopupVote'
import ListHistoryLogModal from '../../ListHistoryLog/ListHistoryLogModal'
import VoteWorkDetail from './VoteWorkDetail'
import './SubWorkDetail.less'
import UnreadText from '../../../components/UnreadText/UnreadText'
import queryString from 'query-string'
const duration = 500

const defaultStyle = {
  transition: `all ${duration}ms cubic-bezier(.4,0,.2,1)`,
  opacity: 0,
  padding: '15px 0',
  margin: 0,
  maxHeight: 0,
}

const transitionStyles = {
  entering: { padding: 0, maxHeight: 0, opacity: 0 },
  entered: { padding: '15px 0', maxHeight: 5000, opacity: 1 },
  exiting: { padding: 0, maxHeight: 0, opacity: 0 },
  exited: { padding: 0, maxHeight: 0, opacity: 0 },
}

const { confirm } = Modal

const SubWorkDetail = props => {
  const {
    workStore,
    selectUserStore,
    currentViewingDetailWorkCodes,
    setCurrentViewingDetailWorkCodes,
    authenticationStore,
    loadingAnimationStore,
    voteStore,
    selectPeopleStore,
  } = props

  const { currentUser } = authenticationStore

  const [loadingStatus, setLoadingStatus] = useState(false)

  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [workDetail, setWorkDetail] = useState(props.workDetail || {})
  const [status, setStatus] = useState(workDetail.status)
  const [isVisibleHistory, setIsVisibleHistory] = useState(false)
  const [documentHistory, setDocumentHistory] = useState({})
  const [voteDetail, setVoteDetail] = useState({})
  const {
    code,
    content,
    attachments,
    work_type,
    assignees,
    created_at,
    title,
    start_date,
    parent_id,
    due_date,
  } = workDetail
  const [is_read, setIsRead] = useState(workDetail.is_read)

  useEffect(() => {
    reloadVoteDetail()
  }, [code])

  useEffect(() => {
    setHandlerList(
      assignees
        ? selectUserStore.convertListToDefault(
            toJS(assignees).filter(
              el =>
                el.permission === ASSIGNEE_TYPE.HANDLER &&
                el.assignee_code !== 'admin'
            )
          )
        : []
    )
    setFollowerList(
      assignees
        ? selectUserStore.convertListToDefault(
            toJS(assignees).filter(
              el =>
                el.permission === ASSIGNEE_TYPE.FOLLOWER &&
                el.assignee_code !== 'admin'
            )
          )
        : []
    )
  }, [assignees])

  const renderDate = date => {
    if (!date) return <EmptyText>Không rõ</EmptyText>
    return (
      <span style={{ fontStyle: 'italic' }}>
        {' '}
        {date_format.DATE_FORMAT_SLASH(date)}
      </span>
    )
  }

  const handleUpdateStatusWork = async status => {
    setLoadingStatus(true)
    try {
      await workStore.updateStatusWork(code, status)
      if (status === STATUS_DEFAULT.COMPLETE) {
        return message.success('Bạn đã đánh dấu công việc hoàn thành!')
      }
      message.success('Bạn đã đánh dấu công việc là đang thực hiện!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setLoadingStatus(false)
      setStatus(status)
    }
  }

  const showConfirmDeleteWork = () => {
    confirm({
      title: 'Bạn có muốn xóa công việc này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteWork()
      },
    })
  }

  const handleDeleteWork = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await workStore.deleteWork(code)
      const parentId = workStore.workDetail.code
      if (parentId) {
        window.history.replaceState(
          null,
          null,
          `/works-procedure/view/${parentId}`
        )
        await workStore.getWorkDetail(parentId, true)
      }

      message.success('Xóa công việc thành công!')
    } catch (err) {
      console.log(err)
      if (err?.errorCode === 'WORK-106') {
        message.error('Công việc này đã được gán ở KPI nên không thể xóa')
      } else message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const {
    isOpen: isVisibleAddPersonWork,
    handleOpen: handleOpenAddPersonWork,
    handleClose: handleClosePopupAddPersonWork,
  } = useOpenItem()

  const {
    isOpen: isVisiblePopupUpdateWork,
    handleOpen: handleOpenPopupUpdateWork,
    handleClose: handleClosePopupUpdateWork,
  } = useOpenItem()

  const handleCloseUpdateWork = () => {
    handleClosePopupUpdateWork()
    reloadWorkDetail()
  }

  const handleCloseAddPersonWork = () => {
    handleClosePopupAddPersonWork()
    reloadWorkDetail()
  }

  const {
    isOpen: isVisibleEditPersonVote,
    handleOpen: handleOpenEditPersonVote,
    handleClose: handleCloseEditPersonVote,
  } = useOpenItem()

  const {
    isOpen: isVisibleAddPersonVote,
    handleOpen: openPopupAddPersonVote,
    handleClose: handleCloseAddPersonVote,
  } = useOpenItem()

  const handleCloseHistory = async type => {
    setIsVisibleHistory(false)
  }

  const reloadWorkDetail = () => {
    workStore.getWorkDetailResponse(code).then(response => {
      let workDetailData = response.data
      if (response.data.code !== code && response.data.subwork.length > 0) {
        workDetailData = response.data.subwork[0]
      }
      const complete_date = moment(workDetailData.complete_date).format(
        ISO_DATE_FORMAT
      )
      const due_date = moment(workDetailData.due_date).format(ISO_DATE_FORMAT)
      if (
        workDetailData.due_date &&
        workDetailData.status === WORK_STATUS.COMPLETE &&
        moment(complete_date).isAfter(due_date)
      ) {
        workDetailData = {
          ...workDetailData,
          overdue: true,
        }
      }
      setWorkDetail(workDetailData)
    })
  }

  const reloadVoteDetail = () => {
    voteStore.getVoteDetailResponse(code).then(response => {
      let vd = response.data.users.map(user => ({
        ...user,
        permission: 'USER',
        assignee_type: 'USER',
      }))
      setVoteDetail(vd)
    })
  }

  const handleOpenPopupAddPersonVote = () => {
    openPopupAddPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj([])
    )
  }
  const handleOpenPopupeditPersonVote = () => {
    handleOpenEditPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj(voteDetail)
    )
  }

  const exportWork = () => {
    utils.exportPDF('divToPrint', title)
  }

  const handleOpenWorkDetail = async (code, parent_id) => {
    let list = []
    if (!currentViewingDetailWorkCodes.includes(code)) {
      list.push(code)
    }
    setCurrentViewingDetailWorkCodes(list)
    const queryStringStringify = queryString.stringify(
      {
        child_id: code,
      },
      {
        skipNull: true,
      }
    )
    window.history.replaceState(
      null,
      null,
      `/works-procedure/view/${parent_id}?${queryStringStringify}`
    )
    workStore.getWorkDetailResponse(code)
  }

  const isOpenDetail = code => {
    return currentViewingDetailWorkCodes.includes(code)
  }

  const menuButton = (
    <Menu>
      <>
        {status !== STATUS_DEFAULT.COMPLETE &&
          (currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              ?.assignee_code ||
            handlerList.some(el => currentUser?.username === el.id)) && (
            <Menu.Item
              key="add_assignee"
              icon={<PlusCircleOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenAddPersonWork()
              }}>
              <span style={{ color: blue }}>Thêm người</span>
            </Menu.Item>
          )}

        <Menu.Item
          key="history"
          icon={<HistoryOutlined style={{ color: blue }} />}
          onClick={() => {
            setDocumentHistory({
              code: workDetail.code,
            })
            setIsVisibleHistory(true)
          }}>
          <span style={{ color: blue }}>Lịch sử</span>
        </Menu.Item>

        <Menu.Item
          key="print"
          icon={<PrinterOutlined style={{ color: blue }} />}
          onClick={() => {
            exportWork()
          }}>
          <span style={{ color: blue }}>In</span>
        </Menu.Item>

        {status !== STATUS_DEFAULT.COMPLETE &&
          currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              ?.assignee_code && (
            <Menu.Item
              key="delete"
              danger
              icon={<DeleteOutlined />}
              onClick={showConfirmDeleteWork}
              // disabled={disableChange}
            >
              <span>Xóa công việc</span>
            </Menu.Item>
          )}
      </>
    </Menu>
  )
  return (
    <List.Item
      key={workDetail.code}
      style={{
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        display: 'block',
        marginTop: '0 !important',
        marginBottom: '0 !important',
      }}>
      <div
        style={{
          backgroundColor: '#ffffff',
          position: 'relative',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: isOpenDetail(workDetail.code)
            ? 'none'
            : '5px',
          borderBottomRightRadius: isOpenDetail(workDetail.code)
            ? 'none'
            : '5px',
          borderTop: '1px solid #EBEBED',
          borderLeft: '1px solid #EBEBED',
          borderRight: '1px solid #EBEBED',
          borderBottom: isOpenDetail(workDetail.code)
            ? 'none'
            : '1px solid #EBEBED',
        }}>
        <div
          style={{
            lineHeight: '35px',
            paddingLeft: '20px',
            paddingRight: '20px',
            color: '#827C9E',
            backgroundColor: '#ffffff',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            width: 'calc(100% - 300px)',
            borderBottomLeftRadius: isOpenDetail(code) ? 'none' : '5px',
            borderBottomRightRadius: isOpenDetail(code) ? 'none' : '5px',
          }}
          onClick={() => {
            setIsRead(true)
            handleOpenWorkDetail(code, parent_id)
          }}>
          {status === STATUS_DEFAULT.COMPLETE ? (
            <CheckCircleFilled
              style={{ marginRight: 8, color: greenPrimary }}
            />
          ) : (
            <CheckCircleOutlined style={{ marginRight: 8 }} />
          )}
          <div
            style={{
              display: 'inline-block',
              width: 'calc(100% - 30px)',
              verticalAlign: 'middle',
            }}>
            <UnreadText text={title} width={'100%'} isUnread={!is_read} />
          </div>
        </div>
        <div
          style={{
            right: '25px',
            position: 'absolute',
            height: '100%',
            top: 0,
          }}>
          <div style={{ display: 'inline-block' }}>
            {handlerList.length > 0 ? (
              <PopoverWatchTime
                userList={handlerList}
                title={
                  work_type === WORK_STATUS.ELECTRONIC
                    ? 'Lãnh đạo ký duyệt'
                    : 'Danh sách người xử lý'
                }
                isFullViewer={false}
                compared_date={VIEWLOG_DATE.work_read_until}
                typeId={code}
                created_at={created_at}
              />
            ) : (
              <EmptyText>Không có.</EmptyText>
            )}
          </div>
          <div
            style={{
              verticalAlign: 'middle',
              display: 'inline-block',
              paddingLeft: 20,
            }}
            onClick={() => handleOpenWorkDetail(code, parent_id)}>
            {isOpenDetail(code) ? 'ẩn' : 'hiện'}
          </div>
          <div
            style={{
              height: '100%',
              verticalAlign: 'middle',
              display: 'inline-block',
            }}></div>
        </div>
      </div>
      <Transition
        in={isOpenDetail(code)}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={duration}>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              borderLeft: '1px solid #EBEBED',
              borderRight: '1px solid #EBEBED',
              borderBottom: '1px solid #EBEBED',
              padding: '0px 5px 5px 5px',
            }}>
            <div
              style={{
                height: 0.5,
                backgroundColor: '#f0f0f0',
                marginBottom: 10,
              }}
            />
            <div style={{ padding: '10px', width: '100%' }}>
              <div
                style={{
                  paddingBottom: 15,
                }}
                className="clearfix">
                <HeadingButtonWrapper className="work-action-btn ">
                  <Dropdown overlay={menuButton} trigger={['click']}>
                    <Button icon={<MoreOutlined />} />
                  </Dropdown>
                </HeadingButtonWrapper>
                {status !== STATUS_DEFAULT.COMPLETE && (
                  <Button
                    className="work-action-btn"
                    key="create_vote"
                    icon={<FormOutlined style={{ color: blue }} />}
                    onClick={() => {
                      voteDetail?.length > 0
                        ? handleOpenPopupeditPersonVote()
                        : handleOpenPopupAddPersonVote()
                    }}>
                    <span style={{ color: blue }}>Lấy ý kiến</span>
                  </Button>
                )}
                {status !== STATUS_DEFAULT.COMPLETE &&
                  currentUser?.username ===
                    assignees?.find(
                      el => el.permission === ASSIGNEE_TYPE.CREATE
                    )?.assignee_code && (
                    <Button
                      className="work-action-btn"
                      key="edit"
                      icon={<EditOutlined style={{ color: blue }} />}
                      onClick={() => {
                        handleOpenPopupUpdateWork()
                      }}
                      // #1333 cho phép sửa khi đã có comment
                      // disabled={disableChange}
                    >
                      <span style={{ color: blue }}>Sửa công việc</span>
                    </Button>
                  )}

                {status !== STATUS_DEFAULT.COMPLETE ? (
                  <Button
                    className="work-action-btn"
                    type={'primary'}
                    loading={loadingStatus}
                    icon={<CheckOutlined />}
                    onClick={() =>
                      handleUpdateStatusWork(STATUS_DEFAULT.COMPLETE)
                    }
                    style={{
                      backgroundColor: '#3aaf50',
                      borderColor: '#3aaf50',
                      verticalAlign: 'middle',
                    }}>
                    Hoàn thành
                  </Button>
                ) : (
                  <Button
                    className="work-action-btn"
                    icon={<RetweetOutlined />}
                    loading={loadingStatus}
                    style={{ verticalAlign: 'middle' }}
                    onClick={() =>
                      handleUpdateStatusWork(STATUS_DEFAULT.PENDING)
                    }>
                    Chưa hoàn thành
                  </Button>
                )}
                {/* </div> */}
              </div>
              <div
                style={{
                  border: '1px solid #EBEBED',
                  marginTop: 10,
                  borderRadius: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}>
                <ContentWrapper>
                  <div>
                    {content ? (
                      WordBreak(content, true)
                    ) : (
                      <div style={{ marginBottom: 14 }}>
                        <EmptyText>Không có nội dung.</EmptyText>
                      </div>
                    )}
                  </div>
                </ContentWrapper>
              </div>

              <div
                style={{
                  display: 'inline-block',
                  paddingTop: 24,
                }}>
                <span>
                  <b>Thời gian: </b>
                  {renderDate(start_date)}
                  <span>{` - `}</span>
                  {renderDate(due_date)}
                </span>
              </div>
              <div style={{ paddingTop: 20 }} className="clearfix">
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                  <dt>
                    {work_type === WORK_STATUS.ELECTRONIC
                      ? 'Lãnh đạo ký duyệt'
                      : 'Người xử lý'}
                  </dt>
                  <dd>
                    {handlerList.length > 0 ? (
                      <PopoverWatchTime
                        userList={handlerList}
                        title={
                          work_type === WORK_STATUS.ELECTRONIC
                            ? 'Lãnh đạo ký duyệt'
                            : 'Danh sách người xử lý'
                        }
                        isFullViewer={true}
                        compared_date={VIEWLOG_DATE.work_read_until}
                        typeId={code}
                        created_at={created_at}
                      />
                    ) : (
                      <EmptyText>Không có.</EmptyText>
                    )}
                  </dd>
                  <dt>Người theo dõi</dt>
                  <dd>
                    {followerList.length > 0 ? (
                      <PopoverWatchTime
                        userList={followerList}
                        title={'Danh sách người theo dõi'}
                        isFullViewer={true}
                        compared_date={VIEWLOG_DATE.work_read_until}
                        typeId={code}
                        created_at={created_at}
                      />
                    ) : (
                      <EmptyText>Không có người theo dõi.</EmptyText>
                    )}
                  </dd>
                  <dt>Tài liệu đính kèm</dt>
                  <dd>
                    {attachments && attachments.length > 0 ? (
                      attachments.map(file => (
                        <FileItem
                          key={file.file_id}
                          file_id={file.file_id}
                          file_name={file.file_name}
                          file_type={utils.getExtensionFile(file.file_name)}
                        />
                      ))
                    ) : (
                      <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                    )}
                  </dd>
                </div>
                {voteDetail.length > 0 && (
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                      marginLeft: 50,
                      float: 'right',
                    }}>
                    <dt>Lãnh đạo phê duyệt</dt>
                    <dd>
                      <VoteWorkDetail
                        voteDetail={voteDetail}
                        reloadVoteDetail={reloadVoteDetail}
                      />
                    </dd>
                  </div>
                )}
              </div>
              <WorkAllCommentList code={code} />
            </div>
          </div>
        )}
      </Transition>
      <PopupUpdateWork
        workDetail={workDetail}
        isVisiblePopupUpdateWork={isVisiblePopupUpdateWork}
        handleCloseUpdateWork={handleCloseUpdateWork}
      />

      <PopupAddPersonWork
        workDetail={workDetail}
        isVisibleAddPersonWork={isVisibleAddPersonWork}
        handleCloseAddPersonWork={handleCloseAddPersonWork}
      />
      <PopupEditPersonVote
        isVisibleEditPersonVote={isVisibleEditPersonVote}
        workDetail={workDetail || {}}
        handleCloseEditPersonVote={() => {
          handleCloseEditPersonVote()
          reloadVoteDetail()
          reloadWorkDetail()
        }}
      />

      <PopupAddPersonVote
        isVisibleAddPersonVote={isVisibleAddPersonVote}
        workDetail={workDetail || {}}
        handleCloseAddPersonVote={() => {
          handleCloseAddPersonVote()
          reloadVoteDetail()
          reloadWorkDetail()
        }}
      />
      <ListHistoryLogModal
        type={'WORK'}
        isVisible={isVisibleHistory}
        taskInfo={documentHistory}
        handleCloseHistory={handleCloseHistory}
      />
    </List.Item>
  )
}

SubWorkDetail.propTypes = {}

export default inject(
  'workStore',
  'aclStore',
  'selectUserStore',
  'voteStore',
  'authenticationStore',
  'selectPeopleStore',
  'loadingAnimationStore',
  'selectPeopleStore'
)(observer(SubWorkDetail))
