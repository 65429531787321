import React, { memo, useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import positionStore from '../../stores/positionStore'
import { MODAL_MODE } from '../../ui_constants'
import { inject, observer } from 'mobx-react'
const CreatePositonModal = props => {
  const {
    isVisibleCreatePositionModal,
    mode,
    handleCloseCreatePositionModal,
    currentPosition,
    setCurrentPosition,
    loadUserList,
    userStore,
    loadingAnimationStore,
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisibleCreatePositionModal && mode === MODAL_MODE.UPDATE) {
      form.setFieldsValue({
        name: currentPosition?.name,
      })
    }
  }, [isVisibleCreatePositionModal])

  const onFinish = async values => {
    try {
      setIsLoading(true)

      if (mode === MODAL_MODE.UPDATE) {
        if (currentPosition.name !== values.name.trim()) {
          await positionStore.update(currentPosition.code, values)
          currentPosition.name = values.name
        }
      } else {
        await positionStore.create(values)
      }

      closeAndResetForm()

      message.success(
        `${
          mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
        } chức danh thành công`
      )

      const response = await positionStore.getPositionList()
      if (mode === MODAL_MODE.CREATE) {
        loadingAnimationStore.setTableLoading(true)
        userStore.setFilter(
          'userOfPositionListByPositionCode',
          response?.data[response?.data.length - 1].code
        )
        userStore.setFilter('userOfPositionListPageIndex', 0)
        await loadUserList()
        setCurrentPosition(response?.data[response?.data.length - 1])
      }
    } catch (e) {
      if (e.errorCode && e.errorCode === 'POSITION_100') {
        message.error('Tên chức danh đã tồn tại')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      setIsLoading(false)
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseCreatePositionModal()
  }

  return (
    <Modal
      title={`${
        mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
      } chức danh`}
      footer={null}
      closable={true}
      visible={isVisibleCreatePositionModal}
      onCancel={closeAndResetForm}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên chức danh!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên chức danh:'}>
          <Input placeholder={'Nhập tên chức danh'} />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type={'primary'}>
            {mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreatePositonModal.propTypes = {}

export default memo(
  inject(
    'positionStore',
    'userStore',
    'loadingAnimationStore'
  )(observer(CreatePositonModal))
)
