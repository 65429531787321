import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout'
import TabHeader from '../../components/reports/TabHeader'
import { Button, Divider, Empty, message, Popconfirm, Tooltip } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import ReportCreateModal from './ReportCreateModal'
import TableComponent from '../../components/Common/TableComponent'
import queryString from 'query-string'

const ReportPage = ({ history, reportStore }) => {
  const { type, getReportList, reportList, deleteReport } = reportStore
  const [isLoading, setIsLoading] = useState(false)
  const [showAddReport, setShowAddReport] = useState(false)
  const [editReport, setEditReport] = useState(false)

  const handleCancelAddReport = useCallback(() => {
    setShowAddReport(false)
  }, [])

  const loadReportList = async params => {
    try {
      setIsLoading(true)
      await getReportList({ type })
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    const queryStringStringify = queryString.stringify(
      { type },
      {
        skipNull: true,
      }
    )
    history.replace(`/report?${queryStringStringify}`)
    ;(async () => {
      await loadReportList()
    })()
  }, [type])
  const handleDeleteReport = async code => {
    try {
      setIsLoading(true)
      await deleteReport(code)
      await loadReportList()
      message.success('Đã xóa!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }
  // const handleClickEditReport = record => {
  //   setEditReport(record)
  //   setShowAddReport(true)
  // }
  const tableColumns = [
    {
      title: 'Tiêu đề',
      key: 'title',
      render: record => <span>{record.title}</span>,
      width: '80%',
    },

    {
      title: 'Tác vụ',
      key: 'action',
      align: 'right',
      width: '10%',
      render: record => (
        <div>
          {/* <Tooltip title={'Sửa thông tin'}>
            <EditOutlined
              className={'anticon-color'} */}
          {/* // onClick={() => handleClickEditReport(record)} */}
          {/* /> */}
          {/* </Tooltip> */}
          <Divider type={'vertical'} />
          <div
            style={{ display: 'inline-block' }}
            onClick={e => {
              e.stopPropagation()
            }}>
            <Tooltip title={'Xoá'}>
              <Popconfirm
                onConfirm={() => handleDeleteReport(record.code)}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xoá rủi ro nhận diện này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      ),
    },
  ]
  return (
    <DashboardLayout>
      <Helmet>
        <title>Báo cáo | Danh sách Báo cáo</title>
      </Helmet>
      <PageTitle location={location} title="Danh sách Báo cáo" hiddenGoBack>
        <Button
          onClick={() => {
            setShowAddReport(true)
          }}
          type={'primary'}
          icon={<PlusOutlined />}>
          Tạo mới
        </Button>
      </PageTitle>
      <ContentBlockWrapper style={{ minHeight: 600 }}>
        <TabHeader />
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(`/report/view/${record.code}?type=${type}`),
            }
          }}
          rowKey={record => record.code}
          dataSource={reportList.reverse()}
          columns={tableColumns}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={isLoading}
        />
        <ReportCreateModal
          modalVisible={showAddReport}
          onCancel={handleCancelAddReport}
          isAdmin={true}
          typeCompany={type}
          loadAdminsList={''}
          onRefresh={loadReportList}
          editReport={editReport}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject('reportStore')(observer(ReportPage))
