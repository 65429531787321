import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import AccessControlAction from '../../../components/AccessControlAction'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import DashboardLayout from '../../../layouts/DashboardLayout'
import ApprovalTemplateList from './ApprovalTemplateList'
import ApprovalTemplateCreate from './ApprovalTemplateCreate'
import { runInAction } from 'mobx'
import { systemName } from '../../../config'

const ApprovalTemplatePage = props => {
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
    if (!bool) {
      runInAction(() => {
        props.approvalTemplateStore.approvalTemplateLevels = []
      })
    }
  }

  return (
    <>
      {!props.isAdministrativeManagementTab ? (
        <DashboardLayout>
          <Helmet>
            <title>Cấp duyệt | {systemName} Cấp duyệt</title>
          </Helmet>
          <PageTitle location={props.location}>
            <AccessControlAction
            // aclActionType={ACL_ACTION_TYPE.create__PROPOSAL_ADVANCE}
            >
              <Button
                onClick={handleToggleCreateModal(true)}
                type={'primary'}
                icon={<PlusOutlined />}>
                Tạo mới
              </Button>
            </AccessControlAction>
          </PageTitle>
          <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
            <ApprovalTemplateList />
          </ContentBlockWrapper>
        </DashboardLayout>
      ) : (
        <ApprovalTemplateList />
      )}
      <Modal
        width={1000}
        title={'Tạo mới cấp duyệt'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ApprovalTemplateCreate onCancel={handleToggleCreateModal(false)} />
        )}
      </Modal>
    </>
  )
}

export default inject('approvalTemplateStore')(observer(ApprovalTemplatePage))
