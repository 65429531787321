import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../components/PageTitle'
import {
  BlockLeft,
  BlockRight,
  PageContentWrapper,
} from './ChuTruongPageStyled'
import ChuTruongList from '../../../components/ChuTruongList'
import ChuTruongDetail from '../../../components/ChuTruongDetail'
import { inject, observer } from 'mobx-react'
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DashboardLayout from '../../../layouts/DashboardLayout'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { systemName } from '../../../config'

const consultWrapperStyle = {
  height: 'calc(100vh - 92px)',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}

const ChuTruongPage = props => {
  const {
    history,
    chuTruongCommentStore,
    loadingAnimationStore,
    match,
    chuTruongStore,
  } = props

  const { selectedPolicy, policyList } = chuTruongStore
  const { policyId } = match.params

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    chuTruongStore.clearSelectedPolicy()
    chuTruongStore.clearAllPolicyListFilter()
    chuTruongStore.setPolicyListPageIndex(0)
    ;(async () => {
      try {
        await chuTruongStore.getPolicyList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (!policyId) return
    ;(async () => {
      try {
        loadingAnimationStore.setShowSpinInline(true)
        await chuTruongStore.getPolicyDetail(policyId)
        await chuTruongCommentStore.getPolicyComment(policyId)
      } catch (err) {
        console.log(err)
        history.push('/administrative/policy')
        message.error(err.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    })()
    return () => {
      chuTruongCommentStore.clearPolicyCommentList()
      chuTruongStore.clearSelectedPolicy()
    }
  }, [policyId, chuTruongStore.sameCodeDetail])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý chủ trương | {systemName} Quản lý chủ trương</title>
      </Helmet>
      <PageTitle location={props.location}>
        <Button
          onClick={() => history.push('/administrative/policy/create')}
          type={'primary'}
          icon={<PlusOutlined />}>
          Tạo chủ trương mới
        </Button>
      </PageTitle>
      <PageContentWrapper hasContent={selectedPolicy.code}>
        <BlockLeft>
          <ChuTruongList />
        </BlockLeft>
        <BlockRight hasContent={selectedPolicy.code}>
          {
            <div style={consultWrapperStyle}>
              {selectedPolicy.code && policyList.length !== 0 ? (
                <ChuTruongDetail policyId={policyId} />
              ) : (
                <span style={{ fontSize: 20 }}>
                  {!loadingAnimationStore.showSpinInline &&
                    'Chọn chủ trương để xem chi tiết'}
                </span>
              )}
              <SpinnerInlineComponent />
            </div>
          }
        </BlockRight>
      </PageContentWrapper>
    </DashboardLayout>
  )
}

ChuTruongPage.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'chuTruongCommentStore',
  'chuTruongStore'
)(observer(ChuTruongPage))
