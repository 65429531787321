import React, { useEffect, useRef, useState } from 'react'
import {
  FormButtonGroup,
  UploadFileListWrapper,
  UploadWrapper,
} from '../../../../components/CommentComponent/CommentComponentStyled'
import UploadFileItem from '../../../../components/Common/FileItemComponent/UploadFileItem'
import utils from '../../../../utils'
import { Button, message, Upload } from 'antd'
import { SendOutlined, UploadOutlined } from '@ant-design/icons'
import { CommentBlock } from '../DrawerWorkStyled'
import fileStore from '../../../../stores/fileStore'
import workStore from '../../../../stores/workStore'
import CommentEditor from '../../../../components/RichEditor/CommentEditor'

const WorkCommentEditor = props => {
  const {
    screenWidth,
    breakpoint,
    getCommentWorkList,
    code,
    setLoading,
  } = props

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true)
  const CONTENT_REF = useRef()
  const [editorContent] = useState(null)
  const [fileList, setFileList] = useState([])

  const usePrevious = value => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const useHasChanged = val => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }

  const fileListChanged = useHasChanged(fileList)

  useEffect(() => {
    if (fileListChanged) {
      onHandleChange()
    }
  })

  const onHandleChange = () => {
    let comment =
      (CONTENT_REF.current &&
        CONTENT_REF.current.editor &&
        CONTENT_REF.current.editor.getData()) ||
      ''
    if (!utils.isNullish(comment) || (fileList && fileList.length > 0)) {
      setDisabledSubmitBtn(false)
    } else {
      setDisabledSubmitBtn(true)
    }
  }

  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
    onHandleChange()
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const handleCreateComment = async () => {
    let comment = CONTENT_REF.current.editor.getData()

    const scrollCommentWrapper = document.querySelector('.scrollComment')
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    setLoading(true)
    try {
      const response = await Promise.all(batchUploadArr)
      const uploadedFiles = response.map(response => response.data.file_id)
      const commentData = {
        content: comment,
        file_ids: uploadedFiles,
      }
      await workStore.createCommentWork(code, commentData)
      getCommentWorkList()
      CONTENT_REF.current.editor.setData('')
      setFileList([])
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CommentBlock isMobile={screenWidth < breakpoint}>
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 'calc(100% - 0px)' }}>
              <CommentEditor
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={editorContent}
                height={162}
                onHandleChange={onHandleChange}
              />
              {fileList.length !== 0 && (
                <UploadFileListWrapper>
                  {fileList.map(file => (
                    <UploadFileItem
                      key={file.uid}
                      file_id={file.uid}
                      file_name={file.name}
                      file_type={utils.getExtensionFile(file.name)}
                      handleRemoveFileFromUploadList={() =>
                        handleRemoveFileFromUploadList(file.uid)
                      }
                    />
                  ))}
                </UploadFileListWrapper>
              )}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              height: '78px',
              marginBottom: 20,
            }}>
            <FormButtonGroup>
              <UploadWrapper>
                <Upload
                  style={{ marginLeft: 4, marginRight: 4 }}
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  onChange={handleChangeFile}
                  beforeUpload={() => false}
                  showUploadList={false}>
                  <Button htmlType={'button'}>
                    <UploadOutlined /> Tải file
                  </Button>
                </Upload>
              </UploadWrapper>
              <Button
                type={'primary'}
                icon={<SendOutlined />}
                onClick={handleCreateComment}
                disabled={disabledSubmitBtn}>
                Đăng ý kiến
              </Button>
            </FormButtonGroup>
          </div>
        </div>
      </CommentBlock>
    </>
  )
}

WorkCommentEditor.propTypes = {}

export default WorkCommentEditor
