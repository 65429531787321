import { action, observable } from 'mobx'
import { RiskRequest } from '../requests/RiskRequest'

class riskStore {
  @observable riskList = []
  @observable total_count = []
  @observable total_page = []
  @observable riskUnits = []
  @observable riskUnitsV2 = {}
  @observable riskDetail = {}
  @observable ruiRoNhanDienType = []
  @observable listTemplate = []

  // filter
  @observable filterPage = 0
  @observable filterSize = 10
  @observable type = null
  @observable keyword = null
  @observable year = null
  @observable createdAt = null
  @observable unitCode = null
  @action setQueryParams = (param, value) => {
    this[param] = value
  }
  @action clearQueryParams = () => {
    this.filterPage = 0
    this.filterSize = 10
    this.type = null
  }

  @action setKeyword = value => {
    this.keyword = value
  }
  @action setCreatedAt = value => {
    this.createdAt = value
  }
  @action getRisks = () => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRisks({
        page: this.filterPage,
        size: this.filterSize,
        type: this.type,
        keyword: this.keyword,
        year: this.year,
        createdAt: this.createdAt,
        unit_code: this.unitCode,
      })
        .then(response => {
          this.riskList = response.data.data
          this.total_count = response.data.total_count
          this.total_page = response.data.total_page
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createRisk = data => {
    return new Promise((resolve, reject) => {
      RiskRequest.createRisk(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getRiskUnits = () => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRiskUnits()
        .then(response => {
          this.riskUnits = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  //api getRiskUnitsV2 và getRiskUnitsByType Không lấy cqvp, cqvp lấy theo list department hiện tại
  @action getRiskUnitsV2 = type => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRiskUnitsV2(type)
        .then(response => {
          this.riskUnitsV2 = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  //không lưu trong store
  @action getRiskUnitsByType = type => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRiskUnitsV2(type)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllRiskUnitUsers = RiskRequest.getAllRiskUnitUsers

  @action getRiskDetail = riskCode => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRiskDetail(riskCode)
        .then(response => {
          this.riskDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action deleteRisk = riskCode => {
    return new Promise((resolve, reject) => {
      RiskRequest.deleteRisk(riskCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateApproval = (code, data) => {
    return new Promise((resolve, reject) => {
      RiskRequest.updateApproval(code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateTierApproval = (code, data) => {
    return new Promise((resolve, reject) => {
      RiskRequest.updateTierApproval(code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateRisk = (code, data) => {
    return new Promise((resolve, reject) => {
      RiskRequest.updateRisk(code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getRuiRoNhanDienType = () => {
    return new Promise((resolve, reject) => {
      RiskRequest.getRuiRoNhanDienType()
        .then(response => {
          this.ruiRoNhanDienType = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getListTemplate = type => {
    return new Promise((resolve, reject) => {
      RiskRequest.getListTemplate({ type })
        .then(response => {
          this.listTemplate = response.data.data.map(record => ({
            ...record,
            rui_ro_nhan_dien:
              record.rui_ro_nhan_dien?.replace(/\r\n/g, '<br/>') ?? '',
            bien_phap_phong_ngua_giam_thieu: record.bien_phap_phong_ngua_giam_thieu?.replace(
              /\r\n/g,
              '<br/>'
            ),
            nguyen_nhan: record.nguyen_nhan?.replace(/\r\n/g, '<br/>'),
          }))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearRiskStore = () => {
    this.riskList = []
    this.total_count = []
    this.total_page = []
    this.riskUnits = []
    this.riskUnitsV2 = []
    this.riskDetail = []
    this.ruiRoNhanDienType = []
    this.listTemplate = []
  }
  @action clearSearch = () => {
    this.unitCode = null
    this.createdAt = null
    this.year = null
    this.keyword = null
  }
}

export default new riskStore()
