import React from 'react'
import { Helmet } from 'react-helmet'
import ContentTemplatePage from '../../ContentTemplate/ContentTemplatePage'
import AdministrativeManagementLayout from './AdministrativeManagementLayout'

const AdministrativeManagementContentTemplate = () => {
  return (
    <AdministrativeManagementLayout selectedKey="content-template">
      <Helmet>
        <title>Văn bản mẫu | Quản trị | Hành chính</title>
      </Helmet>
      <ContentTemplatePage isAdministrativeManagementTab />
    </AdministrativeManagementLayout>
  )
}

export default AdministrativeManagementContentTemplate
