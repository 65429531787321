import { Menu, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import DashboardLayout from '../../../layouts/DashboardLayout'
import CompanyDepartmentTable from './CompanyDepartmentTable'
import { systemName } from '../../../config'

const CompanyDepartment = ({ ...otherProps }) => {
  const { location } = otherProps

  return (
    <DashboardLayout isSuperAdminPage>
      <Helmet>
        <title>Quản lý phòng ban | {systemName} Quản lý công ty</title>
      </Helmet>
      <PageTitle location={location} />
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          style={{ marginBottom: 16 }}
          selectedKeys={['/company-department']}>
          <Menu.Item key="/company">
            <Link to={'/company'}>Công ty</Link>
          </Menu.Item>
          <Menu.Item key="/company-department">
            <Link to={'/company-department'}>Phòng ban</Link>
          </Menu.Item>
          <Menu.Item key="/company-acl">
            <Link to={'/company-acl'}>Phân quyền</Link>
          </Menu.Item>
        </Menu>
        <CompanyDepartmentTable />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'companyStore',
  'departmentStore'
)(observer(CompanyDepartment))
