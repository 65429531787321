import React, { useEffect, useState } from 'react'
// Component
import { EmptyText } from '../../../components/Common/CellText'
// Style Component
import {
  ButtonCloseDrawer,
  DrawerWrapper,
  HeaderDrawer,
  InfoDrawer,
  InfoDrawerLeft,
  InfoDrawerLeftWrapper,
} from './DrawerWorkStyled'
// Ant design
import { message, Popconfirm, Tooltip } from 'antd'
import {
  DoubleRightOutlined,
  LoadingOutlined,
  StarFilled,
  StarTwoTone,
} from '@ant-design/icons'
// Other
import { ACL_ACTION_TYPE } from '../../../constants'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import utils from '../../../utils'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import queryString from 'query-string'
import { WorkRequest } from '../../../requests/WorkRequest'
import ContentDrawerRight from './ContentDrawerRight'
import date_format from '../../../date_format'
import { hozDivider } from '../../../ui_constants'
import SubWorkList from './SubWorkList'

const DrawerWork = props => {
  const {
    onCloseDrawerWork,
    isVisibleDrawerWork,
    workStore,
    aclStore,
    childCode,
  } = props

  const { workDetail, queryParams, workList } = workStore

  const { code, title, important } = workDetail

  const [screenWidth] = useState(window.innerWidth)
  const breakpoint = 900

  const renderDate = date => {
    if (!date) return <EmptyText>Không rõ</EmptyText>
    return (
      <span style={{ fontStyle: 'italic' }}>
        {' '}
        {date_format.DATE_FORMAT_SLASH(date)}
      </span>
    )
  }
  // Đánh dấu công việc ưu tiên
  const [isMarkingImportant, setIsMarkingImportant] = useState(false)
  const [isImportantWork, setIsImportantWork] = useState(false)
  useEffect(() => {
    setIsImportantWork(important)
  }, [workDetail])
  const handleMarkImportantWork = async () => {
    const parentId = utils.getParameterByName('parent_id')
    try {
      setIsMarkingImportant(true)
      await WorkRequest.markImportantWork(code, !isImportantWork)
      if (!parentId) {
        const workMarkedIndex = workList.findIndex(item => item.code === code)
        if (workMarkedIndex !== -1) {
          const newWorkList = [...toJS(workList)]
          newWorkList[workMarkedIndex].important = !isImportantWork
          workStore.setWorkList(newWorkList)
        }
      } else {
        const workParentIndex = workList.findIndex(
          item => item.code === parentId
        )
        if (workParentIndex !== -1) {
          const workMarkedIndex = workList[workParentIndex].subwork.findIndex(
            item => item.code === code
          )
          if (workMarkedIndex !== -1) {
            const newWorkList = [...workList]
            newWorkList[workParentIndex].subwork[
              workMarkedIndex
            ].important = !isImportantWork
            workStore.setWorkList(newWorkList)
          }
        }
      }
      setIsImportantWork(!isImportantWork)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsMarkingImportant(false)
    }
  }

  const infoDrawerRight = <ContentDrawerRight renderDate={renderDate} />

  return (
    <DrawerWrapper
      getContainer={false}
      placement="right"
      onClose={onCloseDrawerWork}
      visible={isVisibleDrawerWork}
      closable={false}
      width={
        screenWidth < breakpoint ? 'calc(100% - 50px)' : 'calc(100% - 300px)'
      }
      style={{
        transform: 'translateY(50px)',
        position: 'absolute',
        zIndex: 998,
      }}
      className={'listCommentWrapper'}>
      <div style={{ height: 52 }} />
      <InfoDrawer>
        <InfoDrawerLeftWrapper
          style={{
            // width: screenWidth < breakpoint ? 790 : 565,
            overflow: 'auto',
          }}>
          <HeaderDrawer>
            {isVisibleDrawerWork && (
              <Tooltip title="Đóng">
                <div
                  style={{
                    position: 'absolute',
                    left: '-43px',
                    top: -2,
                    zIndex: '-1',
                  }}>
                  <ButtonCloseDrawer
                    onClick={() => {
                      workStore.clearWorkDetail()
                      onCloseDrawerWork()
                      const queryStringStringify = queryString.stringify(
                        {
                          ...queryParams,
                          parent_id: null,
                        },
                        {
                          skipNull: true,
                        }
                      )
                      window.history.replaceState(
                        null,
                        null,
                        `/works?${queryStringStringify}`
                      )
                    }}>
                    <DoubleRightOutlined style={{ fontSize: 14 }} />
                  </ButtonCloseDrawer>
                </div>
              </Tooltip>
            )}

            <span>
              {isMarkingImportant ? (
                <LoadingOutlined style={{ fontSize: 20 }} />
              ) : (
                <span>
                  {aclStore.checkAccessControlAction(
                    ACL_ACTION_TYPE.uu_tien__WORK
                  ) ? (
                    <Popconfirm
                      title={
                        isImportantWork
                          ? 'Bạn có chắc chắn muốn bỏ đánh dấu ưu tiên công việc này?'
                          : 'Bạn có chắc chắn muốn đánh dấu ưu tiên công việc này?'
                      }
                      okText={isImportantWork ? 'Bỏ đánh dấu' : 'Đánh dấu'}
                      okType={isImportantWork ? 'danger' : 'primary'}
                      onConfirm={handleMarkImportantWork}
                      placement={'top'}
                      cancelText={'Không'}>
                      <Tooltip
                        title={
                          isImportantWork
                            ? 'Bỏ đánh dấu ưu tiên'
                            : 'Đánh dấu ưu tiên'
                        }>
                        <span className={'star-icon'}>
                          {isImportantWork ? (
                            <StarFilled />
                          ) : (
                            <StarTwoTone twoToneColor={'#bfbfbf'} />
                          )}
                        </span>
                      </Tooltip>
                    </Popconfirm>
                  ) : (
                    <span className={'star-icon'}>
                      {isImportantWork ? (
                        <StarFilled />
                      ) : (
                        <StarTwoTone twoToneColor={'#bfbfbf'} />
                      )}
                    </span>
                  )}
                </span>
              )}
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: 0,
                  marginLeft: 8,
                }}>
                {title}
              </span>
            </span>
          </HeaderDrawer>
          <div style={hozDivider} />
          <InfoDrawerLeft
            className={'scrollComment'}
            style={{ paddingTop: 20 }}>
            <SubWorkList childCode={childCode} />

            {screenWidth < breakpoint && infoDrawerRight}
          </InfoDrawerLeft>

          <SpinnerInlineComponent noBackground />
        </InfoDrawerLeftWrapper>
        {!(screenWidth < breakpoint) && infoDrawerRight}
      </InfoDrawer>
    </DrawerWrapper>
  )
}

DrawerWork.propTypes = {}

export default inject('workStore', 'aclStore')(observer(DrawerWork))
