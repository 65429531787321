import { requests } from './DefaultRequest'
import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export default {
  getRiskTemplateList: filter =>
    requests.get(`/api/v1/rui-ro-nhan-dien-template`, true, {
      ...filter,
      sort_by_created: 'ASC',
    }),

  getRiskTemplateTypes: () => {
    return requests.get(`/api/v1/rui-ro-nhan-dien-type`)
  },

  getRiskTemplateDetail: code =>
    requests.get(`/api/v1/rui-ro-nhan-dien-template/${code}`),

  createRiskTemplate: payload =>
    requests.post(`/api/v1/rui-ro-nhan-dien-template`, payload),

  updateRiskTemplate: (code, payload) =>
    requests.put(`/api/v1/rui-ro-nhan-dien-template/${code}`, payload),

  delete: code => requests.delete(`/api/v1/rui-ro-nhan-dien-template/${code}`),

  export: filter =>
    requests.getFile({
      url: '/api/v1/rui-ro-nhan-dien-template/export',
      searchs: {
        ...filter,
        sort_by_created: 'ASC',
      },
      responseType: 'blob',
    }),

  exportRisk: params =>
    requests.getFile({
      url: '/api/v1/risks/export',
      searchs: params,
      responseType: 'blob',
    }),
}
