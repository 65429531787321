import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, message, Upload } from 'antd'
import utils from '../../utils'
import MentionTextInput from '../../components/MentionTextInput/MentionTextInput'
import {
  FormButtonGroup,
  UploadFileListWrapper,
  UploadWrapper,
} from '../../components/CommentComponent/CommentComponentStyled'
import { SendOutlined, UploadOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import CommentComponent from '../../components/CommentComponent/CommentComponent'
import { DOCUMENT_TYPE, SORT_TYPE } from '../../constants'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import validator from './../../validator'

const InternalDocumentComment = props => {
  const {
    internalDocumentStore,
    documentId,
    notificationStore,
    fileStore,
    setIsLoadingComment,
  } = props
  const { internalDocumentCommentList } = internalDocumentStore
  const { notificationType } = notificationStore

  const [fileList, setFileList] = useState([])
  const [sort, setSort] = useState(SORT_TYPE.DESCENDING)
  const [commentList, setCommentList] = useState([])
  const [comment, setComment] = useState('')
  const [isShowDelComments, setIsShowDelComments] = useState(false)

  const handleOnchangeComment = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setComment(newValue)
  }
  const handleChangeSortComment = sort => {
    setSort(sort)
    const commentListReverse = commentList.reverse()
    setCommentList([...commentListReverse])
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }
  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleCreateComment = async (documentId, commentData) => {
    try {
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      let newCommentList = [...commentList]
      await internalDocumentStore.createInternalDocumentComment(
        documentId,
        commentData
      )
      const response = await internalDocumentStore.getInternalDocumentComment(
        documentId
      )
      if (response.data.length === 1) {
        await internalDocumentStore.getInternalDocumentIncomingById(documentId)
      }
      const newComment = response.data[response.data.length - 1]
      if (sort === SORT_TYPE.DESCENDING) {
        newCommentList.push(newComment)
      }
      if (sort === SORT_TYPE.ASCENDING) {
        setSort(SORT_TYPE.DESCENDING)
        newCommentList.unshift(newComment)
        newCommentList = newCommentList.reverse()
      }
      setCommentList(newCommentList)
      handleShowDeletedComments(newCommentList, isShowDelComments)
      setComment('')
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Gửi ý kiến không thành công!')
    }
  }
  const sendComment = useCallback(async () => {
    if (utils.isNullish(comment)) return
    if (comment.length > 500) {
      return message.error('Ý kiến không vượt quá 500 ký tự!')
    }
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    setIsLoadingComment(true)
    try {
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      const commentData = {
        content: comment.replace(/\s+/g, ' ').trim(),
        file_ids: uploadedFiles,
      }
      if (commentData.content.trim() === '') {
        message.error('Vui lòng không để trống ý kiến.')
        return
      }
      await handleCreateComment(documentId, commentData)
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingComment(false)
    }
  }, [fileList, commentList, comment])

  const renderCommentForm = (
    <div className={'comment-form'}>
      <MentionTextInput
        value={comment}
        readonly={false}
        onChange={handleOnchangeComment}
        placeHolder={'Viết ý kiến...'}
      />
      {fileList.length !== 0 && (
        <UploadFileListWrapper>
          {fileList.map(file => (
            <UploadFileItem
              key={file.uid}
              file_id={file.uid}
              file_name={file.name}
              file_type={utils.getExtensionFile(file.name)}
              handleRemoveFileFromUploadList={() =>
                handleRemoveFileFromUploadList(file.uid)
              }
            />
          ))}
        </UploadFileListWrapper>
      )}
      <FormButtonGroup>
        <UploadWrapper>
          <Upload
            style={{ marginLeft: 4, marginRight: 4 }}
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}>
            <Button htmlType={'button'}>
              <UploadOutlined /> Tải file
            </Button>
          </Upload>
        </UploadWrapper>
        <Button onClick={sendComment} type={'primary'} icon={<SendOutlined />}>
          Đăng ý kiến
        </Button>
      </FormButtonGroup>
    </div>
  )
  const handleDeleteComment = async (comment_id, index) => {
    try {
      setIsLoadingComment(true)
      await internalDocumentStore.deleteInternalDocumentComment(
        documentId,
        comment_id
      )
      const newCommentList = [...commentList]
      newCommentList[index] = {
        ...newCommentList[index],
        deleted: true,
      }
      setCommentList(newCommentList)
      handleShowDeletedComments(newCommentList, isShowDelComments)
      message.success('Xóa ý kiến thành công!')
    } catch (err) {
      console.log(err)
      message.error('Xóa ý kiến thất bại!')
    } finally {
      setIsLoadingComment(false)
    }
  }

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */
    let commentAfterDeleteList = []
    let key = -1
    internalDocumentCommentList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el))
      }
      if (el.deleted && i > key) {
        const arrDeletedComment = []
        for (
          let index = i;
          index < internalDocumentCommentList.length;
          index++
        ) {
          key = index
          if (internalDocumentCommentList[index].deleted === el.deleted) {
            arrDeletedComment.push(toJS(internalDocumentCommentList[index]))
          }
          if (
            internalDocumentCommentList[index].deleted === !el.deleted ||
            index === internalDocumentCommentList.length - 1
          )
            break
        }
        commentAfterDeleteList.push({
          comment_id: arrDeletedComment[0].comment_id,
          arrDeletedComment: arrDeletedComment,
          isHidden: true,
        })
      }
    })
    setCommentList(commentAfterDeleteList)
  }, [internalDocumentCommentList])

  const getInternalDocumentComment = async () => {
    try {
      setIsLoadingComment(true)
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      await internalDocumentStore.getInternalDocumentComment(documentId)
      await scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingComment(false)
    }
  }

  useEffect(() => {
    if (!documentId) return
    if (!utils.isIOSDevice()) {
      const channel4Broadcast = new BroadcastChannel('channel4')
      channel4Broadcast.onmessage = async even => {
        if (
          (even.data.type === DOCUMENT_TYPE.INCOMING ||
            even.data.type === DOCUMENT_TYPE.OUTGOING) &&
          even.data.code === documentId
        ) {
          await getInternalDocumentComment()
        }
      }
    }
    return () => {
      internalDocumentStore.clearInternalDocumentCommentList()
    }
  }, [documentId])

  useEffect(() => {
    if (!documentId) return /** Check thiết bị IOS */
    ;(async () => {
      if (
        utils.isIOSDevice() &&
        notificationType &&
        (notificationType.type === DOCUMENT_TYPE.INCOMING ||
          notificationType.type === DOCUMENT_TYPE.OUTGOING)
      ) {
        await getInternalDocumentComment()
      }
    })()
    return () => {
      internalDocumentStore.clearInternalDocumentCommentList()
    }
  }, [documentId, notificationType])

  useEffect(() => {
    if (!documentId) return
    ;(async () => {
      await getInternalDocumentComment()
    })()
    return () => {
      internalDocumentStore.clearInternalDocumentCommentList()
    }
  }, [documentId])

  const handleShowDeletedComments = async (commentList, isShow) => {
    if (isShow) {
      const newCommentList = []
      commentList.forEach(comment => {
        if (comment.isHidden) {
          newCommentList.push(...comment.arrDeletedComment)
          comment.isHidden = !comment.isHidden
        } else newCommentList.push(comment)
      })
      setCommentList(newCommentList)
    } else {
      await getInternalDocumentComment()
    }
  }

  const handleToggleShowDelCommentsMenu = () => {
    handleShowDeletedComments(commentList, !isShowDelComments)
    setIsShowDelComments(!isShowDelComments)
  }
  return (
    <CommentComponent
      handleToggleShowDelCommentsMenu={handleToggleShowDelCommentsMenu}
      handleDeleteComment={handleDeleteComment}
      isShowDelComments={isShowDelComments}
      handleShowDeletedComment={handleShowDeletedComments}
      commentList={commentList}
      renderCommentForm={renderCommentForm}
      handleChangeSortComment={handleChangeSortComment}
      sortComment={sort}
    />
  )
}

InternalDocumentComment.propTypes = {}

export default inject(
  'internalDocumentStore',
  'notificationStore',
  'fileStore'
)(observer(InternalDocumentComment))
