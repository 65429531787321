import React, { useEffect, useState } from 'react'
// Component
import { EmptyText, subStringAvatar } from '../../components/Common/CellText'
//Styled Component
import { OverviewWrapper, UserName } from './MyProfilePageStyled'
// Ant design
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Space,
  Tooltip,
  Upload,
} from 'antd'
import {
  BankFilled,
  CalendarFilled,
  CameraFilled,
  CheckOutlined,
  CloseOutlined,
  CrownFilled,
  EditOutlined,
  HeartFilled,
  IdcardFilled,
  MailFilled,
  MobileFilled,
  PhoneFilled,
  SlackCircleFilled,
} from '@ant-design/icons'

// Other
import { blue } from '../../color'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import validator from '../../validator'
import moment from 'moment'

const styleIcon = {
  color: blue,
  fontSize: 14,
  marginRight: 5,
}
const nullText = <EmptyText>Chưa cập nhật</EmptyText>

const Overview = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    userStore,
    aclStore,
  } = props

  const { aclAclGroupName } = aclStore

  const { currentUser, currentUserAvatar } = authenticationStore

  const [editing, setEditing] = useState(false)

  const [fields, setFields] = useState([
    {
      name: ['email'],
      value: undefined,
    },
    {
      name: ['phone'],
      value: undefined,
    },
    {
      name: ['home_phone'],
      value: undefined,
    },
    {
      name: ['gender'],
      value: undefined,
    },
  ])

  const setFieldsOrigin = () => {
    setFields([
      {
        name: ['email'],
        value: currentUser?.email,
      },
      {
        name: ['phone'],
        value: currentUser?.phone,
      },
      {
        name: ['home_phone'],
        value: currentUser?.home_phone,
      },
      {
        name: ['gender'],
        value: currentUser?.gender,
      },
    ])
  }

  const handleUploadAvatar = async (file, fileList) => {
    if (file.size / 1000 / 1024 > 3) {
      message.error('Vui lòng chọn file < 3MB')
    } else {
      const formData = new FormData()
      formData.append('file', fileList[0])
      loadingAnimationStore.showSpinner(true)
      try {
        await authenticationStore.updateCurrentUserAvatar(formData)
        await authenticationStore.getCurrentUserAvatar()
        message.success('Cập nhật avatar thành công')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    return false // Disable default Antd Design upload action
  }

  const onUpdate = async value => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUser(value)
      authenticationStore.checkCurrentUser()
      setEditing(false)
      message.success('Cập nhật thông tin thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!currentUser) return
    setFieldsOrigin()
  }, [currentUser])

  return (
    <OverviewWrapper>
      <div className={'avatar'}>
        <Avatar
          size={180}
          style={{ backgroundColor: blue }}
          src={currentUserAvatar && URL.createObjectURL(currentUserAvatar)}>
          {subStringAvatar(currentUser?.name_uppercase)}
        </Avatar>
        <Tooltip title={'Thay đổi ảnh đại diện'}>
          <Upload
            showUploadList={false}
            supportServerRender={true}
            beforeUpload={handleUploadAvatar}>
            <span className={'edit-avatar-icon'}>
              <CameraFilled />
            </span>
          </Upload>
        </Tooltip>
      </div>
      <div className={'overview-info'}>
        <Form onFinish={onUpdate} fields={fields}>
          <UserName>
            <h2 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {currentUser?.name_lowercase || nullText}
              <span className={'username'}>({currentUser?.username})</span>
            </h2>
            {!editing && (
              <Button
                type={'primary'}
                icon={<EditOutlined />}
                onClick={() => {
                  setFieldsOrigin()
                  setEditing(true)
                }}>
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Space>
                <Button
                  onClick={() => {
                    setFieldsOrigin()
                    setEditing(false)
                  }}
                  danger
                  icon={<CloseOutlined />}>
                  Hủy bỏ
                </Button>
                <Button
                  type={'primary'}
                  icon={<CheckOutlined />}
                  htmlType={'submit'}>
                  Lưu thay đổi
                </Button>
              </Space>
            )}
          </UserName>
          <Row gutter={[20, 0]}>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div style={{ display: 'flex' }}>
                <MailFilled style={styleIcon} />
                <div>
                  {!editing ? (
                    <div> {currentUser?.email || nullText}</div>
                  ) : (
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: 'Vui lòng nhập email!' },
                        { validator: validator.validateEmail },
                      ]}>
                      <Input placeholder={'Email'} style={{ minWidth: 250 }} />
                    </Form.Item>
                  )}
                  <span
                    className={'desc'}
                    style={{ color: '#65676b', fontSize: '10px' }}>
                    Email
                  </span>
                </div>
              </div>
              <div className={'item-info'}>
                <div>
                  <div>
                    <IdcardFilled style={styleIcon} />
                    {currentUser?.ma_nv || nullText}
                  </div>
                  <span className={'desc'}>Mã nhân viên</span>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <PhoneFilled style={styleIcon} />
                <div>
                  {!editing ? (
                    <div>{currentUser?.home_phone || nullText}</div>
                  ) : (
                    <Form.Item name={'home_phone'}>
                      <Input
                        placeholder={'Số máy nội bộ'}
                        style={{ minWidth: 250 }}
                      />
                    </Form.Item>
                  )}
                  <span
                    className={'desc'}
                    style={{ color: '#65676b', fontSize: '10px' }}>
                    Số máy nội bộ
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <MobileFilled style={styleIcon} />
                <div>
                  {!editing ? (
                    <div>{currentUser?.phone || nullText}</div>
                  ) : (
                    <Form.Item
                      rules={[{ validator: validator.validatePhoneNumber }]}
                      name={'phone'}>
                      <Input
                        placeholder={'Điện thoại di động'}
                        style={{ minWidth: 250 }}
                      />
                    </Form.Item>
                  )}

                  <span
                    className={'desc'}
                    style={{ color: '#65676b', fontSize: '10px' }}>
                    Điện thoại di động
                  </span>
                </div>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div style={{ display: 'flex' }}>
                <HeartFilled style={styleIcon} />
                <div>
                  {!editing ? (
                    <div>
                      {utils.getGenderName(currentUser?.gender) || nullText}
                    </div>
                  ) : (
                    <Form.Item name={'gender'}>
                      <Radio.Group>
                        <Radio.Button value={1}>Nam</Radio.Button>
                        <Radio.Button value={0}>Nữ</Radio.Button>
                        <Radio.Button value={2}>Khác</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  <span className={'desc'}>Giới tính</span>
                </div>
              </div>
              <div className={'item-info'}>
                <div>
                  <div>
                    <BankFilled style={styleIcon} />
                    {currentUser?.department?.name || nullText}
                  </div>
                  <span className={'desc'}>Phòng ban</span>
                </div>
              </div>
              <div className={'item-info'}>
                <div>
                  <div>
                    <CrownFilled style={styleIcon} />
                    {currentUser?.position?.name || nullText}
                  </div>
                  <span className={'desc'}>Chức danh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <div>
                  <div>
                    <CalendarFilled style={styleIcon} />
                    {currentUser?.birthDate
                      ? moment(currentUser?.birthDate).format('DD/MM/YYYY')
                      : nullText}
                  </div>
                  <span className={'desc'}>Ngày sinh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <div>
                  <div>
                    <SlackCircleFilled style={styleIcon} />
                    {aclAclGroupName}
                  </div>
                  <span className={'desc'}>Phân quyền</span>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </OverviewWrapper>
  )
}

Overview.propTypes = {}

export default inject(
  'authenticationStore',
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore'
)(observer(Overview))
