import React from 'react'
import { EmptyText, WordBreak } from '../../../components/Common/CellText'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT, STATUS_DEFAULT } from '../../../constants'
import utils from '../../../utils'
import { apiUrl } from '../../../config'
import { blue, grayPrimary, greenPrimary } from '../../../color'
import date_format from '../../../date_format'

const WorkPdf = props => {
  const {
    workDetail,
    commentList,
    isImportantWork,
    attachments,
    voteList,
  } = props

  const { content, due_date, start_date, status, title, code } = workDetail
  const renderDate = date => {
    if (!date) return <EmptyText>Không rõ</EmptyText>
    return <span>{moment(date).format(DATE_FORMAT_DEFAULT)}</span>
  }

  const divider = (
    <div
      style={{
        margin: '10px 0',
        textAlign: 'center',
        color: '#c4bcbc',
      }}>
      ---------------------------------------------------------------------------------------------------------------------------------------------------------
    </div>
  )

  const convertContentUnit = content => {
    let newContent
    newContent = content.replaceAll(
      /rgba\((\s*\d{1,3},\d{1,3},\d{1,3})((?:,[0-9.]*)?)\)/gm,
      function (x) {
        return convertRgbaColor(x)
      }
    )

    newContent = newContent.replaceAll('margin', 'padding')

    newContent = newContent.replaceAll(/(\d*\.?\d+)\s?(in+)/g, function (x) {
      return (Number(x.slice(0, x.length - 2)) * 72).toString() + 'pt'
    })

    return newContent
  }

  const convertRgbaColor = color => {
    const rgbaValue = color.slice(5, color.length - 1).split(',')
    return `rgb(${rgbaValue[0]},${rgbaValue[1]},${rgbaValue[2]});opacity:${rgbaValue[3]};`
  }

  return (
    <div id={'divToPrint'}>
      <div style={{ textAlign: 'right' }}>
        <a href={`${apiUrl}/works/view/${code}`}>{code}</a>
      </div>
      <br />
      <div>
        <span>
          <b style={{ margin: 6 }}>Ngày bắt đầu: </b>
          {renderDate(start_date)}
          <span> - </span>
        </span>
        <span>
          <b style={{ margin: 6 }}>Ngày kết thúc: </b>
          {renderDate(due_date)}
        </span>
        <span
          style={{
            color: status === STATUS_DEFAULT.COMPLETE ? greenPrimary : blue,
          }}>
          {status === STATUS_DEFAULT.COMPLETE
            ? '  Hoàn thành  '
            : '  Đang thực hiện  '}
        </span>
        <span>{isImportantWork && '(công việc ưu tiên)'}</span>
      </div>
      {divider}
      <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>{title}</h3>
      <div>
        {content ? (
          WordBreak(convertContentUnit(content), true)
        ) : (
          <EmptyText>Không có nội dung.</EmptyText>
        )}
      </div>
      {divider}
      <h6 style={{ fontSize: 16 }}>
        Tài liệu đính kèm:
        {attachments.length === 0 && (
          <i
            style={{
              fontWeight: 400,
              color: grayPrimary,
              fontSize: 14,
              marginLeft: 8,
            }}>
            &nbsp;Không có tài liệu đính kèm.
          </i>
        )}
      </h6>
      <div>
        <ul>
          {attachments.map((item, index) => {
            return (
              <li key={index} style={{ lineHeight: 1.2 }}>
                <div key={item.file_id} style={{ marginBottom: 8 }}>
                  <a
                    href={`${apiUrl}/api/v1/images/${item.file_id}`}
                    target={'_blank'}>
                    {item.file_name}
                  </a>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      {divider}
      <h6 style={{ fontSize: 16 }}>
        Danh sách ý kiến:
        {commentList.length === 0 && (
          <i style={{ fontWeight: 400, color: grayPrimary, fontSize: 14 }}>
            &nbsp;Không có ý kiến nào.
          </i>
        )}
      </h6>
      <div>
        <ul>
          {commentList.map(item => {
            return (
              <div key={item.comment_id}>
                <div style={{}}>
                  <b>{utils.getNameInCapitalize(item.name_uppercase)}: </b>
                  <i>({date_format.renderTime(item.created_at)})</i>
                  <br />
                  <div>{WordBreak(item.content, true)}</div>
                  <div>
                    {item.files?.map(file => (
                      <div key={file.file_id} style={{ marginBottom: 8 }}>
                        <a
                          href={`${apiUrl}/api/v1/images/${file.file_id}`}
                          target={'_blank'}>
                          {file.file_name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div></div>
              </div>
            )
          })}
        </ul>
      </div>
      {divider}
      <h6 style={{ fontSize: 16 }}>
        Ý kiến:
        {voteList.length === 0 && (
          <i style={{ fontWeight: 400, color: grayPrimary, fontSize: 14 }}>
            &nbsp;Không có ý kiến.
          </i>
        )}
      </h6>
      <div>
        <ul>
          {voteList.map(user => {
            return (
              <div key={user.assignee_code}>
                <p style={{}}>
                  <b>
                    <span>
                      {utils.getNameInCapitalize(user.name_uppercase)}:{' '}
                    </span>
                  </b>
                  <i>
                    {user.result === false ? (
                      <span style={{ color: 'red' }}>Không đồng ý</span>
                    ) : user.result === true ? (
                      <span style={{ color: greenPrimary }}>Đồng ý</span>
                    ) : (
                      <span style={{ color: grayPrimary }}>Không ý kiến</span>
                    )}
                  </i>
                  {user.result !== null && (
                    <span>
                      &nbsp;&nbsp;
                      <i>({date_format.renderTime(user.updated_at)})</i>
                    </span>
                  )}
                </p>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
WorkPdf.propTypes = {}

export default WorkPdf
