import {
  Avatar,
  Col,
  Comment,
  Empty,
  Input,
  Pagination,
  Row,
  Tabs,
  Tooltip,
  message,
  Form,
  Button,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState, useRef } from 'react'
import {
  TextWrap,
  trimOverlengthString,
} from '../../../components/Common/CellText'
import { CellEclipseBox } from '../../../components/Common/Table'
import TableComponent from '../../../components/Common/TableComponent'
import { apiUrl } from '../../../config'
import authenticationStore from '../../../stores/authenticationStore'
import contactStore from '../contactStore'
import {
  ListDepartmentContent,
  TabsDepartmentWrapper,
} from './ListDepartmentStyled'
import UserDetail from './UserDetail'

import XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { useMediaQuery } from 'react-responsive'

const { TabPane } = Tabs
const { Search } = Input

const ListDepartmentPage = props => {
  const [form] = Form.useForm()
  const { departmentStore, userStore, loadingAnimationStore, aclStore } = props
  const { departmentList } = departmentStore
  const { companyCode } = contactStore

  const {
    userOfDepartmentListTotalCount,
    userOfDepartmentListPageIndex,
    userOfDepartmentListPageSize,
  } = userStore

  const [userOfDepartmentData, setUserOfDepartmentData] = useState([])

  const [selectedUser, setSelectedUser] = useState(null)

  /* excel */
  const exportToExcel = async (
    tableData,
    fileName,
    fieldsToExport,
    headers,
    columnWidths
  ) => {
    const processField = (item, field) => {
      const fieldParts = field.split('.')
      let value = item
      fieldParts.forEach(part => {
        if (value && typeof value === 'object' && part in value) {
          value = value[part]
        } else {
          value = undefined
        }
      })
      if (value === undefined || value === null || value === "") {
        return 'Không có'
      }
      if (typeof value === 'object') {
        return JSON.stringify(value)
      }
      return value
    }

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')

    const columns = fieldsToExport.map((field, index) => ({
      header: headers[index] || field,
      key: field,
      width: columnWidths[index] || 10,
      style: {
        font: { name: 'Arial', color: { argb: 'FFFFFFFF' }, bold: true },
      },
    }))

    worksheet.columns = columns

    tableData.forEach(item => {
      const row = {}
      fieldsToExport.forEach((field, index) => {
        row[field] = processField(item, field)
      })
      worksheet.addRow(row)
    })

    // style
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        // Header row
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2C65AC' },
          }
          cell.font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
          }
        })
      } else {
        // Other rows
        const bgColor = rowNumber % 2 === 0 ? 'FFFFFFFF' : 'FFE9F0FD'
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: bgColor },
          }
          cell.font = {
            color: { argb: 'FF000000' },
          }
        })
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName + '.xlsx'
    a.click()
  }
  /* End excel */

  const onExportToExcel = () => {
    /* get all data */
    loadingAnimationStore.setTableLoading(true)
    userStore.clearSelectedUser()
    userStore
      .getAllUserListByDepartment()
      .then(response => {
        /* export */
        exportToExcel(
          response.data,
          'danh-sach-nhan-vien',
          [
            'name_uppercase',
            'email',
            'phone',
            'position.name',
            'department.name',
          ],
          ['Tên', 'Email', 'Điện thoại', 'Chức vụ', 'Phòng ban'],
          [30, 30, 30, 30, 30]
        )
        loadingAnimationStore.setTableLoading(false)
      })
      .catch(() => {
        loadingAnimationStore.setTableLoading(false)
        message.warning('Lỗi lấy thông tin người dùng trong phòng ban!')
      })
  }

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
      width: '40%',
    },
    {
      title: 'Email',
      render: record => (
        <Tooltip title={record?.email}>
          <p style={{ wordBreak: 'break-word' }}>{record?.email}</p>
        </Tooltip>
      ),
      width: '20%',
    },
    {
      title: 'Điện thoại',
      render: record => record?.phone || 'Không có',
      width: '15%',
    },

    {
      title: 'Chức vụ',
      render: record => (record.position ? record?.position.name : 'Chưa rõ'),
      width: '25%',
    },
  ]

  const tableColumnCollapse = [
    {
      title: 'Thông tin',
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
      width: '40%',
    },
  ]
  const onChangeTabDepartment = key => {
    setIsShowInfoRight(false)
    form.setFieldsValue({ searchBar: '' })
    userStore.setFilter('userOfDepartmentListByDepartmentCode', key)
    userStore.setFilter('userOfDepartmentListPageIndex', 0)
    userStore.setFilter('userListPageIndex', 0)
    userStore.setFilter('userOfDepartmentListHasAdmin', false)
    loadingAnimationStore.setTableLoading(true)
    userStore.clearUserListKeyword()
    userStore.getUserListByDepartment().then(response => {
      setUserOfDepartmentData(response.data)
      loadingAnimationStore.setTableLoading(false)
    })
  }

  const onChangePagination = (pageIndex, pageSize) => {
    userStore.setFilter('userOfDepartmentListPageIndex', pageIndex - 1)
    userStore.setFilter('userOfDepartmentListPageSize', pageSize)
    userStore.setFilter('userOfDepartmentListHasAdmin', false)
    loadingAnimationStore.setTableLoading(true)
    userStore.clearSelectedUser()
    userStore
      .getUserListByDepartment()
      .then(response => {
        setUserOfDepartmentData(response.data)
        loadingAnimationStore.setTableLoading(false)
      })
      .catch(() => {
        loadingAnimationStore.setTableLoading(false)
        message.warning('Lỗi lấy thông tin người dùng trong phòng ban!')
      })
  }

  /* log data */
  /* useEffect(() => {
    console.log('agagaga', userOfDepartmentData)
  }, [userOfDepartmentData]) */
  /* end log data */

  const [isShowInfoRight, setIsShowInfoRight] = useState(false)
  const isLessThan1500 = useMediaQuery({ query: '(max-width: 1500px)' })

  useEffect(() => {
    console.log(authenticationStore.currentUser)
    console.log(companyCode)

    if (authenticationStore.currentUser && companyCode) {
      loadingAnimationStore.showSpinner(true)
      setSelectedUser(authenticationStore.currentUser)
      userStore.getAvatarByUserCode(authenticationStore.currentUser?.code)
      departmentStore
        .getAllDepartmentList(companyCode)
        .then(resAllDepartmentList => {
          userStore.setFilter(
            'userOfDepartmentListByDepartmentCode',
            null
            // resAllDepartmentList.data[0].code
          )
          userStore.setFilter('userOfDepartmentListPageIndex', 0)
          userStore.setFilter('userListPageIndex', 0)
          userStore.setFilter(
            'userOfDepartmentListSort',
            'department-asc, position-asc'
          )
          userStore
            .getUserListByDepartment()
            .then(response => {
              setUserOfDepartmentData(response.data)

              loadingAnimationStore.showSpinner(false)
            })
            .catch(() => {
              loadingAnimationStore.showSpinner(false)
              message.warning('Lỗi không tìm thấy thông tin!')
            })
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
          message.warning('Lỗi không tìm thấy thông tin!')
        })
    }
    return () => {
      departmentStore.clearDepartmentList()
    }
  }, [authenticationStore.currentUser, companyCode])

  const selectUser = record => {
    userStore.clearAvatarByUserCode()
    userStore.getAvatarByUserCode(record?.code)
    setSelectedUser(record)
    aclStore.getACLDetailByUser(record.username)
  }
  const onSearch = value => {
    console.log('search')
    try {
      userStore.setUserListKeyword(value)
      userStore.setFilter('userOfDepartmentListPageIndex', 0)
      userStore.setFilter('userListKeyword', value?.trim())
      userStore.setFilter('userOfDepartmentListSort', [
        'department,desc',
        'position,desc',
      ])
      loadingAnimationStore.setTableLoading(true)
      userStore.clearSelectedUser()
      userStore.getUserListByDepartment().then(response => {
        setUserOfDepartmentData(response.data)
      })
    } catch (error) {
      console.log(error)
      message.error('Đã xảy ra lỗi!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
      loadingAnimationStore.showSpinner(false)
    }
  }
  if (departmentList.length === 0) {
    return <Empty />
  }

  return (
    <ListDepartmentContent>
      <TabsDepartmentWrapper
        tabPosition={'left'}
        onChange={onChangeTabDepartment}>
        {departmentList.length !== 0 &&
          [
            {
              name: 'Tất cả phòng ban',
              code: undefined,
            },
            ...departmentList,
          ]?.map(department => {
            return (
              <TabPane
                tab={
                  <>
                    <TextWrap>
                      {trimOverlengthString(department.name, 180)}
                    </TextWrap>
                  </>
                }
                key={department.code}>
                {!isLessThan1500 && (
                  <Row>
                    <Col span={15}>
                      <div
                        style={{
                          width: '50%',
                          marginBottom: '10px',
                          marginLeft: 'auto',
                        }}>
                        <Form form={form}>
                          <Form.Item name={'searchBar'}>
                            <Search
                              placeholder="Tìm kiếm theo tên"
                              allowClear
                              style={{ width: '100%' }}
                              onSearch={onSearch}
                            />
                          </Form.Item>
                        </Form>
                      </div>

                      <TableComponent
                        rowKey={record => record.code}
                        columns={tableColumn}
                        dataSource={userOfDepartmentData}
                        loading={loadingAnimationStore.tableLoading}
                        pagination={false}
                        onRow={(record, index) => ({
                          onClick: () => {
                            selectUser(record)
                          },
                        })}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '15px 0',
                        }}>
                        <Button
                          onClick={() => {
                            onExportToExcel()
                          }}>
                          In file Excel
                        </Button>
                        <Pagination
                          onChange={onChangePagination}
                          hideOnSinglePage={true}
                          total={userOfDepartmentListTotalCount}
                          pageSize={userOfDepartmentListPageSize}
                          current={userOfDepartmentListPageIndex + 1}
                          showSizeChanger={false}
                          showLessItems
                        />
                      </div>
                    </Col>
                    <Col span={9}>
                      <UserDetail selectedUser={selectedUser} />
                    </Col>
                  </Row>
                )}

                {isLessThan1500 && (
                  <Row>
                    <Col span={isShowInfoRight ? 12 : 24}>
                      <div
                        style={{
                          width: '50%',
                          marginBottom: '10px',
                          marginLeft: 'auto',
                        }}>
                        <Form form={form}>
                          <Form.Item name={'searchBar'}>
                            <Search
                              placeholder="Tìm kiếm theo tên"
                              allowClear
                              style={{ width: '100%' }}
                              onSearch={onSearch}
                            />
                          </Form.Item>
                        </Form>
                      </div>

                      <TableComponent
                        rowKey={record => record.code}
                        columns={
                          isShowInfoRight ? tableColumnCollapse : tableColumn
                        }
                        dataSource={userOfDepartmentData}
                        loading={loadingAnimationStore.tableLoading}
                        pagination={false}
                        onRow={(record, index) => ({
                          onClick: () => {
                            selectUser(record)
                            setIsShowInfoRight(true)
                          },
                        })}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '15px 0',
                        }}>
                        <Button
                          onClick={() => {
                            onExportToExcel()
                          }}>
                          In file Excel
                        </Button>
                        <Pagination
                          onChange={onChangePagination}
                          hideOnSinglePage={true}
                          total={userOfDepartmentListTotalCount}
                          pageSize={userOfDepartmentListPageSize}
                          current={userOfDepartmentListPageIndex + 1}
                          showSizeChanger={false}
                          showLessItems
                        />
                      </div>
                    </Col>
                    {isShowInfoRight && (
                      <Col span={isShowInfoRight ? 12 : 9}>
                        <UserDetail selectedUser={selectedUser} />
                      </Col>
                    )}
                  </Row>
                )}
              </TabPane>
            )
          })}
      </TabsDepartmentWrapper>
    </ListDepartmentContent>
  )
}

ListDepartmentPage.propTypes = {}

export default memo(
  inject(
    'departmentStore',
    'userStore',
    'loadingAnimationStore',
    'aclStore'
  )(observer(ListDepartmentPage))
)
