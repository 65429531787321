import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const WorkRequest = {
  getWorkList: (params, cancelToken) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work/current-user-v2?${
        params.assignee ? params.assignee : ''
      }`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
      cancelToken: cancelToken,
    }),

  //API chưa có
  getWorkTypeList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work_type`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createWork: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/work`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  getWorkDetail: (work_code, params) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work/${work_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  updateStatusWork: (work_code, status) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/work/${work_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        status: status,
      },
    }),

  deleteWork: work_code =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/work/${work_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateWork: (work_code, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/work/${work_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  markImportantWork: (work_code, isImportant) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/work/${work_code}/mark_important/${isImportant}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updatePeopleInvole: (work_code, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/work/${work_code}/update-people-invole`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  getCommentWorkList: work_code =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work/${work_code}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createCommentWork: (work_code, data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/work/${work_code}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  deleteCommentWork: (work_code, id_comment) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/work/${work_code}/comments/${id_comment}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}
