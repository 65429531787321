import userEvent from '@testing-library/user-event'
import { action, observable } from 'mobx'
import { VoteRequest } from '../requests/VoteRequest'

class voteStore {
  @observable voteDetail = []

  @action createVote = data => {
    return new Promise((resolve, reject) => {
      VoteRequest.createVote(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getVoteDetail = workCode => {
    return new Promise((resolve, reject) => {
      VoteRequest.getVoteDetail(workCode)
        .then(response => {
          this.voteDetail = response.data.users.map(user => ({
            ...user,
            permission: 'USER',
            assignee_type: 'USER',
          }))
          // console.log('response', response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getVoteDetailResponse = workCode => {
    return new Promise((resolve, reject) => {
      VoteRequest.getVoteDetail(workCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updatePersonVote = (userCode, data) => {
    return new Promise((resolve, reject) => {
      VoteRequest.updatePersonVote(userCode, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateVote = (userCode, data) => {
    return new Promise((resolve, reject) => {
      console.log(data)
      VoteRequest.updateVote(userCode, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearStore = () => {
    this.voteDetail = []
  }
}

export default new voteStore()
