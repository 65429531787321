import { MoreOutlined, PrinterOutlined } from '@ant-design/icons'
import { Dropdown, Menu, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { blue } from '../../color'
import { TitleContentBox } from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import RichText from '../../components/RichEditor/RichText'
import useOpenItem from '../../hooks/useOpenItem'
import { Container } from '../../layouts/Container/Container'
import DashboardLayout from '../../layouts/DashboardLayout'
import utils from '../../utils'
import { DocumentInfoList } from '../ConnectedCommitteeDocumentIncomingDocDetailPage/ConnectedCommitteeDocumentIncomingDocDetailPageStyled'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import ReportPdf from './ReportPdf'
import { systemName } from '../../config'

const ReportDetailPage = props => {
  const { reportStore, riskStore, companyStore } = props
  const { reportDetail, getReportDetail } = reportStore
  const { reportId } = useParams()
  const { riskUnits } = riskStore
  const { getCompanyList, companyList } = companyStore
  const {
    isOpen: isLoading,
    handleOpen: visibleLoading,
    handleClose: cancelLoading,
  } = useOpenItem()
  const getRiskDetail = () => {
    visibleLoading()
    ;(async () => {
      try {
        await getReportDetail(reportId)
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        cancelLoading()
      }
    })()
  }
  const [filter, setFilter] = useState({
    keyword: '',
    status: true,
  })
  useEffect(() => {
    getRiskDetail()
  }, [reportId])
  const menuHandleDocument = (
    <Menu>
      <Menu.Item
        key="print"
        icon={<PrinterOutlined style={{ color: blue }} />}
        onClick={() => {
          utils.exportPDF(
            'reportDivToPrint',
            reportDetail.title,
            'TimesNewRoman'
          )
        }}>
        <span style={{ color: blue }}>In</span>
      </Menu.Item>
    </Menu>
  )
  const ReportTable = ({
    bgColor = '',
    color = 'black',
    borderColor = 'black',
  }) => (
    <table style={{ width: '100%' }}>
      <tr style={{ background: bgColor, color: color }}>
        <th
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            borderRadius: '10px 0 0 0px',
            width: '5%',
          }}>
          <p>TT</p>
        </th>
        <th style={{ paddingTop: 12, paddingBottom: 12, width: '40%' }}>
          <p> NỘI DUNG BÁO CÁO</p>
        </th>
        <th
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            borderRadius: '0 10px   0px 0',
            width: '55%',
          }}>
          <p>
            BIỆN PHÁP XỬ LÝ/TÌNH HÌNH KHẮC PHỤC/GIẢI PHÁP PHÒNG NGỪA/KIẾN NGHỊ
          </p>
        </th>
      </tr>
      <tr>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p> 1</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>
            {' '}
            Báo cáo kết quả thực hiện công tác Quản trị rủi ro: (Áp dụng các
            nguyên tắc QTRR một cách có hệ thống trong tất cả các hoạt động kinh
            doanh của Đơn vị. Tuân thủ Chính sách, Quy trình quản lý rủi ro đã
            được phê duyệt và các quy định pháp luật khác)
          </p>

          <p>- Những kết quả đạt được</p>
          <p>- Những kết quả chưa đạt được</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <RichText htmlText={reportDetail.section1}></RichText>
        </td>
      </tr>
      <tr>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>2</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>Các rủi ro xảy ra trong kỳ báo cáo đã được xử lý</p>
          <p>- Nêu các rủi ro</p>
          <p>- Hậu quả do rủi ro xảy ra</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <RichText htmlText={reportDetail.section2}></RichText>
        </td>
      </tr>
      <tr>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>3</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>
            Các rủi ro xảy ra trong kỳ báo cáo chưa được xử lý - Nêu các rủi ro
          </p>
          <p> - Nguyên nhân, lý do</p> <p>- Các tồn tại, vướng mắc</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <RichText htmlText={reportDetail.section3}></RichText>
        </td>
      </tr>
      <tr>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p> 4</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <p>
            Nội dung phát hiện trong kỳ báo cáo: Có phát sinh rủi ro so với Nhận
            diện rủi ro đầu năm
          </p>
          <p>- Nêu rủi ro phát sinh</p>{' '}
          <p>- Hậu quả do rủi ro phát sinh gây ra</p>
        </td>
        <td
          style={{
            verticalAlign: 'top',
            border: `1px solid ${borderColor}`,
            padding: 20,
          }}>
          <RichText htmlText={reportDetail.section4}></RichText>
        </td>
      </tr>
    </table>
  )
  useEffect(() => {
    ;(async () => {
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])
  const dataUnit = reportDetail.risk_dependent_code?.map(code =>
    companyList.filter(item => item.code === code)
  )
  const dataUnitArray = dataUnit?.flatMap(subArray => subArray)
  const riskUnit = reportDetail.risk_dependent_code
    ?.map((items, index, array) => {
      return (
        <span key={index}>
          {riskUnits.find(item => item.code === items)?.name}
          {index < array?.length - 1 ? ', ' : ''}
        </span>
      )
    })
    .filter(name => name !== undefined)

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Chi tiết báo cáo | {systemName} chi tiết báo cáo</title>
        </Helmet>
        <PageTitle
          showTitle={true}
          location={props.location}
          title={reportDetail?.title}>
          <HeadingButtonWrapper>
            <Dropdown
              overlayStyle={{ minWidth: 100 }}
              overlay={menuHandleDocument}
              trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </PageTitle>

        <div style={{ marginBottom: 15 }} />

        <ContentBlockWrapper style={{ position: 'relative', minHeight: 600 }}>
          <Container maxWidth={1000}>
            <h3>
              <b>Đơn vị:</b>{' '}
              {reportDetail.risk_unit_code
                ?.map(items => {
                  return riskUnits.find(item => item.code === items)?.name
                })
                .join(', ')}
            </h3>
            <h3>
              <b>Đơn vị phụ thuộc:</b>{' '}
              {/* {reportDetail.risk_dependent_code
                ?.map(items => {
                  return riskUnits.find(item => item.code === items)?.name
                })
                .filter(name => name !== undefined)
                .join(', ')} */}
              {riskUnit}
              {/* {dataUnitArray && riskUnit ? <>, </> : <></>} */}
              {dataUnitArray?.map((items, index, array) => {
                return (
                  <span key={index}>
                    {utils.getNameInCapitalize(items.name)}
                    {index < array?.length - 1 ? ',' : ''}
                  </span>
                )
              })}
            </h3>
            <ContentBlockWrapper>
              <ReportTable bgColor={blue} color="white" borderColor="#d9d9d9" />
            </ContentBlockWrapper>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>{' '}
      <div style={{ display: 'none' }}>
        <ReportPdf
          unit={
            riskUnits.find(item => item.code === reportDetail.risk_unit_code)
              ?.name
          }
          // riskDetail={riskDetail}
          // dataActions={dataActions}
          // createUser={F
          //   riskDetail?.tiersF
          //     ? utils.getInfoUser(
          //         riskDetail?.tiers[0].approvals[0].peoples[0]?.username,
          //         originDepartmentWithUserList
          //       )
          //     : ''
          // }
          // selectUserList={selectUserList}
        >
          <ReportTable />
        </ReportPdf>
      </div>
    </>
  )
}

export default inject(
  'reportStore',
  'riskStore',
  'companyStore'
)(observer(ReportDetailPage))
