import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import { Button, message, Pagination, Popconfirm, Tooltip } from 'antd'
import uuid from 'uuid'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import signAccountStore from '../../stores/signAccountStore'
import EditSignAccountModal from '../SignAccountPage/EditSignAccountModal/EditSignAccountModal'
import TableComponent from '../../components/Common/TableComponent'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import timekeepingStore from '../../stores/timekeepingStore'

const TimekeepingPage = props => {
  const { loadingAnimationStore, signAccountStore, history } = props

  const { list, totalCount, filter } = signAccountStore
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false)

  useEffect(() => {
    load()

    return () => signAccountStore.clear()
  }, [signAccountStore])

  function load() {
    loadingAnimationStore.showSpinner(true)

    signAccountStore
      .getList(filter.page, filter.size)
      .then(() => {})
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onChangeTable = useCallback(
    (current, size) => {
      if (size !== filter.size) {
        filter.page = 1
        filter.size = size
      } else {
        filter.page = current
      }
      signAccountStore.setFilter(filter)
      load()
    },
    [signAccountStore, filter]
  )

  const handleCloseModal = () => {
    setIsVisibleModal(false)
    signAccountStore.clearDetails()
  }

  const handleDelete = async record => {
    try {
      loadingAnimationStore.showSpinner(true)
      await signAccountStore.delete(record)
      load()
    } catch (error) {
      message.error(error.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onItemClick = async () => {
    try {
      await timekeepingStore.timekeepingSync()
    } catch (error) {
      message.error(error.vi)
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chấm công</title>
      </Helmet>

      <PageTitle location={props.location} title={'Chấm công'} hiddenGoBack>
        <div>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__TAI_KHOAN_KY_SO}>
            <Button
              style={{ margin: '0 15px' }}
              type={'primary'}
              onClick={() => {
                onItemClick()
              }}>
              Đồng bộ chấm công
            </Button>
          </AccessControlAction>
        </div>
      </PageTitle>

      <ContentBlockWrapper>
        {/* <TableBottomPaginationBlock>
            <Pagination
              current={filter.page}
              pageSize={filter.size}
              total={totalCount}
              onChange={onChangeTable}
              showSizeChanger={false}
              showLessItems
            />
          </TableBottomPaginationBlock> */}

        <EditSignAccountModal
          isVisibleModal={isVisibleModal}
          handleCloseModal={handleCloseModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

TimekeepingPage.propTypes = {}

export default memo(
  inject('loadingAnimationStore', 'signAccountStore')(observer(TimekeepingPage))
)
