import {
  Button,
  Divider,
  Empty,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import TableComponent from '../../components/Common/TableComponent'
import { SearchBar } from '../UserAccountManagementPage/UserAcountManagementPageStyled'
import CompanyCreateModal from './CompanyCreateModal'

const { Search } = Input
const { Option } = Select

const CompanyList = props => {
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const history = useHistory()
  const params = useParams()
  const { companyStore } = props
  const {
    getCompanyList,
    companyList,
    deleteCompany,
    updateCompanyStatus,
  } = companyStore

  const [editCompany, setEditCompany] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({ status: null, keyword: null })
  const [isRefresh, setIsRefresh] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)

  useEffect(() => {
    ;(async () => {
      setFilter({
        keyword: queryStringParse.keyword || undefined,
        status: queryStringParse.status || undefined,
      })
      await loadCompanyList({
        keyword: queryStringParse.keyword || undefined,
        status: queryStringParse.status || undefined,
      })
      setIsRefresh(false)
    })()
  }, [])
  useEffect(() => {
    if (isRefresh) return
    const queryStringStringify = queryString.stringify(filter, {
      skipNull: true,
    })
    history.replace(`/company?${queryStringStringify}`)
    ;(async () => {
      await loadCompanyList(filter)
    })()
  }, [filter])

  const loadCompanyList = async params => {
    try {
      setIsLoading(true)
      await getCompanyList(params || filter)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeStatus = status => {
    setFilter({ ...filter, status: status })
  }

  const handleCancelCreateModal = () => {
    setShowCreateModal(false)
  }
  const handleCancelEditModal = () => {
    setEditCompany(null)
    setShowCreateModal(false)
  }
  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }
  const handleDeleteCompany = async code => {
    try {
      setIsLoading(true)
      await deleteCompany(code)
      await loadCompanyList(filter)
      message.success('Đã xóa!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }
  const handleUpdateCompanyStatus = async (code, status) => {
    try {
      setIsLoading(true)
      await updateCompanyStatus(code, { status })
      await loadCompanyList(filter)
      message.success('Cập nhật trạng thái thành công!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }
  const handleClickEditRisk = record => {
    setEditCompany(record)
    setShowCreateModal(true)
  }

  const handleSearch = value => {
    setFilter({ ...filter, keyword: value })
  }


  const tableColumns = [
    {
      title: 'Tên công ty',
      key: 'name',
      render: record => <span>{record.name}</span>,
      width: '80%',
    },
    {
      title: 'Trạng thái',
      align: 'right',
      key: 'status',
      width: ' 10%',
      render: record => (
        <Switch
          onChange={status => handleUpdateCompanyStatus(record?.code, status)}
          checked={record?.status}
        />
      ),
    },
    {
      title: 'Tác vụ',
      key: 'action',
      align: 'right',
      width: '10%',
      render: record => (
        <>
          <Tooltip title={'Sửa thông tin'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditRisk(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteCompany(record.code)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá công ty này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ]
  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 14,
          }}>
          <SearchBar>
            <Search
              allowClear
              onSearch={handleSearch}
              placeholder={'Tìm kiếm theo mã, tên công ty'}
              defaultValue={queryStringParse?.keyword}
            />
          </SearchBar>
          <Select
            style={{ minWidth: '200px' }}
            placeholder={'Trạng thái'}
            allowClear
            value={filter?.status}
            onChange={value => handleChangeStatus(value)}>
            <Option key={'active'} value={'true'}>
              Hoạt động
            </Option>
            <Option key={'inactive'} value={'false'}>
              Không hoạt động
            </Option>
          </Select>
        </div>
        <Button type={'primary'} onClick={handleShowCreateModal}>
          <PlusCircleOutlined />
          Tạo mới
        </Button>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={companyList.reverse()}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <CompanyCreateModal
        showCreateModal={showCreateModal}
        handleCancelCreateModal={handleCancelCreateModal}
        handleCancelEditModal={handleCancelEditModal}
        companyStore={companyStore}
        loadCompanyList={loadCompanyList}
        company={editCompany}
        setEditCompany={setEditCompany}
      />
    </>
  )
}

export default inject('companyStore')(observer(CompanyList))
