import React from 'react'
import { Input, Select, Tabs } from 'antd'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { REPORT_TYPE } from '../../constants'

const { TabPane } = Tabs

const TabHeader = ({reportStore}) => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const onChangeTab = key => {
    reportStore.setType(key)
  }
  const tabKeys = [REPORT_TYPE.PERIODICAL_REPORT, REPORT_TYPE.UNUSUAL_REPORT]
  const tabName = tabKey => {
    switch (tabKey) {
      case REPORT_TYPE.PERIODICAL_REPORT:
        return 'Báo cáo định kỳ'
      case REPORT_TYPE.UNUSUAL_REPORT:
        return 'Báo cáo đột xuất'
     
      default:
        return 'other'
    }
  }
  return (
    <>
      <Tabs
        defaultActiveKey={reportStore.type}
        onChange={onChangeTab}>
        {tabKeys.map(tabkey => (
          <TabPane tab={tabName(tabkey)} key={tabkey} />
        ))}
      </Tabs>
    </>
  )
}

export default inject(
  'reportStore',
)(observer(TabHeader))
