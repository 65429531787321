import { ASSIGNEE_TYPE, WORK_STATUS, WORK_TYPE } from './constants'

const common_history_log = {
  enumToText: enumValue => {
    let text = ''
    switch (enumValue) {
      case 'CREATE':
        text = 'Tạo'
        break
      case 'UPDATE':
        text = 'Chỉnh sửa'
        break
      case 'DELETE':
        text = 'Xóa'
        break
      case 'UPDATE_STATUS':
        text = 'Cập nhật trạng thái'
        break
      case 'MARK_IMPORTANT':
        text = 'Đánh dấu ưu tiên'
        break
      case 'ADD_SUB_WORK':
        text = 'Thêm công việc phụ'
        break
      case 'UPDATE_PROGRESS':
        text = 'Cập nhật tiến độ'
        break
      case 'MOVE':
        text = 'Chuyển sang tháng'
        break
      case 'ADD_WORK_FROM_TASK':
        text = 'Tạo công việc từ nhiệm vụ'
        break
      case 'CREATE_ACTION':
        text = 'Tạo nội dung phiếu rủi ro'
        break
      case 'EDIT_ACTION':
        text = 'Cập nhật nội dung phiếu rủi ro'
        break
      case 'DELETE_ACTION':
        text = 'Xóa nội dung phiếu rủi ro'
        break
      case 'APPROVE':
        text = 'Thay đổi trạng thái duyệt'
        break
      case 'EDIT_APPROVE_PEOPLE':
        text = 'Cập nhật người duyệt'
        break
      default:
        text = undefined
        break
    }
    return text
  },
}

const assign_type = {
  enumToText: enumValue => {
    let text = ''
    switch (enumValue) {
      case ASSIGNEE_TYPE.HANDLER:
        text = 'Xử lý chính'
        break
      case ASSIGNEE_TYPE.LEADER:
        text = 'Lãnh đạo giao việc'
        break
      case ASSIGNEE_TYPE.COMBINER:
        text = 'Phối hợp xử lý'
        break
      case ASSIGNEE_TYPE.FOLLOWER:
        text = 'Theo dõi'
        break
      case ASSIGNEE_TYPE.UNIT_LEADER:
        text = 'Lãnh đạo đơn vị'
        break
      case ASSIGNEE_TYPE.KT_KTNB:
        text = 'KT_KTNB'
        break
      case ASSIGNEE_TYPE.HDQT:
        text = 'Hội đồng quản trị'
        break
    }
    return text
  },
}

const field_history_log = {
  toText: (/** @type {any} */ fieldLabel, /** @type {any} */ type) => {
    switch (type) {
      case 'TASK':
        switch (fieldLabel) {
          case 'noiDung':
            return 'Nội dung'
          case 'noiDungKetQua':
            return 'Nội dung kết quả'
          case 'kienNghi':
            return 'Kiến nghị'
          case 'phanTramHoanThanh':
            return 'Phần trăm thực hiện'
          case 'ghiChu':
            return 'Ghi chú'
          case 'idTaskLevel':
            return 'Cấp giao nhiệm vụ'
          case 'ngayBatDau':
            return 'Ngày bắt đầu'
          case 'ngayKetThuc':
            return 'Ngày kết thúc'
          case 'createdAt':
            return 'Ngày tạo'
          case 'updatedAt':
            return 'Ngày cập nhật'
          case 'loaiNhiemVu':
            return 'Loại nhiệm vụ'
          case 'displayDate':
            return 'Hiển thị vào tháng'
          case 'assignees':
            return 'Vai trò'
          case 'documents':
            return 'Văn bản liên quan'
          case 'documentFiles':
            return 'Tài liệu đính kèm văn bản'
          case 'files':
            return 'Tài liệu đính kèm'
          case 'works':
            return 'Công việc'
          default:
            return fieldLabel
        }
      case 'WORK':
        switch (fieldLabel) {
          case 'workType':
            return 'Loại công việc'
          case 'title':
            return 'Tiêu đề'
          case 'startDate':
            return 'Ngày bắt đầu'
          case 'dueDate':
            return 'Ngày kết thúc'
          case 'status':
            return 'Trạng thái'
          case 'content':
            return 'Nội dung'
          case 'important':
            return 'Độ ưu tiên'
          case 'assignees':
            return 'Vai trò'
          case 'files':
            return 'Tài liệu đính kèm'
          default:
            return fieldLabel
        }
      case 'RISK':
        switch (fieldLabel) {
          case 'title':
            return 'Tiêu đề'
          case 'content':
            return 'Nội dung'
          case 'riskUnitCode':
            return 'Đơn vị'
          case 'status':
            return 'Trạng thái'
          case 'level':
            return 'Người duyệt'
          case 'data':
            return 'Nội dung phiếu rủi ro'
          default:
            return fieldLabel
        }
    }
  },
}

const work_type = {
  toText: enumKey => {
    return WORK_TYPE[enumKey]
  },
}

const work_status = {
  toText: enumKey => {
    switch (enumKey) {
      case WORK_STATUS.PENDING:
        return 'Đang thực hiện'
      case WORK_STATUS.COMPLETE:
        return 'Hoàn thành'
    }
  },
}

const work_important = {
  toText: value => {
    return value ? 'Ưu tiên' : 'Không ưu tiên'
  },
}

const ACTION_TITLES = {
  rui_ro_nhan_dien: 'Rủi ro nhận diện',
  ban_cmnvdn_htpt: 'Ban CMNV/DN HTPT',
  linh_vuc_hoat_dong: 'Lĩnh vực/Hoạt động',
  nguyen_nhan: 'Nguyên nhân',
  bien_phap_phong_ngua: 'Biện pháp phòng ngừa, giảm thiểu',
  p: 'P',
  s: 'S',
  bien_phap_xu_ly_giam_thieu: 'Giải pháp giảm thiểu',
  cong_viec_thuc_hien: 'Công việc thực hiện',
  lo_trinh_thuc_hien: 'Lộ trình thực hiện',
  nhan_dien_rui_ro_doi_voi_cong_viec_thuc_hien:
    'Nhận diện rủi ro đối với công việc thực hiện',
  rui_ro_da_nhan_dien: 'Rủi ro đã nhận diện',
  doi_tuong_thuc_hien: 'Đối tượng thực hiện',
  dvpt_cua_vimc: 'ĐVPT của VIMC',
  cac_don_vi_thuoc_cq_vp_cua_vimc: 'Các đơn vị thuộc CQ/VP của VIMC',
  I_nhan_dien_rui_ro_va_danh_gia_rui_ro:
    'I. Nhận diện rủi ro và đánh giá rủi ro',
  cong_viec_linh_vuc_hoat_dong: 'Công việc lĩnh vực hoạt động',
  noi_dung_cong_viec: 'Nội dung công việc',
  nhan_dien_rui_ro_va_danh_gia_rui_ro: 'Nhận diện rủi ro và đánh giá rủi ro',
  nhan_dien_rui_ro: 'Nhận diện rủi ro',
  xay_dung_giai_phap_giam_thieu_phong_ngua:
    'Xây dựng giải pháp giảm thiểu, phòng ngừa',
  lanh_dao_don_vi_nddpv_phu_trach_chung:
    'Lãnh đạo đơn vị NĐDPV phụ trách chung',
  II_xu_ly_rui_ro: 'II. Xử lý rủi ro',
  de_xuat_loai_rui_ro_can_xu_ly: 'Đề xuất loại rủi ro cần xử lý',
  bien_phap_xu_ly: 'Biện pháp xử lý',
  lanh_dao_vimc: 'Lãnh đạo VIMC',
  nddpv_cua_vimc_tai_dntv: 'NĐDPV của VIMC tại DNTV',
  ban_pc_qtrr_vimc: 'Ban PC&QTRR VIMC',
  ban_cmnv_vimc: 'Ban CMNV VIMC',
  III_kiem_soat_rui_ro: 'III. Kiểm soát rủi ro',
  doi_voi_cac_loai_rui_ro_da_nhan_dien: 'Đối với các loại rủi ro đã nhận diện',
  doi_voi_danh_gia_muc_rui_ro: 'Đối với đánh giá mức rủi ro',
  y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
    'Ý kiến khác của Ban PC&QTRR/Ban CMNV VIMC',
  ban_kt_ktnb: ' Ban KT-KTNB',
  tgd_ptgd: 'Tổng giám đốc, các Phó tổng giám đốc',
  lanh_dao_ban: 'Lãnh đạo ban',
  IV_ket_qua_ra_soat_rui_ro: 'IV. Kết quả rà soát rủi ro',
  kien_nghi_giai_phap_khac_phuc:
    'Kiến nghị giải pháp khắc phục BAN KT-KTNB VIMC',
  kien_nghi_giai_phap_khac_phuc_ban_dh:
    'Kiến nghị giải pháp khắc phục BAN ĐIỀU HÀNH',
  kien_nghi_giai_phap_khac_phuc_hdqt:
    'Kiến nghị giải pháp khắc phục HĐQT VIMC (CT/TV HĐQT)',
  ban_kt_ktnb_vimc: 'Ban KT-KTNB VIMC',
  hdqt_vimc: 'HĐQT VIMC (CT/TV HĐQT)',
  ban_dieu_hanh: 'Ban Điều hành',
  cac_y_kien_lien_quan: 'Các ý kiến liên quan',
  ban_pc_qtrr_ban_kt_ktnb_vimc: 'Ban PC&QTRR/Ban KT-KTNB VIMC',
  ban_dieu_hanh_hdqt_vimc: 'Ban Điều hành/HĐQT VIMC',
  cong_viec_theo: 'Công việc theo',
  cong_viec_thuoc_chuc_nang_nhiem_vu: 'Công việc thuộc chức năng nhiệm vụ',
  cong_viec_theo_muc_tieu_chien_luoc: 'Công việc theo mục tiêu chiến lược',
  nguoi_thuc_hien: 'Người thực hiện',
  thoi_gian_hoan_thanh: 'Thời gian hoàn thành',
  noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
    'Nội dung công việc khác (không thuộc các nhóm trên)',
  don_vi_thuc_hien_xu_ly_rui_ro: 'Đơn vị thực hiện xử lý rủi ro',
  loai_rui_ro_de_xuat_khac: 'Loại rủi ro đề xuất khác',
  bo_phan_de_xuat: 'Bộ phận đề xuất',
  dong_y: 'Đồng ý',
  dieu_chinh_bo_sung: 'Điều chỉnh bổ sung',
  chuyen_vien_thuc_hien: 'Chuyên viên thực hiện',
  dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai:
    'Đồng ý với đánh giá rủi ro, xử lý rủi ro ở tầng phòng vệ thứ nhất và thứ hai (Ban KT-KTNB VIMC)',
  dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh:
    'Đồng ý với đánh giá rủi ro, xử lý rủi ro ở tầng phòng vệ thứ nhất và thứ hai (Ban Điều hành)',
  dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt:
    'Đồng ý với đánh giá rủi ro, xử lý rủi ro ở tầng phòng vệ thứ nhất và thứ hai (HĐQT)',
  nguoi_thuc_hien_sign_khac: 'Người thực hiện',
  lanh_dao_don_vi_phu_thuoc: 'Lãnh đạo đơn vị phụ thuộc',
  loai_rui_ro_de_xuat: 'Loại rủi ro đề xuất',
  bo_phan_de_xuat_khac: 'Bộ phận đề xuất khác',
  nguoi_dai_dien_phan_von_phu_trach_chung:
    'Người đại diện phân vốn phụ trách chung',
  phong_ban_nddpv: 'Phòng ban NĐDPV',
  chuyen_vien_thuc_hien_nddpv: 'Chuyên viên thực hiện NĐDPV',
  lanh_dao_ban_nddpv2: 'Lãnh đạo ban NĐDPV',
  bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
    'Bộ phận nuế ý kiến khác của ban PC&QTRR/Ban CMNV VIMC',
  ket_qua: 'Kết quả',
  cong_viec_theo_kpi_kri: 'Công việc theo KPI/KRI',
  nguoi_thuc_hien_signed: 'Người thực hiện',
  nguoi_thuc_hien_sign: 'Người thực hiện',
  nddpv_phu_trach_chung: 'NĐDPV phụ trách chung',
  nddpv_phu_trach_chung__signed: 'NĐDPV phụ trách chung (ký)',
  lanh_dao_don_vi_phu_thuoc_cqvp: 'Lãnh đạo Đơn vị/NĐDPV phụ trách chung',
  don_vi_thuc_hien_xu_ly_rui_ro__signed: 'Đơn vị thực hiện xử lý rủi ro (ký)',
  nguoi_dai_dien_phan_von_phu_trach_chung__signed:
    'NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG (ký)',
  nguoi_thuc_hien__de_xuat_khac: 'Người thực hiện',
  department_code_nddpv_cua_vimc_tai_dntv: 'NĐDPV của VIMC tại DNTV',
}

const action_title = {
  toText: value => ACTION_TITLES[value] ?? value,
}

export default common_history_log
export {
  action_title,
  assign_type,
  field_history_log,
  work_important,
  work_status,
  work_type,
}
