import axios from 'axios'
import { apiUrl, apiUrlTruc } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const SignedDocumentRequest = {
  getAllDocumentSigned: (pageSize, pageIndex, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/sign-document`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
        keyword: keyword,
      },
    }),

  eSignDocument: formData =>
    axios({
      url: `${apiUrl}/api/v1/sign-document`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),

  deleteDocumentSigned: signedId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/sign-document/${signedId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  downloadDocumentSigned: fileId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/sign-document/attachments/${fileId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),

  getSignatureList: (pageIndex, pageSize, filter) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/digital-sign?keyword=${
        filter?.keyword ?? ''
      }&status=${filter?.status ?? ''}&user_name=${filter?.user_name ?? ''}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      params: {
        page: pageIndex,
        size: pageSize,
      },
    }),
  getUserSignatureList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/digital-sign/user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
    }),
  handleSignature: (idSignature, type) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/digital-sign/${idSignature}/approval/${type}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
    }),

  deleteSignature: idSignature =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/digital-sign/${idSignature}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
    }),

  createSignature: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/digital-sign`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: data,
    }),

  createSignatureBySendFileId: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/digital-sign`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: data,
    }),

  signDocumentSavis: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/sign-document/createSavisSign`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: data,
      timeout: 0,
    }),

  signDocumentSavisTruc: data =>
    axios({
      method: 'post',
      url: `${apiUrlTruc}/api/v1/sign-document/createSavisSign`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: data,
      timeout: 0,
    }),
}
