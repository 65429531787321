import axios from 'axios'
import { apiUrl, apiUrlTruc } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ConnectedDocumentRequest = {
         /** Incoming document */

         getIncomingDocument: (pageIndex, pageSize, subject) => {
           return axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/incoming`,
             headers: {
               Authorization: `Bearer  ${
                 JSON.parse(authenticationStore.trucToken)?.access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               page: pageIndex,
               size: pageSize,
               keyword: subject || null,
             },
           })
         },
         getIncomingDocumentById: document_id =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/incoming/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         deleteIncomingDocumentByID: document_id =>
           axios({
             method: 'delete',
             url: `${apiUrlTruc}/api/v1/docs/incoming/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),
         updateIncomingDocumentByID: document_id =>
           axios({
             method: 'delete',
             url: `${apiUrlTruc}/api/v1/docs/incoming/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         getIncomingDocumentAttachmentById: (document_id, fileId) =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/incoming/${document_id}/attachments/${fileId}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             responseType: 'blob',
           }),

         readIncomingDocument: document_id =>
           axios({
             method: 'patch',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/read`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         approveIncomingDocument: document_id =>
           axios({
             method: 'patch',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/approve`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         getUserLogsDocument: (document_id, pageIndex, pageSize) =>
           axios({
             method: 'get',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/user-logs`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               document_id: document_id,
               page: pageIndex,
               size: pageSize,
             },
           }),

         getConnectedDocumentIncomingCommentList: (document_id, sort) =>
           axios({
             method: 'get',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/comments`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               sort_by_date_post: sort,
             },
           }),

         createConnectedDocumentIncomingComment: (document_id, content) =>
           axios({
             method: 'post',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/comments`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             data: {
               content: content,
             },
           }),

         deleteConnectedDocumentIncomingComment: (document_id, comment_id) =>
           axios({
             method: 'delete',
             url: `${apiUrl}/api/v1/documents-incoming/${document_id}/comments/${comment_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         // OutGoing Document
         getOutgoingDocument: (pageIndex, pageSize, subject) =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/outgoing`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               page: pageIndex,
               size: pageSize,
               keyword: subject || null,
             },
           }),

         getOutgoingDocumentById: document_id =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/outgoing/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         deleteOutgoingDocumentById: document_id =>
           axios({
             method: 'delete',
             url: `${apiUrlTruc}/api/v1/docs/outgoing/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         approvedOutgoingDocument: document_id =>
           axios({
             method: 'patch',
             url: `${apiUrl}/api/v1/documents-outgoing/${document_id}/approve`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         getOutgoingDocumentAttachmentById: (document_id, fileId) =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/docs/outgoing/${document_id}/attachments/${fileId}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             responseType: 'blob',
           }),

         createOutgoingDocument: documentInfo =>
           axios({
             method: 'post',
             url: `${apiUrlTruc}/api/v1/docs/outgoing`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             data: documentInfo,
           }),

         updateOutgoingDocumentReceive: (id, data) =>
           axios({
             method: 'patch',
             url: `${apiUrlTruc}/api/v1/docs/outgoing/${id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             data: data,
           }),

         updateOutgoingDocument: (document_id, documentInfo) =>
           axios({
             method: 'put',
             url: `${apiUrlTruc}/api/v1/docs/outgoing/${document_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             data: documentInfo,
           }),

         getLogDocument: (objectId, objectType) =>
           axios({
             method: 'get',
             url: `${apiUrlTruc}/api/v1/action-log`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.trucToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               objectId,
               objectType,
             },
           }),

         getConnectedDocumentOutgoingCommentList: (document_id, sort) =>
           axios({
             method: 'get',
             url: `${apiUrl}/api/v1/documents-outgoing/${document_id}/comments`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             params: {
               sort_by_date_post: sort,
             },
           }),

         createConnectedDocumentOutgoingComment: (document_id, content) =>
           axios({
             method: 'post',
             url: `${apiUrl}/api/v1/documents-outgoing/${document_id}/comments`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
             data: {
               content: content,
             },
           }),

         deleteConnectedDocumentOutgoingComment: (document_id, comment_id) =>
           axios({
             method: 'delete',
             url: `${apiUrl}/api/v1/documents-outgoing/${document_id}/comments/${comment_id}`,
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'application/json',
             },
           }),

         eSignDocument: formData =>
           axios({
             url: `${apiUrlTruc}/api/v1/sign-document/createFptSign`,
             method: 'post',
             headers: {
               Authorization: `Bearer ${
                 JSON.parse(authenticationStore.appToken).access_token
               }`,
               'Content-Type': 'multipart/form-data',
             },
             data: formData,
           }),
       }
