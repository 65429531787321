import React, { useEffect, useState } from 'react'
import TrucWrapper from '../../components/TrucWrapper'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Container } from '../../layouts/Container/Container'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import { inject, observer } from 'mobx-react'
import EmptyContent from '../../components/EmptyContent'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST } from '../../constants'
import validator from '../../validator'
import {
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import SelectPeoplePopupTruc from '../../components/SelectPeoplePopupTruc/SelectPeoplePopupTruc'
import SelectGroupPopupTruc from '../../components/SelectGroupPopupTruc/SelectGroupPopupTruc'
import { toJS } from 'mobx'
import { blue, yellowPrimary } from '../../color'
import PopupRenameFile from '../../components/PopupRenameFile/PopupRenameFile'
import utils from '../../utils'
import {
  FormActionFooter,
  SelectList,
  SelectTitle,
  UploadFileListItem,
  UploadFileListWrapper,
} from './ConnectedDocumentOutgoingDocUpdatePageStyled'
import { AvatarHasName } from '../../components/Common/Avatar'
import moment from 'moment'

const { TextArea } = Input
const { Option } = Select

const ConnectedDocumentOutgoingDocUpdatePage = props => {
  const {
    loadingAnimationStore,
    userTrucStore,
    match,
    history,
    connectedDocumentStore,
    fileStore,
    authenticationStore,
  } = props
  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    handleCancelSelectGroupTruc,
    selectUserDataTruc,
    selectGroupDataTruc,
    handleOpenSelectGroupPopupTruc,
    isVisibleSelectGroupPopupTruc,
  } = userTrucStore

  const { selectedOutgoingDocument } = connectedDocumentStore
  const { currentUser } = authenticationStore
  const {
    steeringType,
    code,
    subject,
    listUserTo,
    promulgationDate,
    documentType,
    documentTypeName,
    content,
    competence,
    signer,
    appendixes,
    signerPosition,
    dueDate,
    priority,
    docReason,
    sphereOfPromulgation,
    typeNotation,
    promulgationAmount,
    toPlaces,
    pageAmount,
  } = selectedOutgoingDocument

  const { documentId } = match.params
  const [selectedListUserTo, setSelectedListUserTo] = useState([])

  /** Rename File */
  const [fileExt, setFileExt] = useState(undefined)
  const [isRenameFileUpdate, setIsRenameFileUpdate] = useState(false)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(
    false
  )
  const [fieldsRenameFile, setFieldsRenameFile] = useState([
    {
      name: ['rename'],
      value: '',
    },
  ])

  /** List file nhận */

  const [fileList, setFileList] = useState([]) // Từ thiết bị
  const [fileListUpdate, setFileListUpdate] = useState([]) // Từ detail

  const [form] = Form.useForm()

  useEffect(() => {
    const userList = toJS(listUserTo)
      ?.filter(el => el.type === ASSIGNEE_TYPE.USER)
      .map(user => {
        return {
          id: user.userName,
          name: user.name,
        }
      })
    const groupList = toJS(listUserTo)
      ?.filter(el => el.type === ASSIGNEE_TYPE.GROUP)
      .map(group => {
        return {
          id: parseInt(group.userName),
          name: group.name,
        }
      })
    userList && userTrucStore.setSelectUserDataTruc(userList)
    groupList && userTrucStore.setSelectGroupDataTruc(groupList)
  }, [listUserTo])

  useEffect(() => {
    setSelectedListUserTo([
      ...toJS(selectUserDataTruc),
      ...toJS(selectGroupDataTruc),
    ])
  }, [selectUserDataTruc, selectGroupDataTruc])

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ attachments: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileUpdate = file_id => {
    const newFileList = fileListUpdate.filter(file => file.fileId !== file_id)
    setFileListUpdate(newFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
    if (newFileList.length === 0) {
      form.setFieldsValue({
        attachments: [],
      })
    }
  }

  const handleOpenPopupRenameFile = (file, index) => {
    setFieldsRenameFile([
      {
        name: ['rename'],
        value: utils.removeExtensionFile(file.name),
      },
    ])
    setFileExt('.' + utils.getExtensionFile(file.name))
    setFileTargetRename({
      ...file,
      index: index,
    })
    setIsModalVisibleRenameFile(true)
  }

  const renderListFile = fileList.map((file, index) => (
    <UploadFileListItem key={file.uid || file.id}>
      <FileTextOutlined />
      <span>{file.name}</span>
      {
        <Button
          className={'rename'}
          type="text"
          onClick={() => {
            setIsRenameFileUpdate(false)
            handleOpenPopupRenameFile(file, index)
          }}>
          Đổi tên
        </Button>
      }
      <Tooltip title={'Xoá tập tin'}>
        <DeleteOutlined
          onClick={() => handleRemoveFileFromUploadList(file.uid, index)}
        />
      </Tooltip>
    </UploadFileListItem>
  ))

  const renderListFileUpdate =
    fileListUpdate &&
    fileListUpdate.map((file, index) => (
      <UploadFileListItem key={file.fileId}>
        <FileTextOutlined style={{ color: '#1890FF' }} />
        <span style={{ color: '#1890FF' }}>{file.name}</span>
        <Button
          className={'rename'}
          type="text"
          onClick={() => {
            setIsRenameFileUpdate(true)
            handleOpenPopupRenameFile(file, index)
          }}>
          Đổi tên
        </Button>
        <Tooltip title={'Xoá tập tin'}>
          <DeleteOutlined
            onClick={() => handleRemoveFileUpdate(file.fileId, index)}
          />
        </Tooltip>
      </UploadFileListItem>
    ))

  const handleCancelUpdate = () => {
    form.resetFields()
    setSelectedListUserTo([])
    history.goBack();
  }

  /** For đổi tên file  */

  const renameFile = (originalFile, newName) => {
    // Hàm đổi tên file từ thiết bị
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = async value => {
    const index = fileTargetRename?.index

    // Đổi tên file detail
    if (isRenameFileUpdate) {
      try {
        loadingAnimationStore.showSpinner(true)
        await fileStore.renameFileTruc(
          fileTargetRename.fileId,
          value.rename + fileExt
        )
        const fileUpdateAfterRename = [...fileListUpdate]
        fileUpdateAfterRename[index].name = value.rename + fileExt
        setFileListUpdate(fileUpdateAfterRename)
        setIsModalVisibleRenameFile(false)
        message.success('Đổi tên file thành công!')
        return
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    // Đổi tên file nhận từ thiết bị
    if (fileTargetRename.originFileObj) {
      const newFileList = [...fileList]
      newFileList[index].name = value.rename + fileExt
      newFileList[index].originFileObj = renameFile(
        newFileList[index].originFileObj,
        newFileList[index].name
      )
      setFileList(newFileList)
      setIsModalVisibleRenameFile(false)
      message.success('Đổi tên file thành công!')
    }
  }

  const handleUpdateOutgoingDocument = async submitData => {
    try {
      await connectedDocumentStore.updateOutgoingDocument(
        documentId,
        submitData
      )
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const onFinish = async values => {
    if (selectedListUserTo.length === 0) {
      return message.warning('Vui lòng chọn công ty nhận!')
    }

    const selectUserDataValue = selectUserDataTruc.map(user => {
      return {
        id: user.id,
        type: ASSIGNEE_TYPE.USER,
      }
    })

    const selectGroupDataValue = selectGroupDataTruc.map(group => {
      return {
        id: group.id,
        type: ASSIGNEE_TYPE.GROUP,
      }
    })

    const authoriserInfoValue = {
      username: currentUser?.username,
      full_name: currentUser?.name_uppercase,
      department: currentUser?.department.name,
      email: currentUser?.email,
      phone: currentUser?.phone,
    }
    const listUserToValue = [...selectUserDataValue, ...selectGroupDataValue]

    loadingAnimationStore.showSpinner(true)
    const batchUploadList = []
    fileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadList.push(fileStore.uploadFileLTVB(formData))
    })
    try {
      const response = await Promise.all(batchUploadList)
      const submitValues = {
        ...values,
        attachments: [
          ...response.map(response => response.data.fileId),
          ...fileListUpdate.map(el => el.fileId),
        ],
        listUserTo: listUserToValue,
        authoriser: currentUser?.username,
        authoriserInfo: JSON.stringify(authoriserInfoValue),
      }
      await handleUpdateOutgoingDocument(submitValues)
      handleCancelUpdate()
      message.success(`Cập nhật văn bản thành công!`)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    if (!documentId) return
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const res = await connectedDocumentStore.getOutgoingDocumentById(
          documentId
        )
        res.data.attachments
          ? setFileListUpdate([...toJS(res.data.attachments)])
          : setFileListUpdate([])
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [documentId])

  useEffect(() => {
    if (!documentId) return
    form.setFieldsValue({
      steeringType:
        steeringType || steeringType === 0 ? steeringType + '' : steeringType,
      promulgationDate: promulgationDate && moment(promulgationDate),
      documentType: documentType && documentType.toString(),
      documentTypeName: documentTypeName && documentTypeName,
      content: content && content,
      competence: competence && competence,
      signer: signer && signer,
      pageAmount: pageAmount && pageAmount,
      appendixes: appendixes && appendixes,
      docReason: docReason && docReason,
      signer_position: signerPosition && signerPosition,
      dueDate: dueDate && moment(dueDate),
      priority: priority || priority === 0 ? priority + '' : priority,
      sphereOfPromulgation: sphereOfPromulgation && sphereOfPromulgation,
      typeNotation: typeNotation && typeNotation,
      promulgationAmount: promulgationAmount && promulgationAmount,
      toPlaces: toPlaces && toPlaces,
      code: code && code,
      subject: subject && subject,
    })
  }, [documentId, selectedOutgoingDocument])

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Chỉnh sửa văn bản đi | Liên thông văn bản</title>
        </Helmet>
        <PageTitle location={props.location} title={'Chỉnh sửa văn bản đi'} />
        <ContentBlockWrapper>
          <Container maxWidth={820}>
            <Form
              form={form}
              scrollToFirstError={true}
              name={'update-outgoing-document'}
              layout={'vertical'}
              onFinish={onFinish}>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Số hiệu'}
                    name={'code'}
                    rules={[
                      {
                        required: true,
                        message: ' Vui lòng nhập số hiệu văn bản!',
                      },
                      { validator: validator.validateInputString },
                    ]}>
                    <Input
                      maxLength={30}
                      placeholder={'Nhập số hiệu văn bản'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Loại chỉ đạo'} name={'steeringType'}>
                    <Select
                      allowClear
                      notFoundContent={<EmptyContent />}
                      placeholder={'-- Chọn loại chỉ đạo --'}>
                      <Option value={'0'}>Không phải là chỉ đạo</Option>
                      <Option value={'1'}>Chỉ đạo</Option>
                      <Option value={'2'}>Báo cáo chỉ đạo</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item label={'Cơ quan ban hành'} name={'place'}>
                    <Input
                      maxLength={50}
                      placeholder={'Nhập cơ quan ban hành văn bản'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Ngày ban hành'} name={'promulgationDate'}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={DATE_FORMAT_LIST}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={'Loại văn bản'} name={'documentType'}>
                <Select
                  allowClear
                  notFoundContent={<EmptyContent />}
                  placeholder={'-- Chọn loại văn bản --'}>
                  <Option value={'1'}>Văn bản hành chính</Option>
                  <Option value={'2'}>Văn bản quy phạm pháp luật</Option>
                </Select>
              </Form.Item>

              <Form.Item label={'Tên loại văn bản'} name={'documentTypeName'}>
                <Input maxLength={100} placeholder={'Nhập tên loại văn bản'} />
              </Form.Item>

              <Form.Item
                label={'Trích yếu'}
                name={'subject'}
                rules={[
                  { required: true, message: ' Vui lòng nhập trích yếu!' },
                  { validator: validator.validateCharactersCannotExceed },
                ]}>
                <Input maxLength={500} placeholder={'Nhập trích yếu'} />
              </Form.Item>
              <Form.Item label={'Nội dung văn bản'} name={'content'}>
                <TextArea
                  rows={4}
                  placeholder={
                    'Nhập nội dung văn bản, chỉ áp dụng đối với văn bản dạng phi cấu trúc có độ dài không quá 500 ký tự'
                  }
                />
              </Form.Item>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item label={'Thẩm quyền'} name={'competence'}>
                    <Input maxLength={100} placeholder={'Nhập thẩm quyền'} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Người ký'} name={'signer'}>
                    <Input maxLength={50} placeholder={'Nhập tên người ký'} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={'Chức vụ người ký văn bản'}
                name={'signer_position'}>
                <Input
                  maxLength={500}
                  placeholder={'Nhập chức danh người ký'}
                />
              </Form.Item>

              <Form.Item label={'Hạn trả lời văn bản'} name={'dueDate'}>
                <DatePicker
                  format={DATE_FORMAT_LIST}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item label={'Độ khẩn'} name={'priority'}>
                <Select
                  allowClear
                  notFoundContent={<EmptyContent />}
                  placeholder={'-- Chọn độ khẩn --'}>
                  <Option value={'0'}>Thường</Option>
                  <Option value={'1'}>Khẩn</Option>
                  <Option value={'2'}>Thượng khẩn</Option>
                  <Option value={'3'}>Hỏa tốc</Option>
                  <Option value={'4'}>Hỏa tốc hẹn giờ</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Lĩnh vực ban hành'}
                name={'sphereOfPromulgation'}>
                <Input maxLength={100} placeholder={'Nhập lĩnh vực ban hành'} />
              </Form.Item>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={
                      'Ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'
                    }
                    name={'typeNotation'}>
                    <Input maxLength={100} placeholder={'Nhập ký hiệu'} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={'Số lượng ban hành'}
                    name={'promulgationAmount'}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      max={10000}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Tổng số trang của văn bản'}
                    name={'pageAmount'}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      max={10000}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Ban hành theo chỉ đạo'}
                    name={'direction'}
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label={'Phụ lục'} name={'appendixes'}>
                <Input maxLength={200} placeholder={'Nhập thông tin phụ lục'} />
              </Form.Item>
              <Form.Item label={'Nơi nhận'} name={'toPlaces'}>
                <Input maxLength={1000} placeholder={'Nhập nơi nhận'} />
              </Form.Item>

              <Form.Item label={'Lý do ban hành'} name={'docReason'}>
                <Input maxLength={200} placeholder={'Nhập lí do ban hành'} />
              </Form.Item>
              <Form.Item
                name={'attachments'}
                label={
                  fileListUpdate.length !== 0 ? (
                    <>
                      <span
                        style={{
                          color: '#ff4d4f',
                          fontFamily: 'SimSun, sans-serif',
                          marginRight: '2px',
                        }}>
                        *
                      </span>
                      Tài liệu đính kèm
                    </>
                  ) : (
                    'Tài liệu đính kèm'
                  )
                }
                rules={
                  fileListUpdate.length === 0 && [
                    {
                      required: true,
                      message: ' Vui lòng chọn tài liệu đính kèm!',
                    },
                  ]
                }>
                <Upload
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  beforeUpload={() => false}
                  onChange={handleChangeFile}
                  showUploadList={false}>
                  <Button icon={<UploadOutlined />}>
                    Chọn tài liệu đính kèm
                  </Button>
                </Upload>
              </Form.Item>
              {
                <UploadFileListWrapper>
                  {renderListFile}
                  {renderListFileUpdate}
                </UploadFileListWrapper>
              }
              <SelectTitle>
                <span>
                  <span
                    style={{
                      color: '#ff4d4f',
                      fontFamily: 'SimSun, sans-serif',
                      marginRight: '-4px',
                    }}>
                    *{' '}
                  </span>
                  <span style={{ marginRight: '10px' }}>Công ty nhận:</span>
                </span>
                <Space>
                  <Tooltip title="Chọn người dùng" color={blue}>
                    <Button
                      icon={<UserOutlined style={{ color: blue }} />}
                      onClick={() => handleOpenSelectUserPopupTruc(false)}
                    />
                  </Tooltip>
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() => handleOpenSelectGroupPopupTruc(false)}
                    />
                  </Tooltip>
                </Space>
              </SelectTitle>
              <SelectList>
                {selectedListUserTo.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => userTrucStore.handleRemoveSelectTruc(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.name}
                      icon={typeof el.id === 'number' && <TeamOutlined />}
                    />
                  </Tag>
                ))}
              </SelectList>
              <FormActionFooter>
                <Button onClick={handleCancelUpdate}>Huỷ bỏ</Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type={'primary'}
                  htmlType={'submit'}>
                  Cập nhật văn bản
                </Button>
              </FormActionFooter>
            </Form>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>
      <SelectPeoplePopupTruc
        isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
        handleCancelSelectUserTruc={handleCancelSelectUserTruc}
        handleSubmitSelectUserTruc={() =>
          userTrucStore.setIsVisibleSelectUserPopupTruc(false)
        }
      />
      <SelectGroupPopupTruc
        isVisibleSelectGroupPopupTruc={isVisibleSelectGroupPopupTruc}
        handleCancelSelectGroupTruc={handleCancelSelectGroupTruc}
        handleSubmitSelectGroupTruc={() =>
          userTrucStore.setIsVisibleSelectGroupPopupTruc(false)
        }
      />
      <PopupRenameFile
        isModalVisibleRenameFile={isModalVisibleRenameFile}
        handleCloseModalRenameFile={() => setIsModalVisibleRenameFile(false)}
        submitRenameFile={submitRenameFile}
        fieldsRenameFile={fieldsRenameFile}
        fileExt={fileExt}
      />
    </TrucWrapper>
  )
}

ConnectedDocumentOutgoingDocUpdatePage.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'userTrucStore',
  'connectedDocumentStore',
  'fileStore',
  'authenticationStore'
)(observer(ConnectedDocumentOutgoingDocUpdatePage))
