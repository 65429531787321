import React, { useEffect, useState } from 'react'
import { Empty, Pagination } from 'antd'
import TableComponent from '../../components/Common/TableComponent'
import { useHistory, useLocation } from 'react-router-dom'
import { EmptyText } from '../../components/Common/CellText'
import date_format from '../../date_format'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { stylePagination } from '../../ui_constants'

const KpiQuarterList = props => {
  const { kpiQuarterPageStore } = props
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)

  const { list, isLoading, page, total, limit } = kpiQuarterPageStore

  const tableColumns = [
    {
      title: 'Tiêu đề',
      render: record =>
        record.name ? (
          <span>{record.name}</span>
        ) : (
          <EmptyText>Không có tiêu đề.</EmptyText>
        ),
    },
    {
      title: 'Ngày tạo',
      render: record => (
        <span>{date_format.DEFAULT_DATE(record.created_at)}</span>
      ),
    },
  ]

  useEffect(() => {
    kpiQuarterPageStore.setQueryParams(
      'page',
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    setCheckPagination(false)
    kpiQuarterPageStore.load().finally(() => {
      setCheckPagination(true)
    })

    return () => {
      kpiQuarterPageStore.clearStore()
    }
  }, [])

  const handleChangePagination = (page, size) => {
    let newPage = page - 1
    const queryParams = {
      page: newPage,
    }
    const queryStringStringify = queryString.stringify(queryParams, {
      skipNull: true,
    })
    history.replace(`/kpi_quarter?${queryStringStringify}`)

    kpiQuarterPageStore.setQueryParams('page', newPage)
    kpiQuarterPageStore.load()
  }

  return (
    <>
      <TableComponent
        onRow={record => {
          return {
            onClick: () => history.push(`/kpi_quarter/view/${record.code}`),
          }
        }}
        rowKey={'code'}
        dataSource={list}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText:
            checkPagination === true ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ) : (
              <div style={{ opacity: 0.1, height: '150px' }} />
            ),
        }}
        loading={isLoading}
      />
      {checkPagination === true ? (
        <Pagination
          style={stylePagination}
          current={page + 1}
          pageSize={limit}
          total={total}
          onChange={handleChangePagination}
        />
      ) : (
        ''
      )}
    </>
  )
}

KpiQuarterList.propTypes = {}

export default inject('kpiQuarterPageStore')(observer(KpiQuarterList))
