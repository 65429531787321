import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import { inject, observer } from 'mobx-react'
import validator from '../../validator'
import { FormButtonGroup } from './ReportPageStyled'
import { REPORT_TYPE } from '../../constants'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import RichEditor from '../../components/RichEditor/RichEditor'
import { blue } from '../../color'
import RuiRoNhanDienTemplate from '../RiskCreatePage/RuiRoNhanDienTemplate'
import utils from '../../utils'
import { handleFilterSelect } from '../../utils/filterSelect'

const { Option } = Select

const ReportCreateModal = props => {
  const [form] = Form.useForm()

  const {
    modalVisible,
    onCancel,
    editReport,
    loadingAnimationStore,
    onRefresh,
    reportStore,
    riskStore,
    companyStore,
    roleStore,
    positionStore,
  } = props
  const { type: reportType, createReport } = reportStore
  const { riskUnits, riskUnitsV2 } = riskStore
  const { getCompanyList, companyList } = companyStore

  const arrUnit = riskUnits.concat(companyList)
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState({
    section2: [],
    section3: [],
  })
  const [section, setSection] = useState()
  const SECTION1_REF = useRef()
  const SECTION2_REF = useRef()
  const SECTION3_REF = useRef()
  const SECTION4_REF = useRef()
  useEffect(() => {
    if (!editReport) {
      return
    }
    form.setFieldsValue({
      title: editReport.title,
    })
  }, [editReport])
  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 600)
    } else {
      setIsRenderRichText(false)
    }
  }, [modalVisible])

  useEffect(() => {
    ;(async () => {
      try {
        await riskStore.getRiskUnitsV2('DVPT')
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {}
  }, [])
  useEffect(() => {
    ;(async () => {
      await positionStore.getPositionList()
      await roleStore.getRoles()
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])
  useEffect(() => {
    if (ruiRoNhanDien) {
      SECTION2_REF.current?.editor.setData(
        `${ruiRoNhanDien.section2
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
      SECTION3_REF.current?.editor.setData(
        `${ruiRoNhanDien.section3
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
    }
  }, [ruiRoNhanDien])

  const onFinish = values => {
    const submitData = {
      type: reportType,
      title: values.title,
      risk_unit_code: values.risk_unit_code,
      risk_dependent_code: values.risk_dependent_code,
      section1: SECTION1_REF.current.editor.getData(),
      section2: SECTION2_REF.current.editor.getData(),
      section3: SECTION3_REF.current.editor.getData(),
      section4: SECTION4_REF.current.editor.getData(),
    }
    loadingAnimationStore.showSpinner(true)
    createReport(submitData)
      .then(() => {
        handleCancelAdd()
        loadingAnimationStore.showSpinner(false)
        message.success('Tạo báo cáo thành công')
        if (onRefresh) {
          onRefresh()
        }
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const onReset = useCallback(() => {
    form.resetFields()
    SECTION1_REF.current.editor.setData('')
    SECTION2_REF.current.editor.setData('')
    SECTION3_REF.current.editor.setData('')
    SECTION4_REF.current.editor.setData('')
  }, [form])

  const handleCancelAdd = useCallback(() => {
    onCancel()
    form.resetFields()
  }, [form, onCancel])

  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40 }}
        title={'Tạo báo cáo'}
        visible={modalVisible}
        scrollToFirstError
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={handleCancelAdd}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          preserve={false}
          scrollToFirstError={true}>
          <h2>
            Loại:{' '}
            {reportType === REPORT_TYPE.PERIODICAL_REPORT
              ? 'Báo cáo định kỳ'
              : 'Báo cáo đột xuất'}
          </h2>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}>
            <Input placeholder={'Tiêu đề báo cáo'} />
          </Form.Item>
          <Form.Item
            name={'risk_unit_code'}
            label={'Đơn vị:'}
            rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}>
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị'}>
              {riskUnits?.map(unit => (
                <Option
                  key={unit.code}
                  name={'risk_unit_code'}
                  value={unit.code}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'risk_dependent_code'}
            label={'Đơn vị phụ thuộc:'}
            rules={[
              { required: true, message: 'Vui lòng chọn đơn vị phụ thuộc!' },
            ]}>
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị phụ thuộc'}>
              {Array.isArray(riskUnitsV2) &&
                riskUnitsV2?.map(unit => (
                  <Option
                    key={unit.code}
                    name={'risk_dependent_code'}
                    value={unit.code}>
                    {unit.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <p style={{ marginTop: 16 }}>
            1. Báo cáo kết quả thực hiện công tác Quản trị rủi ro: (Áp dụng các
            nguyên tắc QTRR một cách có hệ thống trong tất cả các hoạt động của
            Văn phòng cơ quan/Ban chuyên môn/TTCNTT. Tuân thủ Chính sách, Quy
            trình quản lý rủi ro đã được phê duyệt và các quy định pháp luật
            khác)
          </p>
          <p>
            BIỆN PHÁP XỬ LÝ/TÌNH HÌNH KHẮC PHỤC/GIẢI PHÁP PHÒNG NGỪA/KIẾN NGHỊ
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION1_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section1}
            />
          )}
          <p style={{ marginTop: 16 }}>
            2. Các rủi ro xảy ra trong kỳ báo cáo đã được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section2')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION2_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section2}
            />
          )}
          <p style={{ marginTop: 16 }}>
            3. Các rủi ro xảy ra trong kỳ báo cáo chưa được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section3')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION3_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section3}
            />
          )}
          <p style={{ marginTop: 16 }}>
            4. Nội dung phát hiện trong kỳ báo cáo: Có phát sinh rủi ro so với
            Nhận diện rủi ro đầu năm
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION4_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section2}
            />
          )}
          <FormButtonGroup>
            <Button type={'danger'} ghost onClick={onCancel}>
              Huỷ bỏ
            </Button>
            <Space size={'middle'}>
              <Button onClick={onReset}>Nhập lại</Button>
              <Button type={'primary'} htmlType={'submit'}>
                Tạo mới
              </Button>
            </Space>
          </FormButtonGroup>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => {
          setIsVisibleRuiRoNhanDienTemplate(false)
        }}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
        multiple={true}
        section={section}
        ruiRoNhanDien={ruiRoNhanDien}
      />
    </>
  )
}
export default inject(
  'reportStore',
  'riskStore',
  'loadingAnimationStore',
  'companyStore',
  'positionStore',
  'roleStore'
)(observer(ReportCreateModal))
