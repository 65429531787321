import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ProposalTypeListItem from './ProposalTypeListItem'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

const ProposalTypePage = props => {
  return !props.isAdministrativeManagementTab ? (
    <DashboardLayout>
      <Helmet>
        <title>Loại hành chính</title>
      </Helmet>
      <PageTitle location={props.location}></PageTitle>
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <ProposalTypeListItem />
      </ContentBlockWrapper>
    </DashboardLayout>
  ) : (
    <ProposalTypeListItem />
  )
}

ProposalTypePage.propTypes = {
  isAdministrativeManagementTab: PropTypes.bool,
}

export default inject('contentTemplateStore')(observer(ProposalTypePage))
