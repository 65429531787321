import { action, observable } from 'mobx'
import { SignedDocumentRequest } from '../requests/SignedDocumentRequest'

class SignedDocumentStore {
  @observable documentSignedList = []
  @observable userDetail = {}
  @observable totalCount = 0
  @observable pageIndex = 0
  @observable pageSize = 10
  @observable keyword = undefined

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action getAllDocumentSigned = () => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.getAllDocumentSigned(
        this.pageSize,
        this.pageIndex,
        this.keyword
      )
        .then(response => {
          const data = response.data.data
          const totalCount = response.data.totalElements
          this.documentSignedList = data
          this.totalCount = totalCount
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action eSignDocument = formData => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.eSignDocument(formData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action deleteDocumentSigned = signedId => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.deleteDocumentSigned(signedId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action downloadDocumentSigned = fileId => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.downloadDocumentSigned(fileId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Ký số savis
  @observable fileBlob = null
  @observable signatureList = undefined
  @observable pageSignatureIndex = 0
  @observable pageSignatureSize = 5
  @observable signatureTotalCount = 0
  @observable originFileObj = null

  @action clearSignatureStore = () => {
    this.pageSignatureIndex = 0
    this.pageSignatureSize = 5
  }

  @action setFileBlob = file => {
    this.fileBlob = file
  }

  @action setOriginFileObj = file => {
    this.originFileObj = file
  }

  @action getSignatureList = filter => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.getSignatureList(
        this.pageSignatureIndex,
        this.pageSignatureSize,
        filter
      )
        .then(response => {
          this.signatureTotalCount = response.data.totalElements
          this.signatureList = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getUserSignatureList = () => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.getUserSignatureList()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action handleSignature = (idSignature, type) => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.handleSignature(idSignature, type)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteSignature = signId => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.deleteSignature(signId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createSignature = data => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.createSignature(data)
        .then(response => {
          console.log(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createSignatureBySendFileId = data => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.createSignatureBySendFileId(data)
        .then(response => {
          console.log(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action signDocumentSavis = data => {
    // kích thước trang sẽ tính theo A4 (595x845 point
    // toạ độ [llx,lly] xác định điểm góc dưới của vùng ký
    // toạ độ [urx,ury] xác định điểm góc trên của vùng ký
    // A4 info
    //   llx: 300,
    //   lly: 794,
    //   urx: 590,
    //   ury: 830,
    // }
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.signDocumentSavis(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // sign Savis trên trục liên thông
  @action signDocumentSavisTruc = data => {
    return new Promise((resolve, reject) => {
      SignedDocumentRequest.signDocumentSavisTruc(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action clearSignedDocumentListStore = () => {
    this.documentSignedList.length = 0
    this.totalCount = 0
    this.pageIndex = 0
    this.pageSize = 10
    this.keyword = undefined
    this.signatureList = undefined
    this.pageSignatureIndex = 0
    this.pageSignatureSize = 100
  }
  @action clearDataPopupSign = () => {
    this.fileBlob = undefined
    this.originFileObj = null
    this.responseSign = undefined
  }

  @action clearSignatureList = () => {
    this.signatureList = undefined
    this.pageSignatureIndex = 0
    this.pageSignatureSize = 100
  }

  @observable responseSign = undefined
  @action setResponseSign = res => {
    this.responseSign = res
  }

  @action clearFilePDFSign = () => {
    this.fileBlob = undefined
    this.originFileObj = null
    this.responseSign = undefined
  }
}

export default new SignedDocumentStore()
