import { action, observable } from 'mobx'
import { SignAccountRequest } from '../requests/SignAccountRequest'

class SignAccountStore {
  @observable list = []
  @observable signAccountDetails = {}
  @observable totalCount = 0
  @observable filter = {
    page: 1,
    size: 10,
    keyword: '',
    accountKeyword: '',
    type: '',
  }

  @observable selectedAccount = []

  @action setFilter = filter => {
    this.filter = Object.assign({}, this.filter, filter)
  }

  @action getList = () => {
    return new Promise((resolve, reject) => {
      SignAccountRequest.getList(
        this.filter.size,
        this.filter.page - 1,
        this.filter.keyword,
        this.filter.accountKeyword,
        this.filter.type
      )
        .then(response => {
          const data = response.data.data
          const totalCount = response.data.totalElements
          this.list = data
          this.totalCount = totalCount
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action listAll = () => {
    return new Promise((resolve, reject) => {
      SignAccountRequest.listAll()
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action create = signAccount => {
    return new Promise((resolve, reject) => {
      SignAccountRequest.create(signAccount)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action update = signAccount => {
    return new Promise((resolve, reject) => {
      SignAccountRequest.update(signAccount)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action delete = signAccount => {
    return new Promise((resolve, reject) => {
      SignAccountRequest.delete(signAccount.id)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action clear = () => {
    this.list.length = 0
    this.totalCount = 0
    this.filter = {
      page: 1,
      size: 10,
      keyword: '',
      accountKeyword: '',
      type: '',
    }
  }

  @action clearDetails = () => {
    this.signAccountDetails = {}
    // console.log("clearDetails")
  }
}

export default new SignAccountStore()
