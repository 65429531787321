import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Button, Col, Form, Input, Popover, Row, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { ERROR_CODE_MESSAGE } from '../../constants'
import validator from '../../validator'
import { ProfileGroupWrapper } from './MyProfilePageStyled'

const ChangePassword = ({
  userStore = undefined,
  loadingAnimationStore = undefined,
  onAfterPasswordChanged,
  onCancel,
  visible = false,
}) => {
  const [form] = Form.useForm()

  const onFinish = async values => {
    loadingAnimationStore.showSpinner(true)
    try {
      await userStore.updateCurrentUserPassword(values)

      form.resetFields()
      onAfterPasswordChanged()
      message.success('Thay đổi mật khẩu thành công!')
    } catch (err) {
      message.error(
        ERROR_CODE_MESSAGE[err.errorCode] || err?.vi || 'Đã có lỗi xảy ra!'
      )
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const notiPassword = (
    <div>
      <p>- Mật khẩu không được để trống.</p>
      <p>- Mật khẩu phải chứa ít nhất 8 ký tự.</p>
      <p>- Mật khẩu phải chứa ít nhất một ký tự viết hoa.</p>
      <p>- Mật khẩu phải chứa ít nhất một ký tự thường.</p>
      <p>- Mật khẩu phải chứa ít nhất một số.</p>
      <p>- Mật khẩu phải chứa ít nhất một ký tự đặc biệt.</p>
      <p>- Mật khẩu không chứa space.</p>
    </div>
  )

  return (
    <Fragment>
      <ProfileGroupWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          onFinish={onFinish}
          colon={true}
          layout="vertical"
          name={'profile-change-password'}
          id={'profile-change-password'}>
          <Row type={'flex'} gutter={30} justify={'space-between'}>
            <Col xs={24} md={12}>
              <Form.Item
                name={'password_old'}
                label={'Mật khẩu cũ'}
                rules={[
                  {
                    required: true,
                    message: 'Mật khẩu cũ không được để trống!',
                  },
                ]}>
                <Input.Password placeholder={'Nhập lại mật khẩu cũ'} />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'password_new'}
                required
                label={
                  <span>
                    Mật khẩu mới
                    <Popover content={notiPassword}>
                      <InfoCircleOutlined
                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                      />
                    </Popover>
                  </span>
                }
                rules={[{ validator: validator.validatorPassword }]}>
                <Input.Password placeholder={'Nhập mật khẩu mới'} />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={'retype_password_new'}
                label="Nhập lại mật khẩu mới"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (form.getFieldValue('password_new') !== value) {
                        throw new Error('Mật khẩu nhập lại không khớp!')
                      }
                    },
                  },
                ]}>
                <Input.Password placeholder={'Nhập mật khẩu mới'} />
              </Form.Item>
            </Col>
          </Row>
          {visible ? (
            <div
              style={{
                marginTop: '30px',
                marginLeft: '91px',
              }}>
              <Button
                danger
                onClick={onCancel}
                icon={<CloseOutlined />}
                style={{ marginRight: 10 }}>
                Huỷ bỏ
              </Button>
              <Button
                icon={<CheckOutlined />}
                form="profile-change-password"
                type="primary"
                key="submit"
                htmlType="submit">
                Lưu thay đổi
              </Button>
            </div>
          ) : (
            <Fragment>
              <Button
                danger
                onClick={onCancel}
                icon={<CloseOutlined />}
                style={{ marginRight: 10 }}>
                Huỷ bỏ
              </Button>
              <Button
                icon={<CheckOutlined />}
                form="profile-change-password"
                type="primary"
                key="submit"
                htmlType="submit">
                Lưu thay đổi
              </Button>
            </Fragment>
          )}
        </Form>
      </ProfileGroupWrapper>
    </Fragment>
  )
}

ChangePassword.propTypes = {}
export default inject(
  'userStore',
  'loadingAnimationStore'
)(observer(ChangePassword))
