import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const TimekeepingSyncRequest = {
  timekeepingSync: () =>
    axios({
      method: 'post',
      url: `${apiUrl}/management/timekeepingSync`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/text',
      },
    }),
}
