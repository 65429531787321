// @ts-nocheck
import { UserOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Steps, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { blue } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import useOpenItem from '../../hooks/useOpenItem'
import RiskAssessmentCreatePopup from '../../pages/RiskCreatePage/RiskAssessmentCreatePopup'
import RiskControlResult from '../../pages/RiskCreatePage/RiskControlResult'
import RiskIdentificationCreatePopup from '../../pages/RiskCreatePage/RiskIdentificationCreatePopup'
import RiskWorkCreatePopup from '../../pages/RiskCreatePage/RiskWorkCreatePopup'
import utils from '../../utils'
import RiskActionDetails from '../RiskActionDetails'
import {
  DepartmentTag,
  UserTag,
  UserTagV2,
} from '../RiskSelecUsertList/RiskSelecUsertListStyle'
import RiskSelectUserList from '../RiskSelecUsertList/RiskSelectUserList'
import SelectPeoplePopup from '../SelectPeoplePopup'
import SelectPeopleRiskPopup from '../SelectPeopleRiskPopup'
import './RiskStepsStyle'
import { CreateSubTitle, StepsWrapper } from './RiskStepsStyle'
import { systemName } from '../../config'

const { Step } = Steps

const SelectUserItem = ({
  title,
  userList = [],
  departmentList = [],
  setCheckSelectOne = undefined,
  handleSelectUser,
  departmentCode = undefined,
  originDepartmentWithUserList,
}) => {
  const department_name = departmentList.find(el => el.code === departmentCode)
    ?.name

  const openPopup = useCallback(() => {
    handleSelectUser()
    if (title === 'Đơn vị thực hiện xử lý rủi ro:' && setCheckSelectOne) {
      setCheckSelectOne(() => true)
    }
  }, [setCheckSelectOne, title, handleSelectUser])

  // tìm chức danh của user
  const resultListUser =
    Array.isArray(originDepartmentWithUserList) &&
    originDepartmentWithUserList.flatMap(unit =>
      unit?.users
        ?.filter(userUnitsV2 =>
          userList?.some(
            itemUserList => itemUserList.id === userUnitsV2.user_name
          )
        )
        .map(userUnitsV2 => {
          const matchingUserList = userList.find(
            itemUserList => itemUserList.id === userUnitsV2.user_name
          )
          return {
            user_code: userUnitsV2.user_code,
            full_name: userUnitsV2.name_uppercase,
            user_name: userUnitsV2.user_name,
            position_name:
              userUnitsV2.position_name || matchingUserList.position_name,
          }
        })
    )

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 8,
          alignItems: 'center',
          marginBottom: 8,
          marginTop: -8,
        }}>
        <div>{title}</div>
        <Tooltip title="Chọn người dùng" color={blue}>
          <Button
            icon={<UserOutlined style={{ color: blue }} />}
            onClick={openPopup}
          />
        </Tooltip>
      </div>
      <div style={{ fontWeight: 600, marginBottom: '5px' }}>
        {userList[0]?.permission === 'don_vi_thuc_hien_xu_ly_rui_ro_3'
          ? (department_name ?? userList[0]?.department_name)?.toUpperCase()
          : ''}
      </div>

      <RiskSelectUserList userList={resultListUser} />
    </>
  )
}

const SelectUserRiskItem = ({ userList = [], riskUnitsV2 = [] }) => {
  // tìm chức danh của user
  const resultListUser =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2.flatMap(unit =>
      unit.unit_users
        .filter(userUnitsV2 =>
          userList?.some(
            itemUserList => itemUserList.username === userUnitsV2.username
          )
        )
        .map(userUnitsV2 => {
          const matchingUserList = userList.find(
            itemUserList => itemUserList.username === userUnitsV2.username
          )
          return {
            user_code: userUnitsV2.user_code,
            full_name: userUnitsV2.full_name,
            username: userUnitsV2.username,
            position_name:
              userUnitsV2.position_name || matchingUserList.position_name,
          }
        })
    )

  return (
    <>
      {resultListUser?.length > 0
        ? resultListUser?.map((item, index) => {
            return (
              <div key={index} style={{ marginTop: 4, height: '30px' }}>
                <UserTagV2>
                  {utils.getNameInCapitalize(item?.full_name || item?.title)}{' '}
                  {item?.position_name ? (
                    <>
                      - <span>{item?.position_name}</span>
                    </>
                  ) : null}
                </UserTagV2>
              </div>
            )
          })
        : userList?.map((item, index) => {
            return (
              <div key={index} style={{ marginTop: 4, height: '30px' }}>
                <UserTagV2>
                  {utils.getNameInCapitalize(item?.full_name || item?.title)}{' '}
                  {item?.position_name ? (
                    <>
                      - <span>{item?.position_name}</span>
                    </>
                  ) : null}
                </UserTagV2>
              </div>
            )
          })}
    </>
  )
}

const RiskSteps = props => {
  const {
    selectPeopleStore,
    createUser,
    selectUserList,
    current,
    onChangeSteps,
    updateTierApproval,
    actionStore,
    getRiskDetail,
    type,
    setType,
    riskDetail,
    loadingAnimationStore,
    riskStore,
    departmentStore,
    authenticationStore,
    riskUnitUserStore,
    onUpdateTier1RiskExecutor,
    onUpdateTier1UnitLeader,
    onUpdateTier2RiskExecutor,
    onUpdateTier3RiskExecutor,
    onUpdateNDDPVgeneral,
    onUpdateTier2DVPTprofessional,
    onUpdateTier1NDDPVgeneral,
  } = props
  const [dataRiskUnitsByType, setRiskUnitsByType] = useState([])
  const [dataRiskUnitsByTypeNDDPV, setRiskUnitsByTypeNDDPV] = useState([])
  const [listDepartment, setListDepartment] = useState([])
  const [listDepartmentTuyen2CQVP, setListDepartmentTuyen2CQVP] = useState([])
  const [confirm, setConfim] = useState()
  const [selectedListAndType, setSelectedListAndType] = useState([])

  const [
    listDepartmentTuyen2CQVPCreate,
    setListDepartmentTuyen2CQVPCreate,
  ] = useState([])

  const getRiskUnitsByTypeList = async type => {
    await riskStore.getRiskUnitsByType(type).then(response => {
      setRiskUnitsByType(response.data)
    })
  }
  const getRiskUnitsByTypeListNDDPV = async type => {
    await riskStore.getRiskUnitsByType(type).then(response => {
      setRiskUnitsByTypeNDDPV(response.data)
    })
  }
  useEffect(() => {
    getRiskUnitsByTypeList('DVPT')
    getRiskUnitsByTypeListNDDPV('NDDPV')
  }, [])
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    isVisibleSelectUserDepartmentPopup,
    originDepartmentWithUserList,
  } = selectPeopleStore
  const { departmentList } = departmentStore

  useEffect(() => {
    ;(async () => {
      if (authenticationStore.currentCompanyCode()) {
        try {
          departmentStore.getDepartmentListFromCompanyCode(
            authenticationStore.currentCompanyCode()
          )
        } catch (error) {
          console.log(error)
          message.error(error?.vi || 'Đã có lỗi xảy ra!')
        }
      }
    })()
  }, [authenticationStore.currentUser])

  const { dataActions } = actionStore
  const { riskUnitsV2 } = riskStore

  const {
    isOpen: isVisibleSelectUserPopup,
    handleOpen: handleVisibleSelectUserPopup,
    handleClose: handleCloseSelectUserPopup,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskId,
    handleOpen: handleVisibleCreateRiskId,
    handleClose: handleCloseCreateRiskId,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskAssessment,
    handleOpen: handleVisibleCreateRiskAssessment,
    handleClose: handleCloseCreateRiskAssessment,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskWork,
    handleOpen: handleVisibleCreateRiskWork,
    handleClose: handleCloseCreateRiskWork,
  } = useOpenItem()
  const {
    isOpen: isVisibleRiskControlResult,
    handleOpen: handleVisibleRiskControlResult,
    handleClose: handleCloseRiskControlResult,
  } = useOpenItem()
  const {
    isOpen: isVisibleSelectOneUserPopup,
    handleOpen: handleVisibleSelectOneUserPopup,
    handleClose: handleCloseSelectOneUserPopup,
  } = useOpenItem()

  const handleGetCompany = async type => {
    if (type === 'CQVP') {
      await departmentStore.getDepartmentList()
    }
    await riskStore.getRiskUnitsV2(type)
  }
  useEffect(() => {
    handleGetCompany(type)
  }, [type])
  const [checkSelectOne, setCheckSelectOne] = useState(false)

  const handleSelectUser = useCallback(
    assigneeType => {
      handleVisibleSelectUserPopup()
      handleOpenSelectUserDepartmentPopup(assigneeType)
    },
    [handleVisibleSelectOneUserPopup, handleOpenSelectUserDepartmentPopup]
  )

  useEffect(() => {
    riskUnitUserStore.getDVPTUsers()
  }, [])

  useEffect(() => {
    const result = Array.isArray(riskUnitsV2)
      ? riskUnitsV2.filter(risk =>
          risk.unit_users.some(user =>
            riskDetail?.tiers?.[1]?.approvals[1]?.peoples
              .map(users => users.username)
              .includes(user.username)
          )
        )
      : []
    setListDepartment(result)
  }, [riskUnitsV2])

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)

      await Promise.allSettled([
        riskUnitUserStore.getNDDPVUsers().catch(console.error),
        riskUnitUserStore.getDVPTUsers().catch(console.error),
      ])
      loadingAnimationStore.showSpinner(false)

      const tier1RiskExecutors = riskDetail?.tiers?.[0]?.approvals[0]?.peoples
      const tier1UnitLeaders = riskDetail?.tiers?.[0]?.approvals[1]?.peoples
      const tier2RiskExecutors = riskDetail?.tiers?.[1]?.approvals[0]?.peoples
      const tier3RiskExecutors = riskDetail?.tiers?.[2]?.approvals[0]?.peoples
      const tier1NDDPVgeneral = riskDetail?.tiers?.[0]?.approvals[1]?.peoples
      const tier2NDDPVgeneral = riskDetail?.tiers?.[1]?.approvals[1]?.peoples
      const tier2DVPTprofessional =
        riskDetail?.tiers?.[1]?.approvals[1]?.peoples

      switch (type) {
        case 'NDDPV':
          if (tier1RiskExecutors?.length > 0) {
            riskUnitUserStore.setSelectedTier1RiskExecutors(
              tier1RiskExecutors
                .filter(executor => !!executor.username)
                .map(executor => executor.username)
            )
          }
          if (tier2RiskExecutors?.length > 0) {
            riskUnitUserStore.setSelectedTier2RiskExecutors(
              tier2RiskExecutors
                .filter(executor => !!executor.username)
                .map(executor => executor.username)
            )
          }
          if (tier3RiskExecutors?.length > 0) {
            riskUnitUserStore.setSelectedTier3RiskExecutors(
              tier3RiskExecutors
                .filter(executor => !!executor.username)
                .map(executor => executor.username),
              type
            )
          }
          if (tier1NDDPVgeneral?.length > 0) {
            await riskUnitUserStore.setSelectedTier1NDDPVgeneral(
              tier1NDDPVgeneral
                .filter(general => !!general.username)
                .map(general => general.username)
            )
          }
          if (tier2NDDPVgeneral?.length > 0) {
            await riskUnitUserStore.setSelectedTier2NDDPVgeneral(
              tier2NDDPVgeneral
                .filter(general => !!general.username)
                .map(general => general.username)
            )
          }

          break

        case 'DVPT':
          const customTier1Executor = tier1RiskExecutors?.find(
            executor => !executor.username
          )
          if (customTier1Executor) {
            riskUnitUserStore.setCustomTier1RiskExecutorFullname(
              customTier1Executor.full_name
            )
          }
          if (tier1UnitLeaders?.length > 0) {
            riskUnitUserStore.setSelectedTier1UnitLeaders(
              tier1UnitLeaders
                .filter(executor => !!executor.username)
                .map(executor => executor.username),
              type
            )
          }
          if (tier2DVPTprofessional?.length > 0) {
            await riskUnitUserStore.setSelectedTier2DVPTprofessional(
              tier2DVPTprofessional
                .filter(professional => !!professional.username)
                .map(professional => professional.username)
            )
          }
          if (tier3RiskExecutors?.length > 0) {
            riskUnitUserStore.setSelectedTier3RiskExecutors(
              tier3RiskExecutors
                .filter(executor => !!executor.username)
                .map(executor => executor.username),
              type
            )
          }

          break

        case 'CQVP':
        default:
          break
      }
    })()
  }, [loadingAnimationStore, riskDetail, riskUnitUserStore, type])

  useEffect(() => {
    return () => {
      riskUnitUserStore.clearStore()
    }
  }, [riskUnitUserStore])

  const tier1ExecutorLabel =
    type === 'NDDPV'
      ? 'Người đại diện phần vốn thực hiện phiếu:'
      : 'Chuyên viên thực hiện:'

  const [uniqueNames, setUniqueNames] = useState([])

  useEffect(() => {
    const names = {}

    listDepartment.forEach(item => {
      if (!names[item.name]) {
        names[item.name] = true
      }
    })
    setUniqueNames(Object.keys(names))
  }, [listDepartment])

  // Lấy department list tuyến 2 CQVP
  useEffect(() => {
    const result = riskDetail?.tiers?.[1].approvals[1]?.peoples
      ?.map(el => departmentList.filter(els => els.code === el.department_code))
      .flat()

    // Loại bỏ các phần tử trùng lặp
    const uniqueResult = Array.from(
      new Set(result?.map(JSON.stringify)),
      JSON.parse
    )

    setListDepartmentTuyen2CQVP(uniqueResult)
  }, [departmentList, riskDetail])

  useEffect(() => {
    // Lấy hết department_code ra, xong nếu có giống nhau thì chỉ lấy 1 cái
    const result = new Set(
      selectUserList?.VIMCLeader?.map(el => el.department_code)
    )
    // Chuyển lại về array
    const resultArray = Array.from(result)
    // Lọc với departmentList, nếu giống nhau thì lấy item giống của departmentList ra, và nó đang bị lồng [Array(1)], nên fix lại
    const resultFinal = resultArray.map(el => {
      const filteredItems = departmentList.filter(els => els.code === el)
      if (filteredItems.length > 0) {
        return filteredItems[0] // Truy xuất phần tử đầu tiên nếu có nhiều phần tử
      }
      return null // Hoặc xử lý trường hợp không tìm thấy
    })

    setListDepartmentTuyen2CQVPCreate(resultFinal)
  }, [selectUserList, departmentList])

  const [
    isVisibleSelectPeopleRiskPopup,
    setIsVisibleSelectPeopleRiskPopup,
  ] = useState(false)

  const showModal = () => {
    setIsVisibleSelectPeopleRiskPopup(true)
  }
  let onConfirm

  switch (confirm) {
    case 'Tier1RiskExecutor':
      onConfirm = onUpdateTier1RiskExecutor
      break
    case 'Tier1NDDPVgeneral':
      onConfirm = onUpdateTier1NDDPVgeneral
      break
    case 'Tier2RiskExecutor':
      onConfirm = onUpdateTier2RiskExecutor
      break
    case 'NDDPVgeneral':
      onConfirm = onUpdateNDDPVgeneral
      break
    case 'Tier3RiskExecutor':
      onConfirm = onUpdateTier3RiskExecutor
      break
    case 'Tier1UnitLeader':
      onConfirm = onUpdateTier1UnitLeader
      break
    case 'Tier2DVPTprofessional':
      onConfirm = onUpdateTier2DVPTprofessional
      break
    default:
      // Hành động mặc định khi không có trường hợp nào khớp
      break
  }

  const updateCustomRiskExecutorFullname = useCallback(
    ({ target: { value: fullname } }) => {
      riskUnitUserStore.setCustomTier1RiskExecutorFullname(fullname)
    },
    [riskUnitUserStore]
  )
  return (
    <>
      <StepsWrapper>
        <Steps onChange={onChangeSteps} current={current}>
          <Step />
          <Step />
          <Step />
          <Step />
        </Steps>
      </StepsWrapper>
      {current === 0 ? (
        <>
          <CreateSubTitle>Tuyến phòng vệ thứ nhất</CreateSubTitle>
          <Row type={'flex'} gutter={15}>
            <Col span={12}>
              {type === 'CQVP' ? (
                <>
                  <SelectUserItem
                    title="Chuyên viên thực hiện:"
                    handleSelectUser={() =>
                      handleSelectUser(
                        ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1
                      )
                    }
                    userList={selectUserList.chuyen_vien_thuc_hien_cqvp_tuyen1}
                    originDepartmentWithUserList={originDepartmentWithUserList}
                  />
                </>
              ) : type === 'NDDPV' ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 4 }}>{tier1ExecutorLabel}</div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('Tier1RiskExecutor')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[0].approvals?.[0]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[0].approvals?.[0]?.peoples}
                      riskUnitsV2={riskUnitsV2}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'Tier1RiskExecutor' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </>
              ) : (
                <>
                  <div style={{ marginRight: 4, marginTop: 8 }}>
                    {tier1ExecutorLabel}
                  </div>
                  <Tooltip title="Nhấn enter để xác nhận">
                    <Input
                      placeholder={`Nhập chuyên viên thực hiện`}
                      value={riskUnitUserStore.customRiskExecutorFullname.tier1}
                      onChange={updateCustomRiskExecutorFullname}
                      onPressEnter={onUpdateTier1RiskExecutor}
                      width={'calc(50% - 32px)'}
                      style={{ width: '80%', marginTop: 8 }}
                    />
                  </Tooltip>
                </>
              )}
            </Col>
            <Col span={12}>
              {type === 'CQVP' ? (
                <SelectUserItem
                  title="Lãnh đạo đơn vị: "
                  handleSelectUser={() =>
                    handleSelectUser(ASSIGNEE_TYPE.UNIT_LEADER)
                  }
                  userList={selectUserList.unitLeader}
                  originDepartmentWithUserList={originDepartmentWithUserList}
                />
              ) : type === 'NDDPV' ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 4 }}>
                      Người đại diện phần vốn phụ trách chung:
                    </div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('Tier1NDDPVgeneral')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[0].approvals?.[1]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[0].approvals?.[1]?.peoples}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'Tier1NDDPVgeneral' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 4 }}>Lãnh đạo đơn vị:</div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('Tier1UnitLeader')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[0].approvals?.[1]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[0].approvals?.[1]?.peoples}
                      riskUnitsV2={riskUnitsV2}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'Tier1UnitLeader' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </>
              )}
            </Col>
          </Row>
          <RiskActionDetails
            dataRiskUnitsByType={dataRiskUnitsByType}
            riskUnitsV2={riskUnitsV2}
            updateTierApproval={updateTierApproval}
            data={dataActions?.tier1}
            tier={'1'}
            openEditPopUp={handleVisibleCreateRiskId}
            type={type}
          />
        </>
      ) : current === 1 ? (
        <>
          {' '}
          <CreateSubTitle>Tuyến phòng vệ thứ hai</CreateSubTitle>
          {type === 'NDDPV' ? (
            <>
              <Row type={'flex'} gutter={15}>
                <Col span={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 4 }}>
                      Người đại diện phần vốn thực hiện xử lý rủi ro:
                    </div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('Tier2RiskExecutor')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[1].approvals?.[0]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[1].approvals?.[0]?.peoples}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'Tier2RiskExecutor' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </Col>
                <Col span={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 4 }}>
                      Người đại diện phần vốn phụ trách chung:
                    </div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('NDDPVgeneral')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[1].approvals?.[1]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[1].approvals?.[1]?.peoples}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'NDDPVgeneral' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              {type === 'DVPT' ? (
                <>
                  <Row type={'flex'} gutter={15}>
                    <Col span={8}>
                      <div style={{ marginTop: 8, marginBottom: 8 }}>
                        Đơn vị thực hiện xử lý rủi ro:
                      </div>
                      {uniqueNames?.map(el => (
                        <div
                          key={el?.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}>
                          <DepartmentTag>{el}</DepartmentTag>
                        </div>
                      ))}
                    </Col>
                    <Col span={16}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 4 }}>
                          Lãnh đạo đơn vị thực hiện xử lý rủi ro:
                        </div>
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() => {
                              showModal()
                              setConfim('Tier2DVPTprofessional')
                            }}
                          />
                        </Tooltip>
                      </div>

                      {riskDetail?.tiers?.[1].approvals?.[1]?.peoples?.length >
                      0 ? (
                        <SelectUserRiskItem
                          userList={
                            riskDetail?.tiers?.[1].approvals?.[1]?.peoples
                          }
                          riskUnitsV2={riskUnitsV2}
                        />
                      ) : (
                        selectedListAndType?.map(item =>
                          item?.type === 'Tier2DVPTprofessional' ? (
                            <SelectUserRiskItem userList={item.userList} />
                          ) : null
                        )
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row type={'flex'} gutter={15}>
                    <Col span={8}>
                      <div style={{ marginBottom: 8 }}>
                        Đơn vị thực hiện xử lý rủi ro:
                      </div>
                      {listDepartmentTuyen2CQVP.length === 0
                        ? listDepartmentTuyen2CQVPCreate?.map(el => (
                            <div
                              key={el?.id}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <UserTag>{el?.name}</UserTag>
                            </div>
                          ))
                        : listDepartmentTuyen2CQVP?.map(el => (
                            <div
                              key={el?.id}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <UserTag>{el?.name}</UserTag>
                            </div>
                          ))}
                    </Col>
                    <Col span={8}>
                      <SelectUserItem
                        title="Lãnh đạo đơn vị thực hiện xử lý rủi ro:"
                        handleSelectUser={() =>
                          handleSelectUser(ASSIGNEE_TYPE.LEADER)
                        }
                        userList={selectUserList.VIMCLeader}
                        originDepartmentWithUserList={
                          originDepartmentWithUserList
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          <RiskActionDetails
            riskUnitsV2={riskUnitsV2}
            updateTierApproval={updateTierApproval}
            data={dataActions?.tier2}
            tier={'2'}
            openEditPopUp={handleVisibleCreateRiskAssessment}
            type={type}
          />
        </>
      ) : current === 2 ? (
        <>
          <CreateSubTitle>Tuyến phòng vệ thứ ba</CreateSubTitle>
          <Row type={'flex'} gutter={15}>
            <Col span={8}>
              {type === 'CQVP' ? (
                <SelectUserItem
                  title="Đơn vị thực hiện xử lý rủi ro:"
                  handleSelectUser={() =>
                    handleSelectUser(
                      ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3
                    )
                  }
                  departmentList={departmentList}
                  userList={selectUserList.don_vi_thuc_hien_xu_ly_rui_ro_3}
                  departmentCode={
                    riskDetail?.tiers[2]?.approvals[0]?.peoples[0]
                      ?.department_code
                  }
                  originDepartmentWithUserList={originDepartmentWithUserList}
                />
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '-8px',
                    }}>
                    <div style={{ marginRight: 4 }}>
                      {type === 'DVPT' ? '' : null}{' '}
                      {type === 'NDDPV'
                        ? 'Người đại diện phần vốn thực hiện xử lý rủi ro:'
                        : 'Đơn vị thực hiện xử lý rủi ro:'}
                    </div>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          showModal()
                          setConfim('Tier3RiskExecutor')
                        }}
                      />
                    </Tooltip>
                  </div>

                  {riskDetail?.tiers?.[2].approvals?.[0]?.peoples?.length >
                  0 ? (
                    <SelectUserRiskItem
                      userList={riskDetail?.tiers?.[2].approvals?.[0]?.peoples}
                    />
                  ) : (
                    selectedListAndType?.map(item =>
                      item?.type === 'Tier3RiskExecutor' ? (
                        <SelectUserRiskItem userList={item.userList} />
                      ) : null
                    )
                  )}
                </>
              )}
            </Col>

            <Col span={8}>
              <SelectUserItem
                title={`BAN KTNB ${systemName}:`}
                handleSelectUser={() => handleSelectUser(ASSIGNEE_TYPE.KT_KTNB)}
                userList={selectUserList.KT_KTNB}
                originDepartmentWithUserList={originDepartmentWithUserList}
              />
            </Col>
            <Col span={8}>
              <SelectUserItem
                title={`HĐQT ${systemName}:`}
                handleSelectUser={() => handleSelectUser(ASSIGNEE_TYPE.HDQT)}
                userList={selectUserList.HDQT}
                originDepartmentWithUserList={originDepartmentWithUserList}
              />
            </Col>
          </Row>
          <RiskActionDetails
            riskUnitsV2={riskUnitsV2}
            updateTierApproval={updateTierApproval}
            data={dataActions?.tier3}
            tier={'3'}
            openEditPopUp={handleVisibleCreateRiskWork}
          />
        </>
      ) : (
        <>
          {' '}
          <CreateSubTitle>Kết quả</CreateSubTitle>
          <RiskActionDetails
            updateTierApproval={updateTierApproval}
            data={dataActions?.tier4}
            tier={'4'}
            openEditPopUp={handleVisibleRiskControlResult}
          />
        </>
      )}
      {updateTierApproval ? (
        <>
          {checkSelectOne ? (
            <SelectPeoplePopup
              setCheckSelectOne={setCheckSelectOne}
              checkSelectOne
              onlySelectOne
              disableSelectDepartment
              handleCancelSelectUser={handleCancelSelectUserDepartment}
              handleUpdateEmpty={() => updateTierApproval('empty')}
              handleSubmitSelectUser={() => {
                updateTierApproval()
                handleCancelSelectUserDepartment()
              }}
              isVisibleSelectPeoplePopup={
                isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
              }
              isUserDisableList={false}
            />
          ) : (
            <SelectPeoplePopup
              disableSelectDepartment
              handleCancelSelectUser={handleCancelSelectUserDepartment}
              handleUpdateEmpty={() => updateTierApproval('empty')}
              handleSubmitSelectUser={() => {
                updateTierApproval()
                handleCancelSelectUserDepartment()
              }}
              isVisibleSelectPeoplePopup={
                isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
              }
              isUserDisableList={false}
            />
          )}
        </>
      ) : (
        <>
          {checkSelectOne ? (
            <SelectPeoplePopup
              setCheckSelectOne={setCheckSelectOne}
              checkSelectOne
              onlySelectOne
              disableSelectDepartment
              handleCancelSelectUser={handleCancelSelectUserDepartment}
              handleSubmitSelectUser={handleCancelSelectUserDepartment}
              isVisibleSelectPeoplePopup={
                isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
              }
              isUserDisableList={false}
            />
          ) : (
            <SelectPeoplePopup
              disableSelectDepartment
              handleCancelSelectUser={handleCancelSelectUserDepartment}
              handleSubmitSelectUser={handleCancelSelectUserDepartment}
              isVisibleSelectPeoplePopup={
                isVisibleSelectUserDepartmentPopup && isVisibleSelectUserPopup
              }
              isUserDisableList={false}
            />
          )}
        </>
      )}
      {isVisibleCreateRiskId && (
        <RiskIdentificationCreatePopup
          dataRiskUnitsByType={dataRiskUnitsByType}
          departmentList={departmentList}
          dataRiskUnitsByTypeNDDPV={dataRiskUnitsByTypeNDDPV}
          riskUnitsV2={riskUnitsV2}
          handleCancel={handleCloseCreateRiskId}
          isVisible={isVisibleCreateRiskId}
          getRiskDetail={getRiskDetail}
          selectUserList={selectUserList}
          type={type}
          setType={setType}
          handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
        />
      )}
      <RiskAssessmentCreatePopup
        riskUnitsV2={riskUnitsV2}
        type={type}
        handleCancel={handleCloseCreateRiskAssessment}
        isVisible={isVisibleCreateRiskAssessment}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
      />
      <RiskWorkCreatePopup
        type={type}
        handleCancel={handleCloseCreateRiskWork}
        isVisible={isVisibleCreateRiskWork}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
        handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
      />
      <RiskControlResult
        type={type}
        handleCancel={handleCloseRiskControlResult}
        isVisible={isVisibleRiskControlResult}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
        handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
      />
      <SelectPeoplePopup
        disableSelectDepartment={true}
        isUserDisableList={false}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={handleCancelSelectUserDepartment}
        isVisibleSelectPeoplePopup={
          isVisibleSelectUserDepartmentPopup && isVisibleSelectOneUserPopup
        }
      />
      <SelectPeopleRiskPopup
        selectedListAndType={selectedListAndType}
        setSelectedListAndType={setSelectedListAndType}
        updateTierApproval={updateTierApproval}
        type={type}
        confirm={confirm}
        onConfirm={onConfirm}
        disableSelectDepartment={true}
        isUserDisableList={false}
        setIsVisibleSelectPeopleRiskPopup={setIsVisibleSelectPeopleRiskPopup}
        handleSubmitSelectUser={handleCancelSelectUserDepartment}
        isVisibleSelectPeopleRiskPopup={isVisibleSelectPeopleRiskPopup}
        setListDepartment={setListDepartment}
      />
    </>
  )
}

export default inject(
  'loadingAnimationStore',
  'selectPeopleStore',
  'departmentStore',
  'actionStore',
  'authenticationStore',
  'riskStore',
  'riskUnitUserStore'
)(observer(RiskSteps))
