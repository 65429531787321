import React, { useEffect, useState } from 'react'
import { Button, Row, Space, Col, Tooltip, message } from 'antd'
import { blue } from '../../../color'
import { UserOutlined } from '@ant-design/icons'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  WORK_STATUS,
} from '../../../constants'
import { inject, observer } from 'mobx-react'

import MainModal from '../../../components/Common/MainModal'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup'
import { SelectPeopleWrapper } from './PopupVoteStyled'
import SelectListComponent from '../../../components/Common/SelectListComponent'
import SelectUserButton from '../../../components/Common/SelectUserButton'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import useOpenItem from '../../../hooks/useOpenItem'

const PopupEditPersonVote = props => {
  const {
    isVisibleEditPersonVote,
    handleCloseEditPersonVote,
    selectPeopleStore,
    loadingAnimationStore,
    voteStore,
    workStore,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    assigneePopupType,
    setSelectUserData,
    clearSelectUserData,
  } = selectPeopleStore
  const { workDetail } = workStore
  const { assignees, title, code } = workDetail
  const { voteDetail } = voteStore
  const [voterList, setVoterList] = useState([])
  const {
    isOpen: isLoading,
    handleOpen: handleLoading,
    handleClose: handleCancelLoading,
  } = useOpenItem()

  useEffect(() => {
    if (!assigneePopupType) return
    setVoterList([
      ...selectUserData[assigneePopupType],
      ...selectDepartmentData[assigneePopupType],
    ])
  }, [selectUserData, selectDepartmentData, assigneePopupType])

  const handleSubmitSelectVoter = async () => {
    setIsVisibleSelectUserDepartmentPopup(false)
    const handlerList = assignees.filter(
      el =>
        el.permission === ASSIGNEE_TYPE.HANDLER &&
        el.assignee_code !== 'admin' &&
        el.assignee_type === 'USER'
    )
    const newVoterList = voterList.map(voter => ({ assignee_code: voter.id }))
    const newHandlerList = voterList
      .map(voter => ({ ...voter, assignee_code: voter.id }))
      .filter(
        voter =>
          !handlerList.some(
            handler => handler.assignee_code === voter.assignee_code
          )
      )
    const remove_items = voteDetail
      .map(voter => ({ assignee_code: voter.assignee_code }))
      .filter(
        prev =>
          !newVoterList.some(
            voter => voter.assignee_code === prev.assignee_code
          )
      )
    const new_items = newVoterList.filter(
      voter =>
        !voteDetail.some(prev => prev.assignee_code === voter.assignee_code)
    )

    const data = {
      document_code: code,
      document_title: title,
      type: 'WORK',
      vote_users: {
        remove_items: remove_items,
        new_items: new_items,
      },
      current_assignee: handlerList
        .concat(newHandlerList)
        .filter(
          handler =>
            !remove_items.some(
              item => item.assignee_code === handler.assignee_code
            )
        ),
    }
    console.log('handlerList', data)

    if (
      data.vote_users.new_items.length > 0 ||
      data.vote_users.remove_items.length > 0
    ) {
      handleLoading()
      try {
        await voteStore.updatePersonVote(code, data)
        await voteStore.getVoteDetail(code)
        await workStore.getWorkDetail(code)
        message.success('Bạn đã cập nhật lấy ý kiến!')
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra')
      } finally {
        handleCancelLoading()
      }
    } else message.error('Bạn chưa chọn người lấy ý kiến')
    handleCloseEditPersonVote()
  }

  return (
    <div style={{ position: 'relative' }}>
      <MainModal
        title="Chọn người lấy ý kiến"
        okText="Cập nhật"
        closeText="Đóng"
        handleCancel={() => {
          handleCloseEditPersonVote()
          clearSelectUserData()
        }}
        handleSubmit={handleSubmitSelectVoter}
        isVisible={isVisibleEditPersonVote}>
        <SelectPeopleWrapper>
          <SelectUserButton
            handleOnClick={() => {
              setIsVisibleSelectUserDepartmentPopup(true)
              handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.USER)
            }}
          />
          <SelectListComponent userList={voterList} />
        </SelectPeopleWrapper>

        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
        <SpinnerInlineComponent
          sizeSpin={'small'}
          tip="Đang tải dữ liệu..."
          isLoading={isLoading}
          backGround={'#ffffffc7'}
        />
      </MainModal>
    </div>
  )
}

PopupEditPersonVote.propTypes = {}

export default inject(
  'workStore',
  'voteStore',
  'selectPeopleStore',
  'loadingAnimationStore'
)(observer(PopupEditPersonVote))
