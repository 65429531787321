import React from 'react'
import { RiskHorizontalDisplay } from '../../components/Common'
const ReportPdf = props => {
  const { dataActions, children,unit } = props

  return (
    <div id={'reportDivToPrint'} style={{ fontSize: '10px !important ' }}>
    <RiskHorizontalDisplay
        labels={[
          {
            value: (
              <>
                <div style={{ textAlign: 'center' }}>TỔNG CÔNG TY HÀNG HẢI VIỆT NAM – CTCP</div>
                <div style={{ textAlign: 'center' }}>{unit}</div>
              </>
            ),
            style:{ margin: '0 ', width: '100%' }
          },
          {
            value: (
              <div style={{ textAlign: 'center' }}>
                <i>Hà Nội, ngày.........tháng........năm 2023  </i>
              </div>
            ),style:{ margin: '0 0 0 20px', width: '80%' }
          },
        ]}
        
      />
    <h4 style={{textAlign :'center'}}>KẾT QUẢ CÔNG TÁC QUẢN TRỊ RỦI RO</h4>
    <p style={{textAlign :'center'}}><i>Từ ngày ..................... đến ngày .....................</i></p>
     <p style={{paddingTop:30}}>
        {children}
       
     </p>
    </div>
  )
}
ReportPdf.propTypes = {}

export default ReportPdf
