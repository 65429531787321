import { TASK_TYPE_fromKey } from '../../../constants'
import date_format from '../../../date_format'
import {
  action_title,
  assign_type,
  field_history_log,
  work_important,
  work_status,
  work_type,
} from '../../../enum_utils'
import utils from '../../../utils'

const {
  default: _,
  isEqual,
  isEmpty,
  difference,
  isUndefined,
  isNil,
} = require('lodash')

const compareObjects = (oldObject, newObject) => {
  if (isEmpty(oldObject)) {
    return {
      oldValues: {},
      newValues: filterNonEmptyValue(newObject),
    }
  }
  if (isEmpty(newObject)) {
    return {
      oldValues: filterNonEmptyValue(oldObject),
      newValues: {},
    }
  }

  const obj1Copy = { ...oldObject }
  const obj2Copy = { ...newObject }
  let oldValues = {}
  let newValues = {}

  const diffKeys = difference(Object.keys(obj2Copy), Object.keys(obj1Copy))
  for (const key of diffKeys) {
    obj1Copy[key] = null
  }

  for (const [key, value] of Object.entries(obj1Copy)) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const {
        oldValues: oldValuesNested,
        newValues: newValuesNested,
      } = compareObjects(value, obj2Copy[key])
      if (isEmpty(oldValuesNested) && isEmpty(newValuesNested)) {
        continue
      }

      oldValues = { ...oldValues, [key]: oldValuesNested }
      newValues = { ...newValues, [key]: newValuesNested }
    } else {
      if (!isEqual(value, obj2Copy[key])) {
        oldValues[key] = value
        newValues[key] = obj2Copy[key]
      }
    }
  }

  return { oldValues, newValues }
}

const filterNonEmptyValue = obj => {
  const result = {}
  for (const [key, value] of Object.entries(obj)) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const filteredValue = filterNonEmptyValue(value)
      if (!isEmpty(filteredValue)) {
        result[key] = filteredValue
      }
    } else {
      if (!isEmpty(value)) {
        result[key] = value
      }
    }
  }
  return result
}

export const compare = (type, dataBefore, dataAfter, riskDetail = {}) => {
  if (!dataAfter) {
    return []
  }

  //find & match keys between before & after
  const keyBefore = Object.keys(dataBefore)
  const keyAfter = Object.keys(dataAfter)
  const diffKeys = difference(keyAfter, keyBefore)
  diffKeys.forEach(key => {
    dataBefore[key] = null
  })

  switch (type) {
    case 'TASK':
      return compareTaskBeforeAndAfter(dataBefore, dataAfter)
    case 'WORK':
      return compareWorkBeforeAndAfter(dataBefore, dataAfter)
    case 'RISK':
      return compareRiskBeforeAndAfter(dataBefore, dataAfter, riskDetail)
    default:
      return
  }
}

const compareTaskBeforeAndAfter = (dataBefore, dataAfter) => {
  const diff = []
  for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
    const valueAfter = dataAfter[keyBefore]

    const pushDiff = (before, after) => {
      diff.push({
        field: field_history_log.toText(keyBefore, 'TASK'),
        fieldValueBefore: before ?? '',
        fieldValueAfter: after ?? '',
      })
    }

    if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
      switch (keyBefore) {
        case 'data':
        case 'noiDungKetQua':
        case 'kienNghi':
        case 'phanTramHoanThanh':
        case 'ghiChu':
        case 'idTaskLevel':
          if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
          break
        case 'ngayBatDau':
        case 'ngayKetThuc':
        case 'createdAt':
        case 'updatedAt':
          const strBefore =
            (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ?? 'Không có'
          const strAfter =
            (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'

          if (!isEqual(strBefore, strAfter)) {
            pushDiff(strBefore, strAfter)
          }
          break
        case 'loaiNhiemVu':
          if (valueBefore !== valueAfter) {
            pushDiff(
              TASK_TYPE_fromKey(valueBefore)?.value ?? 'Không có',
              TASK_TYPE_fromKey(valueAfter)?.value ?? 'Không có'
            )
          }
          break
        case 'displayDate':
          const monthBefore = date_format.MMMM_YYYY(valueBefore)
          const monthAfter = date_format.MMMM_YYYY(valueAfter)
          if (!isEqual(monthBefore, monthAfter))
            pushDiff(monthBefore, monthAfter)
          break
      }
    } else {
      switch (keyBefore) {
        case 'ngayKetThuc':
          const strBefore =
            (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ?? 'Không có'
          const strAfter =
            (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'
          pushDiff(strBefore, strAfter)
          break
      }
    }
  }

  //assignees
  const removedPeople = (dataAfter?.remove_persons ?? [])
    .map(
      item =>
        `<p><s style='color:red'><b>- ${
          item.nameUppercase
        }</b>: ${assign_type.enumToText(item.permission)}</s></p>`
    )
    .join('')

  const addedPeople = (dataAfter?.add_persons ?? [])
    .map(
      item =>
        `<p style='color:blue'><b>+ ${
          item.nameUppercase
        }</b>: ${assign_type.enumToText(item.permission)}</p>`
    )
    .join('')

  if (!isEmpty(removedPeople) || !isEmpty(addedPeople)) {
    diff.push({
      field: field_history_log.toText('assignees', 'TASK'),
      fieldValueBefore: (dataBefore?.old_persons ?? [])
        .map(
          item =>
            `<p><b>• ${item.nameUppercase}</b>: ${assign_type.enumToText(
              item.permission
            )}</p>`
        )
        .join(''),
      fieldValueAfter: addedPeople.concat(removedPeople),
    })
  }

  //documents
  const removedDocuments = (dataAfter?.remove_van_ban_lq ?? [])
    .map(
      item =>
        `<p><s style='color:red'><b>-</b> ${item.documentNumber} - ${item.title}</s></p>`
    )
    .join('')
  const addedDocuments = (dataAfter?.add_van_ban_lq ?? [])
    .map(
      item =>
        `<p style='color:blue'><b>+</b> ${item.documentNumber} - ${item.title}</p>`
    )
    .join('')
  if (!isEmpty(removedDocuments) || !isEmpty(addedDocuments)) {
    diff.push({
      field: field_history_log.toText('documents', 'TASK'),
      fieldValueBefore: (dataBefore?.old_van_ban_lq ?? [])
        .map(item => `<p><b>•</b> ${item.documentNumber} - ${item.title}</p>`)
        .join(''),
      fieldValueAfter: addedDocuments.concat(removedDocuments),
    })
  }

  //files vb
  const removedDocumentFiles = (dataAfter?.remove_file_vb_dinh_kem ?? [])
    .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
    .join('')
  const addedDocumentFiles = (dataAfter?.add_file_vb_dinh_kem ?? [])
    .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
    .join('')
  if (!isEmpty(removedDocumentFiles) || !isEmpty(addedDocumentFiles)) {
    diff.push({
      field: field_history_log.toText('documentFiles', 'TASK'),
      fieldValueBefore: (dataBefore?.old_file_vb_dinh_kem ?? [])
        .map(item => `<p><b>•</b> ${item.fileName}</p>`)
        .join(''),
      fieldValueAfter: addedDocumentFiles.concat(removedDocumentFiles),
    })
  }
  //files
  const removeFiles = (dataAfter?.remove_files ?? [])
    .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
    .join('')
  const addFiles = (dataAfter?.add_files ?? [])
    .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
    .join('')

  if (!isEmpty(removeFiles) || !isEmpty(addFiles)) {
    diff.push({
      field: field_history_log.toText('files', 'TASK'),
      fieldValueBefore: (dataBefore?.old_files ?? [])
        .map(item => `<p><b>•</b> ${item.fileName}</p>`)
        .join(''),
      fieldValueAfter: addFiles.concat(removeFiles),
    })
  }

  //works
  if (dataAfter?.works) {
    diff.push({
      field: field_history_log.toText('works', 'TASK'),
      fieldValueBefore: (dataBefore?.works ?? [])
        .map(item => `<p><b>•</b> ${item.title}</p>`)
        .join(''),
      fieldValueAfter: (dataAfter?.works ?? [])
        .map(item => `<p style='color:blue'><b>+</b> ${item.title}</p>`)
        .join(''),
    })
  }

  return diff
}

const compareWorkBeforeAndAfter = (dataBefore, dataAfter) => {
  const diff = []

  for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
    let valueAfter = dataAfter[keyBefore]

    const pushDiff = (before, after) => {
      diff.push({
        field: field_history_log.toText(keyBefore, 'WORK'),
        fieldValueBefore: before ?? '',
        fieldValueAfter: after ?? '',
      })
    }

    if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
      switch (keyBefore) {
        case 'title':
        case 'content':
          if (valueBefore !== valueAfter) {
            pushDiff(valueBefore, valueAfter)
          }
          break
        case 'startDate':
        case 'dueDate':
        case 'createdAt':
          let strBefore =
            (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ?? 'Không có'
          let strAfter =
            (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'

          if (!isEqual(strBefore, strAfter)) pushDiff(strBefore, strAfter)

          break
        case 'status':
          if (valueBefore !== valueAfter) {
            pushDiff(
              work_status.toText(valueBefore),
              work_status.toText(valueAfter)
            )
          }
          break
        case 'important':
          if (valueBefore !== valueAfter)
            pushDiff(
              work_important.toText(valueBefore),
              work_important.toText(valueAfter)
            )
          break
        case 'workType':
          if (valueBefore !== valueAfter) {
            pushDiff(
              work_type.toText(valueBefore),
              work_type.toText(valueAfter)
            )
          }
          break
      }
    } else {
      switch (keyBefore) {
        case 'dueDate':
          let strBefore =
            (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ?? 'Không có'
          let strAfter =
            (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'
          pushDiff(strBefore, strAfter)
          break
      }
    }
  }

  //assignees
  let removePersons = (dataAfter?.remove_persons ?? [])
    .map(
      item =>
        `<p><s style='color:red'><b>- ${
          item.nameUppercase
        }</b>: ${assign_type.enumToText(item.permission)}</s></p>`
    )
    .join('')
  //
  let addPersons = (dataAfter?.add_persons ?? [])
    .map(
      item =>
        `<p style='color:blue'><b>+ ${
          item.nameUppercase
        }</b>: ${assign_type.enumToText(item.permission)}</p>`
    )
    .join('')
  //
  if (!isEmpty(removePersons) || !isEmpty(addPersons)) {
    diff.push({
      field: field_history_log.toText('assignees', 'WORK'),
      fieldValueBefore: (dataBefore?.old_persons ?? [])
        .map(
          item =>
            `<p><b>• ${item.nameUppercase}</b>: ${assign_type.enumToText(
              item.permission
            )}</p>`
        )
        .join(''),
      fieldValueAfter: addPersons.concat(removePersons),
    })
  }

  //files
  let removeFiles = (dataAfter?.remove_files ?? [])
    .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
    .join('')
  let addFiles = (dataAfter?.add_files ?? [])
    .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
    .join('')
  if (!isEmpty(removeFiles) || !isEmpty(addFiles)) {
    diff.push({
      field: field_history_log.toText('files', 'WORK'),
      fieldValueBefore: (dataBefore?.old_files ?? [])

        .map(item => `<p><b>•</b> ${item.fileName}</p>`)
        .join(''),
      fieldValueAfter: addFiles.concat(removeFiles),
    })
  }

  //files vb
  let remove_file_vb_dinh_kem = (dataAfter?.remove_file_vb_dinh_kem ?? [])
    .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
    .join('')
  let add_file_vb_dinh_kem = (dataAfter?.add_file_vb_dinh_kem ?? [])
    .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
    .join('')
  if (!isEmpty(remove_file_vb_dinh_kem) || !isEmpty(add_file_vb_dinh_kem)) {
    diff.push({
      field: field_history_log.toText('documentFiles', 'WORK'),
      fieldValueBefore: (dataBefore?.old_file_vb_dinh_kem ?? [])
        .map(item => `<p><b>•</b> ${item.fileName}</p>`)
        .join(''),
      fieldValueAfter: addFiles.concat(remove_file_vb_dinh_kem),
    })
  }

  return diff
}

const compareRiskBeforeAndAfter = (dataBefore, dataAfter, riskDetail) => {
  const diff = []
  for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
    let valueAfter = dataAfter[keyBefore]

    const pushDiff = (before, after) => {
      diff.push({
        field: field_history_log.toText(keyBefore, 'RISK'),
        fieldValueBefore: before ?? '',
        fieldValueAfter: after ?? '',
      })
    }

    if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
      switch (keyBefore) {
        case 'content':
          if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
          break
        case 'title':
          if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
          break
        case 'riskUnitCode':
          if (valueBefore !== valueAfter)
            pushDiff(
              dataBefore?.old_unit?.value?.name ?? '',
              dataAfter?.new_unit?.value?.name ?? ''
            )
          break
        case 'status':
          const level =
            riskDetail?.tiers?.find(tier =>
              tier?.approvals?.some(
                approval => approval.code === dataBefore.riskTierApprovalCode
              )
            )?.level ?? ''
          console.log('result', level)
          if (valueBefore !== valueAfter)
            pushDiff(
              `<div>Tuyến phòng vệ thứ ${level}</div>${
                dataBefore?.status === 'APPROVED'
                  ? "<div style='color:green'><b>Đã duyệt</b></div>"
                  : dataBefore?.status === 'PENDING'
                  ? "<div style='color:orange'><b>Chờ duyệt</b></div>"
                  : "<div style='color:red'><b>Từ chối</b></div>"
              }`,
              dataAfter?.status === 'APPROVED'
                ? "<div style='color:green'><b>Đã duyệt</b></div>"
                : dataBefore?.status === 'PENDING'
                ? "<div style='color:orange'><b>Chờ duyệt</b></div>"
                : "<div style='color:red'><b>Từ chối</b></div>"
            )
          break
        case 'level':
          pushDiff(
            `<div style='margin-bottom:10px'>${
              dataAfter.name
            }</div>${dataBefore.old_persons
              .map(
                item =>
                  `<p><b>•&nbsp;${utils.getNameInCapitalize(
                    item.nameUppercase
                  )}</b></p>`
              )
              .join('')}`,
            `${
              dataAfter?.add_persons?.length > 0
                ? (dataAfter?.add_persons ?? [])
                    .map(
                      item =>
                        `<p style='color:blue'><b>+ ${utils.getNameInCapitalize(
                          item.nameUppercase
                        )}</b></p>`
                    )
                    .join('')
                : ''
            }${
              dataAfter?.remove_persons?.length > 0
                ? (dataAfter?.remove_persons ?? [])
                    .map(
                      item =>
                        `<p><s style='color:red'><b>- ${utils.getNameInCapitalize(
                          item.nameUppercase
                        )}</b></s></p>`
                    )
                    .join('')
                : ''
            }`
          )
          break
        case 'data':
          const formattedDataAfter = JSON.parse(dataAfter?.data)
          const formattedDataBefore = JSON.parse(dataBefore?.data)

          if (formattedDataBefore?.name) {
            delete formattedDataBefore.name
          }
          if (formattedDataAfter?.name) {
            delete formattedDataAfter.name
          }

          if (!isEqual(formattedDataBefore, formattedDataAfter)) {
            const dataAfterName = dataAfter?.name ?? ''
            pushDiff(
              dataAfter.isDeleted
                ? convertObjToHTML(formattedDataBefore)
                : !formattedDataBefore
                ? ''
                : `<p style='margin-bottom:10px; color:blue'><b>${dataAfterName}</b></p>${convertObjToHTML(
                    compareObjects(formattedDataBefore, formattedDataAfter)
                      .oldValues
                  )}`,
              dataAfter.isDeleted
                ? `<p style='margin-bottom:10px; color:red'><b>- ${dataAfterName}</b></p>`
                : `<p style='margin-bottom:10px; color:blue'><b>${dataAfterName}</b></p>${convertObjToHTML(
                    compareObjects(formattedDataBefore, formattedDataAfter)
                      .newValues
                  )}`
            )
          }
          break
      }
    }
  }

  return diff
}

const convertObjToHTML = (obj, options) => {
  if (isEmpty(obj)) {
    return ''
  }

  const depth = options?.depth ?? 0
  const indents = Array(depth * 4)
    .fill('&nbsp;')
    .join('')
  const result = []
  const emptyText = '&nbsp;&nbsp;<span style="color: #ccc">Trống</span>'
  for (const [key, value] of Object.entries(obj)) {
    const actionName = action_title.toText(key)

    if (Array.isArray(value)) {
      result.push(
        `${indents}<b>${actionName}</b>: ${
          value.length > 0
            ? value
                .map(item => {
                  const isUser = !isNil(item?.full_name)
                  if (isUser) {
                    return item.full_name
                  }
                  const actionValue = action_title.toText(item)
                  return actionValue ? actionValue : item
                })
                .filter(item => typeof item !== 'object')
                .join(', ')
            : emptyText
        }`
      )
    } else if (typeof value === 'object') {
      if (isEmpty(value)) {
        result.push(`${indents}<b>${actionName}</b>:${emptyText}`)
      } else {
        result.push(
          `${indents}<b>${actionName}</b>:<br>${convertObjToHTML(value, {
            depth: depth + 1,
          })}`
        )
      }
    } else {
      result.push(
        `${indents}<b>${actionName}</b>: ${
          isEmpty(value) ? emptyText : action_title.toText(value)
        }`
      )
    }
  }

  return result.join('<br>')
}
