import {
  Button,
  Divider,
  Empty,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SearchBar } from '../../UserAccountManagementPage/UserAcountManagementPageStyled'
import RiskAriseUpsertModal from './RiskAriseUpsertModal'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { dowloaderUtils } from '../../../dowloaderUtils'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import { useFilters } from '../../../hooks/useFilters'

const { Search } = Input
const { Option } = Select

const RiskAriseList = props => {
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const history = useHistory()
  const { riskAriseStore } = props
  const [riskAriseList, setRiskAriseList] = useState([])
  const [riskAriseTypes, setRiskAriseTypes] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  const loadList = async filters => {
    try {
      await setCheckPagination(false)
      const { data } = await riskAriseStore.getRiskAriseList(
        filters || currentFilters
      )
      setRiskAriseList(data.data)
      setTotalPage(data.total)
      setCheckPagination(true)
    } catch (error) {
      console.log('🚀 error:', error)
      message.error('Đã có lỗi xảy ra')
    }
  }

  const loadTypes = async () => {
    try {
      const { data } = await riskAriseStore.getRiskAriseTypes()
      setRiskAriseTypes(data)
    } catch (error) {
      console.log('🚀 error:', error)
      message.error('Đã có lỗi xảy ra')
    }
  }

  const [editRisk, setEditRisk] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isExportingDocx, setIsLoadingDocx] = useState(false)
  const [currentFilters, setCurrentFilters] = useFilters({
    type: null,
    keyword: null,
    page: 0,
    pageSize: 10,
  })
  const [showCreateModal, setShowCreateModal] = useState(false)

  useEffect(() => {
    loadTypes()
  }, [])

  useEffect(() => {
    setCurrentFilters({ ...currentFilters, page: 0, pageSize: 10 })
  }, [currentFilters.keyword, currentFilters.type])

  useEffect(() => {
    ;(async () => {
      if (riskAriseTypes) {
        setCurrentFilters({
          keyword: queryStringParse.keyword || null,
          type: queryStringParse.type || null,
          page: 0,
          pageSize: 10,
        })
      }
    })()
  }, [JSON.stringify(riskAriseTypes)])

  useEffect(() => {
    const queryStringStringify = queryString.stringify(currentFilters, {
      skipNull: true,
    })
    history.replace(`/risk-template?${queryStringStringify}`)
    loadRiskAriseList()
  }, [currentFilters])

  const loadRiskAriseList = async params => {
    try {
      setIsLoading(true)
      await loadList(params || currentFilters)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeType = type => {
    setCurrentFilters({ ...currentFilters, type: type })
    loadList(currentFilters)
  }

  const handleCancelCreateModal = () => {
    setShowCreateModal(false)
  }
  const handleCancelEditModal = () => {
    setEditRisk(null)
    setShowCreateModal(false)
  }
  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }

  const handleDeleteRisk = async code => {
    try {
      setIsLoading(true)
      await riskAriseStore.delete(code)
      await loadRiskAriseList(currentFilters)
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickEditRisk = record => {
    setEditRisk(record)
    setShowCreateModal(true)
  }

  const handleSearch = value => {
    setCurrentFilters({ ...currentFilters, keyword: value })
  }

  const exportDocx = async () => {
    setIsLoadingDocx(true)
    const { data } = await riskAriseStore.export(currentFilters)

    let fileNamePostfix = ''
    if (currentFilters.keyword) {
      fileNamePostfix += ` ${currentFilters.keyword}`
    }
    if (currentFilters.type) {
      fileNamePostfix += ` - ${
        riskAriseTypes.find(type => type.code === currentFilters.type).name
      }`
    }

    dowloaderUtils.downloadDocxFromResponse(data, {
      filename: `RRNDPS${fileNamePostfix}.docx`,
    })
    setIsLoadingDocx(false)
  }

  const tableColumns = [
    {
      title: 'Công việc được giao',
      render: record => <span>{record.assigned_work}</span>,
      width: '25%',
    },
    {
      title: 'Rủi ro nhận diện',
      render: record => <span>{record.identification_risk}</span>,
      width: '30%',
    },
    {
      title: 'Thời gian phát sinh',
      render: record => <span>{record.time_arises}</span>,
      width: '15%',
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: '15%',
      render: record => (
        <>
          <Tooltip title={'Sửa thông tin'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditRisk(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteRisk(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá rủi ro nhận diện này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ]

  const handleChangePagination = value => {
    setCurrentFilters({ ...currentFilters, page: value - 1 })
  }

  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 14,
          }}>
          <SearchBar>
            <Search
              allowClear
              onSearch={handleSearch}
              placeholder={'Tìm kiếm rủi ro nhận diện...'}
              defaultValue={queryStringParse?.keyword}
            />
          </SearchBar>
          <Select
            style={{ minWidth: '250px' }}
            name={'proposal_status'}
            placeholder={'-- Lọc theo loại --'}
            allowClear
            onChange={value => handleChangeType(value)}>
            {riskAriseTypes?.length > 0 &&
              riskAriseTypes.map(type => (
                <Option key={type.code} value={type.code}>
                  {type.name}
                </Option>
              ))}
          </Select>
        </div>
        <Row style={{ justifyContent: 'space-evenly' }}>
          <Button type={'primary'} onClick={handleShowCreateModal}>
            <PlusCircleOutlined />
            Tạo mới
          </Button>
          <Button
            loading={isExportingDocx}
            onClick={exportDocx}
            style={{ marginLeft: '10px' }}>
            <PrinterOutlined />
            In
          </Button>
        </Row>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={riskAriseList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText:
            checkPagination === true ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ) : (
              <div style={{ opacity: 0.1, height: '150px' }} />
            ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        {checkPagination === true ? (
          <Pagination
            current={currentFilters.page + 1}
            pageSize={10}
            total={totalPage}
            onChange={e => handleChangePagination(e)}
            showSizeChanger={false}
            showLessItems
          />
        ) : (
          ''
        )}
      </TableBottomPaginationBlock>
      {showCreateModal ? (
        <RiskAriseUpsertModal
          isVisible={showCreateModal}
          handleCancelCreateModal={handleCancelCreateModal}
          handleCancelEditModal={handleCancelEditModal}
          type={currentFilters?.type}
          riskAriseStore={riskAriseStore}
          risk={editRisk}
          setEditRisk={setEditRisk}
          onFinish={loadList}
        />
      ) : null}
    </>
  )
}

export default inject('riskAriseStore')(observer(RiskAriseList))
