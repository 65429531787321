import {
  Button,
  Divider,
  Empty,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SearchBar } from '../../UserAccountManagementPage/UserAcountManagementPageStyled'
import RiskTemplateCreateModal from './RiskTemplateCreateModal'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { dowloaderUtils } from '../../../dowloaderUtils'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'

const { Search } = Input
const { Option } = Select

const ApprovalTemplateList = props => {
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const history = useHistory()
  const params = useParams()
  const { authenticationStore, riskTemplateStore } = props
  const { riskTemplateTypes, riskTemplateList } = riskTemplateStore

  const [editRisk, setEditRisk] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isExportingDocx, setIsExportingDocx] = useState(false)
  const [filter, setFilter] = useState({
    type: null,
    keyword: null,
    page: 0,
    pageSize: 10,
  })
  const [isRefresh, setIsRefresh] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  useEffect(() => {
    ;(async () => {
      await riskTemplateStore.getRiskTemplateTypes()
    })()
  }, [])
  useEffect(() => {
    setFilter({ ...filter, page: 0, pageSize: 10 })
  }, [filter.keyword, filter.type])
  useEffect(() => {
    ;(async () => {
      if (riskTemplateTypes) {
        await setCheckPagination(false)
        setFilter({
          keyword: queryStringParse.keyword || undefined,
          type: queryStringParse.type || null,
          page: 0,
          pageSize: 10,
        })
        await loadRiskTemplateList({
          keyword: queryStringParse.keyword || '',
          type: queryStringParse.type || null,
          page: 0,
          pageSize: 10,
        })
        setIsRefresh(false)
        setCheckPagination(true)
      }
    })()
  }, [JSON.stringify(riskTemplateTypes)])
  useEffect(
    filter => {
      if (isRefresh) return
      const queryStringStringify = queryString.stringify(filter, {
        skipNull: true,
      })
      history.replace(`/risk-template?${queryStringStringify}`)
      ;(async () => {
        await loadRiskTemplateList(filter)
      })()
    },
    [filter]
  )

  const loadRiskTemplateList = async params => {
    try {
      setIsLoading(true)
      await riskTemplateStore.getRiskTemplateList(params || filter)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeType = type => {
    setFilter({ ...filter, type: type })
  }

  const handleCancelCreateModal = () => {
    setShowCreateModal(false)
  }
  const handleCancelEditModal = () => {
    setEditRisk(null)
    setShowCreateModal(false)
  }
  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }
  const handleDeleteRisk = async code => {
    try {
      setIsLoading(true)
      await riskTemplateStore.delete(code)
      await loadRiskTemplateList(filter)
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }
  const handleClickEditRisk = record => {
    setEditRisk(record)
    setShowCreateModal(true)
  }

  const handleSearch = value => {
    setFilter({ ...filter, keyword: value })
  }

  const exportDocx = async () => {
    setIsExportingDocx(true)
    const { data } = await riskTemplateStore.export(filter)

    let fileNamePostfix = ''
    if (filter.keyword) {
      fileNamePostfix += ` ${filter.keyword}`
    }
    if (filter.type) {
      fileNamePostfix += ` - ${
        riskTemplateStore.riskTemplateTypes.find(
          type => type.code === filter.type
        ).name
      }`
    }

    dowloaderUtils.downloadDocxFromResponse(data, {
      filename: `RRND${fileNamePostfix}.docx`,
    })

    setIsExportingDocx(false)
  }

  const tableColumns = [
    {
      title: 'Lĩnh vực hoạt động',
      render: record => <span>{record.linh_vuc_hoat_dong}</span>,
      width: '30%',
    },
    {
      title: 'Tiêu đề',
      render: record => <span>{record.rui_ro_nhan_dien}</span>,
      width: '50%',
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: '20%',
      render: record => (
        <>
          <Tooltip title={'Sửa thông tin'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditRisk(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteRisk(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá rủi ro nhận diện này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ]

  const handleChangePagination = value => {
    setFilter({ ...filter, page: value - 1 })
  }

  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 14,
          }}>
          <SearchBar>
            <Search
              allowClear
              onSearch={handleSearch}
              placeholder={'Tìm kiếm rủi ro nhận diện...'}
              defaultValue={queryStringParse?.keyword}
            />
          </SearchBar>
          <Select
            style={{ minWidth: '250px' }}
            name={'proposal_status'}
            placeholder={'-- Lọc theo loại --'}
            allowClear
            onChange={value => handleChangeType(value)}>
            {riskTemplateTypes?.length > 0 &&
              riskTemplateTypes.map(type => (
                <Option key={type.code} value={type.code}>
                  {type.name}
                </Option>
              ))}
          </Select>
        </div>
        <Row style={{ justifyContent: 'space-evenly' }}>
          <Button type={'primary'} onClick={handleShowCreateModal}>
            <PlusCircleOutlined />
            Tạo mới
          </Button>
          <Button
            loading={isExportingDocx}
            onClick={exportDocx}
            style={{ marginLeft: '10px' }}>
            <PrinterOutlined />
            In
          </Button>
        </Row>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={riskTemplateList.data}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText:
            checkPagination === true ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ) : (
              <div style={{ opacity: 0.1, height: '150px' }} />
            ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        {checkPagination === true ? (
          <Pagination
            current={filter.page + 1}
            pageSize={10}
            total={riskTemplateList.total}
            onChange={e => handleChangePagination(e)}
            showSizeChanger={false}
            showLessItems
          />
        ) : (
          ''
        )}
      </TableBottomPaginationBlock>
      <RiskTemplateCreateModal
        showCreateModal={showCreateModal}
        handleCancelCreateModal={handleCancelCreateModal}
        handleCancelEditModal={handleCancelEditModal}
        type={filter?.type}
        riskTemplateStore={riskTemplateStore}
        loadRiskTemplateList={loadRiskTemplateList}
        risk={editRisk}
        setEditRisk={setEditRisk}
      />
    </>
  )
}

export default inject('riskTemplateStore')(observer(ApprovalTemplateList))
