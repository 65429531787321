import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Layouts
import DashboardLayout from '../../layouts/DashboardLayout'
import { Container } from '../../layouts/Container/Container'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
// ant
// Mobx
import { inject, observer } from 'mobx-react'
import MissionCreateForm from './MissionCreateForm'
import { systemName } from '../../config'

const MissionCreatePage = props => {
  const { history, selectPeopleStore } = props

  useEffect(() => {
    return () => {
      selectPeopleStore.clearSelected()
    }
  }, [])

  const handleAfterCreatedMission = () => {
    history.push('/mission')
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới nhiệm vụ | {systemName} tạo mới nhiệm vụ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm nhiệm vụ tuần'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <MissionCreateForm
            handleAfterCreatedMission={handleAfterCreatedMission}
          />
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject('selectPeopleStore')(observer(MissionCreatePage))
