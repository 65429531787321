import React, { useEffect, useState } from 'react'
import { ACL_ACTION_TYPE, STATUS_DEFAULT } from '../../../constants'
import { inject, observer } from 'mobx-react'
import { ListSubWork, SubWorkTitle } from './DrawerWorkStyled'
import { Tooltip } from 'antd'

import AccessControlAction from '../../../components/AccessControlAction'
import { PlusCircleOutlined } from '@ant-design/icons'
import { blue } from '../../../color'
import { EmptyText } from '../../../components/Common/CellText'
import queryString from 'query-string'
import SubWorkDetail from './SubWorkDetail'
import PopupWorkForm from '../PopupWorkForm'
import { useLocation } from 'react-router-dom'
import utils from '../../../utils'

const SubWorkList = props => {
  const { workStore, selectPeopleStore, childCode } = props

  const { workDetail } = workStore

  const { status, subwork } = workDetail

  const [
    currentViewingDetailWorkCodes,
    setCurrentViewingDetailWorkCodes,
  ] = useState([])

  useEffect(() => {
    let arr = []
    if (childCode) {
      arr.push(childCode)
    }

    setCurrentViewingDetailWorkCodes(arr)
  }, [childCode])

  useEffect(() => {
    const code = utils.getParameterByName('child_id')
    let list = []
    if (code) {
      list.push(code)
    }
    setCurrentViewingDetailWorkCodes(list)
  }, [])

  const [isVisiblePopupWorkForm, setIsVisiblePopupWorkForm] = useState(false)
  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  return (
    <>
      <SubWorkTitle>
        <span style={{ paddingTop: 20 }}>
          Thêm các bước công việc:
          {status !== STATUS_DEFAULT.COMPLETE && (
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.them_cong_viec_phu__WORK}>
              <Tooltip title="Thêm các bước công việc">
                <PlusCircleOutlined
                  style={{
                    cursor: 'pointer',
                    color: blue,
                    marginLeft: 6,
                  }}
                  onClick={() => {
                    selectPeopleStore.clearSelected()
                    setIsVisiblePopupWorkForm(true)
                  }}
                />
              </Tooltip>
            </AccessControlAction>
          )}
        </span>
      </SubWorkTitle>
      <ListSubWork
        size={'small'}
        locale={{
          emptyText: <EmptyText>Chưa có các bước công việc</EmptyText>,
        }}
        dataSource={subwork || []}
        renderItem={item => (
          <SubWorkDetail
            workDetail={item}
            currentViewingDetailWorkCodes={currentViewingDetailWorkCodes}
            setCurrentViewingDetailWorkCodes={setCurrentViewingDetailWorkCodes}
          />
        )}
      />
      <PopupWorkForm
        queryStringParsed={queryStringParsed}
        isVisiblePopupWorkForm={isVisiblePopupWorkForm}
        handleCloseWorkForm={() => setIsVisiblePopupWorkForm(false)}
      />
    </>
  )
}

SubWorkList.propTypes = {}

export default inject(
  'selectPeopleStore',
  'workStore',
  'notificationStore',
  'loadingAnimationStore',
  'aclStore'
)(observer(SubWorkList))
