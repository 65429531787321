import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  ISO_DATE_FORMAT,
  STATUS_DEFAULT,
  VIEWLOG_DATE,
  WORK_STATUS,
} from '../../../constants'
import { inject, observer } from 'mobx-react'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import utils from '../../../utils'
import {
  HeadingButtonWrapper,
  InfoDrawerRight,
  InfoDrawerRightWrapper,
} from './DrawerWorkStyled'
import { Button, Col, Dropdown, Menu, message, Modal, Row } from 'antd'
import {
  CheckOutlined,
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { blue } from '../../../color'
import selectPeopleStore from '../../../stores/selectPeopleStore'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import aclStore from '../../../stores/aclStore'
import queryString from 'query-string'
import voteStore from '../../../stores/voteStore'
import useOpenItem from '../../../hooks/useOpenItem'
import { PopupAddPersonVote, PopupEditPersonVote } from '../PopupVote'
import WorkAllCommentList from './WorkAllCommentList'
import { toJS } from 'mobx'
import ListHistoryLogModal from '../../ListHistoryLog/ListHistoryLogModal'
import PopupUpdateWork from '../PopupUpdateWork/PopupUpdateWork'
import PopupAddPersonWork from '../PopupAddPersonWork'
import moment from 'moment'
import PopupElectronicReleaseForm from '../PopupElectronicReleaseForm'

const breakpoint = 900

const { confirm } = Modal

const ContentDrawerRight = props => {
  const {
    onCloseDrawerWork,
    selectUserStore,
    authenticationStore,
    workStore,
    isChild,
    renderDate,
  } = props

  const history = useHistory()
  const { currentUser } = authenticationStore
  const { queryParams } = workStore
  const [workDetail, setWorkDetail] = useState(workStore.workDetail)
  const {
    work_type,
    attachments,
    outgoingDocument,
    parent_id,
    status,
    workList,
    code,
    assignees,
    title,
    created_at,
    start_date,
    due_date,
  } = workDetail

  const [isVisibleHistory, setIsVisibleHistory] = useState(false)
  const [documentHistory, setDocumentHistory] = useState({})
  const [screenWidth] = useState(window.innerWidth)
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [voteDetail, setVoteDetail] = useState(null)

  useEffect(() => {
    setWorkDetail(workStore.workDetail)
  }, [workStore.workDetail])

  useEffect(() => {
    if (code) {
      reloadVoteDetail()
    }
  }, [code])

  useEffect(() => {
    setHandlerList(
      assignees
        ? selectUserStore.convertListToDefault(
            toJS(assignees).filter(
              el =>
                el.permission === ASSIGNEE_TYPE.HANDLER &&
                el.assignee_code !== 'admin'
            )
          )
        : []
    )
    setFollowerList(
      assignees
        ? selectUserStore.convertListToDefault(
            toJS(assignees).filter(
              el =>
                el.permission === ASSIGNEE_TYPE.FOLLOWER &&
                el.assignee_code !== 'admin'
            )
          )
        : []
    )
  }, [assignees])

  const {
    isOpen: isVisibleAddPersonWork,
    handleOpen: handleOpenAddPersonWork,
    handleClose: handleClosePopupAddPersonWork,
  } = useOpenItem()

  const {
    isOpen: isVisiblePopupUpdateWork,
    handleOpen: handleOpenPopupUpdateWork,
    handleClose: handleClosePopupUpdateWork,
  } = useOpenItem()

  const {
    isOpen: isVisibleEditPersonVote,
    handleOpen: handleOpenEditPersonVote,
    handleClose: handleCloseEditPersonVote,
  } = useOpenItem()

  const {
    isOpen: isVisibleAddPersonVote,
    handleOpen: openPopupAddPersonVote,
    handleClose: handleCloseAddPersonVote,
  } = useOpenItem()

  const {
    isOpen: isVisiblePopupElectronicRelease,
    handleOpen: handleOpenPopupElectronicRelease,
    handleClose: handleClosePopupElectronicRelease,
  } = useOpenItem()

  const handleCloseUpdateWork = () => {
    handleClosePopupUpdateWork()
    reloadWorkDetail()
  }

  const handleCloseAddPersonWork = () => {
    handleClosePopupAddPersonWork()
    reloadWorkDetail()
  }

  const reloadWorkDetail = () => {
    workStore.getWorkDetailResponse(code).then(response => {
      let workDetailData = response.data
      if (response.data.code !== code && response.data.subwork.length > 0) {
        workDetailData = response.data.subwork[0]
      }
      const complete_date = moment(workDetailData.complete_date).format(
        ISO_DATE_FORMAT
      )
      const due_date = moment(workDetailData.due_date).format(ISO_DATE_FORMAT)
      if (
        workDetailData.due_date &&
        workDetailData.status === WORK_STATUS.COMPLETE &&
        moment(complete_date).isAfter(due_date)
      ) {
        workDetailData = {
          ...workDetailData,
          overdue: true,
        }
      }
      setWorkDetail(workDetailData)
    })
  }

  const reloadVoteDetail = () => {
    voteStore.getVoteDetailResponse(code).then(response => {
      let vd = response.data.users.map(user => ({
        ...user,
        permission: 'USER',
        assignee_type: 'USER',
      }))
      setVoteDetail(vd)
    })
  }

  const handleCloseHistory = async type => {
    setIsVisibleHistory(false)
  }

  useEffect(() => {
    ;(async () => {
      try {
        code && (await voteStore.getVoteDetail(code))
      } catch (error) {
        console.log(error)
        // message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
      }
    })()
  }, [code])
  useEffect(() => {
    return () => {
      voteStore.clearStore()
    }
  }, [])

  const handleUpdateStatusWork = async status => {
    loadingAnimationStore.setTableLoading(true)
    loadingAnimationStore.setShowSpinInline(true)
    try {
      await workStore.updateStatusWork(code, status)
      if (status === STATUS_DEFAULT.COMPLETE) {
        return message.success('Bạn đã đánh dấu công việc hoàn thành!')
      }
      message.success('Bạn đã đánh dấu công việc là đang thực hiện!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const showConfirmDeleteWork = () => {
    confirm({
      title: 'Bạn có muốn xóa công việc này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteWork()
      },
    })
  }

  const handleOpenPopupAddPersonVote = () => {
    openPopupAddPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj([])
    )
  }
  const handleOpenPopupeditPersonVote = () => {
    handleOpenEditPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj(voteDetail)
    )
  }

  const handleDeleteWork = async () => {
    const parentId = utils.getParameterByName('parent_id')
    loadingAnimationStore.showSpinner(true)
    try {
      await workStore.deleteWork(code)
      if (parentId) {
        await workStore.getListSubWork(parentId)
        await workStore.getWorkDetail(parentId, true)
        window.history.replaceState(
          null,
          null,
          `/works-procedure/view/${parentId}`
        )
      } else {
        const indexParentTarget = workList.findIndex(item => item.code === code)
        workList.splice(indexParentTarget, 1)
        onCloseDrawerWork()
        const queryStringStringify = queryString.stringify(
          {
            ...queryParams,
            parent_id: null,
          },
          {
            skipNull: true,
          }
        )
        window.history.replaceState(
          null,
          null,
          `/works-procedure?${queryStringStringify}`
        )
      }
      message.success('Xóa công việc thành công!')
    } catch (err) {
      console.log(err)
      if (err?.errorCode === 'WORK-106') {
        message.error('Công việc này đã được gán ở KPI nên không thể xóa')
      } else message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const exportWork = () => {
    utils.exportPDF('divToPrint', title)
  }

  const menuButton = (
    <Menu>
      <>
        {status !== STATUS_DEFAULT.COMPLETE &&
          currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              .assignee_code && (
            <Menu.Item
              key="edit"
              icon={<EditOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenPopupUpdateWork()
              }}
              // #1333 cho phép sửa khi đã có comment
              // disabled={disableChange}
            >
              <span style={{ color: blue }}>Sửa công việc</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          (currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              ?.assignee_code ||
            handlerList.some(el => currentUser?.username === el.id)) && (
            <Menu.Item
              key="add_assignee"
              icon={<PlusCircleOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenAddPersonWork()
              }}>
              <span style={{ color: blue }}>Thêm người</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          !parent_id &&
          aclStore.checkAccessControlAction(
            ACL_ACTION_TYPE.phat_hanh__WORK
          ) && (
            <Menu.Item
              key="publish_document"
              icon={<ContainerOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenPopupElectronicRelease()
              }}>
              <span style={{ color: blue }}>Phát hành điện tử</span>
            </Menu.Item>
          )}
        {status !== STATUS_DEFAULT.COMPLETE && (
          <Menu.Item
            key="create_vote"
            icon={<FormOutlined style={{ color: blue }} />}
            onClick={() => {
              voteStore?.voteDetail?.length > 0
                ? handleOpenPopupeditPersonVote()
                : handleOpenPopupAddPersonVote()
            }}>
            <span style={{ color: blue }}>Lấy ý kiến</span>
          </Menu.Item>
        )}
        <Menu.Item
          key="history"
          icon={<HistoryOutlined style={{ color: blue }} />}
          onClick={() => {
            setDocumentHistory({
              code: workDetail.code,
            })
            setIsVisibleHistory(true)
          }}>
          <span style={{ color: blue }}>Lịch sử</span>
        </Menu.Item>

        <Menu.Item
          key="print"
          icon={<PrinterOutlined style={{ color: blue }} />}
          onClick={() => {
            exportWork()
          }}>
          <span style={{ color: blue }}>In</span>
        </Menu.Item>

        {status !== STATUS_DEFAULT.COMPLETE &&
          currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              ?.assignee_code && (
            <Menu.Item
              key="delete"
              danger
              icon={<DeleteOutlined />}
              onClick={showConfirmDeleteWork}
              // disabled={disableChange}
            >
              <span>Xóa công việc</span>
            </Menu.Item>
          )}
      </>
    </Menu>
  )

  return (
    <InfoDrawerRightWrapper
      isMobile={screenWidth < breakpoint}
      style={{ overflow: isChild ? 'none' : 'auto' }}>
      <Row gutter={8}>
        <Col flex={'auto'}>
          {status !== STATUS_DEFAULT.COMPLETE ? (
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.COMPLETE)}
              style={{
                backgroundColor: '#3aaf50',
                borderColor: '#3aaf50',
                width: '100%',
              }}>
              Hoàn thành
            </Button>
          ) : (
            <Button
              icon={<RetweetOutlined />}
              style={{ width: '100%' }}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.PENDING)}>
              Chưa hoàn thành
            </Button>
          )}
        </Col>
        <Col>
          <HeadingButtonWrapper>
            <Dropdown overlay={menuButton} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </HeadingButtonWrapper>
        </Col>
      </Row>
      <InfoDrawerRight>
        <Row
          style={{
            margin: '14px 0',
          }}>
          <Col>
            <span>
              <b>Thời gian: </b>
              {renderDate(start_date)}
              <span>{` - `}</span>
              {renderDate(due_date)}
            </span>
          </Col>
        </Row>
        <dt>
          {work_type === WORK_STATUS.ELECTRONIC
            ? 'Lãnh đạo ký duyệt'
            : 'Người xử lý'}
        </dt>
        <dd>
          {handlerList.length > 0 ? (
            <PopoverWatchTime
              userList={handlerList}
              title={
                work_type === WORK_STATUS.ELECTRONIC
                  ? 'Lãnh đạo ký duyệt'
                  : 'Danh sách người xử lý'
              }
              isFullViewer={true}
              compared_date={VIEWLOG_DATE.work_read_until}
              typeId={workDetail.code}
              created_at={created_at}
            />
          ) : (
            <EmptyText>Không có.</EmptyText>
          )}
        </dd>
        <dt>Người theo dõi</dt>
        <dd>
          {followerList.length > 0 ? (
            <PopoverWatchTime
              userList={followerList}
              title={'Danh sách người theo dõi'}
              isFullViewer={true}
              compared_date={VIEWLOG_DATE.work_read_until}
              typeId={workDetail.code}
              created_at={created_at}
            />
          ) : (
            <EmptyText>Không có người theo dõi.</EmptyText>
          )}
        </dd>
        <dt>Tài liệu đính kèm</dt>
        <dd>
          {attachments && attachments.length > 0 ? (
            attachments.map(file => (
              <FileItem
                key={file.file_id}
                file_id={file.file_id}
                file_name={file.file_name}
                file_type={utils.getExtensionFile(file.file_name)}
              />
            ))
          ) : (
            <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          )}
        </dd>

        {outgoingDocument && (
          <>
            <dt>Văn bản liên kết</dt>
            <dd
              onClick={() =>
                history.push(
                  `/internal-document/outgoing-document/view/${outgoingDocument.document_code}`
                )
              }>
              <a>{outgoingDocument.document_name}</a>
            </dd>
          </>
        )}
      </InfoDrawerRight>
      <div
        style={{
          height: 0.5,
          backgroundColor: '#f0f0f0',
          marginBottom: 10,
        }}
      />

      <WorkAllCommentList code={code} />

      <PopupUpdateWork
        workDetail={workDetail}
        isVisiblePopupUpdateWork={isVisiblePopupUpdateWork}
        handleCloseUpdateWork={handleCloseUpdateWork}
      />

      <PopupAddPersonWork
        workDetail={workDetail}
        isVisibleAddPersonWork={isVisibleAddPersonWork}
        handleCloseAddPersonWork={handleCloseAddPersonWork}
      />
      <PopupEditPersonVote
        workDetail={workDetail}
        isVisibleEditPersonVote={isVisibleEditPersonVote}
        handleCloseEditPersonVote={() => {
          handleCloseEditPersonVote()
        }}
      />

      <PopupAddPersonVote
        workDetail={workDetail}
        isVisibleAddPersonVote={isVisibleAddPersonVote}
        handleCloseAddPersonVote={() => {
          handleCloseAddPersonVote()
        }}
      />
      <ListHistoryLogModal
        type={'WORK'}
        isVisible={isVisibleHistory}
        taskInfo={documentHistory}
        handleCloseHistory={handleCloseHistory}
      />
      <PopupElectronicReleaseForm
        isVisiblePopupElectronicRelease={isVisiblePopupElectronicRelease}
        handleCloseElectronicRelease={handleClosePopupElectronicRelease}
      />
    </InfoDrawerRightWrapper>
  )
}

ContentDrawerRight.propTypes = {}

export default inject(
  'voteStore',
  'workStore',
  'authenticationStore',
  'selectUserStore'
)(observer(ContentDrawerRight))
