import { useCallback } from 'react'

export const useCreatingUnitLeader = ({
  type,
  selectUserList,
  riskUnitUserStore = null,
}) => {
  const getTier1UnitLeader = useCallback(() => {
    if (type === 'DVPT') {
      return [
        ...riskUnitUserStore.selectedUnitLeaders.tier1.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
    }

    return selectUserList.unitLeader?.map(user => ({
      username: user.id,
      status: 'PENDING',
    }))
  }, [riskUnitUserStore.selectedUnitLeaders.tier1, type, selectUserList])

  return { getTier1UnitLeader }
}
