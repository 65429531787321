import { message, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, withRouter } from 'react-router-dom'
import { MODULE_CODE } from '../../../constants'
import InternalDocumentConfigPage from '../../Documents/InternalDocumentConfigPage'
import InternalDocumentLayout from './InternalDocumentLayout'
import { systemName } from '../../../config'

const DocumentBooksGroup = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.moduleStore.moduleList.length === 0) return

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.van_ban_noi_bo)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  return (
    <InternalDocumentLayout>
      <Helmet>
        <title>
          Nhóm sổ văn bản | Văn bản nội bộ | Quản trị | {systemName} Quản lý VB{' '}
          {systemName}
        </title>
      </Helmet>
      <InternalDocumentConfigPage
        isDocumentManagementTab
        historyPath="/document-management/internal-document"
      />
    </InternalDocumentLayout>
  )
}

export default memo(
  withRouter(
    inject(
      'loadingAnimationStore',
      'moduleStore',
      'bookStore',
      'commonStore'
    )(observer(DocumentBooksGroup))
  )
)
