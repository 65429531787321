import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Button, message, Modal, Space, Tag } from 'antd'
import { useHistory } from 'react-router-dom'
import TableComponent from '../../components/Common/TableComponent'
import { blue } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import { ACL_TYPE, ASSIGNEE_TYPE } from '../../constants'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

const ACLPage = props => {
  const { aclStore, selectPeopleStore } = props

  const { aclGroupList } = aclStore

  const [aclGroupListPO, setAclGroupListPO] = useState()

  useEffect(() => {
    setAclGroupListPO(
      aclGroupList.filter(item => (item.code.slice(0, 3) === 'HR_') === false)
    )
  }, [aclGroupList])

  const {
    selectUserData,
    selectDepartmentData,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const history = useHistory()

  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isVisibleSelectModal, setIsVisibleSelectModal] = useState(false)
  const [aclActive, setAclActive] = useState()
  const [isOpening, setIsOpening] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingTable(true)
        await selectPeopleStore.getDepartmentWithUsers()
        await aclStore.getACLGroupList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingTable(false)
      }
    })()
    return () => {
      aclStore.clearAclGroupList()
    }
  }, [])

  const handleDeleteACL = async code => {
    try {
      setIsLoadingTable(true)
      await aclStore.deleteACLGroup(code)
      await aclStore.getACLGroupList()
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingTable(false)
    }
  }

  const handleCloneACL = async code => {
    try {
      setIsLoadingTable(true)
      await aclStore.cloneACLGroup(code)
      await aclStore.getACLGroupList()
      message.success('Nhân bản thành công!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingTable(false)
    }
  }

  const handleSubmitSelectUser = async () => {
    const users = []
    selectUserData[ASSIGNEE_TYPE.HANDLER].forEach(item => {
      users.push(item.id)
    })
    selectDepartmentData[ASSIGNEE_TYPE.HANDLER].forEach(item => {
      const userList =
        originDepartmentWithUserList.find(el => el.code === item.id)?.users ||
        []
      userList.forEach(user => {
        users.push(user.user_name)
      })
    })
    const payload = {
      users: users,
      acl_group_code: aclActive,
    }
    setIsVisibleSelectModal(false)
    try {
      setIsOpening(true)
      await aclStore.assignACLGroup(payload)
      selectPeopleStore.clearSelected()
      setAclActive(null)
      message.success('Áp dụng thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
    }
  }

  const handleOpenSelectPeoplePopup = type => {
    selectPeopleStore.setAssigneePopupType(type)
    setIsVisibleSelectModal(true)
  }

  const renderTag = type => {
    if (type === ACL_TYPE.DEFAULT) {
      return (
        <Tag color={'blue'} style={{ borderRadius: 10 }}>
          Hệ thống
        </Tag>
      )
    }
    if (type === ACL_TYPE.CUSTOM) {
      return (
        <Tag color={'orange'} style={{ borderRadius: 10 }}>
          Tùy chỉnh
        </Tag>
      )
    }
    return (
      <Tag color={'purple'} style={{ borderRadius: 10 }}>
        {type}
      </Tag>
    )
  }

  const handleAOpenApDung = async record => {
    setAclActive(record.code)
    try {
      setIsOpening(true)
      const res = await aclStore.getACLDetail(record.code)
      console.log(res.data.assigned_users)
      const assignedUsers = res.data.assigned_users
      const assignedUsersData = []
      assignedUsers.forEach(item => {
        originDepartmentWithUserList.forEach(el => {
          el.users.forEach(user => {
            if (user.user_name === item) {
              assignedUsersData.push({
                id: user.user_name,
                assignee_type: ASSIGNEE_TYPE.USER,
                full_name: user.name_uppercase,
                permission: ASSIGNEE_TYPE.HANDLER,
                department_code: el.code,
                department_name: el.name,
              })
            }
          })
        })
      })
      selectPeopleStore.setSelectUserData({
        [ASSIGNEE_TYPE.HANDLER]: assignedUsersData,
        [ASSIGNEE_TYPE.COMBINER]: [],
        [ASSIGNEE_TYPE.FOLLOWER]: [],
        [ASSIGNEE_TYPE.LEADER]: [],
      })
      handleOpenSelectPeoplePopup(ASSIGNEE_TYPE.HANDLER)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsOpening(false)
    }
  }

  const showConfirm = code => {
    confirm({
      title:
        'Bạn có chắc chắn muốn xóa nhóm phân quyền này. Những người trong nhóm này sẽ tự động được chuyển sang nhóm mặc định Người dùng thường ?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDeleteACL(code)
      },
    })
  }

  const columns = [
    {
      title: 'Quyền người dùng',
      render: record => <span>{record.name}</span>,
    },
    {
      align: 'center',
      title: 'Loại',
      render: record => renderTag(record.type),
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      render: record => (
        <Space size="middle" onClick={e => e.stopPropagation()}>
          <Button
            loading={isOpening && record.code === aclActive}
            type={'text'}
            style={{ color: blue }}
            onClick={() => handleAOpenApDung(record)}>
            Áp dụng
          </Button>
          {record.type === ACL_TYPE.DEFAULT ? (
            <Button type={'text'} danger disabled>
              Xóa
            </Button>
          ) : (
            <Button
              type={'text'}
              onClick={() => showConfirm(record.code)}
              danger>
              Xóa
            </Button>
          )}

          <Button
            onClick={() => handleCloneACL(record.code)}
            type={'text'}
            style={{ color: blue }}>
            Nhân bản
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý phân quyền</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Quản lý phân quyền'}
        hiddenGoBack>
        <Button onClick={() => history.push('/acl/create')} type={'primary'}>
          <PlusOutlined /> Tạo mới
        </Button>
      </PageTitle>
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <TableComponent
          rowKey={'code'}
          loading={isLoadingTable}
          columns={columns}
          dataSource={aclGroupListPO}
          onRow={record => {
            return {
              onClick: () => history.push(`/acl/view/${record.code}`),
            }
          }}
        />
      </ContentBlockWrapper>

      <SelectPeoplePopup
        isOpenCollapse
        noLoadSelectData
        allowChangeCompany={false}
        title={`Áp dụng quyền`}
        okText={'Áp dụng'}
        handleCancelSelectUser={() => {
          setIsVisibleSelectModal(false)
          selectPeopleStore.clearSelected()
          setAclActive(null)
        }}
        handleSubmitSelectUser={handleSubmitSelectUser}
        isVisibleSelectPeoplePopup={isVisibleSelectModal}
      />
    </DashboardLayout>
  )
}

ACLPage.propTypes = {}

export default inject('aclStore', 'selectPeopleStore')(observer(ACLPage))
