import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableComponent from '../../components/Common/TableComponent'
import { Button, Empty } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import ContentTemplateEditModal from './ContentTemplateEditModal'

const ContentTemplateListItem = props => {
  const { contentTemplateStore } = props
  const { items } = contentTemplateStore

  const [isVisibleUpdateModal, setVisibleUpdateModal] = useState(false)
  const [keyContentTemplate, setKeyContentTemplate] = useState()

  useEffect(() => {
    contentTemplateStore.load()

    return () => {
      contentTemplateStore.clear()
    }
  }, [])

  const handleOpenUpdateModal = key => {
    setKeyContentTemplate(key)
    setVisibleUpdateModal(true)
  }

  const onClose = () => {
    setVisibleUpdateModal(false)
  }

  const tableColumns = [
    {
      title: 'Mã văn bản mẫu',
      render: record => <span>{record.key}</span>,
    },
    {
      title: 'Văn bản mẫu',
      render: record => <span>{record.label}</span>,
    },
    {
      title: 'Thao tác',
      width: 150,
      align: 'center',
      render: record => (
        <div>
          <Button
            icon={<EditOutlined size={20} />}
            onClick={() => handleOpenUpdateModal(record.key)}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <TableComponent
        rowKey={'key'}
        dataSource={items}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
      />

      <ContentTemplateEditModal
        keyContentTemplate={keyContentTemplate}
        handleCloseModal={onClose}
        isVisible={isVisibleUpdateModal}
      />
    </>
  )
}

ContentTemplateListItem.propTypes = {}

export default inject('contentTemplateStore')(observer(ContentTemplateListItem))
