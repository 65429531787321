import React from 'react'
import { TableWrapper } from './Table'
import { Table } from 'antd'

/**
 * @param {import('antd').TableProps} param0 
 * @returns 
 */
const TableComponent = ({...props}) => {
  return (
    <TableWrapper>
      <Table {...props} />
    </TableWrapper>
  )
}

TableComponent.propTypes = {}

export default TableComponent
