import { message } from 'antd'
import { useCallback } from 'react'

export const useUpdatingRiskExecutor = ({
  riskStore,
  riskUnitUserStore,
  loadingAnimationStore,
  type,
  riskId = undefined,
}) => {
  const validateRiskExecutorInput = useCallback(() => {
    switch (type) {
      case 'NDDPV':
        if (riskUnitUserStore.selectedRiskExecutors.tier1.length === 0) {
          throw new Error(
            'Vui lòng chọn người đại diện phần vốn thực hiện phiếu ở tuyến 1'
          )
        }

        if (riskUnitUserStore.selectedRiskExecutors.tier2.length === 0) {
          throw new Error(
            'Vui lòng chọn người đại diện phần vốn thực hiện phiếu ở tuyến 2'
          )
        }

        if (riskUnitUserStore.selectedRiskExecutors.tier3.length === 0) {
          throw new Error(
            'Vui lòng chọn người đại diện phần vốn thực hiện phiếu ở tuyến 3'
          )
        }

        break
      case 'DVPT':
        if (!riskUnitUserStore.customRiskExecutorFullname.tier1) {
          throw new Error(
            'Vui lòng nhập họ tên chuyên viên thực hiện ở tuyến 1'
          )
        }
        if (riskUnitUserStore.selectedRiskExecutors.tier3.length === 0) {
          throw new Error(
            'Vui lòng chọn đơn vị thực hiện phần vốn chúng ở tuyến 3'
          )
        }
        break

      case 'CQVP':
      default:
        break
    }
  }, [
    type,
    riskUnitUserStore.customRiskExecutorFullname.tier1,
    riskUnitUserStore.selectedRiskExecutors.tier1.length,
    riskUnitUserStore.selectedRiskExecutors.tier2.length,
    riskUnitUserStore.selectedRiskExecutors.tier3.length,
  ])

  const getAddingAndRemovingTier1Executors = useCallback(() => {
    const newSelectedItems = riskUnitUserStore.selectedRiskExecutors.tier1
    const allOldItems = riskStore.riskDetail?.tiers[0]?.approvals[0]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems.filter(
      item =>
        !oldSelectedItems.some(oldItem => oldItem.username === item.username)
    )
    const removingItems = oldSelectedItems.filter(
      item =>
        !newSelectedItems.some(newItem => newItem.username === item.username)
    )

    if (riskUnitUserStore.customRiskExecutorFullname.tier1 != null) {
      const existedCustomRiskExecutor = allOldItems.find(item => !item.username)
      const newCustomExecutorFullname =
        riskUnitUserStore.customRiskExecutorFullname.tier1

      if (existedCustomRiskExecutor) {
        removingItems.push(existedCustomRiskExecutor)
      }

      if (newCustomExecutorFullname.length > 0) {
        addingItems.push({
          full_name: newCustomExecutorFullname,
        })
      }
    }

    return {
      addingItems: addingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
      removingItems: removingItems.map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      })),
    }
  }, [
    riskStore.riskDetail,
    riskUnitUserStore.selectedRiskExecutors.tier1,
    riskUnitUserStore.customRiskExecutorFullname.tier1,
  ])

  const updateTier1RiskExecutor = useCallback(async () => {
    if (!riskId) {
      throw new Error('Không tìm thấy mã rủi ro')
    }

    try {
      // validateRiskExecutorInput()

      const {
        addingItems,
        removingItems,
      } = getAddingAndRemovingTier1Executors()
      const riskTierCode =
        riskStore.riskDetail?.tiers[0]?.approvals[0]?.peoples[0]
          ?.risk_tier_approval_code ||
        riskStore.riskDetail?.tiers[0]?.approvals[0]?.code

      loadingAnimationStore.showSpinner(true)
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(error.message ?? `Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [
    loadingAnimationStore,
    riskStore,
    riskId,
    getAddingAndRemovingTier1Executors,
    validateRiskExecutorInput,
  ])

  const getAddingAndRemovingTier2RiskExecutors = useCallback(() => {
    const newSelectedItems = riskUnitUserStore.selectedRiskExecutors.tier2
    const allOldItems = riskStore.riskDetail?.tiers[1]?.approvals[0]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems
      .filter(
        item =>
          !oldSelectedItems.some(oldItem => oldItem.username === item.username)
      )
      .map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      }))
    const removingItems = oldSelectedItems
      .filter(
        item =>
          !newSelectedItems.some(newItem => newItem.username === item.username)
      )
      .map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      }))

    return {
      addingItems,
      removingItems,
    }
  }, [
    riskStore.riskDetail.tiers,
    riskUnitUserStore.selectedRiskExecutors.tier2,
  ])

  const updateTier2RiskExecutor = useCallback(async () => {
    if (!riskId) {
      throw new Error('Không tìm thấy mã rủi ro')
    }

    try {
      // validateRiskExecutorInput()

      const {
        addingItems,
        removingItems,
      } = getAddingAndRemovingTier2RiskExecutors()
      const riskTierCode =
        riskStore.riskDetail?.tiers[1]?.approvals[0]?.peoples[0]
          ?.risk_tier_approval_code ||
        riskStore.riskDetail?.tiers[1]?.approvals[0]?.code

      loadingAnimationStore.showSpinner(true)
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(error.message ?? `Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [
    loadingAnimationStore,
    riskStore,
    riskId,
    getAddingAndRemovingTier2RiskExecutors,
    validateRiskExecutorInput,
  ])

  const getAddingAndRemovingTier3RiskExecutors = useCallback(() => {
    const newSelectedItems = riskUnitUserStore.selectedRiskExecutors.tier3
    const allOldItems = riskStore.riskDetail?.tiers[2]?.approvals[0]?.peoples
    const oldSelectedItems = allOldItems.filter(person => !!person.username)
    const addingItems = newSelectedItems
      .filter(
        item =>
          !oldSelectedItems.some(oldItem => oldItem.username === item.username)
      )
      .map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      }))
    const removingItems = oldSelectedItems
      .filter(
        item =>
          !newSelectedItems.some(newItem => newItem.username === item.username)
      )
      .map(item => ({
        assignee_code: item.username,
        assignee_fullname: item.full_name,
        department_code: item.department_code,
      }))

    return {
      addingItems,
      removingItems,
    }
  }, [
    riskStore.riskDetail.tiers,
    riskUnitUserStore.selectedRiskExecutors.tier3,
  ])

  const updateTier3RiskExecutor = useCallback(async () => {
    if (!riskId) {
      throw new Error('Không tìm thấy mã rủi ro')
    }

    try {
      // validateRiskExecutorInput()

      const {
        addingItems,
        removingItems,
      } = getAddingAndRemovingTier3RiskExecutors()
      const riskTierCode =
        riskStore.riskDetail?.tiers[2]?.approvals[0]?.peoples[0]
          ?.risk_tier_approval_code ||
        riskStore.riskDetail?.tiers[2]?.approvals[0]?.code

      loadingAnimationStore.showSpinner(true)
      await riskStore.updateTierApproval(riskTierCode, {
        approval_people: {
          remove_items: removingItems,
          new_items: addingItems,
        },
      })

      await riskStore.getRiskDetail(riskId)
      message.success(`Cập nhật thành công`)
    } catch (error) {
      console.error(error)
      message.error(error.message ?? `Có lỗi xảy ra`)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [
    loadingAnimationStore,
    riskStore,
    riskId,
    getAddingAndRemovingTier3RiskExecutors,
    validateRiskExecutorInput,
  ])

  return {
    validateRiskExecutorInput,
    updateTier1RiskExecutor,
    updateTier2RiskExecutor,
    updateTier3RiskExecutor
  }
}
