import { action, observable } from 'mobx'
// Request
import { TimekeepingSyncRequest } from '../requests/TimekeepingSyncRequest'

class TimekeepingStore {
  @action timekeepingSync = () => {
    return new Promise((resolve, reject) => {
      TimekeepingSyncRequest.timekeepingSync()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new TimekeepingStore()
