import React from 'react'
import { useRef } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd'
import RichEditor from '../../components/RichEditor/RichEditor'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CloseOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const { Option } = Select
const RiskUpdatePopup = ({
  handleCancel,
  isVisible,
  riskStore,
  getRiskDetail,
  departmentStore,
  type,
}) => {
  const { riskUnits, riskDetail, updateRisk } = riskStore
  const { riskId } = useParams()
  const { departmentList } = departmentStore
  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  useEffect(() => {
    form.setFieldsValue({
      title: riskDetail.title,
      risk_unit_code: riskDetail.risk_unit_code,
    })
  }, [riskDetail])

  useEffect(() => {
    ;(async () => {
      try {
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const handleUpdateRisk = async value => {
    const submitData = {
      ...value,
      content: CONTENT_REF.current.editor.getData(),
    }
    try {
      handleCancel()
      await updateRisk(riskId, submitData)
      await getRiskDetail()
      message.success('Cập nhật thành công!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
      message.error(err.vi || 'Cập nhật thất bại!')
    }
  }
  return (
    <Modal
      width={800}
      style={{ top: 40, zIndex: 500 }}
      title={'Cập nhật phiếu kiểm soát rủi ro'}
      visible={isVisible}
      maskClosable={false}
      onCancel={() => {
        handleCancel()
      }}
      footer={null}>
      <Form form={form} layout={'vertical'} onFinish={handleUpdateRisk}>
        <Row type={'flex'} gutter={15}>
          <Col span={12}>
            <Form.Item
              name={'title'}
              label={'Tiêu đề:'}
              rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}>
              <Input placeholder={'Tiêu đề phiếu kiểm soát rủi ro'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'risk_unit_code'}
              label={'Đơn vị:'}
              rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}>
              <Select placeholder={'Đơn vị'}>
                {type !== 'CQVP'
                  ? riskUnits?.map(unit => (
                      <Option
                        key={unit.code}
                        name={'risk_unit_code'}
                        value={unit.code}>
                        {unit.name}
                      </Option>
                    ))
                  : Array.isArray(departmentList) &&
                    departmentList?.map(department => (
                      <Option
                        key={department.code}
                        name={'department_code'}
                        value={department.code}>
                        {department.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={'Nội dung:'} name={'content'}>
          <RichEditor
            EDITOR_REF={CONTENT_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={riskDetail.content && riskDetail.content}
          />
        </Form.Item>
        <FormActionFooter style={{ marginBottom: 0 }}>
          <Button
            icon={<CloseOutlined />}
            danger
            onClick={() => {
              handleCancel()
            }}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Cập nhật
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'selectPeopleStore',
  'authenticationStore',
  'loadingAnimationStore',
  'riskStore',
  'departmentStore'
)(observer(RiskUpdatePopup))
