import React, { useEffect, useLayoutEffect, useRef } from 'react'
import useImage from 'use-image'

import { Image, Transformer } from 'react-konva'

const RectangleBox = props => {
  const { shapeProps, isSelected, onChange, urlImg } = props

  const [image] = useImage(urlImg)
  const shapeRef = useRef()
  const trRef = useRef()

  useEffect(() => {
    if (isSelected) {
      // shapeRef.current.cache();
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current)
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  useLayoutEffect(() => {
    shapeRef.current.cache()
  }, [shapeProps, image, isSelected])

  return (
    <>
    {/* <Rect { ...{
        x: shapeProps.x + 150,
        y: shapeProps.y,
        width: 145,
        height: 36,
        fill: '#ccc',
        // width: 145,
        // height: 150 / ratioImg,
        id: 'rect1',
        border: '1px solid red',
        
        
      }} 
      opacity={0.5}
      draggable={true}
      /> */}
      <Image
        image={image}
        // onClick={onSelect}
        ref={shapeRef}
        // filters={[Konva.Filters.Blur]}
        // blurRadius={10}
        {...shapeProps}
        draggable={true}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()
          // we will reset it back
          node.scaleX(1)
          node.scaleY(1)
          node.width(Math.max(5, node.width() * scaleX))
          node.height(Math.max(node.height() * scaleY))
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: node.width(),
            height: node.height(),
          })
        }}
        opacity={0.8}
      />
      {isSelected && (
        <Transformer
          enabledAnchors={[]}
          rotateEnabled={false}
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
      
    </>
  )
}

export default RectangleBox
